<template>
  <div class="card position-sticky top-1 mt-4">
    <ul class="nav flex-column bg-white border-radius-lg p-3">
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#profile"
        >
          <i class="ni ni-spaceship me-2 text-dark opacity-6"></i>
          <span class="text-sm">Profile</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#basic-info"
        >
          <i class="ni ni-books me-2 text-dark opacity-6"></i>
          <span class="text-sm">Basic Info</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#referral"
        >
          <i class="ni ni-tag me-2 text-dark opacity-6"></i>
          <span class="text-sm">Referral</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#client-support-team-group"
        >
          <i class="ni ni-atom me-2 text-dark opacity-6"></i>
          <span class="text-sm">Client Support Team</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#addresses-group"
        >
          <i class="ni ni-pin-3 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Addresses</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#delivery-preferences"
        >
          <i class="ni ni-delivery-fast me-2 text-dark opacity-6"></i>
          <span class="text-sm">Delivery</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#sessions"
        >
          <i class="ni ni-watch-time me-2 text-dark opacity-6"></i>
          <span class="text-sm">Sessions</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#account-status"
        >
          <i class="ni ni-settings-gear-65 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Deactivate Account</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "EditUserSideNav",
};
</script>
