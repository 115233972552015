import API from "@/services/api";
import apiProfile from "@/services/apiProfile";
export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    current_role: "",
  },
  getters: {
    getCurrentRole(state) {
      return state.current_role;
    },
    getUserRoles(state) {
      return state.user.roles.data
    },
    getCurrentUserId(state) {
      return state.user.id;
    },
    hasRole: (state) => (role) => {
      const userRoles = state.user.roles.data.map((role) => role.id);
      return userRoles.includes(role);
    },
  },
  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setCurrentRole(state, value) {
      state.current_role = value;
    },
  },
  actions: {
    login({ commit }) {
      commit("setAuthenticated", true);
    },
    logout({ commit }) {
      commit("setAuthenticated", false);
      commit("setUser", {});
      commit("setCurrentRole", "");
    },
    async setUser({ commit, dispatch }) {
      return apiProfile.get()
        .then(async ({ data }) => {
          const updatedUser = await dispatch("setUserAbilities", {
            userData: data.data,
          });
          commit("setUser", updatedUser);
          commit("setCurrentRole", data.data.roles.data[0].id);
        })
        .catch(() => {
          commit("setAuthenticated", false);
          commit("setUser", {});
        })
        .finally(() => {});
    },
    setUserAbilities(context, { userData }) {
      let abilities = {};
      if (userData.permissions.data) {
        abilities = API.formatUserPermissionsToCaslAbilities(
          userData.permissions.data
        );
      }
      userData.abilities = abilities;
      return userData;
    },
  },
};
