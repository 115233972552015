<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->

<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <argon-alert
    v-if="isDevelopment"
    color="danger"
    class="text-center position-fixed top-0 w-100"
  >
    <strong>TEST APP!</strong>
  </argon-alert>
  <sidenav v-if="showSidenav" />
  <main
    class="main-content position-relative max-height-vh-100 h-100"
    :class="{ 'pt-5': isDevelopment }"
  >
    <navbar v-if="showNavbar" />
    <router-view />
    <app-footer v-show="showFooter" />
    <configurator
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/components/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
import ArgonAlert from "./components/ArgonAlert.vue";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
    ArgonAlert,
  },
  computed: {
    ...mapState([
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
    isDevelopment() {
      return process.env.VUE_APP_API_URL.includes("kcalapps.com");
    },
  },
  created() {
    this.showDefaultLayout();
  },
  methods: {
    ...mapMutations(["showDefaultLayout"]),
  },
};
</script>