<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-2">{{ cardHeader }}</h6>
      </div>
    </div>
    <div
      class="card-body"
      :class="{
        scroll: !items.some((item) => item.NQStatus),
      }"
    >
      <div
        :class="
          items.length
            ? 'table-responsive-height-300 '
            : '' + ' table-responsive'
        "
      >
        <table :id="tblId" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                v-for="(header, index) of headers"
                :key="index"
                :class="`text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ${
                  index !== 0 ? 'ps-2' : ''
                }`"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <!-- Nutritionist Table body -->
          <tbody v-if="items.some((item) => item.NQStatus) && items.length > 0">
            <tr
              v-for="(
                {
                  brand: { logo, name, phone },
                  plan,
                  expiry,
                  sequence,
                  NQStatus,
                  id,
                  plan_id,
                },
                index
              ) of items"
              :key="index"
            >
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img :src="logo" class="avatar avatar-sm me-3" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs">{{ name }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ phone }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ plan }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ expiry }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ sequence }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ NQStatus }}</p>
              </td>
              <td class="text-left">
                <router-link
                  :to="{
                    name: 'Client Plan',
                    params: { id: id, planId: plan_id },
                  }"
                  ><i
                    class="far fa-edit text-sm opacity-10 text-dark"
                    aria-hidden="true"
                  ></i
                ></router-link>
              </td>
            </tr>
          </tbody>

          <!-- Retention, Bounce back Table body -->
          <tbody v-else-if="items.length > 0">
            <tr
              v-for="(
                { brand: { logo, name, phone }, plan, expiry, sequence, id },
                index
              ) of items"
              :key="index"
            >
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img :src="logo" class="avatar avatar-sm me-3" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs">{{ name }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ phone }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ plan }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ expiry }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ sequence }}</p>
              </td>
              <td class="align-middle">
                <router-link
                  :to="{ name: 'Client Profile', params: { id: id } }"
                  ><i
                    class="ni ni-settings text-sm opacity-10 text-dark"
                    aria-hidden="true"
                  ></i
                ></router-link>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <th
                :colspan="headers.length"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
              >
                No Records Found!
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralTable",
  props: {
    tblId: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      progress: Number,
      budget: String,
      brand: {
        type: Object,
        logo: String,
        name: String,
      },
    },
    cardHeader: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
.table-responsive.table-responsive-height-300 {
  height: 300px;
  overflow-y: scroll;
}
</style>
