<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-12">
              <h5 class="font-weight-bolder">Bag check-in</h5>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <form @submit.prevent="onSubmit">
            <div class="row mt-3">
              <div class="col-md-4">
                <label for="bag-barcode">Scan or Enter Bag ID</label>
                <input
                  id="bag-barcode"
                  v-model="bag_barcode"
                  type="text"
                  class="form-control"
                  placeholder="Bag barcode"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-3">
                <submit-form-button :form-submitted="formSubmitted" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiBagMovement from "@/services/apiBagMovement";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "BagCheckin",
  components: {
    SubmitFormButton,
  },
  data() {
    return {
      formSubmitted: false,
      bag_barcode: "",
    };
  },
  methods: {
    async onSubmit() {
      this.formSubmitted = true;
      const response = await ApiBagMovement.checkin({
        bag_barcode: this.bag_barcode,
        _method: "patch",
      }).catch(handleError);
      if (response.status == 200) {
        showMessage("Bag check-in successfully", "success");
      } else {
        showMessage(response.message, "error");
      }
      this.bag_barcode = "";
      this.formSubmitted = false;
    },
  },
};
</script>
