<template>
  <div id="addresses-group" class="card mt-4">
    <div class="py-3 card-header d-flex align-items-center">
      <div class="d-flex align-items-center">
        <h5>Addresses</h5>
      </div>
      <div class="text-end ms-auto">
        <argon-button
          class="mb-0"
          color="success"
          variant="gradient"
          size="sm"
          @click="addClientAddress"
          >Add address
        </argon-button>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="nav-wrapper position-relative">
        <ul class="p-1 bg-transparent nav nav-pills" role="tablist">
          <client-address-nav
            v-for="(address, key) in clientAddresses"
            :key="key"
            v-model:type="clientAddresses[key].type"
            :address-key="key"
            :active="key == 0 ? ' active' : ''"
          />
        </ul>
      </div>
      <div id="address-tab-content" class="tab-content">
        <client-address
          v-for="(address, key) in clientAddresses"
          :key="key"
          v-model:id="clientAddresses[key].id"
          v-model:city-id="clientAddresses[key].city_id"
          v-model:area-id="clientAddresses[key].area_id"
          v-model:type="clientAddresses[key].type"
          v-model:street="clientAddresses[key].street"
          v-model:landmark="clientAddresses[key].landmark"
          v-model:building-name="clientAddresses[key].building_name"
          v-model:flat-number="clientAddresses[key].flat_number"
          v-model:latitude="clientAddresses[key].latitude"
          v-model:longitude="clientAddresses[key].longitude"
          v-model:notes="clientAddresses[key].notes"
          :active="key === 0 ? ' active show' : ''"
          :address-key="key"
          :area-name="clientAddresses[key]['area.name']"
          :city-name="clientAddresses[key]['city.name']"
          :client-id="clientId"
          :address-areas="addressAreas"
          :address-cities="addressCities"
          :address-types="addressTypes"
          :show-delete-button="clientAddresses.length > 1"
          @create-client-address="setClientAddresses"
          @update-client-address="setClientAddresses"
          @delete-client-address="deleteClientAddress"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiAddresses from "@/services/apiAddresses";
import { showMessage } from "@/assets/js/show-message";
import setNavPills from "@/assets/js/nav-pills.js";
import ClientAddress from "@/views/pages/Clients/components/Address";
import ClientAddressNav from "@/views/pages/Clients/components/AddressNav";
import ArgonButton from "@/components/ArgonButton";
import { handleError } from "@/lib/helpers";

export default {
  name: "AddressesCard",
  components: {
    ClientAddress,
    ClientAddressNav,
    ArgonButton,
  },
  props: {
    initialAddresses: {
      type: Array,
      default: () => [],
    },
    addressAreas: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["clientAddressesChanged"],
  data() {
    return {
      clientAddresses: [],
      addressCities: [],
      addressTypes: [],
      clientId: parseInt(this.$route.params.id),
    };
  },
  async mounted() {
    this.addressCities = await ApiAddresses.getCities("start=0&length=-1");
    this.addressTypes = ApiAddresses.addressTypeChoices();
    this.clientAddresses = this.initialAddresses;
    setNavPills();
  },
  methods: {
    addClientAddress() {
      this.clientAddresses.push({
        id: 0,
        city_id: "",
        area_id: "",
        building_name: "",
        flat_number: "",
        landmark: "",
        latitude: process.env.VUE_APP_ADDRESS_LATITUDE,
        longitude: process.env.VUE_APP_ADDRESS_LONGITUDE,
        notes: "",
        street: "",
        type: "Home",
      });
      this.$nextTick(function () {
        this.selectClientAddressNavTab(this.clientAddresses.length);
      });
    },
    selectClientAddressNavTab(key) {
      const activeLink = document.querySelector(
        "#addresses-group .nav-pills li .nav-link.active"
      );
      if (activeLink) {
        activeLink.classList.remove("active");
      }
      const activePane = document.querySelector(
        "#addresses-group .tab-content .tab-pane.active.show"
      );
      if (activePane) {
        activePane.classList.remove("active", "show");
      }
      const newActiveLink = document.querySelector(
        "#addresses-group .nav-pills li:nth-child(" + key + ") .nav-link"
      );
      if (newActiveLink) {
        newActiveLink.dispatchEvent(new Event("click"));
        window.dispatchEvent(new Event("resize"));
      }
    },
    async setClientAddresses(action) {
      const response = await ApiAddresses.get(this.clientId).catch(handleError);
      if (response.status === 200) {
        this.clientAddresses = response.data.data;
        if (action && action === "do next tick") {
          this.$nextTick(function () {
            this.selectClientAddressNavTab(1);
          });
        }
      } else {
        showMessage(response.message, "error");
      }
      this.$emit("clientAddressesChanged", this.clientAddresses);
    },
    async deleteClientAddress() {
      showMessage("Success. Client address deleted successfully.", "success");
      await this.setClientAddresses("do next tick");
    },
  },
};
</script>
