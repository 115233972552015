<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="client-name">Client name</label>
          <input
            id="client-name"
            v-model="clientName"
            type="search"
            class="form-control"
            placeholder="Client name"
          />
        </div>
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="status">Plan status</label>
          <select id="status" v-model="status" class="form-control" multiple />
        </div>
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="account-manager"
            >Account manager</label
          >
          <select
            id="account-manager"
            v-model="accountManagerId"
            class="form-control"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="created-at"
            >Plan start date from</label
          >
          <flat-pickr
            id="created-at"
            v-model="created_at"
            class="form-control"
            placeholder="Client created at"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-outline-success btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Phone
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Account manager
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { dateFormat } from "@/lib/dateHelper";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import ApiClient from "@/services/apiClient";
export default {
  name: "ListTable",
  components: {
    flatPickr,
  },
  data() {
    return {
      clientName: "",
      clientEmail: "",
      clientMobileNumber: "",
      accountManagerId: 0,
      status: [],
      created_at: "",
      accountManagers: [],
    };
  },
  computed: {},
  async mounted() {
    const appInstance = this;
    await this.initClientStatusChoices();
    await this.setAccountManagers();
    await this.initAccountManagerChoices();
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    $("#list-table").DataTable({
      serverSide: true,
      pagingType: "simple_numbers",
      language: {
        paginate: {
          previous: "‹",
          next: "›",
        },
      },
      columns: [
        {
          data: "id",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
        },
        {
          data: null,
          render: function (data) {
            if (appInstance.$ability.can("view", "clients")) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm"><a href="' +
                appInstance.$router.resolve({
                  name: "Client Profile",
                  params: {
                    id: data.id,
                  },
                }).href +
                '">' +
                data.name +
                "</a> </h6></div>"
              );
            }
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data.name +
              "</h6></div>"
            );
          },
        },
        {
          data: "email",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: "mobile_number",
          render: function (data) {
            return '<p class="text-secondary mb-0 text-sm">' + data + "</p>";
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == "Active" ? "bg-success" : "bg-danger") +
              '"></i><span class="text-dark text-xs">' +
              data +
              "</span></span>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: null,
          render: function (data) {
            return (
              '<p class="text-secondary mb-0 text-sm">' +
              data["accountManager.name"] +
              "</p>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: null,
          render: function (data) {
            let button = "";
            if (appInstance.$ability.can("view", "clients")) {
              button =
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Client Profile",
                  params: {
                    id: data.id,
                  },
                }).href +
                '"><i class="fas fa-eye text-secondary"></i></a> ';
            }
            if (appInstance.$ability.can("update", "clients")) {
              button +=
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Edit Client",
                  params: {
                    id: data.id,
                  },
                }).href +
                '" class="mx-3"><i class="fas fa-user-edit text-secondary"></i></a> ';
            }
            return button;
          },
          className: "align-middle text-center text-sm",
        },
      ],
      ajax: function (data, callback) {
        data.filter = {};
        if (appInstance.created_at.length > 0) {
          data.filter.created_at = appInstance.created_at;
        }
        if (appInstance.clientName.length > 0) {
          data.filter.name = appInstance.clientName;
        }
        if (appInstance.accountManagerId > 0) {
          data.filter.account_manager_id = appInstance.accountManagerId;
        }
        if (appInstance.clientEmail.length > 0) {
          data.filter.email = appInstance.clientEmail;
        }
        if (appInstance.clientMobileNumber.length > 0) {
          data.filter.mobile_number = appInstance.clientMobileNumber;
        }
        if (appInstance.status.length > 0) {
          data.filter.status = appInstance.status;
        }
        ApiClient.list($.param(data))
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          })
          .finally(() => {});
      },
    });
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    dateFormat,
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    async initClientStatusChoices() {
      const id = "status";
      await initChoices(id, {
        choices: formatDataToChoicesJs(ApiClient.getStatusChoices(), [], {
          value: "value",
          label: "label",
          id: "id",
        }),
        placeholder: true,
        placeholderValue: "Select client status",
        duplicateItemsAllowed: false,
      });
    },
    async setAccountManagers() {
      const response = await API.getAccountManagers();
      if (response.status === 200) {
        this.accountManagers = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async initAccountManagerChoices() {
      const id = "account-manager";
      await initChoices(id, {
        choices: formatDataToChoicesJs(
          this.accountManagers,
          "Select account manager"
        ),
        placeholder: true,
        placeholderValue: "Select account manager",
        duplicateItemsAllowed: false,
        maxItemCount: 1,
      });
    },
  },
};
</script>
