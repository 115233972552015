<template>
  <div>
    <div class="row align-items-center">
      <div class="col-6 col-sm-auto">
        <label class="form-label">Start Date</label>
        <div class="form-group">
          <flat-pickr
            v-model="filter.start_date"
            class="form-control"
            placeholder="Please select start date"
            :config="localConfigs.start_date"
            @on-change="onstart_dateChange"
          ></flat-pickr>
        </div>
      </div>
      <div class="col-6 col-sm-auto">
        <label class="form-label">End Date</label>
        <div class="form-group">
          <flat-pickr
            v-model="filter.end_date"
            class="form-control"
            placeholder="Please select end date"
            :config="localConfigs.end_date"
            @on-change="onend_dateChange"
          ></flat-pickr>
        </div>
      </div>
      <div class="col-12 col-md-3 col-sm-6">
        <label>Status</label>
        <div class="form-group">
          <select
            id="refund-request-status"
            v-model="filter.status"
            class="form-control"
          ></select>
        </div>
      </div>
      <div class="col-12">
        <argon-switch
          id="online-refund"
          v-model:checked="filter.online"
          name="online_refund"
          class="form-check form-switch mb-0 d-flex align-items-center"
          >Online refunds</argon-switch
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <button
          v-if="showFilterButton"
          class="btn btn-success text-white btn-sm mb-0"
          @click="emitFilterEvent"
        >
          Filter
        </button>
        <button
          class="btn btn-success text-white btn-sm mb-0"
          :class="showFilterButton ? 'ms-3' : ''"
          @click="emitDownloadEvent"
        >
          Download Excel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import "flatpickr/dist/flatpickr.css";
import { handleError } from "@/lib/helpers";
import apiMiscList from "@/services/apiMiscList";
import { showMessage } from "@/assets/js/show-message";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
export default {
  name: "RefundRequestsListTableFilter",
  components: {
    flatPickr,
    ArgonSwitch,
  },
  props: {
    initialFilter: {
      type: Object,
      required: true,
    },
    configs: {
      type: Object,
      default: () => ({}),
    },
    showFilterButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["start_dateChange", "end_dateChange", "download", "filter"],
  data() {
    return {
      statusChoices: [],
      filter: {
        start_date: "",
        end_date: "",
        status: "Pending",
        online: true,
      },
      localConfigs: {
        start_date: Object.assign({}, this.configs.start_date),
        end_date: Object.assign({}, this.configs.end_date),
      },
    };
  },
  async mounted() {
    this.filter = this.initialFilter;
    await this.setStatusChoices();
    await this.initStatusChoices();
  },
  methods: {
    async setStatusChoices() {
      this.statusChoices = [];
      const response = await apiMiscList
        .invoiceRefundRequestStatus()
        .catch(handleError);
      if (response.status == 200) {
        this.statusChoices = formatDataToChoicesJs(response.data.data, [], {
          value: "value",
          label: "value",
          id: "value",
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    async initStatusChoices() {
      const id = "refund-request-status";
      await initChoices(
        id,
        {
          choices: this.statusChoices,
          placeholder: true,
          placeholderValue: "",
          duplicateItemsAllowed: false,
          searchEnabled: false,
        },
        this.filter.status
      );
    },
    onstart_dateChange(selectedDates, dateStr) {
      this.localConfigs.end_date.minDate = dateStr;
      this.$emit("start_dateChange", dateStr);
    },
    onend_dateChange(selectedDates, dateStr) {
      this.localConfigs.start_date.maxDate = dateStr;
      this.$emit("end_dateChange", dateStr);
    },
    emitDownloadEvent() {
      this.$emit("download", this.filter);
    },
    emitFilterEvent() {
      this.$emit("filter", this.filter);
    },
  },
};
</script>
