<template>
  <div class="row">
    <div class="text-right col-12 d-flex flex-column justify-content-center">
      <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
        <router-link
          :to="{ name: 'New Addon' }"
          class="btn btn-white text-success"
        >
          New Addon
        </router-link>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Value
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Description
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Type
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "ListTable",
  mounted() {
    const appInstance = this;
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    $("#list-table").DataTable({
      serverSide: true,
      pagingType: "simple_numbers",
      language: {
        paginate: {
          previous: "‹",
          next: "›",
        },
      },
      columns: [
        {
          data: "id",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
          width: "60px",
        },
        {
          data: "name",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
        },
        {
          data: "value",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
        },
        {
          data: "description",
          searchable: false,
          sortable: false,
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
        },
        {
          data: "type",
          searchable: false,
          sortable: false,
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
              data +
              "</h6></div>"
            );
          },
        },
        {
          data: "status",
          render: function (data) {
            return (
              '<span class="badge badge-dot me-4"><i class="' +
              (data == "Active" ? "bg-success" : "bg-danger") +
              '"></i><span class="text-dark text-xs">' +
              data +
              "</span></span>"
            );
          },
          className: "align-middle text-center text-sm",
        },
        {
          data: null,
          render: function (data) {
            let button = "a";
            if (appInstance.$ability.can("update", "addons")) {
              button =
                '<div class="d-flex flex-column justify-content-center">' +
                '<h6 class="mb-0 text-sm">' +
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Edit Addons",
                  params: {
                    id: data.id,
                  },
                }).href +
                '" class="mx-3"><i class="far fa-edit text-secondary"></i></a>' +
                "</h6>" +
                "</div>";

              button +=
                '<a href="' +
                appInstance.$router.resolve({
                  name: "View Addons",
                  params: {
                    id: data.id,
                  },
                }).href +
                '" class="mx-3"><i class="fas fa-eye text-secondary"></i></a>';
            }
            return button;
          },
          className: "align-middle text-center text-sm",
        },
      ],
      ajax: function (data, callback) {
        API.getAddons($.param(data))
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          });
      },
    });
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
};
</script>
