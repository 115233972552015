import { httpApi } from "@/services/httpApi";

class ApiClientDailyMenu {
  async getClientDeliveryMenuSubstitutes(clientId, deliveryId, menuId) {
    return await httpApi.get(
      "api/v1/clients/" +
        clientId +
        "/deliveries/" +
        deliveryId +
        "/menu/" +
        menuId +
        "/substitutes"
    );
  }
  async getClientDeliveryMenuSubstitutesOtherPlans(
    clientId,
    deliveryId,
    menuId
  ) {
    return await httpApi.get(
      "api/v1/clients/" +
        clientId +
        "/deliveries/" +
        deliveryId +
        "/menu/" +
        menuId +
        "/substitutes/from-other-plan-cycles"
    );
  }
  async saveSingleMenuItem(id, delivery_id, data) {
    return await httpApi.post(
      "/api/v1/clients/" + id + "/deliveries/" + delivery_id + "/single-menu",
      data
    );
  }
  async saveMenuCustomizations(id, delivery_id, menu_id, data) {
    return await httpApi.post(
      "/api/v1/clients/" +
        id +
        "/deliveries/" +
        delivery_id +
        "/menu/" +
        menu_id +
        "/customizations",
      data
    );
  }
  async revertToCycleMenu(client_id, delivery_id, menu_id) {
    return await httpApi.post(
      "/api/v1/clients/" +
        client_id +
        "/deliveries/" +
        delivery_id +
        "/menu/" +
        menu_id +
        "/revert-to-cycle-menu"
    );
  }
}

export default new ApiClientDailyMenu();
