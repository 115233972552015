import { httpApi } from "@/services/httpApi";

class ApiClientDelivery {
  async info(delivery_barcode) {
    return await httpApi.get(
      "/api/v1/clients/delivery-info/" + delivery_barcode
    );
  }
  async updateStatus(client_id, delivery_id, data) {
    return await httpApi.post(
      "api/v1/clients/" +
        client_id +
        "/deliveries/" +
        delivery_id +
        "/update-bag-delivery-status",
      data
    );
  }
  async changeDeliveryStatus(client_id, delivery_id, data) {
    return await httpApi.post(
      "api/v1/clients/" +
        client_id +
        "/deliveries/" +
        delivery_id +
        "/update-delivery-status",
      data
    );
  }
  async plotDeliveries(client_id, data) {
    return await httpApi.post(
      "api/v1/clients/" + client_id + "/deliveries/plot",
      data
    );
  }
  async makePaidWastage(client_id, delivery_id, data) {
    return await httpApi.post(
      "api/v1/clients/" +
        client_id +
        "/deliveries/" +
        delivery_id +
        "/make-paid-wastage",
      data
    );
  }
  async deleteMenuItem(client_id, delivery_id, menu_id) {
    return await httpApi.delete(
      "api/v1/clients/" +
        client_id +
        "/deliveries/" +
        delivery_id +
        "/menu/" +
        menu_id      
    );
  }
}

export default new ApiClientDelivery();
