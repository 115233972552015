<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="row">
      <div class="col-12 col-sm-6 mt-2">
        <label class="form-label">Start Date</label>
        <flat-pickr
          v-model="form.startDate"
          class="form-control"
          placeholder="Please select start date"
        ></flat-pickr>
        <error-display :errors="v$.form.startDate.$errors" />
      </div>
      <div class="col-12 col-sm-6 mt-2">
        <label class="form-label">Plot delivery days</label>
        <argon-input
          id="name"
          type="number"
          placeholder="Number of delivery days"
          :model-value="form.numberOfDays"
          :errors="v$.form.numberOfDays.$errors"
          @update:model-value="form.numberOfDays = $event"
        />
      </div>
    </div>
    <template #footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="$emit('close')">
        Close
      </button>
      <button class="btn bg-gradient-success" @click="handleClick">
        {{ buttonText }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import flatPickr from "vue-flatpickr-component";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import { validatorMessages, helpers, numeric } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import apiClientDelivery from "@/services/apiClientDelivery";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "PlotDeliveriesModal",
  components: {
    Modal,
    ErrorDisplay,
    ArgonInput,
    flatPickr,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    clientPlanDetailId: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: "Plot deliveries",
    },
    buttonText: {
      type: String,
      default: "Save",
    },
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change", "close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formSubmitted: false,
      form: { startDate: "", numberOfDays: "" },
    };
  },
  methods: {
    async handleClick() {
      const isValid = await this.v$.$validate();
      if (!isValid || this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const data = {
        start_date: this.form.startDate,
        number_of_days: this.form.numberOfDays,
        client_plan_detail_id: this.clientPlanDetailId,
      };
      const response = await apiClientDelivery
        .plotDeliveries(this.clientId, data)
        .catch(handleError);
      if (response.status == 200) {
        showMessage("Delivery days plotted successfully", "success");
        this.$emit("close");
        this.$emit("change");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {
        startDate: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        numberOfDays: {
          required: helpers.withMessage(validatorMessages.required, required),
          numeric: helpers.withMessage(validatorMessages.numeric, numeric),
        },
      },
    };
  },
};
</script>