import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";
class ApiClientActivity {
  async get(client_id, options) {
    let baseUrl = `/api/v1/clients/${client_id}/activity`;
    const queryParams = buildQueryParams(options);
    const url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url);
  }
}

export default new ApiClientActivity();
