<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="invoice-id">ID</label>
          <input
            id="invoice-id"
            v-model="invoice.id"
            type="search"
            class="form-control"
            placeholder="Invoice ID"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="invoice-client-name"
            >Client name</label
          >
          <input
            id="invoice-client-name"
            v-model="invoice.client_name"
            type="search"
            class="form-control"
            placeholder="Client name"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="min-total-amount"
            >Min total amount</label
          >
          <input
            id="min-total-amount"
            v-model="invoice.min_total_amount"
            type="search"
            class="form-control"
            placeholder="Min total amount"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="invoice-type">Invoice type</label>
          <select
            id="invoice-type"
            v-model="invoice.type"
            class="form-control"
          ></select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6 mt-3">
          <label class="visually-hidden" for="invoice-user-id"
            >Invoice owner</label
          >
          <select
            id="invoice-user-id"
            v-model="invoice.user_id"
            class="form-control"
            multiple
          ></select>
        </div>
        <div class="col-md-6 mt-3">
          <label class="visually-hidden" for="invoice-initiator-type"
            >Initiator Type</label
          >
          <select
            id="invoice-initiator-type"
            v-model="invoice.initiator_type"
            class="form-control"
            multiple
          ></select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6 mt-3">
          <label class="visually-hidden" for="invoice-client-purchase-type"
            >Client purchase type</label
          >
          <select
            id="invoice-client-purchase-type"
            v-model="invoice.client_purchase_type"
            class="form-control"
            multiple
          ></select>
        </div>
        <div class="col-md-6 mt-3">
          <label class="visually-hidden" for="invoice-source">Source</label>
          <select
            id="invoice-source"
            v-model="invoice.source"
            class="form-control"
            multiple
          ></select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="invoice-payment-source"
            >Payment source</label
          >
          <select
            id="invoice-payment-source"
            v-model="invoice.payment_source"
            class="form-control"
            multiple
          ></select>
        </div>
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="invoice-payment-status"
            >Payment Status</label
          >
          <select
            id="invoice-payment-status"
            v-model="invoice.payment_status"
            class="form-control"
            multiple
          ></select>
        </div>
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="invoice-status">Status</label>
          <select
            id="invoice-status"
            v-model="invoice.status"
            class="form-control"
            multiple
          ></select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Show invoices created between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="invoice-created-after"
            >Invoice created after</label
          >
          <flat-pickr
            id="invoice-created-after"
            v-model="invoice.created_after"
            class="form-control"
            placeholder="Invoice created after"
            :config="configs.created_after"
            @on-change="onCreatedAfterChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="invoice-created-before"
            >Invoice created before</label
          >
          <flat-pickr
            id="invoice-created-before"
            v-model="invoice.created_before"
            class="form-control"
            placeholder="Invoice created before"
            :config="configs.created_before"
            @on-change="onCreatedBeforeChange"
          />
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Show invoices paid between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="invoice-paid-after"
            >Invoice paid after</label
          >
          <flat-pickr
            id="invoice-paid-after"
            v-model="invoice.paid_after"
            class="form-control"
            placeholder="Invoice paid after"
            :config="configs.paid_after"
            @on-change="onPaidAfterChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="invoice-paid-before"
            >Invoice paid before</label
          >
          <flat-pickr
            id="invoice-paid-before"
            v-model="invoice.paid_before"
            class="form-control"
            placeholder="Invoice paid before"
            :config="configs.paid_before"
            @on-change="onPaidBeforeChange"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Invoice Owner
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amount
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payment date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import API from "@/services/api";
import ApiInvoices from "@/services/apiInvoices";
import { showMessage } from "@/assets/js/show-message";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { flatPickrConfig } from "@/lib/flatPickrHelper";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import { currencyFormat, handleError } from "@/lib/helpers";
import apiMiscList from "@/services/apiMiscList";

export default {
  name: "ListTable",
  components: {
    flatPickr,
  },
  data() {
    return {
      invoice: {
        id: "",
        client_name: "",
        min_total_amount: "",
        type: "",
        user_id: [],
        initiator_type: [],
        client_purchase_type: [],
        source: [],
        payment_source: [],
        payment_status: [],
        status: [],
        created_after: "",
        created_before: "",
        paid_after: "",
        paid_before: "",
      },
      configs: {
        created_after: {
          ...flatPickrConfig,
          maxDate: null,
        },
        created_before: {
          ...flatPickrConfig,
          minDate: null,
        },
        paid_after: {
          ...flatPickrConfig,
          maxDate: null,
        },
        paid_before: {
          ...flatPickrConfig,
          minDate: null,
        },
      },
    };
  },
  async mounted() {
    await this.initInvoiceTypeChoices();
    await this.initInvoiceInitiatorTypeChoices();
    await this.initInvoiceClientPurchaseTypeChoices();
    await this.initInvoiceSourceChoices();
    await this.initInvoicePaymentSourceChoices();
    await this.initInvoicePaymentStatusChoices();
    await this.initInvoiceStatusChoices();
    await this.initUserChoices();
    const appInstance = this;
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    $("#list-table").DataTable({
      serverSide: true,
      searching: false,
      pagingType: "simple_numbers",
      language: {
        paginate: {
          previous: "‹",
          next: "›",
        },
      },
      columns: [
        {
          data: "id",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">#' +
              data +
              "</span></div>"
            );
          },
        },
        {
          data: "created_at",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              moment(data).format("DD MMM YYYY") +
              "</span></div>"
            );
          },
        },
        {
          data: null,
          render: function (data) {
            const array = [
              "success",
              "primary",
              "secondary",
              "warning",
              "danger",
            ];
            let avatar =
              '<div class="avatar avatar-xs me-2 bg-gradient-' +
              array[Math.floor(Math.random() * array.length)] +
              '"><span>' +
              data.client_name[0] +
              "</span></div>";
            if (data.client_avatar) {
              avatar =
                '<div class="avatar avatar-xs me-2">' +
                '<img src="' +
                data.client_avatar +
                '" alt="client image">' +
                "</div>";
            }
            return (
              '<div class="d-flex align-items-center">' +
              avatar +
              '<span class="text-dark text-xs">' +
              data.client_name +
              "</span></div>"
            );
          },
          searchable: false,
          orderable: false,
        },

        {
          data: "owner_name",
          render: function (data) {
            return (
              '<div class="d-flex flex-column"><span class="text-dark text-xs">' +
              (data ? data : "") +
              "</span></div>"
            );
          },
          className: "align-middle  text-sm",
          orderable: false,
        },
        {
          data: "amount",
          render: function (data) {
            return (
              '<div class="d-flex flex-column"><span class="text-dark text-xs">' +
              currencyFormat(data) +
              "</span></div>"
            );
          },
          searchable: false,
          orderable: false,
          className: "align-middle  text-sm",
        },
        {
          data: "status",
          render: function (data) {
            let buttonData = ApiInvoices.getStatusButton(data);

            return (
              '<div class="d-flex align-items-center">' +
              '<button class="btn-md btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center btn mb-0 btn-outline-' +
              buttonData.class +
              '">' +
              '<i class="' +
              buttonData.icon +
              '" aria-hidden="true"></i></button>' +
              '<span class="text-dark text-xs">' +
              data +
              "</span></div>"
            );
          },
          searchable: false,
          orderable: false,
          className: "align-middle text-sm",
        },
        {
          data: "payment_time",
          render: function (data) {
            return (
              '<div class="d-flex flex-column justify-content-center"><span class="text-dark text-xs">' +
              (data ? moment(data).format("DD MMM YYYY") : "") +
              "</span></div>"
            );
          },
          className: "align-middle text-center text-sm",
          searchable: false,
          orderable: false,
        },
        {
          data: null,
          render: function (data) {
            let button = "";
            if (appInstance.$ability.can("view", "invoices")) {
              button +=
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Invoice",
                  params: {
                    id: data.id,
                  },
                }).href +
                '" class="mx-3"><i class="fas fa-eye text-secondary"></i></a> ';
            }
            return button;
          },
          className: "align-middle text-center text-sm",
          orderable: false,
        },
      ],
      ajax: function (data, callback) {
        data.filter = {};
        if (appInstance.invoice.id > 0) {
          data.filter["invoices.id"] = appInstance.invoice.id;
        }
        if (appInstance.invoice.client_name.length > 0) {
          data.filter["client.name"] = appInstance.invoice.client_name;
        }
        if (appInstance.invoice.min_total_amount > 0) {
          data.filter.min_total_amount = appInstance.invoice.min_total_amount;
        }
        if (appInstance.invoice.type.length > 0) {
          data.filter["invoices.type"] = appInstance.invoice.type;
        }
        if (appInstance.invoice.user_id.length > 0) {
          data.filter.user_id = appInstance.invoice.user_id;
        }
        if (appInstance.invoice.initiator_type.length > 0) {
          data.filter.initiator_type = appInstance.invoice.initiator_type;
        }
        if (appInstance.invoice.client_purchase_type.length > 0) {
          data.filter.client_purchase_type =
            appInstance.invoice.client_purchase_type;
        }
        if (appInstance.invoice.source.length > 0) {
          data.filter.source = appInstance.invoice.source;
        }
        if (appInstance.invoice.payment_source.length > 0) {
          data.filter.payment_source = appInstance.invoice.payment_source;
        }
        if (appInstance.invoice.payment_status.length > 0) {
          data.filter.payment_status = appInstance.invoice.payment_status;
        }
        if (appInstance.invoice.status.length > 0) {
          data.filter["invoices.status"] = appInstance.invoice.status;
        }
        if (appInstance.invoice.created_after.length > 0) {
          data.filter.created_after = appInstance.invoice.created_after;
        }
        if (appInstance.invoice.created_before.length > 0) {
          data.filter.created_before = appInstance.invoice.created_before;
        }
        if (appInstance.invoice.paid_after.length > 0) {
          data.filter.paid_after = appInstance.invoice.paid_after;
        }
        if (appInstance.invoice.paid_before.length > 0) {
          data.filter.paid_before = appInstance.invoice.paid_before;
        }
        API.getListsInvoices($.param(data))
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {
            const response = handleError(err);
            showMessage(response.message, "error");
          })
          .finally(() => {});
      },
    });
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    async initInvoiceTypeChoices() {
      const id = "invoice-type";
      await initChoices(
        id,
        {
          choices: API.getInvoiceTypeChoices("Select type"),
          placeholder: true,
          placeholderValue: "Select type",
          duplicateItemsAllowed: false,
        },
        "",
        "Select type"
      );
    },
    async initInvoiceInitiatorTypeChoices() {
      const id = "invoice-initiator-type";
      await initChoices(id, {
        choices: API.getInvoiceInitiatorTypeChoices(),
        placeholder: true,
        placeholderValue: "Select initiator type",
        duplicateItemsAllowed: false,
      });
    },
    async initInvoiceClientPurchaseTypeChoices() {
      const id = "invoice-client-purchase-type";
      const choices = await apiMiscList.purchaseType().catch(handleError);
      await initChoices(id, {
        choices: formatDataToChoicesJs(choices.data.data, "", "enum"),
        placeholder: true,
        placeholderValue: "Select client purchase type",
        duplicateItemsAllowed: false,
      });
    },
    async initInvoiceSourceChoices() {
      const id = "invoice-source";
      await initChoices(id, {
        choices: API.getSourceChoices(),
        placeholder: true,
        placeholderValue: "Select source",
        duplicateItemsAllowed: false,
      });
    },
    async initInvoicePaymentSourceChoices() {
      const id = "invoice-payment-source";
      await initChoices(id, {
        choices: API.getPaymentSourceChoices(),
        placeholder: true,
        placeholderValue: "Select payment source",
        duplicateItemsAllowed: false,
      });
    },
    async initInvoicePaymentStatusChoices() {
      const id = "invoice-payment-status";
      await initChoices(id, {
        choices: API.getPaymentStatusChoices(),
        placeholder: true,
        placeholderValue: "Select payment status",
        duplicateItemsAllowed: false,
      });
    },
    async initInvoiceStatusChoices() {
      const id = "invoice-status";
      await initChoices(id, {
        choices: API.getInvoiceStatusChoices(),
        placeholder: true,
        placeholderValue: "Select status",
        duplicateItemsAllowed: false,
      });
    },
    async initUserChoices() {
      let response = await API.getUsersChoices().catch(handleError);
      let data = [];
      if (response.status == 200) {
        data = response.data.data;
      }
      let options = {
        choices: formatDataToChoicesJs(data, "no empty option"),
        searchEnabled: true,
        placeholder: true,
        placeholderValue: "Select user",
        duplicateItemsAllowed: false,
      };
      await initChoices("invoice-user-id", options);
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    onCreatedAfterChange(selectedDates, dateStr) {
      this.configs.created_before.minDate = dateStr;
    },
    onCreatedBeforeChange(selectedDates, dateStr) {
      this.configs.created_after.maxDate = dateStr;
    },
    onPaidAfterChange(selectedDates, dateStr) {
      this.configs.paid_before.minDate = dateStr;
    },
    onPaidBeforeChange(selectedDates, dateStr) {
      this.configs.paid_after.maxDate = dateStr;
    },
  },
};
</script>
