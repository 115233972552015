<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            type="button"
            class="btn btn-white text-success"
            @click="saveConsultations"
          >
            Update Consultations
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <div v-show="!loading.consultationsModal" class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6 mt-3">
                  <label>Weight</label>
                  <input
                    id="weight"
                    v-model="consultations.weight"
                    class="form-control"
                    placeholder="Weight"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Skeletal Muscle Mass</label>
                  <input
                    id="skeletal_muscle_mass"
                    v-model="consultations.skeletal_muscle_mass"
                    class="form-control"
                    placeholder="Skeletal Muscle Mass"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Body Fat Percentage</label>
                  <input
                    id="body_fat_percentage"
                    v-model="consultations.body_fat_percentage"
                    class="form-control"
                    placeholder="Body Fat Percentage"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Basal Metabolic Ratio</label>
                  <input
                    id="basal_metabolic_ratio"
                    v-model="consultations.basal_metabolic_ratio"
                    class="form-control"
                    placeholder="basal Metabolic Ratio"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Body Mass Index</label>
                  <input
                    id="body_mass_index"
                    v-model="consultations.body_mass_index"
                    class="form-control"
                    placeholder="Body Mass Index"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Client Plan</label>
                  <select
                    id="client_plan_detail_id"
                    v-model="consultations.client_plan_detail_id"
                    name="client_plan_detail_id"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Goal</label>
                  <select
                    id="goal"
                    v-model="consultations.goal"
                    name="goal"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Payable Amount</label>
                  <input
                    id="payable_amount"
                    v-model="consultations.payable_amount"
                    class="form-control"
                    placeholder="Payable Amount"
                    type="number"
                    min="0"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Result Status</label>
                  <select
                    id="result_status"
                    v-model="consultations.result_status"
                    name="result_status"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Private Status</label>
                  <select
                    id="private_status"
                    v-model="consultations.private_status"
                    name="private_status"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Mode</label>
                  <select
                    id="mode"
                    v-model="consultations.mode"
                    name="mode"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Consultation Date</label>
                  <flat-pickr
                    v-model="consultations.consultation_date"
                    class="form-control"
                    placeholder="Select date"
                    name="consultation_date"
                  />
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="plan-notes"
                    placeholder=""
                    :model-value="consultations.notes"
                    @update:model-value="consultations.notes = $event"
                    >Notes
                  </argon-textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="plan-private-notes"
                    placeholder=""
                    :model-value="consultations.private_notes"
                    @update:model-value="consultations.private_notes = $event"
                    >Private notes
                  </argon-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            class="btn mb-0 bg-gradient-success btn-sm null null mb-0"
            :to="{
              name: 'Client Profile',
              params: {
                id: client.id,
              },
            }"
          >
            Back
          </router-link>
          &nbsp;&nbsp;
          <button
            type="button"
            class="btn mb-0 bg-gradient-success btn-sm null null mb-0"
            @click="saveConsultations"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleError } from "@/lib/helpers";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import { initChoices } from "@/assets/js/init-choices";
import { showMessage } from "@/assets/js/show-message";
import flatPickr from "vue-flatpickr-component";
import API from "@/services/api";
import moment from "moment";
export default {
  name: "EditConsultation",
  components: {
    ArgonTextarea,
    flatPickr,
  },
  data() {
    return {
      client: {
        id: this.$route.params.id,
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        gender: "",
        birth_date: "",
        bio: "",
        profile_picture: "",
        height: "",
        weight: "",
        goal_weight: "",
        dislikes: [],
        dislikeExclusions: [],
        plans: [],
        walletTransactions: [],
        invoices: [],
        addresses: [],
        wallets: [],
        consultations: [],
      },
      consultationId: this.$route.params.consultationId,
      consultations: {},
      loading: {},
      formSubmitted: {},
      clientPlanList: [],
    };
  },

  async mounted() {
    this.setClientPlans();
    this.getConsultationsDetrails();
  },
  methods: {
    async getConsultationsDetrails() {
      const appInstance = this;
      await API.getConsultationsDetrails(this.client.id, this.consultationId)
        .then((res) => {
          this.client.consultations = [];

          if (res.data) {
            appInstance.loading.consultationsModal = false;
            this.consultations = res.data.data;
            initChoices(
              "goal",
              {
                choices: [
                  { label: "Weight loss", value: "Weight loss" },
                  { label: "Weight Gain", value: "Weight Gain" },
                  { label: "Weight Maintain", value: "Weight Maintain" },
                  {
                    label: "Body Composition Change",
                    value: "Body Composition Change",
                  },
                  { label: "Health Focused", value: "Health Focused" },
                ],
                searchEnabled: true,
              },
              res.data.data.goal,
              res.data.data.goal
            );
            initChoices(
              "result_status",
              {
                choices: [
                  { label: "Negative", value: "Negative" },
                  { label: "Positive", value: "Positive" },
                  { label: "Neutral", value: "Neutral" },
                ],
                searchEnabled: true,
              },
              res.data.data.result_status,
              res.data.data.result_status
            );
            initChoices(
              "private_status",
              {
                choices: [
                  { label: "Negative", value: "Negative" },
                  { label: "Positive", value: "Positive" },
                  { label: "Neutral", value: "Neutral" },
                ],
                searchEnabled: true,
              },
              res.data.data.private_status,
              res.data.data.private_status
            );
            initChoices(
              "mode",
              {
                choices: [
                  { label: "Physical", value: "Physical" },
                  { label: "Phone Call", value: "Phone Call" },
                  { label: "Video Conference", value: "Video Conference" },
                ],
                searchEnabled: true,
              },
              res.data.data.mode,
              res.data.data.mode
            );

            initChoices(
              "client_plan_detail_id",
              {
                choices: this.clientPlanList,
                searchEnabled: true,
              },
              res.data.data.client_plan_detail_id,
              res.data.data.client_plan_detail_id
            );
          }
        })
        .catch((err) => {
          const response = handleError(err);
          appInstance.loading.consultationsModal = false;
          showMessage(response.message, "error");
        });
    },
    async setClientPlans() {
      await API.getClientPlanDetails(this.client.id)
        .then((res) => {
          this.client.plans = [];
          let choices = [];

          let sortedData = res.data.data.sort(function (a, b) {
            return new Date(b.start_date) - new Date(a.start_date);
          });

          for (let i in sortedData) {
            let start = moment(sortedData[i]["start_date"]);
            let end = moment(sortedData[i]["actual_end_date"]);
            this.client.plans.push({
              image: sortedData[i]["image"],
              name: sortedData[i]["plan.name"],
              id: sortedData[i]["id"],
              start_date: start.format("D MMM YYYY"),
              actual_end_date: end.format("D MMM YYYY"),
              total_days: sortedData[i]["total_days"],
              remaining_days: sortedData[i].pending_days,
              status: sortedData[i].status,
            });
            choices.push({
              label:
                sortedData[i]["plan.name"] +
                " ( " +
                moment(sortedData[i]["start_date"]).format("D MMM YYYY") +
                " )",
              value: sortedData[i]["id"],
            });
          }

          this.clientPlanList = choices;
          initChoices(
            "client_plan_detail_id",
            {
              choices: this.clientPlanList,
              searchEnabled: true,
            },
            this.consultations.client_plan_detail_id,
            this.consultations.client_plan_detail_id
          );
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },

    async saveConsultations() {
      if (this.formSubmitted.saveConsultations) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted.saveConsultations = true;
      let formData = new FormData();

      for (let key in this.consultations) {
        formData.append(key, this.consultations[key]);
      }

      let saveResponse = null;
      let message = null;

      formData.set("_method", "PATCH");
      saveResponse = await API.updateConsultations(
        this.client.id,
        this.consultations.id,
        formData
      ).catch(handleError);

      let messageTimer = 2500;

      if (saveResponse.status === 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          saveResponse.data.message +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveResponse.message +
          "</span>";
        messageTimer = 3500;
      }
      showMessage(message, "", messageTimer, "", "html");
      this.formSubmitted.saveConsultations = false;
    },
  },
};
</script>
