<template>
  <loading-spinner :loading="loading" />
  <div v-if="!loading" class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'recipes')"
            type="button"
            class="btn btn-white text-success"
            @click="updateRecipe"
          >
            Save recipe
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <image-picker
          v-if="!loading"
          title="Recipe image"
          :image-src="recipe.image"
          :default-image="recipeDefaultImage"
          @select-image="selectImage"
          @remove-image="removeImage"
        />
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <recipe-information-card
          v-if="!loading"
          :initial-food-groups-data="initialFoodGroupsData"
          :initial-recipe-data="initialRecipeInformationData"
          @change-recipe="handleChangeRecipe"
          @change-food-groups="handleChangeFoodGroups"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div v-if="$can('update_pricing', 'recipes')" class="col-lg-4">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Pricing</h6>
          </div>
          <div class="card-body p-3">
            <div class="row mb-4">
              <div class="col-6">
                <label>Cost<required-input /></label>
                <input v-model="recipe.cost" class="form-control" type="text" />
              </div>
              <div class="col-6">
                <label>Selling price<required-input /></label>
                <input
                  v-model="recipe.selling_price"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 mt-4 mt-lg-0">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Nutritional information</h6>
          </div>
          <div class="card-body p-3">
            <div class="row mb-4">
              <div class="col-3">
                <label>Calories<required-input /></label>
                <input
                  v-model="recipe.calories"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-3">
                <label>Carbs<required-input /></label>
                <input
                  v-model="recipe.carbs"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-3">
                <label>Fat<required-input /></label>
                <input v-model="recipe.fat" class="form-control" type="text" />
              </div>
              <div class="col-3">
                <label>Protein<required-input /></label>
                <input
                  v-model="recipe.protein"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-4">
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Gluten</span>
                  <argon-switch
                    id="recipe-gluten"
                    v-model:checked="recipe.gluten"
                    name="recipe_gluten"
                    class="ms-3"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Garlic<required-input /></span>
                  <div class="me-2">
                    <select
                      id="recipe-garlic"
                      v-model="recipe.garlic"
                      class="form-control"
                    >
                      <option
                        v-for="(n, index) in 3"
                        :key="index"
                        :value="index"
                      >
                        {{ index }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Vegetarian</span>
                  <argon-switch
                    id="recipe-is-vegetarian"
                    v-model:checked="recipe.is_vegetarian"
                    name="recipe_is_vegetarian"
                    class="ms-3"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Spice Level<required-input /></span>
                  <div class="me-2">
                    <select
                      id="recipe-spice-level"
                      v-model="recipe.spice_level"
                      class="form-control"
                    >
                      <option
                        v-for="(n, index) in 10"
                        :key="index"
                        :value="index"
                      >
                        {{ index }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <ingredients-card
          v-if="showIngredientsCard"
          :card-id="1"
          :initial-ingredients="realIngredients"
          @change="handleChangeRealIngredients"
        />
        <ingredients-card
          v-if="showIngredientsCard"
          class="mt-4"
          :card-id="2"
          :initial-ingredients="allergenIngredients"
          title="Allergens"
          button-text="Add allergen"
          :allergen="true"
          :min-ingredients-required="0"
          @change="handleChangeAllergenIngredients"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'recipes')"
            type="button"
            class="btn btn-outline-success"
            @click="updateRecipe"
          >
            Save recipe
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import recipeDefaultImage from "@/assets/img/recipe.png";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";
import IngredientsCard from "./components/IngredientsCard.vue";
import RecipeInformationCard from "./components/RecipeInformationCard.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ImagePicker from "@/components/ImagePicker.vue";
import { createFormDataFromObject } from "@/lib/objectHelper";
import { handleError } from "@/lib/helpers";

import RequiredInput from "@/components/RequiredInput.vue";
export default {
  name: "Edit",
  components: {
    ArgonSwitch,
    IngredientsCard,
    RecipeInformationCard,
    RequiredInput,
    ImagePicker,
    LoadingSpinner,
  },
  data() {
    return {
      recipeDefaultImage,
      recipeImage: undefined,
      recipe: {},
      foodGroups: [],
      ingredients: [],
      realIngredients: [],
      allergenIngredients: [],
      formSubmitted: false,
      showIngredientsCard: false,
      initialRecipeInformationData: {},
      initialFoodGroupsData: [],
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;
    await this.setRecipe();
    this.setInitialData();
    this.loading = false;
  },
  methods: {
    setInitialData() {
      this.initialRecipeInformationData = {
        name: this.recipe.name,
        description: this.recipe.description,
        type: this.recipe.type,
        kitchen_id: this.recipe.kitchen_id,
        kitchen_recipe_name: this.recipe.kitchen_recipe_name,
        grade: this.recipe.grade,
        erp_reference: this.recipe.erp_reference,
        cuisine_id: this.recipe.cuisine_id,
        weight: this.recipe.weight,
        serving_instruction: this.recipe.serving_instruction,
        notes: this.recipe.notes,
        status: this.recipe.status,
        is_public: this.recipe.is_public,
        is_premium: this.recipe.is_premium,
      };
      this.initialFoodGroupsData = this.foodGroups;
    },

    async setRecipe() {
      const appInstance = this;
      await API.getRecipe(appInstance.$route.params.id)
        .then((res) => {
          this.recipe = {
            name: { en: res.data.data.name, ar: res.data.data.name_ar },
            type: res.data.data.type,
            calories: res.data.data.calories,
            fat: res.data.data.fat,
            carbs: res.data.data.carbs,
            protein: res.data.data.protein,
            description: {
              en: res.data.data.description,
              ar: res.data.data.description_ar,
            },
            serving_instruction: res.data.data.serving_instruction
              .toString()
              .trim(),
            weight: res.data.data.weight,
            notes: res.data.data.notes,
            kitchen_id: res.data.data.kitchen_id,
            kitchen_name: res.data.data.kitchen_name,
            kitchen_recipe_name: res.data.data.kitchen_recipe_name,
            cuisine_id: res.data.data.cuisine_id,
            cuisine_name: res.data.data.cuisine_name,
            gluten: res.data.data.gluten,
            garlic: res.data.data.garlic,
            spice_level: res.data.data.spice_level,
            is_vegetarian: res.data.data.is_vegetarian,
            cost: res.data.data.cost,
            selling_price: res.data.data.selling_price,
            erp_reference: res.data.data.erp_reference,
            grade: res.data.data.grade,
            status: res.data.data.status === "Active",
            is_public: res.data.data.is_public,
            is_premium: res.data.data.is_premium,
            image: this.recipeDefaultImage,
          };
          if (res.data.data.image.length) {
            this.recipe.image = res.data.data.image;
          }
          this.foodGroups = [];
          for (let i in res.data.data.recipeFoodGroups.data) {
            this.foodGroups.push(
              res.data.data.recipeFoodGroups.data[i]["food_group_id"]
            );
          }
          if (res.data.data.recipeIngredients.data) {
            this.ingredients = res.data.data.recipeIngredients.data;
          }
          this.splitIngredients();
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Recipe not found",
              willClose: () => {
                appInstance.$router.push({ name: "Recipes" });
              },
            });
          } else {
            const response = handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
    splitIngredients() {
      this.ingredients.forEach((item) => {
        if (item["ingredient.name"].includes("Allergen")) {
          this.allergenIngredients.push(item);
        } else {
          this.realIngredients.push(item);
        }
      });
      this.updateIngredientSequence(this.realIngredients);
      this.updateIngredientSequence(this.allergenIngredients);
      this.showIngredientsCard = true;
    },
    async updateIngredientSequence(ingredients) {
      for (let i in ingredients) {
        ingredients[i]["sequence"] = parseInt(i) + 1;
      }
    },
    handleChangeRealIngredients(newIngredients) {
      this.realIngredients = newIngredients;
    },
    handleChangeAllergenIngredients(newIngredients) {
      this.allergenIngredients = newIngredients;
    },
    async updateRecipe() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = createFormDataFromObject(this.recipe, [
        "image",
        "cost",
        "selling_price",
      ]);
      if (this.$ability.can("update_pricing", "recipes")) {
        formData.set("cost", this.recipe.cost);
        formData.set("selling_price", this.recipe.selling_price);
      }
      formData.set("gluten", this.recipe.gluten === true ? "1" : "0");
      formData.set(
        "is_vegetarian",
        this.recipe.is_vegetarian === true ? "1" : "0"
      );
      formData.set("is_public", this.recipe.is_public === true ? "1" : "0");
      formData.set(
        "status",
        this.recipe.status === true ? "Active" : "Inactive"
      );
      formData.set("is_premium", this.recipe.is_premium === true ? "1" : "0");

      formData.set("_method", "PATCH");
      if (this.recipeImage) {
        formData.set("image", this.recipeImage);
      }
      let updateRecipe = await API.updateRecipe(
        this.$route.params.id,
        formData
      ).catch(handleError);
      let message = "";
      if (updateRecipe.status == 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Recipe saved successfully." +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          updateRecipe.message +
          "</span>";
      }

      let formDataFoodGroups = new FormData();
      for (let i in this.foodGroups) {
        formDataFoodGroups.append(
          "recipe_food_groups[" + i + "][food_group_id]",
          this.foodGroups[i]
        );
      }
      let updateRecipeFoodGroups = await API.updateRecipeFoodGroups(
        this.$route.params.id,
        formDataFoodGroups
      ).catch(handleError);
      if (updateRecipeFoodGroups.status === 200) {
        message +=
          '<hr class="my-2 horizontal dark"><i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Recipe food groups saved successfully" +
          "</span>";
      } else {
        message +=
          '<hr class="my-2 horizontal dark"><i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          updateRecipeFoodGroups.message +
          "</span>";
      }

      let updateRecipeIngredients = await this.updateIngredients();
      if (updateRecipeIngredients.status === 200) {
        message +=
          '<hr class="my-2 horizontal dark"><i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Recipe ingredients saved successfully" +
          "</span>";
      } else {
        message +=
          '<hr class="my-2 horizontal dark"><i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          updateRecipeIngredients.message;
      }

      showMessage(message, "", 1500, "", "html");

      this.formSubmitted = false;
    },
    addIngredientsToFormData(ingredients, formData, startIndex) {
      for (let i in ingredients) {
        let ingredient = ingredients[i];
        let index = startIndex + parseInt(i);
        formData.append(
          "recipe_ingredients[" + index + "][ingredient_id]",
          ingredient["ingredient_id"]
        );
        formData.append(
          "recipe_ingredients[" + index + "][sequence]",
          index + 1
        );
        if (ingredient["remark"]) {
          formData.append(
            "recipe_ingredients[" + index + "][remark]",
            ingredient["remark"]
          );
        }
        if (ingredient["used_for"]) {
          formData.append(
            "recipe_ingredients[" + index + "][used_for]",
            ingredient["used_for"]
          );
        }
        formData.append(
          "recipe_ingredients[" + index + "][is_public]",
          ingredient["is_public"] === true ? "1" : "0"
        );
        formData.append(
          "recipe_ingredients[" + index + "][removable]",
          ingredient["removable"] === true ? "1" : "0"
        );
      }
    },
    async updateIngredients() {
      let formData = new FormData();
      this.addIngredientsToFormData(this.realIngredients, formData, 0);
      this.addIngredientsToFormData(
        this.allergenIngredients,
        formData,
        this.realIngredients.length
      );
      let response = await API.updateRecipeIngredients(
        this.$route.params.id,
        formData
      ).catch(handleError);
      return response;
    },

    handleChangeRecipe(data) {
      this.recipe = { ...this.recipe, ...data };
    },
    handleChangeFoodGroups(data) {
      this.foodGroups = data;
    },
    selectImage(imageFile) {
      this.recipeImage = imageFile;
    },
    removeImage() {
      this.recipeImage = undefined;
    },
  },
};
</script>
