<template>
  <div class="row g-0">
    <!-- eslint-disable vue/no-v-html -->
    <span class="col ps-1" v-html="macroHtml(caloriesText, calories)"></span>
    <span class="col ps-1" v-html="macroHtml(carbsText, carbs)"></span>
    <span class="col ps-1" v-html="macroHtml(fatText, fat)"></span>
    <span class="col ps-1" v-html="macroHtml(proteinText, protein)"></span>
    <!--eslint-enable-->
  </div>
</template>

<script>
import { numberFormat } from "@/lib/helpers";
export default {
  name: "RecipeMacrosInfo",
  props: {
    caloriesText: {
      type: String,
      default: "Calories",
    },
    calories: {
      type: [Number, String],
      required: true,
    },
    carbs: {
      type: [Number, String],
      required: true,
    },
    carbsText: {
      type: String,
      default: "Carbs",
    },
    fat: {
      type: [Number, String],
      required: true,
    },
    fatText: {
      type: String,
      default: "Fat",
    },
    protein: {
      type: [Number, String],
      required: true,
    },
    proteinText: {
      type: String,
      default: "Protein",
    },
    twoLines: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      separator: " ",
    };
  },
  mounted() {
    if (this.twoLines) {
      this.separator = "<br>";
    }
  },
  methods: {
    numberFormat,
    macroHtml(macroText, macroValue) {
      return macroText + this.separator + this.numberFormat(macroValue);
    },
  },
};
</script>
