<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="row">
      <div class="col-12 col-sm-6 mt-2">
        <label class="form-label">Start Date</label>
        <flat-pickr
          v-model="form.startDate"
          class="form-control"
          placeholder="Please select start date"
          :config="configs.startDate"
          @on-change="onStartDateChange"
        ></flat-pickr>
        <error-display :errors="v$.form.startDate.$errors" />
      </div>
      <div class="col-12 col-sm-6 mt-2">
        <label class="form-label">End Date</label>
        <flat-pickr
          v-model="form.endDate"
          class="form-control"
          placeholder="Please select end date"
          :config="configs.endDate"
          @on-change="onEndDateChange"
        ></flat-pickr>
        <error-display :errors="v$.form.endDate.$errors" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <argon-switch
          id="is-preview"
          v-model:checked="form.isPreview"
          name="For internal use"
          class="form-check form-switch mb-0 d-flex align-items-center"
          >For internal use</argon-switch
        >
      </div>
    </div>
    <template #footer>
      <button
        type="button"
        class="btn btn-sm mb-0 btn-secondary"
        @click="$emit('close')"
      >
        Close
      </button>
      <submit-form-button
        :form-submitted="formSubmitted"
        :default-button-text="buttonText"
        @click="handleClick"
      />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import flatPickr from "vue-flatpickr-component";
import { validatorMessages, helpers } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import apiClientPlanDetails from "@/services/apiClientPlanDetails";
import { downloadFile, handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import { flatPickrConfig } from "@/lib/flatPickrHelper";
import SubmitFormButton from "@/components/SubmitFormButton.vue";

export default {
  name: "DownloadPdfModal",
  components: {
    Modal,
    ErrorDisplay,
    ArgonSwitch,
    flatPickr,
    SubmitFormButton,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    clientPlanDetailId: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: "Select interval",
    },
    buttonText: {
      type: String,
      default: "Download",
    },
    modalId: {
      type: String,
      default: "download-pdf-modal",
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change", "close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formSubmitted: false,
      form: { startDate: "", endDate: "", notes: "", isPreview: false },
      configs: {
        startDate: {
          ...flatPickrConfig,
          maxDate: null,
        },
        endDate: {
          ...flatPickrConfig,
          minDate: null,
        },
      },
    };
  },
  methods: {
    onStartDateChange(selectedDates, dateStr) {
      this.configs.endDate.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.startDate.maxDate = dateStr;
    },

    async handleClick() {
      await this.v$.$validate();
      if (this.v$.$validationGroups.form.$invalid || this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;

      await this.downloadPDF();

      this.formSubmitted = false;
    },
    async downloadPDF() {
      const options = {
        start_date: this.form.startDate,
        end_date: this.form.endDate,
        is_preview: this.form.isPreview ? 1 : 0,
      };
      let response = await apiClientPlanDetails
        .downloadMealPlanPDF(this.clientId, this.clientPlanDetailId, options)
        .catch(handleError);
      if (response.status === 200) {
        this.$emit("close");
        this.$emit("change");
        downloadFile(
          response.data,
          "meal-plan-details.pdf",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
    },
  },
  validations() {
    return {
      form: {
        startDate: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        endDate: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
      $validationGroups: {
        form: ["form"],
      },
    };
  },
};
</script>
