<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <operations-filter
            class="card-header"
            :initial-date="dateFormat('yesterday', 'YYYY-MM-DD')"
            :form-submitted="formSubmitted"
            @download="download"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OperationsFilter from "./components/OperationsFilter.vue";
import { dateFormat } from "@/lib/dateHelper";
import { handleError, downloadFile } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import apiTDO from "@/services/apiTDO";
export default {
  name: "TDO",
  components: {
    OperationsFilter,
  },
  data() {
    return {
      formSubmitted: false,
    };
  },
  methods: {
    dateFormat,
    async download(filter) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const requestData = { date: filter.date, action: "download" };
      let response;
      switch (filter.type) {
        case "TDO all":
          response = await apiTDO.tdo_all(requestData).catch(handleError);
          break;
        case "TDO am":
          response = await apiTDO.tdo_am(requestData).catch(handleError);
          break;
        case "TDO pm":
          response = await apiTDO.tdo_pm(requestData).catch(handleError);
          break;
      }
      if (response.status === 200) {
        downloadFile(
          response.data,
          filter.type + " " + dateFormat(filter.date, "YYYY-MM-DD") + ".xlsx",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
