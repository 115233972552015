<template>
  <div>
    <label class="mt-4">{{ title }}</label>
    <div
      v-for="(option, key) in options"
      :key="option.value"
      class="form-check my-auto ms-auto"
    >
      <input
        :id="name + key"
        type="checkbox"
        :value="option.value"
        :checked="modelValue.includes(option.value)"
        :name="name"
        :disabled="option.disabled || disabled"
        class="form-check-input"
        @change="handleCheckboxChange(option.value)"
      />

      <label :for="name + key"> {{ option.label }} </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "radio-group",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    handleCheckboxChange(value) {
      let updatedValue = this.modelValue.includes(value)
        ? this.modelValue.filter((item) => item !== value)
        : [...this.modelValue, value];

      const validOptions = this.options.map((option) => option.value);
      updatedValue = updatedValue.filter((val) => validOptions.includes(val));
      this.$emit("update:modelValue", updatedValue);
    },
  },
};
</script>

<style scoped>
.radio-option {
  margin-bottom: 10px;
  display: block;
}
</style>
