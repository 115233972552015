<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="row">
      <div class="col-12 mt-2">
        <argon-textarea
          id="paid-wastage-notes"
          placeholder=""
          :model-value="form.notes"
          @update:model-value="form.notes = $event"
          >Paid wastage notes
        </argon-textarea>
      </div>
    </div>
    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">
        Close
      </button>
      <button class="btn bg-gradient-success" @click="handleClick">
        {{ buttonText }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import apiClientDelivery from "@/services/apiClientDelivery";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
export default {
  name: "PaidWastageModal",
  components: {
    Modal,
    ArgonTextarea,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    deliveryId: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: "Make Paid Wastage",
    },
    buttonText: {
      type: String,
      default: "Submit",
    },
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["saved", "close"],
  data() {
    return {
      formSubmitted: false,
      form: { notes: "" },
    };
  },
  methods: {
    async handleClick() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const data = { notes: this.form.notes };
      const response = await apiClientDelivery
        .makePaidWastage(this.clientId, this.deliveryId, data)
        .catch(handleError);
      if (response.status == 200) {
        showMessage("Delivery marked as paid wastage successfully", "success");
        this.$emit("close");
        this.$emit("change");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {},
    };
  },
};
</script>
