<template>
  <div class="card h-100 mt-4">
    <div class="card-body">
      <div class="mt-4 row">
        <div class="col-auto">
          <img
            alt="Image placeholder"
            class="avatar rounded-circle me-3"
            :src="logo"
          />
        </div>
        <div class="col col-md-6">
          <form>
            <div class="row">
              <div class="col-12">
                <input
                  v-model="formData.subject"
                  type="text"
                  class="form-control"
                  placeholder="Subject"
                />
              </div>
              <div class="col-12 mt-3">
                <select
                  id="remark-type"
                  v-model="formData.type"
                  placeholder="Select type"
                >
                  <option value="" disabled selected>Select type</option>
                </select>
              </div>
              <div class="col-12 mt-3">
                <textarea
                  v-model="formData.content"
                  class="form-control"
                  placeholder="Write a comment"
                  rows="4"
                ></textarea>
              </div>
              <div class="col-12 mt-3">
                <button
                  type="button"
                  class="mb-0 btn btn-xs bg-gradient-success"
                  @click="createRemark"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <timeline-list v-if="clientRemarksMainSet.length > 0" class="mt-5">
            <timeline-item
              v-for="(el, index) in clientRemarksMainSet"
              :key="index"
              :icon="{
                component: 'ni ni-check-bold',
                color: 'success',
              }"
              :title="el.subject"
              :date-time="dateFormat(el.created_at, 'dateTime')"
              :description="el.content"
              :badges="[
                { title: el['author.name'], color: 'success' },
                { title: el.type, color: 'warning' },
              ]"
            />
          </timeline-list>
        </div>
        <div class="col-12 col-sm-6">
          <timeline-list
            v-if="clientRemarksNutritionSet.length > 0"
            class="mt-5"
          >
            <timeline-item
              v-for="(el, index) in clientRemarksNutritionSet"
              :key="index"
              :icon="{
                component: 'ni ni-check-bold',
                color: 'success',
              }"
              :title="el.subject"
              :date-time="dateFormat(el.created_at, 'dateTime')"
              :description="el.content"
              :badges="[
                { title: el['author.name'], color: 'success' },
                { title: el.type, color: 'warning' },
              ]"
            />
          </timeline-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showMessage } from "@/assets/js/show-message";
import { dateFormat } from "@/lib/dateHelper";
import { handleError } from "@/lib/helpers";
import API from "@/services/api";
import logo from "@/assets/img/kcal-logo.svg";
import apiMiscList from "@/services/apiMiscList";
import { initChoices } from "@/assets/js/init-choices";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import TimelineList from "@/examples/Cards/TimelineList.vue";
export default {
  name: "RemarksCard",
  components: {
    TimelineItem,
    TimelineList,
  },
  props: {
    clientRemarks: {
      type: Array,
      default: () => [],
    },
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ["remark-created"],
  data() {
    return {
      logo,
      formData: {
        subject: "",
        content: "",
        type: "",
        action_time: "",
      },
    };
  },
  computed: {
    clientRemarksMainSet() {
      return this.clientRemarks.filter((item) => item.type !== "Nutrition");
    },
    clientRemarksNutritionSet() {
      return this.clientRemarks.filter((item) => item.type === "Nutrition");
    },
  },
  mounted() {
    initChoices(
      "remark-type",
      {
        choices: apiMiscList.remarkTypes(),
        searchEnabled: false,
      },
      ""
    );
  },
  methods: {
    dateFormat,
    async createRemark() {
      if (!this.formData.subject) {
        showMessage("Please write a subject", "error");
        return;
      }
      if (!this.formData.content) {
        showMessage("Please write a comment", "error");
        return;
      }

      if (!this.formData.type) {
        showMessage("Please select a type", "error");
        return;
      }

      let data = {
        subject: this.formData.subject,
        content: this.formData.content,
        type: this.formData.type,
      };
      if (this.formData.action_time) {
        data.action_time = this.formData.action_time;
      }
      const response = await API.createClientRemark(this.clientId, data).catch(
        handleError
      );
      if (response.status == 200) {
        this.formData = { subject: "", content: "", type: "", action_time: "" };
        showMessage("Remark created succesfully", "success");
        this.$emit("remark-created");
      } else {
        showMessage(response.message, "error");
      }
    },
  },
};
</script>
