import { httpApi } from "@/services/httpApi";

class ApiDiscounts {
  async get(params) {
    let url = "api/v1/discount-codes?" + params;
    return await httpApi.get(url);
  }
  getDiscountTypeChoices() {
    let options = ["Discount", "Gift Card"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/DiscountCodeType.php
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
  getDiscountCategoryChoices() {
    let options = [
      "Marketing",
      "Gift Card",
      "Loyalty",
      "Referral",
      "Management",
      "Partner In Health",
    ]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/DiscountCodeCategory.php
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
}

export default new ApiDiscounts();
