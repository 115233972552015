import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/dashboards/Default.vue";
import SalesAgentDashboard from "@/views/pages/Dashboards/SalesAgentDashboard";
import NutritionistDashboard from "@/views/pages/Dashboards/NutritionistDashboard";
import SignIn from "../views/auth/signin/Basic.vue";
import SignUp from "../views/auth/signup/Basic.vue";
import UsersList from "../views/pages/Users/List.vue";
import EditUser from "../views/pages/Users/EditUser.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import UserRoles from "../views/pages/Users/UserRoles.vue";
import EditRole from "../views/pages/Users/EditRole.vue";
import ClientsList from "../views/pages/Clients/List.vue";
import ClientsPlansList from "../views/pages/Clients/PlansList.vue";
import EditClient from "../views/pages/Clients/Edit.vue";
import CreateClient from "../views/pages/Clients/Create.vue";
import EditClientAddress from "../views/pages/Clients/EditAddress.vue";
import NewClientAddress from "../views/pages/Clients/NewAddress.vue";
import ProfileClient from "../views/pages/Clients/Profile.vue";
import ClientPlan from "../views/pages/Clients/Plan.vue";
import ClientPlanTransfer from "../views/pages/Clients/PlanTransfer.vue";
import AllergensList from "../views/pages/Config/Allergens/List.vue";
import IngredientsList from "../views/pages/Config/Ingredients/List.vue";
import EditIngredient from "../views/pages/Config/Ingredients/Edit.vue";
import NewIngredient from "../views/pages/Config/Ingredients/New.vue";
import RecipesList from "../views/pages/Config/Recipes/List.vue";
import EditRecipe from "../views/pages/Config/Recipes/Edit.vue";
import NewRecipe from "../views/pages/Config/Recipes/New.vue";
import PlansList from "../views/pages/Config/Plans/List.vue";
import EditPlan from "../views/pages/Config/Plans/Edit.vue";
import NewPlan from "../views/pages/Config/Plans/New.vue";
import EditPlanVariant from "../views/pages/Config/PlanVariants/Edit.vue";
import NewPlanVariant from "../views/pages/Config/PlanVariants/New.vue";
import InvoicesList from "../views/pages/Invoices/List.vue";
import Invoice from "../views/pages/Invoices/Invoice.vue";
import InvoiceRefundRequestsList from "../views/pages/Invoices/RefundRequestsList.vue";
import EditInvoiceRefundRequest from "../views/pages/Invoices/EditRefundRequest.vue";
import CycleMenusList from "../views/pages/Config/CycleMenus/List.vue";
import CycleMenusCopy from "../views/pages/Config/CycleMenus/Copy.vue";
import SubstituteMenusList from "../views/pages/Config/SubstituteMenus/List.vue";
import SubstituteMenusCopy from "../views/pages/Config/SubstituteMenus/Copy.vue";
import Error403 from "../views/auth/error/Error403.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import FoodGroupsList from "../views/pages/Config/FoodGroups/List.vue";
import NewFoodGroup from "../views/pages/Config/FoodGroups/New.vue";
import EditFoodGroup from "../views/pages/Config/FoodGroups/Edit.vue";
import BagMovementsList from "../views/pages/Logistics/BagMovements/List.vue";
import ViewBagMovement from "../views/pages/Logistics/BagMovements/Details.vue";
import BagMovementCheckin from "../views/pages/Logistics/BagMovements/BagCheckin.vue";
import BagMovementCheckout from "../views/pages/Logistics/BagMovements/BagCheckout.vue";
import DeliveriesList from "../views/pages/Logistics/Deliveries/List.vue";
import DeliveryBagMovementsList from "../views/pages/Logistics/DeliveryBagMovements/List.vue";
import AddonsList from "../views/pages/Config/Addons/List.vue";
import NewAddon from "../views/pages/Config/Addons/New.vue";
import EditAddons from "../views/pages/Config/Addons/Edit.vue";
import ViewAddons from "../views/pages/Config/Addons/View.vue";
import DiscountList from "../views/pages/Config/Discount/List.vue";
import EditDiscount from "../views/pages/Config/Discount/Edit.vue";
import ConsultationsView from "../views/pages/Clients/Consultations/View.vue";
import NewConsultations from "../views/pages/Clients/Consultations/New.vue";
import EditConsultations from "../views/pages/Clients/Consultations/Edit.vue";
import BagCollectionRequestsList from "../views/pages/BagCollectionRequests/List.vue";
import NewBagCollectionRequest from "../views/pages/BagCollectionRequests/New.vue";
import EditBagCollectionRequest from "../views/pages/BagCollectionRequests/Edit.vue";
import RevenueReport from "../views/pages/Reports/RevenueReport/List.vue";
import CollectionsReport from "../views/pages/Reports/CollectionsReport/List.vue";
import TDO from "@/views/pages/Operations/TDO.vue";
import PackingList from "@/views/pages/Operations/PackingList.vue";
import DeliverySchedule from "@/views/pages/Operations/DeliverySchedule.vue";
import Labels from "@/views/pages/Operations/Labels.vue";
import FoodLabel from "@/views/pages/Operations/FoodLabel.vue";
import BaaView from "@/views/pages/Baa/View.vue";
import PlanQueues from "@/views/pages/PlanQueues/List.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard-default",
    name: "Default",
    component: Dashboard,
    meta: {
      auth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      auth: true,
    },
  },
  {
    path: "/dashboard-account-manager",
    name: "Account Manager Dashboard",
    component: Dashboard,
    meta: {
      auth: true,
      title: "Account Manager Dashboard",
    },
  },
  {
    path: "/dashboard-account-admin",
    name: "Account Admin Dashboard",
    component: Dashboard,
    meta: {
      auth: true,
      title: "Account Admin Dashboard",
    },
  },
  {
    path: "/dashboard-account-supervisor",
    name: "Account Supervisor Dashboard",
    component: Dashboard,
    meta: {
      auth: true,
      title: "Account Supervisor Dashboard",
    },
  },
  {
    path: "/dashboard-sales-agent",
    name: "Sales Agent Dashboard",
    component: SalesAgentDashboard,
    meta: {
      auth: true,
      title: "Sales Agent Dashboard",
    },
  },
  {
    path: "/dashboard-nutritionist",
    name: "Nutritionist Dashboard",
    component: NutritionistDashboard,
    meta: {
      auth: true,
      title: "Nutritionist Dashboard",
    },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    meta: {
      auth: true,
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    meta: {
      auth: false,
    },
  },
  {
    path: "/users",
    name: "Users List",
    component: UsersList,
    meta: {
      title: "Users List",
      auth: true,
      guard: {
        action: "view",
        subject: "users",
      },
    },
  },
  {
    path: "/current-user/profile",
    name: "User Profile",
    component: () => import("@/views/pages/Users/CurrentUserProfile.vue"),
    meta: {
      title: "User Profile",
      auth: true,
    },
  },
  {
    path: "/users/:id",
    name: "Edit User",
    component: EditUser,
    meta: {
      title: "Edit User",
      auth: true,
      guard: {
        action: "update",
        subject: "users",
      },
    },
  },
  {
    path: "/users/new-user",
    name: "New User",
    component: NewUser,
    meta: {
      auth: true,
      guard: {
        action: "create",
        subject: "users",
      },
    },
  },
  {
    path: "/authentication/error/error404",
    name: "Error404",
    component: Error404,
    meta: {
      auth: false,
    },
  },
  {
    path: "/authentication/error/error403",
    name: "Error403",
    component: Error403,
    meta: {
      auth: false,
    },
  },
  {
    path: "/authentication/error/error500",
    name: "Error500",
    component: Error500,
    meta: {
      auth: false,
    },
  },
  {
    path: "/users/user-roles",
    name: "User Roles",
    component: UserRoles,
    meta: {
      title: "User Roles",
      auth: true,
      guard: {
        action: "view",
        subject: "roles",
      },
    },
  },
  {
    path: "/users/user-roles/:id",
    name: "Edit User Role",
    component: EditRole,
    meta: {
      title: "Edit Role",
      auth: true,
      guard: {
        action: "update",
        subject: "roles",
      },
    },
  },
  {
    path: "/clients",
    name: "Clients List",
    component: ClientsList,
    meta: {
      title: "Clients List",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/plans",
    name: "Clients Plans List",
    component: ClientsPlansList,
    meta: {
      title: "Clients Plans List",
      auth: true,
      guard: {
        action: "view",
        subject: "client_plan_details",
      },
    },
  },
  {
    path: "/clients/:id",
    name: "Edit Client",
    component: EditClient,
    meta: {
      title: "Edit Client",
      auth: true,
      guard: {
        action: "update",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/create/:mobileNumber",
    name: "Create Client",
    component: CreateClient,
    meta: {
      title: "Create Client",
      auth: true,
      guard: {
        action: "create",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/addresses/:addressId",
    name: "Edit Client Address",
    component: EditClientAddress,
    meta: {
      title: "Edit Client Address",
      auth: true,
      guard: {
        action: "update",
        subject: "addresses",
      },
    },
  },
  {
    path: "/clients/:id/addresses",
    name: "New Client Address",
    component: NewClientAddress,
    meta: {
      title: "New Client Address",
      auth: true,
      guard: {
        action: "create",
        subject: "addresses",
      },
    },
  },
  {
    path: "/clients/:id/profile",
    name: "Client Profile",
    component: ProfileClient,
    meta: {
      title: "Client Profile",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/plan/:planId",
    name: "Client Plan",
    component: ClientPlan,
    meta: {
      title: "Client Plan",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/plan/:planId/transfer",
    name: "Client Plan Transfer",
    component: ClientPlanTransfer,
    meta: {
      title: "Client Plan Transfer",
      auth: true,
      guard: {
        action: "transfer",
        subject: "client_plan_details",
      },
    },
  },
  {
    path: "/config/allergens/",
    name: "Allergens",
    component: AllergensList,
    meta: {
      title: "Allergens",
      auth: true,
      guard: {
        action: "view",
        subject: "allergens",
      },
    },
  },
  {
    path: "/config/ingredients/",
    name: "Ingredients",
    component: IngredientsList,
    meta: {
      title: "Ingredients",
      auth: true,
      guard: {
        action: "view",
        subject: "ingredients",
      },
    },
  },
  {
    path: "/config/ingredients/:id",
    name: "Edit Ingredient",
    component: EditIngredient,
    meta: {
      title: "Edit Ingredient",
      auth: true,
      guard: {
        action: "update",
        subject: "ingredients",
      },
    },
  },
  {
    path: "/config/ingredients/new",
    name: "New Ingredient",
    component: NewIngredient,
    meta: {
      title: "New Ingredient",
      auth: true,
      guard: {
        action: "create",
        subject: "ingredients",
      },
    },
  },
  {
    path: "/config/recipes/",
    name: "Recipes",
    component: RecipesList,
    meta: {
      title: "Recipes",
      auth: true,
      guard: {
        action: "view",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/recipes/:id",
    name: "Edit Recipe",
    component: EditRecipe,
    meta: {
      title: "Edit Recipe",
      auth: true,
      guard: {
        action: "update",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/recipes/new",
    name: "New Recipe",
    component: NewRecipe,
    meta: {
      title: "New Recipe",
      auth: true,
      guard: {
        action: "create",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/plans/",
    name: "Plans",
    component: PlansList,
    meta: {
      title: "Plans",
      auth: true,
      guard: {
        action: "view",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/:id",
    name: "Edit Plan",
    component: EditPlan,
    meta: {
      title: "Edit Plan",
      auth: true,
      guard: {
        action: "update",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/new",
    name: "New Plan",
    component: NewPlan,
    meta: {
      title: "New Plan",
      auth: true,
      guard: {
        action: "create",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/:id/variants/:planVariantId",
    name: "Edit Plan Variant",
    component: EditPlanVariant,
    meta: {
      title: "Edit Plan Variant",
      auth: true,
      guard: {
        action: "update",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/:id/variants/new",
    name: "New Plan Variant",
    component: NewPlanVariant,
    meta: {
      title: "New Plan Variant",
      auth: true,
      guard: {
        action: "update",
        subject: "plans",
      },
    },
  },
  {
    path: "/invoices/",
    name: "Invoices List",
    component: InvoicesList,
    meta: {
      title: "Invoices List",
      auth: true,
      guard: {
        action: "view",
        subject: "invoices",
      },
    },
  },
  {
    path: "/invoices/:id",
    name: "Invoice",
    component: Invoice,
    meta: {
      title: "Invoice",
      auth: true,
      guard: {
        action: "view",
        subject: "invoices",
      },
    },
  },
  {
    path: "/invoices/refund-requests",
    name: "Invoice Refund Requests List",
    component: InvoiceRefundRequestsList,
    meta: {
      title: "Invoice Refund Requests List",
      auth: true,
      guard: {
        action: "view",
        subject: "invoice_refund_requests",
      },
    },
  },
  {
    path: "/invoices/:invoice_id/refund-requests/:id",
    name: "Edit Invoice Refund Request",
    component: EditInvoiceRefundRequest,
    meta: {
      title: "Edit Invoice Refund Request",
      auth: true,
      guard: {
        action: "update",
        subject: "invoice_refund_requests",
      },
    },
  },

  {
    path: "/config/cycle-menus/",
    name: "Cycle Menus List",
    component: CycleMenusList,
    meta: {
      title: "Cycle Menus List",
      auth: true,
      guard: {
        action: "create",
        subject: "cycle_menus",
      },
    },
  },
  {
    path: "/config/cycle-menus/:id/copy",
    name: "Cycle Menus Copy",
    component: CycleMenusCopy,
    meta: {
      title: "Cycle Menus Copy",
      auth: true,
      guard: {
        action: "create",
        subject: "cycle_menus",
      },
    },
  },
  {
    path: "/config/substitute-menus/",
    name: "Substitute Menus List",
    component: SubstituteMenusList,
    meta: {
      title: "Substitute Menus List",
      auth: true,
      guard: {
        action: "create",
        subject: "substitute_menus",
      },
    },
  },
  {
    path: "/config/substitute-menus/:id/copy",
    name: "Substitute Menus Copy",
    component: SubstituteMenusCopy,
    meta: {
      title: "Substitute Menus Copy",
      auth: true,
      guard: {
        action: "create",
        subject: "substitute_menus",
      },
    },
  },

  {
    path: "/:catchAll(.*)",
    name: "Page not found",
    component: Error404,
    meta: {
      auth: false,
    },
  },

  {
    path: "/config/food-groups/",
    name: "Food Groups",
    component: FoodGroupsList,
    meta: {
      title: "Food Groups",
      auth: true,
      guard: {
        action: "view",
        subject: "food_groups",
      },
    },
  },

  {
    path: "/config/food-groups/new",
    name: "New Food Group",
    component: NewFoodGroup,
    meta: {
      title: "New Food Group",
      auth: true,
      guard: {
        action: "create",
        subject: "food_groups",
      },
    },
  },

  {
    path: "/config/food-groups/:id",
    name: "Edit Food Group",
    component: EditFoodGroup,
    meta: {
      title: "Edit Food Group",
      auth: true,
      guard: {
        action: "update",
        subject: "food_groups",
      },
    },
  },

  {
    path: "/logistics/bag-movements/",
    name: "Bag Movements",
    component: BagMovementsList,
    meta: {
      title: "Bag Movements",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },

  {
    path: "/logistics/bag-movements/:id",
    name: "View Bag Movement",
    component: ViewBagMovement,
    meta: {
      title: "View Bag Movement",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/logistics/bag-movements/checkin",
    name: "Bag Movement Check-in",
    component: BagMovementCheckin,
    meta: {
      title: "Bag Movement Check-in",
      auth: true,
      guard: {
        action: "update",
        subject: "bag_scanning",
      },
    },
  },
  {
    path: "/logistics/bag-movements/checkout",
    name: "Bag Movement Check-out",
    component: BagMovementCheckout,
    meta: {
      title: "Bag Movement Check-out",
      auth: true,
      guard: {
        action: "update",
        subject: "bag_scanning",
      },
    },
  },
  {
    path: "/logistics/deliveries/",
    name: "Deliveries",
    component: DeliveriesList,
    meta: {
      title: "Deliveries",
      auth: true,
      guard: {
        action: "view",
        subject: "client_deliveries",
      },
    },
  },
  {
    path: "/logistics/delivery-bag-movements/",
    name: "Delivery Bag Movements",
    component: DeliveryBagMovementsList,
    meta: {
      title: "Delivery Bag Movements",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/client/:id/bag-movements",
    name: "Client Bag Movements",
    component: BagMovementsList,
    meta: {
      title: "Client Bag Movements",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },

  {
    path: "/config/addons/",
    name: "Addons",
    component: AddonsList,
    meta: {
      title: "Addons",
      auth: true,
      guard: {
        action: "view",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/addons/new",
    name: "New Addon",
    component: NewAddon,
    meta: {
      title: "New Addon",
      auth: true,
      guard: {
        action: "create",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/addons/:id",
    name: "Edit Addons",
    component: EditAddons,
    meta: {
      title: "Edit Addons",
      auth: true,
      guard: {
        action: "update",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/addons/:id/show",
    name: "View Addons",
    component: ViewAddons,
    meta: {
      title: "View Addons",
      auth: false,
      guard: {
        action: "view",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/discounts/",
    name: "Discounts",
    component: DiscountList,
    meta: {
      title: "Discounts",
      auth: true,
      guard: {
        action: "view",
        subject: "discount_codes",
      },
    },
  },

  {
    path: "/config/discount/new",
    name: "New Discount",
    component: EditDiscount,
    meta: {
      title: "New Discount",
      auth: true,
      guard: {
        action: "create",
        subject: "discount_codes",
      },
    },
  },

  {
    path: "/config/discount/:id",
    name: "Edit Discount",
    component: EditDiscount,
    meta: {
      title: "Edit Discount",
      auth: true,
      guard: {
        action: "update",
        subject: "discount_codes",
      },
    },
  },

  {
    path: "/clients/:id/consultations/new",
    name: "New Consultation",
    component: NewConsultations,
    meta: {
      title: "New Consultation",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/consultations/:consultationId",
    name: "Consultations View",
    component: ConsultationsView,
    meta: {
      title: "Consultations View",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/consultations/Edit/:consultationId",
    name: "Edit Consultations",
    component: EditConsultations,
    meta: {
      title: "Edit Consultation",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/bag-collection-requests",
    name: "Bag Collection Requests List",
    component: BagCollectionRequestsList,
    meta: {
      title: "Bag Collection Requests List",
      auth: false,
      guard: {
        action: "view",
        subject: "bag_collection_requests",
      },
    },
  },
  {
    path: "/bag-collection-requests/new",
    name: "New Bag Collection Request",
    component: NewBagCollectionRequest,
    meta: {
      title: "New Bag Collection Request",
      auth: true,
      guard: {
        action: "create",
        subject: "bag_collection_requests",
      },
    },
  },
  {
    path: "/bag-collection-requests/:id",
    name: "Edit Bag Collection Request",
    component: EditBagCollectionRequest,
    meta: {
      title: "Edit Bag Collection Request",
      auth: true,
      guard: {
        action: "update",
        subject: "bag_collection_requests",
      },
    },
  },
  {
    path: "/reports/revenue-report/",
    name: "Revenue Report",
    component: RevenueReport,
    meta: {
      title: "Revenue Report",
      auth: true,
      guard: {
        action: "view_revenue_report",
        subject: "reports",
      },
    },
  },
  {
    path: "/reports/collections-report/",
    name: "Collections Report",
    component: CollectionsReport,
    meta: {
      title: "Collections Report",
      auth: true,
      guard: {
        action: "view_collections_report",
        subject: "reports",
      },
    },
  },
  {
    path: "/operations/tdo/",
    name: "TDO",
    component: TDO,
    meta: {
      title: "TDO",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/packing-list/",
    name: "Packing List",
    component: PackingList,
    meta: {
      title: "Packing List",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/delivery-schedule/",
    name: "Delivery Schedule",
    component: DeliverySchedule,
    meta: {
      title: "Delivery Schedule",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/labels/",
    name: "Labels",
    component: Labels,
    meta: {
      title: "Labels",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/operations/food-label/",
    name: "Food Label",
    component: FoodLabel,
    meta: {
      title: "Food Label",
      auth: true,
      guard: {
        action: "view",
        subject: "operations",
      },
    },
  },
  {
    path: "/baa/view/:id",
    name: "BAA View",
    component: BaaView,
    meta: {
      title: "View Lead",
      auth: false,
    },
  },
  {
    path: "/plan-queues",
    name: "Plan Queues",
    component: PlanQueues,
    meta: {
      title: "Plan Queues",
      auth: true,
      guard: {
        action: "view",
        subject: "plan_amendment_queues",
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
import ability from "@/services/ability";

router.beforeEach((to, from, next) => {
  document.title =
    process.env.VUE_APP_TITLE + (to.meta.title ? " - " + to.meta.title : "");

  if (store.state.auth.authenticated) {
    let abilities = {};
    if (store.state.auth.user.abilities) {
      abilities = store.state.auth.user.abilities;
    }
    ability.update(abilities);
    if (to.meta.auth) {
      if (
        to.meta.guard &&
        !ability.can(to.meta.guard.action, to.meta.guard.subject)
      ) {
        next({ name: "Error403" });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (to.name !== "SignIn" && to.meta.auth) {
      next({ name: "SignIn" });
    } else {
      next();
    }
  }
});
export default router;