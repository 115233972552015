<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>Refund request</h6>
    </template>
    <refund-request-form
      :invoice-payment-source="invoicePaymentSource"
      :invoice-id="invoiceId"
      :initial-data="initialData"
      footer-class="modal-footer"
      :show-close-button="true"
      @close="$emit('close')"
      @saved="$emit('saved')"
    />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import RefundRequestForm from "./RefundRequestForm.vue";
export default {
  name: "RefundRequestModal",
  components: {
    Modal,
    RefundRequestForm,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    invoicePaymentSource: {
      type: String,
      default: "",
    },
    invoiceId: {
      type: [String, Number],
      required: true,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saved", "close"],

  methods: {},
};
</script>
