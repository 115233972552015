<template>
  <div>
    <data-table
      v-if="showDataTable"
      :fetch-data-function="fetchData"
      :options="options"
      :filter="filter"
    />
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import apiFinancialReport from "@/services/apiFinancialReport";
export default {
  name: "ListTable",
  components: {
    DataTable,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {},
      showDataTable: false,
    };
  },
  mounted() {
    this.setOptions();
    this.showDataTable = true;
  },
  methods: {
    getColumns() {
      return [
        {
          title: "Payment Date",
          data: "payment_date",
          class: "text-xs",
        },
        {
          title: "Invoice ID",
          data: "invoice_id",
          class: "text-xs",
        },
        {
          title: "Payment Source",
          data: "payment_source",
          class: "text-xs",
        },
        {
          title: "KE Number",
          data: "ke_number",
          class: "text-xs",
        },
        {
          title: "Email",
          data: "email",
          class: "text-xs",
        },
        {
          title: "Plan name",
          data: "plan_name",
          class: "text-xs",
        },
        {
          title: "Delivery Count",
          data: "delivery_count",
          class: "text-xs",
        },
        {
          title: "Gross Amount",
          data: "gross_amount",
          class: "text-xs",
        },
        {
          title: "Net Amount",
          data: "net_amount",
          class: "text-xs",
        },
        {
          title: "Discount",
          data: "discount",
          class: "text-xs",
        },
        {
          title: "VAT",
          data: "vat",
          class: "text-xs",
        },
        {
          title: "Addon",
          data: "addon",
          class: "text-xs",
        },
        {
          title: "Addon Amount",
          data: "addon_amount",
          class: "text-xs",
        },
        {
          title: "Bag Deposit",
          data: "bag_deposit",
          class: "text-xs",
        },
        {
          title: "Creator",
          data: "creator",
          class: "text-xs",
        },
        {
          title: "Purchase Type",
          data: "purchase_type",
          class: "text-xs",
        },
        {
          title: "Initiator Type",
          data: "initiator_type",
          class: "text-xs",
        },
        {
          title: "Invoice Category",
          data: "invoice_category",
          class: "text-xs",
        },
        {
          title: "Discount 1",
          data: "discount_1",
          class: "text-xs",
          defaultContent: "",
        },
        {
          title: "Discount 1 Amount",
          data: "discount_amount_1",
          class: "text-xs",
          defaultContent: "",
        },
        {
          title: "Discount 2",
          data: "discount_2",
          class: "text-xs",
          defaultContent: "",
        },
        {
          title: "Discount 2 Amount",
          data: "discount_amount_2",
          class: "text-xs",
          defaultContent: "",
        },
        {
          title: "Discount 3",
          data: "discount_3",
          class: "text-xs",
          defaultContent: "",
        },
        {
          title: "Discount 3 Amount",
          data: "discount_amount_3",
          class: "text-xs",
          defaultContent: "",
        },
      ];
    },
    setOptions() {
      this.options = {
        serverSide: true,
        ordering: false,
        searching: false,
        info: true,
        paging: true,
        columns: this.getColumns(),
      };
    },
    async fetchData(options) {
      try {
        const response = await apiFinancialReport.collectionsReport(options);
        return response.data;
      } catch (error) {
        console.error("Error fetching report data:", error);
        return { data: [], recordsTotal: 0, recordsFiltered: 0 };
      }
    },
  },
};
</script>
