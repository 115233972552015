<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-2">{{ cardHeader }}</h6>
      </div>
    </div>
    <div class="card-body scroll">
      <div
        :class="
          items.length
            ? 'table-responsive-height-300 '
            : '' + ' table-responsive'
        "
      >
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                v-for="(header, index) of headers"
                :key="index"
                :class="`text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ${
                  index !== 0 ? 'ps-2' : ''
                }`"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody v-if="items.length > 0">
            <tr
              v-for="(
                {
                  brand: { logo, name },
                  badge: { color },
                  expiry,
                  sequence,
                  plan,
                  mobileno,
                  //   pendingdays,
                  id,
                  progress,
                },
                index
              ) of items"
              :key="index"
            >
              <td>
                <div class="d-flex px-2">
                  <div>
                    <img
                      :src="logo"
                      class="avatar avatar-sm rounded-circle me-2"
                    />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs">{{ name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ expiry }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ plan }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ sequence }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ mobileno }}</p>
              </td>
              <!-- <td>
                                <p class="text-xs font-weight-bold mb-0">{{ pendingdays }}</p>
                            </td> -->
              <!-- <td>
                                <p class="text-xs font-weight-bold mb-0">{{ budget }}</p>
                            </td> -->
              <!-- <td>
                                <span class="badge badge-dot me-4">
                                    <i :class="`bg-${color}`"></i>
                                    <span class="text-dark text-xs">{{ text }}</span>
                                </span>
                            </td> -->
              <td class="align-middle text-center">
                <div class="d-flex align-items-center">
                  <span class="me-2 text-xs">{{ progress }}%</span>
                  <div>
                    <div class="progress">
                      <div
                        :class="`progress-bar bg-${color}`"
                        role="progressbar"
                        :aria-valuenow="progress"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ width: progress + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <router-link
                  :to="{ name: 'Client Profile', params: { id: id } }"
                  ><i
                    class="ni ni-settings text-sm opacity-10 text-dark"
                    aria-hidden="true"
                  ></i
                ></router-link>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <th
                :colspan="headers?.length"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
              >
                No Records Found!
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientPlansExpiring",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      progress: Number,
      budget: String,
      brand: {
        type: Object,
        logo: String,
        name: String,
      },
      badge: {
        type: Object,
        text: String,
        color: String,
      },
    },
    cardHeader: {
      type: String,
      required: true,
    },
  },
};
</script>
