<template>
  <div class="py-4 container-fluid">
    <loading-spinner :loading="loading.events" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-auto mt-3 mt-lg-0">
                <div class="row">
                  <div class="col-lg-auto">
                    <h6 class="mb-0">{{ cardTitle("Menu") }}</h6>
                  </div>
                  <RecipeMacrosBadge
                    v-if="showEventDetails"
                    class="col-lg mt-3 mt-lg-0"
                    :calories="dailyMacros.calories"
                    :carbs="dailyMacros.carbs"
                    :fat="dailyMacros.fat"
                    :protein="dailyMacros.protein"
                  />
                </div>
                <div class="col-12">
                  <div v-if="showFreezeSwitch" class="d-flex mt-3">
                    <p class="text-sm me-3 my-auto">Freeze</p>
                    <div class="my-auto">
                      <argon-switch
                        id="delivery-status"
                        v-model:checked="deliveryIsFrozen"
                        name="Freeze"
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                        @change="updateDeliveryStatus"
                      />
                    </div>

                    <span
                      v-if="$can('paid_wastage', 'client_deliveries')"
                      class="btn btn-white text-success ms-3 my-auto btn-sm"
                      @click="showModalObject(paidWastageModal)"
                      @close="closeModalObject(paidWastageModal)"
                    >
                      Mark as paid wastage
                    </span>
                  </div>

                  <div v-if="menuItems.length === 0" class="mt-3 text-sm">
                    There is no menu to display for the selected date.
                  </div>
                </div>
              </div>
              <div class="col-lg text-lg-end mt-3 mt-lg-0">
                <router-link
                  v-if="$can('view', 'invoices') && planDetails.invoice"
                  :to="{
                    name: 'Invoice',
                    params: { id: planDetails.invoice.data.id },
                  }"
                  class="btn btn-white text-success mb-3"
                >
                  Invoice
                </router-link>
                <router-link
                  v-if="
                    $can('transfer', 'client_plan_details') &&
                    planDetails.invoice
                  "
                  :to="{
                    name: 'Client Plan Transfer',
                    params: { id: client.id, planId: planDetails.id },
                  }"
                  class="btn btn-white text-success ms-3 mb-3"
                >
                  Plan Transfer
                </router-link>
                <span
                  v-if="$can('export', 'client_plan_details')"
                  class="btn btn-white text-success ms-3 mb-3"
                  @click="showModalObject(downloadPDFModal)"
                >
                  Download PDF
                </span>
                <span
                  class="btn btn-white text-success ms-3 mb-3"
                  @click="showModalObject(freezeModal)"
                >
                  Long Freeze
                </span>
                <span
                  class="btn btn-white text-success ms-3 mb-3"
                  @click="showModalObject(unFreezeModal)"
                >
                  Long Unfreeze
                </span>
                <span
                  v-if="$can('add_compensatory_days', 'client_plan_details')"
                  class="btn btn-white text-success ms-3 mb-3"
                  @click="showModalObject(compensatoryDaysModal)"
                >
                  Add Compensatory Days
                </span>
                <span
                  v-if="$can('add_referral_days', 'client_plan_details')"
                  class="btn btn-white text-success ms-3 mb-3"
                  @click="showModalObject(referralDaysModal)"
                >
                  Add Referral Days
                </span>
                <span
                  class="btn btn-white text-success ms-3 mb-3"
                  @click="showModalObject(plotDeliveriesModal)"
                >
                  Plot deliveries
                </span>
                <router-link
                  v-if="$can('view', 'clients')"
                  :to="{ name: 'Client Profile', params: { id: client.id } }"
                  class="btn btn-white text-success ms-3 mb-3"
                >
                  Back
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxxl-8 mt-4">
        <div
          class="card widget-calendar widget-calendar-week h-100 plan-calendar"
        >
          <div class="p-3 card-body">
            <full-calendar
              v-if="showFullCalendar"
              id="calendar-client-plan"
              ref="fullCalendar"
              :options="calendarOptions"
            >
              <template #eventContent="info">
                <div
                  v-if="info.view.type == 'dayGridWeek'"
                  :class="
                    'cursor-pointer fc-event-main fc-event-main-' +
                    info.event.extendedProps.mealTypeCode
                  "
                >
                  <recipe-macros-info
                    v-if="info.event.extendedProps.macros"
                    class="bg-white text-dark text-xs"
                    :calories="info.event.extendedProps.macros.calories"
                    calories-text="Kcal"
                    :two-lines="true"
                    :carbs="info.event.extendedProps.macros.carbs"
                    :fat="info.event.extendedProps.macros.fat"
                    :protein="info.event.extendedProps.macros.protein"
                    protein-text="Pro"
                  />
                  <div v-else class="fc-event-title py-1">
                    <div
                      :class="
                        `text-xs meal-type-name badge` +
                        (info.event.extendedProps.menuItem.creator_type ==
                        'client'
                          ? ' bg-primary text-white'
                          : ' text-brown-classic')
                      "
                    >
                      {{ info.event.extendedProps.mealTypeName }}
                      <dislikes-allergens-icons
                        :menu-item="info.event.extendedProps.menuItem"
                      />
                    </div>
                    <div
                      class="recipe-name"
                      data-bs-toggle="modal"
                      data-bs-target="#menu-modal"
                      :data-menu-item-code="
                        info.event.extendedProps.menuItem.meal_code
                      "
                      :data-menu-item-id="info.event.extendedProps.menuItem.id"
                      :data-menu-item="
                        JSON.stringify(info.event.extendedProps.menuItem)
                      "
                      :data-daily-macros="
                        JSON.stringify(
                          getMacrosForDeliveryDate(
                            info.event.extendedProps.menuItem.menu_date
                          )
                        )
                      "
                      @click="
                        showDate(
                          info.event.extendedProps.menuItem.menu_date,
                          true
                        )
                      "
                    >
                      {{ info.event.extendedProps.menuItem["recipe.name"] }}
                    </div>
                    <div
                      v-if="info.event.extendedProps.menuItem.customizations"
                      class="text-xxs text-orange"
                    >
                      {{ info.event.extendedProps.menuItem.customizations }}
                    </div>

                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#menu-modal"
                      :data-menu-item-code="
                        info.event.extendedProps.menuItem.meal_code
                      "
                      :data-menu-item-id="info.event.extendedProps.menuItem.id"
                      :data-menu-item="
                        JSON.stringify(info.event.extendedProps.menuItem)
                      "
                      :data-daily-macros="
                        JSON.stringify(
                          getMacrosForDeliveryDate(
                            info.event.extendedProps.menuItem.menu_date
                          )
                        )
                      "
                      :data-show-form="true"
                      @click="
                        showDate(
                          info.event.extendedProps.menuItem.menu_date,
                          true
                        )
                      "
                      ><i class="far fa-edit text-secondary"></i
                    ></span>
                    <span
                        class="ms-2 cursor-pointer"
                        @click="showDate(
                          info.event.extendedProps.menuItem.menu_date,
                          true
                        );deleteMenu(info.event.extendedProps.menuItem.id)"
                    ><i class="fas fa-trash text-secondary"></i
                    ></span>
                  </div>
                </div>
                <div v-else class="fc-event-main">
                  <div class="fc-event-main-frame cursor-pointer">
                    <div class="fc-event-title-container">
                      <div class="fc-event-title fc-sticky">
                        {{ info.event.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>
      <div class="col-xxxl-4 mt-4">
        <plan-details-card
          v-if="!loading.planDetails"
          class="h-100"
          :title="planDetails['planVariant.name']"
          :client-id="client.id"
          :info="{
            id: planDetails.id,
            startDate: planDetails.start_date,
            endDate: planDetails.actual_end_date,
            pendingDays: planDetails.pending_days,
            notes: planDetails.notes,
            status: planDetails.status,
          }"
          :plan-amendment-queues-data="planDetails.planAmendmentQueues.data"
          @plan-amendment-queues-changed="setPlanDetails()"
          @auto-amendments-changed="setPlanDetails()"
        />
      </div>
      <div class="col-xs-12 col-lg-4 mt-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-12 mt-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">First name:</strong><br />
                    {{ client.first_name }}
                  </li>
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Last name:</strong><br />
                    {{ client.last_name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Mobile:</strong><br />
                    {{ client.mobile_number }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Email:</strong> <br />
                    {{ client.email }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-12 mt-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Account manager:</strong> <br />
                    {{ client["account_manager.name"] }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Nutritionist:</strong> <br />
                    {{ client["nutritionist.name"] }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="showEventDetails && !loading.menu && delivery.id > 0"
        class="col-xs-12 col-lg-4 mt-4"
      >
        <plan-delivery-card
          v-model:delivery-address-id="delivery.address_id"
          v-model:delivery-schedule="delivery.delivery_schedule"
          class="h-100"
          :client-id="client.id"
          :delivery-id="delivery.id"
          :card-title="cardTitle('Delivery')"
        />
      </div>
      <div
        v-show="showEventDetails && !loading.menu && delivery.id > 0"
        class="col-xs-12 col-lg-4 mt-4"
      >
        <macro-calculator-card
          v-if="!loading.planDetails"
          :client-id="client.id"
          :default-start-date="planDetails.start_date"
          :default-end-date="planDetails.actual_end_date"
          class="h-100"
        />
      </div>
      <div class="col-xl-12 mt-4">
        <div v-if="showEventDetails && !loading.events" class="row">
          <div class="col-12">
            <template v-if="!loading.menu">
              <div v-if="menuItems.length > 0" class="row">
                <div
                  v-for="(el, key) in mealTypes"
                  :key="key"
                  class="col-12 col-md-6 mt-4"
                >
                  <div class="card h-100">
                    <div class="card-header pb-0">
                      <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                          <h6 class="mb-0 text-capitalize">{{ el.value }}</h6>
                        </div>
                        <div
                          v-if="getNextAvailableCode(menuItemsGroup[el.name])"
                          class="col-md-4 text-end"
                        >
                          <button
                            data-bs-target="#menu-modal"
                            data-bs-toggle="modal"
                            :data-menu-item-code="
                              getNextAvailableCode(menuItemsGroup[el.name])
                            "
                            :data-daily-macros="JSON.stringify(dailyMacros)"
                            class="mb-0 btn btn-xs bg-gradient-success"
                          >
                            <i class="fas fa-plus pe-2" aria-hidden="true"></i>
                            Add {{ el.value }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <template
                        v-for="(menuItem, index) of menuItemsGroup[el.name]"
                        :key="index"
                      >
                        <template v-if="menuItem">
                          <menu-item
                            :menu-item="menuItem"
                            :daily-macros="dailyMacros"
                            @delete-menu="deleteMenu(menuItem.id)"
                          />
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="showDateColumn" class="row">
          <div class="mt-4 col-12">
            <div class="card">
              <div class="card-header p-3 pb-0">
                <h6 class="mb-0">{{ cardTitle("") }}</h6>
              </div>
              <div class="card-body">
                <template v-if="planDetails.pending_days > 0">
                  <argon-textarea
                    id="notes"
                    placeholder=""
                    :model-value="delivery.notes"
                    @update:model-value="delivery.notes = $event"
                    >Delivery notes
                  </argon-textarea>
                </template>
                <template v-else>
                  Pending days: {{ planDetails.pending_days }}
                </template>
              </div>
              <div class="card-footer py-1">
                <template v-if="planDetails.pending_days > 0">
                  <button
                    class="btn btn-outline-success btn-sm"
                    @click="createDelivery"
                  >
                    Create delivery
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <plan-menu-modal
    v-if="menuPlan.type"
    :delivery-id="delivery.id"
    :delivery-date="delivery.date"
    :plan-type-initial="menuPlan.type"
    :plan-type-choices="planTypeChoices"
    :client-id="client.id"
    @saved="afterMenuItemSaved(selectedEvent)"
  />
  <freeze-modal
    v-if="freezeModal.show"
    :modal-id="freezeModal.id"
    :title="freezeModal.title"
    :button-text="freezeModal.buttonText"
    :client-id="client.id"
    :client-plan-detail-id="planDetails.id"
    @change="handleFreezeChange()"
    @close="closeModalObject(freezeModal)"
  />
  <freeze-modal
    v-if="unFreezeModal.show"
    :modal-id="unFreezeModal.id"
    :title="unFreezeModal.title"
    :button-text="unFreezeModal.buttonText"
    :client-id="client.id"
    :client-plan-detail-id="planDetails.id"
    @change="handleFreezeChange()"
    @close="closeModalObject(unFreezeModal)"
  />
  <compensatory-days-modal
    v-if="compensatoryDaysModal.show"
    :modal-id="compensatoryDaysModal.id"
    :client-id="client.id"
    :client-plan-detail-id="planDetails.id"
    @change="handleModalObjectChange()"
    @close="closeModalObject(compensatoryDaysModal)"
  />
  <referral-days-modal
    v-if="referralDaysModal.show"
    :modal-id="referralDaysModal.id"
    :client-id="client.id"
    :client-plan-detail-id="planDetails.id"
    @change="handleModalObjectChange()"
    @close="closeModalObject(referralDaysModal)"
  />
  <plot-deliveries-modal
    v-if="plotDeliveriesModal.show"
    :modal-id="plotDeliveriesModal.id"
    :client-id="client.id"
    :client-plan-detail-id="planDetails.id"
    @change="handleModalObjectChange()"
    @close="closeModalObject(plotDeliveriesModal)"
  />
  <paid-wastage-modal
    v-if="paidWastageModal.show"
    :modal-id="paidWastageModal.id"
    :client-id="client.id"
    :delivery-id="delivery.id"
    :title="'Mark ' + dateFormat(delivery.date) + ' as paid wastage'"
    @saved="handleModalObjectChange()"
    @close="closeModalObject(paidWastageModal)"
  />
  <download-pdf-modal
    v-if="downloadPDFModal.show"
    :modal-id="downloadPDFModal.id"
    :title="downloadPDFModal.title"
    :button-text="downloadPDFModal.buttonText"
    :client-id="client.id"
    :client-plan-detail-id="planDetails.id"
    @close="closeModalObject(downloadPDFModal)"
  />
</template>

<script>
import MenuItem from "./components/MenuItem.vue";
import PlanDeliveryCard from "./components/PlanDeliveryCard";
import PlanDetailsCard from "./components/PlanDetailsCard";
import PlanMenuModal from "./components/PlanMenuModal";
import LoadingSpinner from "@/components/LoadingSpinner";
import ArgonSwitch from "@/components/ArgonSwitch";
import ArgonTextarea from "@/components/ArgonTextarea";
import API from "@/services/api";
import ApiAddresses from "@/services/apiAddresses";
import ApiMiscList from "@/services/apiMiscList";
import apiClientDelivery from "@/services/apiClientDelivery";
import { showMessage } from "@/assets/js/show-message";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import { dateFormat, previousDate, isDateBetween } from "@/lib/dateHelper";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import RecipeMacrosInfo from "@/components/RecipeMacrosInfo";
import RecipeMacrosBadge from "@/components/RecipeMacrosBadge";
import MacroCalculatorCard from "@/components/Cards/MacroCalculatorCard";
import {
  getEventBgColor,
  getHourFromSlotLabelsByName,
  getSlotLabelsByHour,
} from "@/lib/fullCalendarHelper";
import DislikesAllergensIcons from "@/components/DislikesAllergensIcons";
import FreezeModal from "./components/FreezeModal";
import CompensatoryDaysModal from "./components/CompensatoryDaysModal.vue";
import ReferralDaysModal from "./components/ReferralDaysModal.vue";
import PlotDeliveriesModal from "./components/PlotDeliveriesModal";
import PaidWastageModal from "./components/PaidWastageModal";
import DownloadPdfModal from "./components/DownloadPdfModal.vue";
import { showModal, closeModal } from "@/lib/bootstrap";
export default {
  name: "Plan",
  components: {
    DislikesAllergensIcons,
    MacroCalculatorCard,
    RecipeMacrosBadge,
    RecipeMacrosInfo,
    PlanMenuModal,
    PlanDeliveryCard,
    PlanDetailsCard,
    LoadingSpinner,
    MenuItem,
    ArgonSwitch,
    ArgonTextarea,
    FullCalendar,
    FreezeModal,
    CompensatoryDaysModal,
    ReferralDaysModal,
    PlotDeliveriesModal,
    PaidWastageModal,
    DownloadPdfModal,
  },
  emits: ["queueUpdateEvent"],
  data() {
    return {
      planTypeChoices: [],
      client: {
        id: this.$route.params.id,
      },
      events: [],
      initialEventId: 0,
      loading: {
        recipes: true,
        menu: true,
        menuModal: true,
        events: true,
        planDetails: true,
      },
      planDetails: {
        id: this.$route.params.planId,
        start_date: "",
      },
      menuItems: [],
      menuItemsGroup: [],
      dailyMacros: {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      },
      today: dateFormat(new Date().toString(), "YYYY-MM-DD"),
      deliveryIsFrozen: false,
      mealTypes: [],
      menuPlan: {},
      menuItemCode: "",
      formSubmitted: {
        menu: false,
      },
      selectedDate: "",
      selectedEvent: {},
      showEventDetails: false,
      showDateColumn: false,
      delivery: {
        id: 0,
        notes: "",
        date: dateFormat(new Date().toString(), "YYYY-MM-DD"),
        address_id: 0,
        address: "",
        delivery_schedule: "",
      },
      weekEvents: [],
      weekMenuItems: {},
      slotLabelsByHour: [],
      calendarOptions: {
        firstDay: 1,
        showNonCurrentDates: false,
        contentHeight: "auto",
        customButtons: {
          customToday: {
            text: "today",
            click: () => {
              this.calendarApi.gotoDate(this.today);
              this.showDate(this.today);
            },
          },
        },
        plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
        events: [],
        initialView: "dayGridMonth",
        selectable: false,
        editable: false,
        eventClick: this.eventClicked,
        dateClick: this.dateClicked,
        datesSet: this.datesSet,
        eventsSet: this.eventsSet,
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "customToday prev,next dayGridWeek,dayGridMonth,multiMonthYear", // will normally be on the right. if RTL, will be on the left
        },
        views: {
          month: {
            titleFormat: {
              month: "long",
              year: "numeric",
            },
          },
        },
        multiMonthMaxColumns: 6,
        multiMonthMinWidth: 180,
      },
      showFullCalendar: false,
      freezeModal: {
        title: "Select interval to freeze",
        id: "freeze-modal",
        buttonText: "Freeze",
        show: false,
      },
      unFreezeModal: {
        id: "unfreeze-modal",
        title: "Choose start and end dates to unfreeze delivery days",
        buttonText: "UnFreeze",
        show: true,
      },

      compensatoryDaysModal: {
        id: "compensatory-days-modal",
        show: false,
      },
      referralDaysModal: {
        id: "referral-days-modal",
        show: false,
      },
      plotDeliveriesModal: {
        id: "plot-deliveries-modal",
        show: false,
      },
      showFreezeSwitch: false,
      paidWastageModal: {
        id: "paid-wastage-modal",
        show: false,
        deliveryId: 0,
      },
      downloadPDFModal: {
        title: "Select interval to download",
        id: "download-pdf-smodal",
        buttonText: "Download",
        show: false,
      },
    };
  },
  computed: {
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    getEventBgColor,
    getSlotLabelsByHour,
    getHourFromSlotLabelsByName,
  },
  async mounted() {
    await this.setPlanTypeChoices();
    await this.setClient();
    await this.setPlanDetails();
    await this.setMenuPlan();
    await this.setMealTypes();
    this.slotLabelsByHour = await getSlotLabelsByHour(this.mealTypes);
    this.showFullCalendar = true;
  },
  methods: {
    dateFormat,
    getSelectedDate() {
      if (
        isDateBetween(
          this.today,
          this.planDetails.start_date,
          this.planDetails.end_date
        )
      ) {
        return this.today;
      }
      return this.planDetails.start_date;
    },
    async setMealTypes() {
      this.mealTypes = ApiMiscList.mealTypes();
    },
    cardTitle(suffix) {
      let result = "Today's " + suffix;
      let start = moment(this.delivery.date);
      if (this.delivery.date != this.today) {
        result = start.format("Do MMMM") + " " + suffix;
      }
      return result;
    },

    async afterMenuItemSaved(event) {
      if (this.calendarApi.view.type === "dayGridWeek") {
        await this.setWeekEvents(
          dateFormat(this.calendarApi.view.currentStart, "YYYY-MM-DD"),
          dateFormat(
            previousDate(this.calendarApi.view.currentEnd),
            "YYYY-MM-DD"
          )
        );
      }
      await this.showEvent(event);
    },

    async setMenuPlan() {
      const response = await API.getPlan(this.planDetails["plan.id"]).catch(
        handleError
      );
      if (response.status === 200) {
        this.menuPlan = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    setMenuItemsGroup() {
      this.menuItemsGroup = {};
      for (let k in this.mealTypes) {
        this.menuItemsGroup[this.mealTypes[k].name] = {};
        for (
          let i = 1;
          i <= process.env.VUE_APP_MAX_RECIPES_IN_MENU_CATEGORY;
          i++
        ) {
          let mealCode = this.mealTypes[k].name + i;
          this.menuItemsGroup[this.mealTypes[k].name][mealCode] =
            this.menuItems.find((el) => el.meal_code == mealCode);
        }
      }
    },
    setDailyMacros() {
      this.dailyMacros = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };
      for (let i in this.menuItems) {
        this.dailyMacros.calories += parseFloat(
          this.menuItems[i]["recipe.macros"].calories
        );
        this.dailyMacros.carbs += parseFloat(
          this.menuItems[i]["recipe.macros"].carbs
        );
        this.dailyMacros.fat += parseFloat(
          this.menuItems[i]["recipe.macros"].fat
        );
        this.dailyMacros.protein += parseFloat(
          this.menuItems[i]["recipe.macros"].protein
        );
      }
    },
    async deleteMenu(menuItemId) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.formSubmitted.menu) {
            showMessage("Deleting data. Please wait.", "", 1500);
            return;
          }
          this.formSubmitted.menu = true;
          let saveResponse = await apiClientDelivery
            .deleteMenuItem(this.client.id, this.delivery.id, menuItemId)
            .catch(handleError);
          if (saveResponse.status === 200) {
            showMessage("Menu item removed successfully.", "success");
          } else {
            showMessage(saveResponse.message, "error");
          }

          await this.afterMenuItemDeleted(this.selectedEvent);
          this.formSubmitted.menu = false;
        }
      });
    },
    async afterMenuItemDeleted(event) {
      if (this.calendarApi.view.type === "dayGridWeek") {
        await this.setWeekEvents(
            dateFormat(this.calendarApi.view.currentStart, "YYYY-MM-DD"),
            dateFormat(
                previousDate(this.calendarApi.view.currentEnd),
                "YYYY-MM-DD"
            )
        );
      }
      await this.showEvent(event);
    },
    getNextAvailableCode(items) {
      let result = false;
      for (let i in items) {
        if (!items[i]) {
          result = i;
          break;
        }
      }
      return result;
    },
    async updateDeliveryStatus() {
      let formData = new FormData();
      formData.append("status", this.deliveryIsFrozen ? "Freeze" : "Active");
      let saveResponse = await apiClientDelivery
        .changeDeliveryStatus(this.client.id, this.delivery.id, formData)
        .catch(handleError);

      let message = "Delivery unfrozen successfully.";
      if (this.deliveryIsFrozen) {
        message = "Delivery frozen successfully.";
      }
      if (saveResponse.status === 200) {
        showMessage(message, "success");
        if (this.deliveryIsFrozen) {
          this.planDetails.pending_days++;
        } else {
          this.planDetails.pending_days--;
        }
        this.selectedEvent.setExtendedProp(
          "status",
          this.deliveryIsFrozen ? "Freeze" : "Active"
        );
        this.selectedEvent.setProp(
          "classNames",
          getEventBgColor(this.selectedEvent.extendedProps.status)
        );
      } else {
        showMessage(saveResponse.message, "error");
      }
      await this.showEvent(this.selectedEvent);
    },

    async setPlanDetails() {
      this.loading.planDetails = true;
      const appInstance = this;
      await API.getClientPlanDetailsShow(
        appInstance.client.id,
        appInstance.planDetails.id
      )
        .then((res) => {
          appInstance.planDetails = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Client Plan not found",
              willClose: () => {
                appInstance.$router.push({
                  name: "Client Profile",
                  params: { id: appInstance.client.id },
                });
              },
            });
          } else {
            const response = handleError(err);
            showMessage(response.message, "error");
          }
        });
      this.loading.planDetails = false;
    },
    dateClicked(info) {
      if (info.view.type == "multiMonthYear") {
        this.calendarApi.gotoDate(info.dateStr);
        this.calendarApi.changeView("dayGridMonth");
      }
      if (info.dateStr == this.delivery.date) {
        return;
      }
      this.delivery.date = info.dateStr;
      this.showDate(this.delivery.date);
    },
    showDate(date, checkDate) {
      if (checkDate && date == this.delivery.date) {
        return;
      }
      let eventId = this.getEventIdByDate(date);
      if (eventId) {
        this.initialEventId = eventId;
        this.showEvent(this.calendarApi.getEventById(eventId));
      } else {
        this.showEventDetails = false;
        this.showDateColumn = true;
        this.showFreezeSwitch = false;
      }
    },
    async eventClicked(info) {
      if (dateFormat(info.event.start, "YYYY-MM-DD") == this.delivery.date) {
        return;
      }
      await this.showEvent(info.event);
    },
    async showEvent(event) {
      this.showFreezeSwitch = true;
      this.showEventDetails = true;
      this.showDateColumn = false;
      if (!event) {
        this.loading.menu = false;

        return;
      }
      this.loading.menu = true;
      this.selectedEvent = event;
      this.deliveryIsFrozen =
        event.extendedProps.status === "Freeze" ||
        event.extendedProps.status === "Week Off";
      this.delivery.id = event.id;
      this.delivery.date = dateFormat(event.start, "YYYY-MM-DD");
      this.selectedDate = this.delivery.date;
      if (this.deliveryIsFrozen) {
        this.loading.menu = false;
        this.showEventDetails = false;
        this.showDateColumn = false;
        return;
      }
      await this.setMenuItems();
      this.setDailyMacros();
      this.setMenuItemsGroup();
      await initChoices(
        "delivery-address",
        {},
        this.delivery.address_id,
        ApiAddresses.getAddressText(this.delivery.address)
      );
      await initChoices(
        "delivery-schedule",
        {},
        this.delivery.delivery_schedule,
        this.delivery_schedule
      );
      this.loading.menu = false;
    },
    async setMenuItems() {
      this.menuItems = [];

      if (this.delivery.id > 0) {
        const response = await API.getClientDelivery(
          this.client.id,
          this.delivery.id
        ).catch(handleError);
        if (response.status === 200) {
          this.delivery.address_id = response.data.data.address_id;
          this.delivery.address = response.data.data.address;
          this.delivery.delivery_schedule =
            response.data.data.delivery_schedule;
          this.menuItems = response.data.data.clientDailyMenus.data;
        } else {
          showMessage(response.message, "error");
        }
      }
    },
    async createDelivery() {
      if (this.formSubmitted.createDelivery) {
        showMessage("Creating delivery. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted.createDelivery = true;
      let formData = new FormData();
      formData.append("delivery_date", this.delivery.date);
      formData.append("client_plan_detail_id", this.planDetails.id);
      formData.append("notes", this.delivery.notes);
      let saveResponse = await API.createClientDelivery(
        this.client.id,
        formData
      ).catch(handleError);

      if (saveResponse.status === 200) {
        showMessage("Client delivery created successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }

      await this.setEvents(this.delivery.date);
      await this.calendarApi.addEventSource(this.weekEvents);
      this.showDate(this.delivery.date);
      this.formSubmitted.createDelivery = false;
    },
    async setEvents(selectedDate) {
      const appInstance = this;
      this.loading.events = true;
      const status_callbacks = {
        404: { name: "Client Profile", params: { id: appInstance.client.id } },
        403: { name: "Client Profile", params: { id: appInstance.client.id } },
      };
      const response = await API.getClientPlanDeliveries(
        this.client.id,
        this.planDetails.id
      ).catch((err) => handleError(err, status_callbacks));
      this.events = [];
      this.selectedDate = "";
      if (response.status === 200) {
        for (let i in response.data.data) {
          if (this.selectedDate === "") {
            if (selectedDate) {
              this.selectedDate = selectedDate;
            } else {
              this.selectedDate = this.getSelectedDate();
            }
          }
          this.events.push({
            id: response.data.data[i].id,
            title: response.data.data[i].bag_delivery_status,
            start: response.data.data[i].delivery_date,
            end: response.data.data[i].delivery_date,
            className: getEventBgColor(response.data.data[i].status),
            extendedProps: {
              status: response.data.data[i].status,
            },
          });
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.events = false;
    },
    getEventIdByDate(date) {
      let event = this.events.find((x) => x.start === date);
      if (event) {
        return event.id;
      }
      return 0;
    },
    async getEvents(start_date, end_date) {
      this.loading.events = true;
      const response = await API.getClientPlanDeliveries(
        this.client.id,
        this.planDetails.id,
        start_date,
        end_date
      ).catch(handleError);
      let events = [];
      if (response.status === 200) {
        for (let i in response.data.data) {
          let deliveryData = response.data.data[i];
          const menuItems = deliveryData.clientDailyMenus.data;
          for (let mealTypeKey in this.mealTypes) {
            const mealType = this.mealTypes[mealTypeKey];
            if (deliveryData.status === "Active") {
              if (menuItems.length > 0) {
                this.weekMenuItems[deliveryData.delivery_date] = menuItems;
                for (let menuItemKey in menuItems) {
                  const menuItem = menuItems[menuItemKey];
                  const mealTypeCode = menuItem.meal_code.slice(0, 2);
                  if (mealTypeCode != mealType.name) {
                    continue;
                  }
                  const mealTypeName =
                    this.getMealTypeValueByName(mealTypeCode);
                  const hour = getHourFromSlotLabelsByName(
                    mealTypeName,
                    this.slotLabelsByHour
                  );
                  const delivery_date = new Date(
                    deliveryData.delivery_date + " " + hour
                  );
                  let event = {
                    id: deliveryData.id,
                    title: mealTypeName + " " + menuItem["recipe.name"],
                    start: delivery_date,
                    end: delivery_date,
                    className: "py-0 my-0 bg-white text-dark",
                    extendedProps: {
                      type: "event",
                      mealTypeName: mealTypeName,
                      mealTypeCode: mealTypeCode,
                      menuItem: menuItem,
                    },
                  };
                  events.push(event);
                }
              }
            }
          }
          if (menuItems.length > 0 && deliveryData.status == "Active") {
            const delivery_date = new Date(
              deliveryData.delivery_date +
                " " +
                getHourFromSlotLabelsByName("Macros", this.slotLabelsByHour)
            );
            events.push({
              title: "Total",
              start: delivery_date,
              end: delivery_date,
              className: "py-0 my-0 bg-white text-dark ",
              extendedProps: {
                type: "event",
                macros: this.getTotalMacros(menuItems),
                mealTypeCode: "BF",
              },
            });
          }
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.events = false;
      return events;
    },
    getTotalMacros(items) {
      let result = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };
      for (let i in items) {
        result.calories += parseFloat(items[i]["recipe.macros"].calories);
        result.carbs += parseFloat(items[i]["recipe.macros"].carbs);
        result.fat += parseFloat(items[i]["recipe.macros"].fat);
        result.protein += parseFloat(items[i]["recipe.macros"].protein);
      }
      return result;
    },
    async datesSet() {
      this.calendarApi.getEventSources().forEach((eventSource) => {
        eventSource.remove();
      });
      if (this.calendarApi.view.type == "dayGridMonth") {
        if (this.events.length == 0) {
          await this.setEvents();
          if (this.selectedDate) {
            this.calendarApi.gotoDate(this.selectedDate);
            this.showDate(this.selectedDate);
          }
          return;
        }
        this.calendarApi.addEventSource(this.events);
        if (this.selectedEvent.id) {
          this.showEvent(this.selectedEvent);
        }
      }
      if (this.calendarApi.view.type == "dayGridWeek") {
        await this.setWeekEvents(
          dateFormat(this.calendarApi.view.currentStart, "YYYY-MM-DD"),
          dateFormat(
            previousDate(this.calendarApi.view.currentEnd),
            "YYYY-MM-DD"
          )
        );
      }
    },
    async eventsSet() {
      await this.$nextTick();
      this.mealTypes.forEach((mealType) => {
        let elements = document.querySelectorAll(
          ".fc-event-main-" + mealType.name
        );
        let maxHeight = 0;
        elements.forEach((element) => {
          maxHeight = Math.max(maxHeight, element.offsetHeight);
        });
        elements.forEach((element) => {
          element.style.height = maxHeight + "px";
        });
      });
    },
    async setWeekEvents(start_date, end_date) {
      this.calendarApi.getEventSources().forEach((eventSource) => {
        eventSource.remove();
      });
      this.weekEvents = await this.getEvents(start_date, end_date);
      this.calendarApi.addEventSource(this.weekEvents);
    },

    getMealTypeValueByName(name) {
      let item = this.mealTypes.find((x) => x.name === name);
      if (item) {
        return item.value;
      }
      return name;
    },
    getNameFromSlotLabelsByHourSlot(hourSlot) {
      const item = this.slotLabelsByHour.find((el) => el.hourSlot == hourSlot);
      if (item) {
        return item.name;
      }
    },
    async setClient() {
      const response = await API.getClient(this.client.id).catch(handleError);
      if (response.status == 200) {
        this.client = {
          id: response.data.data.id,
          "account_manager.name": response.data.data["account_manager.name"],
          "nutritionist.name": response.data.data["nutritionist.name"],
          first_name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          email: response.data.data.email,
          mobile_number: response.data.data.mobile_number,
        };
      } else {
        showMessage(response.message, "error");
      }
    },
    async setPlanTypeChoices() {
      this.planTypeChoices = [];
      const response = await API.getPlanTypes().catch(handleError);
      if (response.status == 200) {
        this.planTypeChoices = formatDataToChoicesJs(
          response.data.data,
          "no empty option",
          {
            id: "value",
            value: "value",
            label: "name",
          }
        );
      }
    },
    async handleFreezeChange() {
      this.events = [];
      await this.setPlanDetails();
      await this.datesSet();
    },
    async closeFreezeModal() {
      this.freezeModal.show = false;
      closeModal(this.freezeModal.id);
    },
    async showModalObject(modalObject) {
      modalObject.show = true;
      await this.$nextTick();
      showModal(modalObject.id);
    },

    async handleModalObjectChange() {
      await this.setPlanDetails();
    },

    async closeModalObject(modalObject) {
      modalObject.show = false;
      closeModal(modalObject.id);
    },
    getMacrosForDeliveryDate(delivery_date) {
      const matchingEvent = this.weekEvents.find(
        (event) =>
          dateFormat(event.start, "YYYY-MM-DD") === delivery_date &&
          event.title === "Total"
      );
      if (
        matchingEvent &&
        matchingEvent.extendedProps &&
        matchingEvent.extendedProps.macros
      ) {
        return matchingEvent.extendedProps.macros;
      }
      return {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };
    },
  },
};
</script>
<style>
.fc .fc-daygrid-event-harness,
.plan-calendar .fc-daygrid-event,
.plan-calendar .fc-event .fc-event-main {
  position: initial !important;
  display: block;
}
.plan-calendar .fc-daygrid-event-harness {
  display: block;
}
.plan-calendar .fc-daygrid-event-harness * {
  overflow: initial !important;
}
.plan-calendar .meal-type-name.badge {
  padding-left: 0;
  padding-right: 0;
  white-space: normal;
  text-align: left;
}
</style>