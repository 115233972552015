import { httpApi } from "@/services/httpApi";

class apiProfile {
  async get() {
    return await httpApi.get("/api/v1/profile");
  }
  async update(data) {
    return await httpApi.post("/api/v1/profile", data);
  }
}

export default new apiProfile();