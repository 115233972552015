<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-12 mt-2">
          <argon-date
            id="auto-amendment-start-date"
            label="Start Date"
            :model-value="form.startDate"
            placeholder="Please select start date"
            :config="configs.startDate"
            @update:model-value="onStartDateChange"
          />
        </div>
        <div class="col-12 mt-2">
          <argon-date
            id="auto-amendment-end-date"
            label="End Date"
            :model-value="form.endDate"
            placeholder="Please select end date"
            :config="configs.endDate"
            @update:model-value="onEndDateChange"
          />
        </div>

        <div class="col-12 mt-2" :class="footerClass">
          <submit-form-button
            :form-submitted="formSubmitted"
            class="btn bg-gradient-success btn-sm mb-0"
            default-button-text="Save"
          />
          <span
            v-if="showBackButton"
            class="btn bg-gradient-secondary btn-sm ms-3 mb-0"
            @click="$emit('back')"
            >Back</span
          >
          <span
            v-if="showCloseButton"
            class="btn bg-gradient-secondary btn-sm ms-3 mb-0"
            @click="$emit('close')"
            >Close</span
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { handleError, handleResponse } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import ArgonDate from "@/components/ArgonDate.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiPlanAutoAmendments from "@/services/apiPlanAutoAmendments";
export default {
  name: "PlanAutoAmendmentsForm",
  components: {
    SubmitFormButton,
    ArgonDate,
  },
  props: {
    footerClass: {
      type: String,
      default: "",
    },
    planDetailsId: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },

    initialData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saved", "back", "close"],

  data() {
    return {
      formSubmitted: false,
      form: {
        startDate: "",
        endDate: "",
      },
      configs: {
        startDate: {
          maxDate: null,
        },
        endDate: {
          minDate: null,
        },
      },
    };
  },
  computed: {},
  async mounted() {
    this.form = this.initialData;
  },
  methods: {
    onStartDateChange(date) {
      this.form.startDate = date;
      this.configs.endDate.minDate = date;
    },
    onEndDateChange(date) {
      this.form.endDate = date;
      this.configs.startDate.maxDate = date;
    },

    async submitForm() {
      await this.create();
    },

    async create() {
      if (!this.form.startDate) {
        showMessage("Please select start date", "error");
        return;
      }
      if (!this.form.endDate) {
        showMessage("Please select end date", "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let data = {
        client_plan_detail_id: this.planDetailsId,
        start_date: this.form.startDate,
        end_date: this.form.endDate,
      };

      if (this.form.notes) {
        data.notes = this.form.notes;
      }

      let response = await apiPlanAutoAmendments
        .create(data)
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("saved");
      }
      this.formSubmitted = false;
    },
  },
};
</script>