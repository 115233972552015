import { httpApi } from "@/services/httpApi";

class ApiClientDeliveryPrefences {
  async index(id) {
    return await httpApi.get("api/v1/clients/" + id + "/delivery-preferences");
  }

  async store(id, data) {
    return await httpApi.post(
      "api/v1/clients/" + id + "/delivery-preferences",
      data
    );
  }
}

export default new ApiClientDeliveryPrefences();
