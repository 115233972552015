import { httpApi } from "@/services/httpApi";

class ApiRecipes {
  async get(params) {
    return await httpApi.get("api/v1/recipes?" + params);
  }
  async dropdown(params) {
    return await httpApi.get("api/v1/recipes/dropdown?" + params);
  }
  format(recipes) {
    for (let k in recipes) {
      recipes[k]["name"] = this.getRecipeLabel(
        recipes[k]["name"],
        recipes[k]["recipe.allergens"],
        recipes[k]["recipe.macros"]
      );
    }
    return recipes;
  }
  getRecipeLabel(recipeName, recipeAllergens, recipeMacros, textColor) {
    if (!textColor) {
      textColor = "#000000";
    }
    let result = `<span style="color:${textColor}">${recipeName}</span>`;
    if (recipeAllergens.length > 0) {
      result +=
        " <br><span class='text-danger text-small me-3'>" +
        recipeAllergens
          .map(function (e) {
            return e.name;
          })
          .join(", ") +
        "</span>";
    }
    if (recipeMacros) {
      if (recipeAllergens.length == 0) {
        result += "<br>";
      }
      result +=
        " <span class='badge badge-secondary'>Kcal " +
        recipeMacros.calories +
        "</span><span class='badge badge-secondary ms-1'>Carbs " +
        recipeMacros.carbs +
        "</span><span class='badge badge-secondary ms-1'>Fat " +
        recipeMacros.fat +
        "</span><span class='badge badge-secondary ms-1'>Pro" +
        recipeMacros.protein +
        "</span>";
    }
    return result;
  }
}

export default new ApiRecipes();