import { httpApi } from "@/services/httpApi";

class ApiClientPlanDetails {
  getStatusChoices() {
    let options = [
      {
        value: "Draft",
        label: "Draft",
        id: "Draft",
        show: true,
      },
      {
        value: "Queued",
        label: "Queued",
        id: "Queued",
        action: "queue",
        show: true,
        requires: "Draft",
      },
      {
        value: "Approved",
        label: "Approved",
        id: "Approved",
        action: "approve",
        show: true,
      },
      {
        value: "Pushed",
        label: "Pushed",
        id: "Pushed",
        action: "push",
        show: true,
        requires: "Queued",
      },
      {
        value: "Verified",
        label: "Verified",
        id: "Verified",
        action: "verify",
        show: true,
        requires: "Pushed",
      },
      {
        value: "Cancelled",
        label: "Cancelled",
        id: "Cancelled",
      },
      {
        value: "Transferred Out",
        label: "Transferred Out",
        id: "Transferred Out",
      },
      {
        value: "Completed",
        label: "Completed",
        id: "Completed",
      },
    ]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/ClientPlanStatus.php
    return options;
  }

  getNutritionQueueChoices(queueStatus) {
    let QueueOptions = [
      {
        value: "Pending",
        label: "Pending",
        id: "Pending",
        action: "Pending",
        show: true,
      },
      {
        value: "Queued",
        label: "Queued",
        id: "Queued",
        action: "Queued",
        show: true,
      },
      {
        value: "Completed",
        label: "Completed",
        id: "Completed",
        action: "Completed",
        show: true,
      },
      {
        value: "Rejected",
        label: "Rejected",
        id: "Rejected",
        action: "Rejected",
        show: true,
      },
    ];
    let queue = [];
    let reachedQueueStatus = false;

    QueueOptions.forEach((option) => {
      if (option.value == "Rejected" && queueStatus == "Completed") {
        // Disable reject option if status is completed
        option.show = false;
      } else if (option.value == "Completed" && queueStatus == "Rejected") {
        // Disable complete option if status is rejected
        option.show = false;
      }

      if (reachedQueueStatus) {
        // non executed actions pushing to queue
        queue.push(option);
      } else if (option.action == queueStatus) {
        // removing executed actions
        reachedQueueStatus = true;
        option.action = "";
        queue.push(option);
      } else {
        // removing all actions behind executed actions
        option.action = "";
        queue.push(option);
      }
    });
    return queue;
  }

  async setStatus(id, plan_id, status) {
    return await httpApi.post(
      "api/v1/clients/" + id + "/plan-details/" + plan_id + "/status/" + status
    );
  }

  async setNutritionStatus(id, data) {
    return await httpApi.post("api/v1/nutrition-queue/" + id, data);
  }
  async getAverageMacros(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/deliveries/average-macros?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async transfer(client_id, plan_id, data) {
    return await httpApi.post(
      "api/v1/clients/" + client_id + "/plan-details/" + plan_id + "/transfer",
      data
    );
  }
  async getTransferDetails(
    client_id,
    plan_id,
    transfer_to_plan_variant_id,
    transfer_charge_percentage
  ) {
    return await httpApi.get(
      "api/v1/clients/" +
        client_id +
        "/plan-details/" +
        plan_id +
        "/transfer/details?transfer_to_plan_variant_id=" +
        transfer_to_plan_variant_id +
        "&transfer_charge_percentage=" +
        transfer_charge_percentage
    );
  }
  async downloadMealPlanPDF(client_id, plan_id, options) {
    let url =
      "api/v1/clients/" +
      client_id +
      "/plan-details/" +
      plan_id +
      "/download-pdf?is_preview=" +
      options.is_preview;
    if (options.start_date && options.end_date) {
      url +=
        "&start_date=" + options.start_date + "&end_date=" + options.end_date;
    }
    return await httpApi.get(url, { responseType: "blob" });
  }
  async addCompensatoryDays(clientId, clientPlanDetailId, data) {
    return await httpApi.post(
      "api/v1/clients/" +
        clientId +
        "/plan-details/" +
        clientPlanDetailId +
        "/add-compensatory-days",
      data
    );
  }
  async addReferralDays(clientId, clientPlanDetailId, data) {
    return await httpApi.post(
      "api/v1/clients/" +
        clientId +
        "/plan-details/" +
        clientPlanDetailId +
        "/add-referral-days",
      data
    );
  }
}

export default new ApiClientPlanDetails();
