<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>Manual Invoice Payment</h6>
    </template>
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <label>Payment Source</label>
        <div class="from-group">
          <select
            id="payment_sources"
            v-model="manualPaymentDetails.payment_source"
            class="form-control"
          ></select>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label>Amount</label>
        <argon-input
          id="amount"
          type="text"
          placeholder="AED"
          :errors="v$.manualPaymentDetails.amount.$errors"
          :model-value="manualPaymentDetails.amount"
          @update:model-value="manualPaymentDetails.amount = $event"
        />
      </div>
      <div class="col-12">
        <argon-textarea
          id="description"
          placeholder=""
          :model-value="manualPaymentDetails.description"
          :errors="v$.manualPaymentDetails.description.$errors"
          @update:model-value="manualPaymentDetails.description = $event"
          >Description
        </argon-textarea>
      </div>

      <div class="col-12">
        <label>Reference</label>
        <argon-input
          id="reference"
          type="text"
          :model-value="manualPaymentDetails.reference"
          :errors="v$.manualPaymentDetails.reference.$errors"
          @update:model-value="manualPaymentDetails.reference = $event"
        />
      </div>
    </div>
    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">
        Close
      </button>
      <button type="button" class="btn btn-primary" @click="addManualPayment">
        Save
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { validatorMessages, helpers } from "@/lib/validators";
import { required, numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import apiMiscList from "@/services/apiMiscList";
import API from "@/services/api";
export default {
  name: "ManualPaymentModal",
  components: {
    Modal,
    ArgonTextarea,
    ArgonInput,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    invoiceId: {
      type: [String, Number],
      default: "",
    },
  },
  emits: ["saved", "close"],
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      manualPaymentDetails: {
        payment_source: "",
        amount: "",
        description: "",
        reference: "",
        meta: [],
      },
      manualPaymentSubmitted: false,
    };
  },
  async mounted() {
    await this.initManualPaymentSourceChoices();
  },
  methods: {
    async addManualPayment() {
      if (this.manualPaymentSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }
      this.v$.$reset();
      this.manualPaymentSubmitted = true;
      let formData = new FormData();
      for (let key in this.manualPaymentDetails) {
        formData.append(key, this.manualPaymentDetails[key]);
      }
      let message = "";
      let messageTimer = 2500;

      const saveResponse = await API.saveManualPaymentInvoice(
        this.invoiceId,
        formData
      ).catch(API.handleError);

      if (saveResponse.status && saveResponse.status === 200) {
        let planModal = this.$store.state.bootstrap.Modal.getInstance(
          document.getElementById("manual-payment-modal")
        );
        planModal.hide();
        this.resetManualpaymentModal();
        this.$emit("saved");
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Payment recorded successfully." +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveResponse.message +
          "</span>";
        messageTimer = 3500;
      }
      showMessage(message, "", messageTimer, "", "html");
      this.manualPaymentSubmitted = false;
    },
    resetManualpaymentModal() {
      this.manualPaymentDetails.payment_source = "Cash";
      this.manualPaymentDetails.amount = "";
      this.manualPaymentDetails.description = "";
      this.manualPaymentDetails.reference = "";
      this.manualPaymentDetails.meta = [];
    },
    async initManualPaymentSourceChoices() {
      const id = "payment_sources";
      let choices = [];
      const response = await apiMiscList
        .manualPaymentSources()
        .catch(handleError);
      if (response.status == 200) {
        choices = response.data.data.filter(option => option.name !== "WALLET");
      } else {
        showMessage(response.message, "error");
      }
      await initChoices(
        id,
        {
          choices: formatDataToChoicesJs(choices, [], {
            id: "value",
            value: "value",
            label: "value",
          }),
          placeholder: true,
          placeholderValue: "",
          duplicateItemsAllowed: false,
        },
        "Cash"
      );
      this.manualPaymentDetails.payment_source = "Cash";
    },
  },

  validations() {
    return {
      manualPaymentDetails: {
        amount: {
          required: helpers.withMessage(validatorMessages.required, required),
          numeric: helpers.withMessage(validatorMessages.numeric, numeric),
        },
        description: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        reference: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>