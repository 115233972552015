export function getEventBgColor(status) {
  let bgColor = "bg-white text-dark";
  switch (status) {
    case "Active":
    case "BF":
      bgColor = "bg-success text-white";
      break;
    case "MS":
      bgColor = "bg-warning text-white";
      break;
    case "AS":
      bgColor = "bg-info text-white";
      break;
    case "LU":
      bgColor = "bg-yellow text-dark";
      break;
    case "DN":
      bgColor = "bg-danger text-white";
      break;
    case "AD":
      bgColor = "bg-dark text-white";
      break;
    case "Week Off":
      bgColor = "bg-muted text-dark";
      break;
    case "Freeze":
      bgColor = "bg-danger text-white";
      break;
  }
  return bgColor;
}
export async function getSlotLabelsByHour(mealTypes) {
  let slotLabelsByHour = [];
  let i = 0;
  for (let index in mealTypes) {
    i++;
    slotLabelsByHour.push({
      hourSlot: i + "am",
      hour: i + ":00",
      name: mealTypes[index].value,
    });
  }
  i++;
  slotLabelsByHour.push({
    hourSlot: i + "am",
    hour: i + ":00",
    name: "Macros",
  });
  return slotLabelsByHour;
}
export function getHourFromSlotLabelsByName(name, slotLabelsByHour) {
  const item = slotLabelsByHour.find((el) => el.name == name);
  if (item) {
    return item.hour;
  }
}
