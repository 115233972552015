<template>
  <div class="card">
    <div class="card-body">
      <h5 class="font-weight-bolder">Upload Plan Images</h5>
      <input
        ref="files"
        type="file"
        multiple
        accept="image/png, image/jpeg"
        style="display: none"
        @change="selectPlanImages"
      />
      <button class="btn btn-success" @click="showPlanImagesPicker">
        Select Images
      </button>
      <div class="mt-3 row">
        <div class="row mt-3">
          <div
            v-for="(image, index) in existingPlanImages"
            :key="'existing-' + index"
            class="col-md-3"
          >
            <img
              :src="image.url"
              class="img-fluid img-thumbnail"
              alt="Plan image"
            />
          </div>
        </div>
        <div
          v-for="(image, index) in newPlanImages"
          :key="'new-' + index"
          class="mt-2 col-md-3 col-12"
        >
          <img
            :src="image.url"
            class="img-fluid img-thumbnail"
            alt="plan image"
          />
          <button class="btn btn-danger btn-sm" @click="removePlanImage(index)">
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanImagesCard",
  props: {
    existingPlanImages: {
      type: Array,
      default: () => [],
    },
    clearPlanImages: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:newPlanImages"],
  data() {
    return {
      newPlanImages: [],
    };
  },
  watch: {
    clearPlanImages(newVal) {
      if (newVal) {
        this.newPlanImages = [];
        this.$emit("update:newPlanImages", this.newPlanImages);
      }
    },
  },
  methods: {
    showPlanImagesPicker() {
      this.$refs.files.click();
    },
    selectPlanImages(event) {
      const files = event.target.files;
      if (files) {
        this.newPlanImages = Array.from(files).map((file) => ({
          url: URL.createObjectURL(file),
          file: file,
        }));
        this.$emit("update:newPlanImages", this.newPlanImages);
      }
    },
    removePlanImage(index) {
      this.newPlanImages.splice(index, 1);
      this.$emit("update:newPlanImages", this.newPlanImages);
    },
  },
};
</script>
