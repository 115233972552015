export function appendNonEmptyFields(source, target) {
  Object.keys(source).forEach((key) => {
    if (source[key] !== "" && source[key] != null) {
      if (typeof source[key] === "object" && !Array.isArray(source[key])) {
        // Initialize target[key] as an object if it's not already set
        target[key] = target[key] || {};
        appendNonEmptyFields(source[key], target[key]);
      } else {
        target[key] = source[key];
      }
    }
  });
}
export function createFormDataFromObject(
  obj,
  exceptions = [],
  rootKey = "",
  formData = new FormData()
) {
  Object.keys(obj).forEach((key) => {
    if (exceptions.includes(key)) {
      return; // Skip this key
    }
    let formKey = rootKey ? `${rootKey}[${key}]` : key;

    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !(obj[key] instanceof File)
    ) {
      createFormDataFromObject(obj[key], exceptions, formKey, formData);
    } else {
      formData.append(formKey, obj[key]);
    }
  });

  return formData;
}
