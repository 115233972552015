<template>
  <loading-spinner :loading="loading" />
  <div v-if="!loading" class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'recipes')"
            type="button"
            class="btn btn-white text-success"
            @click="saveRecipe"
          >
            Save recipe
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <image-picker
          v-if="!loading"
          title="Recipe image"
          :image-src="recipe.image"
          :default-image="recipeDefaultImage"
          @select-image="selectImage"
          @remove-image="removeImage"
        />
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <recipe-information-card
          v-if="!loading"
          :initial-food-groups-data="initialFoodGroupsData"
          :initial-recipe-data="initialRecipeInformationData"
          @change-recipe="handleChangeRecipe"
          @change-food-groups="handleChangeFoodGroups"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div v-if="$can('update_pricing', 'recipes')" class="col-lg-4">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Pricing</h6>
          </div>
          <div class="card-body p-3">
            <div class="row mb-4">
              <div class="col-6">
                <label>Cost<required-input /></label>
                <input v-model="recipe.cost" class="form-control" type="text" />
              </div>
              <div class="col-6">
                <label>Selling price<required-input /></label>
                <input
                  v-model="recipe.selling_price"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 mt-4 mt-lg-0">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Nutritional information</h6>
          </div>
          <div class="card-body p-3">
            <div class="row mb-4">
              <div class="col-3">
                <label>Calories<required-input /></label>
                <input
                  v-model="recipe.calories"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-3">
                <label>Carbs<required-input /></label>
                <input
                  v-model="recipe.carbs"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-3">
                <label>Fat<required-input /></label>
                <input v-model="recipe.fat" class="form-control" type="text" />
              </div>
              <div class="col-3">
                <label>Protein<required-input /></label>
                <input
                  v-model="recipe.protein"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-4">
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Gluten</span>
                  <argon-switch
                    id="recipe-gluten"
                    v-model:checked="recipe.gluten"
                    name="recipe_gluten"
                    class="ms-3"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Garlic<required-input /></span>
                  <div class="me-2">
                    <select
                      id="recipe-garlic"
                      v-model="recipe.garlic"
                      class="form-control"
                    >
                      <option
                        v-for="(n, index) in 3"
                        :key="index"
                        :value="index"
                      >
                        {{ index }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Vegetarian</span>
                  <argon-switch
                    id="recipe-is-vegetarian"
                    v-model:checked="recipe.is_vegetarian"
                    name="recipe_is_vegetarian"
                    class="ms-3"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between"
                >
                  <span class="text-sm">Spice Level<required-input /></span>
                  <div class="me-2">
                    <select
                      id="recipe-spice-level"
                      v-model="recipe.spice_level"
                      class="form-control"
                    >
                      <option
                        v-for="(n, index) in 10"
                        :key="index"
                        :value="index"
                      >
                        {{ index }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'recipes')"
            type="button"
            class="btn btn-outline-success"
            @click="saveRecipe"
          >
            Save recipe
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import recipeDefaultImage from "@/assets/img/recipe.png";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";
import RecipeInformationCard from "./components/RecipeInformationCard.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ImagePicker from "@/components/ImagePicker.vue";
import { createFormDataFromObject } from "@/lib/objectHelper";
import { handleError } from "@/lib/helpers";

import RequiredInput from "@/components/RequiredInput.vue";
export default {
  name: "New",
  components: {
    ArgonSwitch,
    RecipeInformationCard,
    RequiredInput,
    ImagePicker,
    LoadingSpinner,
  },
  data() {
    return {
      recipeDefaultImage,
      recipeImage: undefined,
      recipe: {},
      foodGroups: [],
      formSubmitted: false,
      initialRecipeInformationData: {},
      initialFoodGroupsData: [],
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;
    this.setRecipe();
    this.setInitialData();
    this.loading = false;
  },
  methods: {
    setInitialData() {
      this.initialRecipeInformationData = {
        name: this.recipe.name,
        description: this.recipe.description,
        type: this.recipe.type,
        kitchen_id: this.recipe.kitchen_id,
        kitchen_recipe_name: this.recipe.kitchen_recipe_name,
        grade: this.recipe.grade,
        erp_reference: this.recipe.erp_reference,
        cuisine_id: this.recipe.cuisine_id,
        weight: this.recipe.weight,
        serving_instruction: this.recipe.serving_instruction,
        notes: this.recipe.notes,
        status: this.recipe.status,
        is_public: this.recipe.is_public,
        is_premium: this.recipe.is_premium,
      };
      this.initialFoodGroupsData = this.foodGroups;
    },
    setRecipe() {
      this.recipe = {
        id: 0,
        name: { en: "", ar: "" },
        description: { en: "", ar: "" },
        type: "",
        kitchen_id: "",
        kitchen_recipe_name: "",
        grade: "",
        erp_reference: "",
        cuisine_id: "",
        weight: "",
        serving_instruction: "",
        notes: "",
        status: false,
        is_public: false,
        is_premium: false,
        calories: "",
        fat: "",
        carbs: "",
        protein: "",
        gluten: false,
        garlic: "",
        spice_level: "",
        is_vegetarian: false,
        cost: "",
        selling_price: "",
        image: this.recipeDefaultImage,
      };
    },

    async saveRecipe() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = createFormDataFromObject(this.recipe, [
        "image",
        "cost",
        "selling_price",
      ]);
      if (this.$ability.can("update_pricing", "recipes")) {
        formData.set("cost", this.recipe.cost);
        formData.set("selling_price", this.recipe.selling_price);
      }
      if (this.recipe.notes.length == 0) {
        formData.set("notes", "n/a");
      }
      formData.set("gluten", this.recipe.gluten === true ? "1" : "0");
      formData.set(
        "is_vegetarian",
        this.recipe.is_vegetarian === true ? "1" : "0"
      );
      formData.set("is_public", this.recipe.is_public === true ? "1" : "0");
      formData.set(
        "status",
        this.recipe.status === true ? "Active" : "Inactive"
      );
      formData.set("is_premium", this.recipe.is_premium === true ? "1" : "0");
      if (this.recipeImage) {
        formData.set("image", this.recipeImage);
      }
      let saveRecipeResponse = { status: 0 };
      let updateRecipeFoodGroups = { status: 0 };
      let message = "";
      saveRecipeResponse = await API.createRecipe(formData).catch(handleError);
      if (saveRecipeResponse.status == 200) {
        this.recipe.id = saveRecipeResponse.data.data.id;
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Recipe saved successfully." +
          "</span>";
      } else {
        showMessage(saveRecipeResponse.message, "error");
        this.formSubmitted = false;
        return;
      }
      if (this.recipe.id > 0) {
        let formDataFoodGroups = new FormData();
        for (let i in this.foodGroups) {
          formDataFoodGroups.append(
            "recipe_food_groups[" + i + "][food_group_id]",
            this.foodGroups[i]
          );
        }

        updateRecipeFoodGroups = await API.updateRecipeFoodGroups(
          this.recipe.id,
          formDataFoodGroups
        ).catch(API.handleError);
        if (updateRecipeFoodGroups.status === 200) {
          message +=
            '<hr class="my-2 horizontal dark"><i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
            "Recipe food groups saved successfully" +
            "</span>";
        } else {
          message +=
            '<hr class="my-2 horizontal dark"><i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
            updateRecipeFoodGroups.message +
            "</span>";
        }
        showMessage(message, "", 5000, "", "html");
        this.$router.push({
          name: "Edit Recipe",
          params: { id: this.recipe.id },
        });
      }
      this.formSubmitted = false;
    },

    handleChangeRecipe(data) {
      this.recipe = { ...this.recipe, ...data };
    },
    handleChangeFoodGroups(data) {
      this.foodGroups = data;
    },
    selectImage(imageFile) {
      this.recipeImage = imageFile;
    },
    removeImage() {
      this.recipeImage = undefined;
    },
  },
};
</script>
