<template>
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-6 col-sm-auto">
        <label class="form-label">Date</label>
        <div class="form-group">
          <flat-pickr
            v-model="filter.date"
            class="form-control"
            placeholder="Please select start date"
          ></flat-pickr>
        </div>
      </div>
      <div v-if="showTypeFilter" class="col-6 col-sm-auto">
        <label class="form-label">Type</label>
        <div class="form-group">
          <select v-model="filter.type" class="form-control">
            <option v-for="(el, key) in types" :key="key" :value="el">
              {{ el }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <button
          v-if="showEmailButton"
          class="btn text-white btn-sm mb-0"
          :class="formSubmitted ? 'btn-dark opacity-5' : 'btn-success'"
          @click="emitEmailEvent"
        >
          {{ formSubmitted ? "Please wait" : "Email" }}
        </button>
        <button
          v-if="showDownloadButton"
          class="btn text-white btn-sm mb-0"
          :class="formSubmitted ? 'btn-dark opacity-5' : 'btn-success'"
          @click="emitDownloadEvent"
        >
          {{ formSubmitted ? "Please wait" : "Download" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "TdoFilter",
  components: {
    flatPickr,
  },
  props: {
    initialDate: {
      type: String,
      default: null,
    },
    initialType: {
      type: String,
      default: "TDO all",
    },
    types: {
      type: Array,
      default: () => ["TDO all", "TDO am", "TDO pm"],
    },
    showTypeFilter: {
      type: Boolean,
      default: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    showDownloadButton: {
      type: Boolean,
      default: true,
    },
    showEmailButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["download", "email"],
  data() {
    return {
      filter: {
        date: "",
        type: "",
      },
    };
  },
  mounted() {
    this.filter = {
      date: this.initialDate,
      type: this.initialType,
    };
  },
  methods: {
    emitDownloadEvent() {
      this.$emit("download", this.filter);
    },
    emitEmailEvent() {
      this.$emit("email", this.filter);
    },
  },
};
</script>
