import { httpApi } from "@/services/httpApi";


class ApiCycleMenu {
  async get(plan_id, start_date, end_date) {
    return await httpApi.get(
      "/api/v1/plans/" +
        plan_id +
        "/cycle-menus?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async save(plan_id, data) {
    return await httpApi.post(
      "/api/v1/plans/" + plan_id + "/cycle-menus/",
      data
    );
  }
  async copy(data) {
    return await httpApi.post("/api/v1/plans/cycle-menus/copy", data);
  }
  async downloadPDF(plan_id, start_date, end_date) {
    return await httpApi.post(
      "/api/v1/plans/" +
        plan_id +
        "/cycle-menus/download-pdf?start_date=" +
        start_date +
        "&end_date=" +
        end_date,
      {},
      { responseType: "blob" }
    );
  }
  async downloadExcel(plan_id, start_date, end_date) {
    return await httpApi.post(
      "/api/v1/plans/" +
        plan_id +
        "/cycle-menus/export?start_date=" +
        start_date +
        "&end_date=" +
        end_date,
      {},
      { responseType: "blob" }
    );
  }
  async changeStatus(plan_id, data) {
    return await httpApi.post(`/api/v1/plans/${plan_id}/cycle-menus/change-status`, data);
  }
}

export default new ApiCycleMenu();