import { httpApi } from "@/services/httpApi";

class ApiSubstituteMenu {
  async get(plan_type, start_date, end_date, meal_type) {
    let $url =
      "/api/v1/substitute-menus/" +
      plan_type +
      "?start_date=" +
      start_date +
      "&end_date=" +
      end_date;
    if (meal_type) {
      $url += "&meal_type=" + meal_type;
    }
    return await httpApi.get($url);
  }
  async create(plan_type, data) {
    return await httpApi.post("/api/v1/substitute-menus/" + plan_type, data);
  }
  async update(plan_type, item_id, data) {
    return await httpApi.post(
      "/api/v1/substitute-menus/" + plan_type + "/" + item_id,
      data
    );
  }
  async delete(plan_type, item_id) {
    return await httpApi.delete(
      "/api/v1/substitute-menus/" + plan_type + "/" + item_id
    );
  }
  async copy(data) {
    return await httpApi.post("/api/v1/substitute-menus/copy", data);
  }
}

export default new ApiSubstituteMenu();
