import * as Choices from "choices.js";

export async function initChoices(id, options, selectedValue) {
  let element = document.getElementById(id);
  if (!element) {
    return;
  }
  let defaultOptions = {
    allowHTML: true,
    searchEnabled: true,
    searchResultLimit: 100,
    searchFields: ["label"],
    searchPlaceholderValue: "Type here to search",
    shouldSort: true,
    removeItems: true,
    removeItemButton: true,
    placeholder: true,
    placeholderValue: "Search",
    itemSelectText: "",
    maxItemCount: -1,
    searchFloor: 2,
    fuseOptions: {
      threshold: 0.2,
      ignoreLocation: true,
    },
    sorter: function (a, b) {
      if (a.value.length == 0) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      }
      return 0;
    },
    valueComparer: (value1, value2) => {
      return value1 == value2;
    },
  };

  let choiceOptions = {
    ...defaultOptions,
    ...options,
  };
  if (element._choices) {
    if (choiceOptions.choices) {
      await element._choices.setChoices(
        choiceOptions.choices,
        "value",
        "label",
        true
      );
    }
  } else {
    element._choices = new Choices(element, choiceOptions);
    element.addEventListener("showDropdown", function () {
      setTimeout(function () {
        element._choices.input.element.focus();
      }, 100);
    });
  }
  setChoiceByValue(id, selectedValue);
}
export function setChoiceByValue(id, values) {
  const element = document.getElementById(id);
  if (element && element._choices) {
    element._choices.removeActiveItems();
    if (values !== undefined && values !== null) {
      if (!Array.isArray(values)) {
        values = [values];
      }
      values.forEach((value) => {
        element._choices.setChoiceByValue(value);
      });
      if (values.length == 1) {
        const el = element.parentElement.nextSibling.querySelector(
          `.choices__list--dropdown .choices__item[data-value="${values[0]}"]`
        );
        element._choices._highlightChoice(el);
      }
    }
  }
}
export function formatDataToChoicesJs(data, empty_option, fields) {
  if (!fields) {
    fields = {
      value: "id",
      label: "name",
      id: "id",
    };
  }
  if (fields === "enum") {
    fields = {
      value: "value",
      label: "value",
      id: "value",
    };
  }
  let result = [
    {
      value: "",
      label: "Select",
      id: "",
    },
  ];
  if (
    !empty_option ||
    empty_option === "no empty option" ||
    (empty_option && empty_option.length === 0)
  ) {
    result = [];
  }
  const generateLabel = (item, labelFields) => {
    if (
      typeof labelFields === "object" &&
      labelFields.separator &&
      Array.isArray(labelFields.fields)
    ) {
      return (
        labelFields.prefix +
        labelFields.fields
          .map((field) => item[field])
          .join(labelFields.separator) +
        labelFields.suffix
      );
    } else {
      return item[labelFields];
    }
  };
  for (let i in data) {
    result.push({
      value: data[i][fields.value],
      label: generateLabel(data[i], fields.label),
      id: data[i][fields.id],
    });
  }
  return result;
}
let searchTimeOut;
export async function handleSearchChoices(
  element,
  searchValue,
  searchFunction
) {
  clearTimeout(searchTimeOut);
  if (element && element._choices) {
    element._choices.setChoices(
      [{ value: "", label: "Searching...", disabled: true }],
      "value",
      "label",
      true
    );
  }
  searchTimeOut = setTimeout(async () => {
    await searchFunction(searchValue);
  }, 800);
}
export async function initChoicesAdvanced(id, options = {}, searchFunction) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  const defaultOptions = {
    searchFloor: 0,
    searchChoices: false,
  };
  let choiceOptions = {
    ...defaultOptions,
    ...options,
  };
  let initListener = !element._choices;
  await initChoices(id, choiceOptions, "");
  if (initListener) {
    element.addEventListener("search", (event) =>
      handleSearchChoices(element, event.detail.value, searchFunction)
    );
  }
}
