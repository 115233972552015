import { helpers } from "@vuelidate/validators";

const strongPassword = helpers.regex(
  /^(?=.*\d)(?=.*[!@#$%^&*`~])(?=.*[a-zA-Z]).{6,}$/
);
const sameAsPassword = (value, vm) => vm.password === value;
const greaterThanZero = (value) => value > 0;
const validatorMessages = {
  allRequired: "Please fill all the required fields.",
  required: "This field is required.",
  email: "Enter a valid email address.",
  numeric: "Enter a valid number",
  strongPassword:
    "The password must contain a minimum of 6 characters (letters, one number and one special character).",
  sameAsPassword: "Confirm new password must match the new password.",
  greaterThanZero: "Value must be greater than zero",
};
export { validatorMessages, strongPassword, sameAsPassword, greaterThanZero };
export * from "@vuelidate/validators";
