<template>
  <li class="nav-item">
    <a
      :class="'px-0 py-1 mb-0 nav-link' + active"
      data-bs-toggle="tab"
      :data-bs-target="'#tab-content-address-' + addressKey"
      href="javascript:;"
      role="tab"
      aria-selected="false"
    >
      <span class="ms-1">{{ type }}</span>
    </a>
  </li>
</template>
<script>
export default {
  name: "ClientAddressNav",
  props: {
    addressKey: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      default: "",
    },
    active: {
      type: String,
      default: "",
    },
  },
};
</script>
