import { httpApi } from "@/services/httpApi";

class ApiAddons {
  async get(params) {
    let url = "api/v1/addons?" + params;
    return await httpApi.get(url);
  }
}

export default new ApiAddons();
