<template>
  <div>
    <data-table
      v-if="showDataTable"
      :fetch-data-function="fetchData"
      :options="options"
      :filter="filter"
    />
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import apiBagMovement from "@/services/apiBagMovement";
export default {
  name: "ListTable",
  components: {
    DataTable,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: ["loaded"],
  data() {
    return {
      options: {},
      showDataTable: false,
    };
  },
  mounted() {
    this.setOptions();
    this.showDataTable = true;
  },
  methods: {
    getColumns() {
      return [
        {
          data: "client_name",
          title: "Client",
          class: "",
          defaultContent: "",
        },
        {
          data: "allocated_bags_count",
          title: "Allocated",
          class: "",
          defaultContent: "",
          searchable: false,
        },
        {
          data: "with_count",
          title: "With client",
          class: "",
          defaultContent: "",
          searchable: false,
        },
        {
          data: "delivery_schedule",
          title: "Delivery",
          class: "",
          defaultContent: "",
          searchable: false,
        },
        {
          data: "city",
          title: "City",
          class: "",
          defaultContent: "",
          searchable: false,
        },
        {
          data: "master_area",
          title: "Master Area",
          class: "",
          defaultContent: "",
          searchable: false,
        },
        {
          data: "account_manager",
          title: "Account manager",
          class: "",
          defaultContent: "",
          searchable: false,
        },
      ];
    },
    setOptions() {
      this.options = {
        serverSide: true,
        ordering: false,
        searching: true,
        info: true,
        paging: true,
        columns: this.getColumns(),
      };
    },
    async fetchData(options) {
      options.columns[0]["data"] = "clients.name";
      try {
        const response = await apiBagMovement.deliveryBagMovements(options);
        this.$emit("loaded");
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$emit("loaded");
        return { data: [], recordsTotal: 0, recordsFiltered: 0 };
      }
    },
  },
};
</script>
