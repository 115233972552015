<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <operations-filter
            class="card-header"
            :initial-date="dateFormat('yesterday', 'YYYY-MM-DD')"
            :form-submitted="formSubmitted"
            :show-type-filter="false"
            @download="download"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OperationsFilter from "./components/OperationsFilter.vue";
import { dateFormat } from "@/lib/dateHelper";
import { handleError, downloadFile } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import apiTDO from "@/services/apiTDO";
export default {
  name: "DeliverySchedule",
  components: {
    OperationsFilter,
  },
  data() {
    return {
      formSubmitted: false,
    };
  },
  methods: {
    dateFormat,
    async download(filter) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const requestData = { date: filter.date, action: "download" };
      let response = await apiTDO
        .deliverySchedule(requestData)
        .catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          "Delivery Schedule " + dateFormat(filter.date, "YYYY-MM-DD") + ".pdf",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
