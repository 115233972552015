import router from "@/router";
import { showMessage } from "@/assets/js/show-message";

export function handleError(err, status_callbacks) {
  let message = "Server Error";
  if (err.message) {
    message = err.message;
  }
  if (err.response) {
    if (err.response.data && err.response.data.message) {
      message = err.response.data.message;
    }
    if (err.response.status) {
      if (err.response.status === 401) {
        router.push({ name: "SignIn" });
      }
      if (status_callbacks) {
        for (let status_code in status_callbacks) {
          if (err.response.status == status_code) {
            router.push(status_callbacks[status_code]);
          }
        }
      }
    }
  } else {
    err.response = {};
  }
  err.response.message = message;
  return err.response;
}
export function getResponseMessage(response) {
  if (response.data?.data?.message) {
    return response.data.message;
  } else if (response.data?.message) {
    return response.data.message;
  } else if (response.message) {
    return response.message;
  }
}
export function getResponseData(response) {
  if (!response) {
    return;
  }
  if (response.data?.data) {
    return response.data.data;
  }
  if (response.data) {
    return response.data;
  }
  return response;
}
export async function handleResponse(response, successCallback, errorCallback) {
  let message = null;
  if (response && response.status === 200) {
    if (successCallback) {
      await successCallback();
    }
    message = getResponseMessage(response);
    if (message) {
      showMessage(message, "success");
    }
    return getResponseData(response);
  } else if (response) {
    if (errorCallback) {
      await errorCallback();
    }
    message = getResponseMessage(response);
    if (message) {
      showMessage(response.message, "error");
    }
    return [];
  }
  return [];
}
export function currencyFormat(number, currency, locales) {
  if (!locales) {
    locales = "en-UK";
  }
  if (!currency) {
    currency = "AED";
  }
  let result = number;
  if (!isNaN(number)) {
    result = new Intl.NumberFormat(locales, {
      style: "currency",
      currency: currency,
    }).format(number);
  }
  return result;
}
export function numberFormat(number, locales) {
  if (!locales) {
    locales = "en-UK";
  }
  let result = number;
  if (!isNaN(number)) {
    result = new Intl.NumberFormat(locales).format(number);
  }
  return result;
}
export function downloadFile(blob_data, file_name, contentDisposition) {
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
    if (filenameMatch && filenameMatch[1]) {
      file_name = filenameMatch[1];
    }
  }
  const FILE = window.URL.createObjectURL(new Blob([blob_data]));
  const docUrl = document.createElement("a");
  docUrl.href = FILE;
  docUrl.setAttribute("download", file_name);
  document.body.appendChild(docUrl);
  docUrl.click();
}