import { httpApi } from "@/services/httpApi";

class ApiClient {
  async register(data) {
    return await httpApi.post("api/v1/clients", data);
  }
  getStatusChoices(empty_option_name) {
    let options = ["Active", "Inactive", "Incomplete"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/ClientStatus.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
  async list(params) {
    return await httpApi.get("api/v1/lists/clients?" + params);
  }
}

export default new ApiClient();
