<template>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>New User</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="createUser">
        <div class="row">
          <div class="col-6">
            <label class="form-label">Name</label>
            <argon-input
              id="name"
              type="text"
              placeholder="Full Name"
              :model-value="user.name"
              :errors="v$.user.name.$errors"
              @update:model-value="user.name = $event"
            />
          </div>
          <div class="col-6">
            <label class="form-label mt-2">Email</label>
            <argon-input
              id="email"
              type="email"
              placeholder="example@email.com"
              :model-value="user.email"
              :errors="v$.user.email.$errors"
              @update:model-value="user.email = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label">Username</label>
            <argon-input
              id="username"
              type="text"
              placeholder="Username"
              :model-value="user.username"
              :errors="v$.user.username.$errors"
              @update:model-value="user.username = $event"
            />
          </div>
          <div class="col-6">
            <label class="form-label mt-2">Mobile Number</label>
            <argon-input
              id="mobile_number"
              type="text"
              placeholder="+971551234567"
              :model-value="user.mobile_number"
              :errors="v$.user.mobile_number.$errors"
              @update:model-value="user.mobile_number = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label">Password</label>
            <argon-input
              id="password"
              type="password"
              placeholder="Password"
              :model-value="user.password"
              :errors="v$.user.password.$errors"
              @update:model-value="user.password = $event"
            />
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Profile picture</label>
              <input
                ref="file"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                class="form-control"
                @change="selectProfilePicture"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 align-self-center">
            <argon-textarea
              id="bio"
              placeholder="Say a few words about who you are or what you're working on."
              :model-value="user.bio"
              @update:model-value="user.bio = $event"
              >Bio
            </argon-textarea>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label mt-2">Roles</label>
              <select
                id="choices-roles"
                v-model="user.roles"
                class="form-control"
                multiple
              ></select>
              <div
                v-for="(err, index) of v$.user.roles.$errors"
                :key="index"
                class="text-danger text-sm"
              >
                {{ err.$message }}
              </div>
            </div>
          </div>
        </div>
        <argon-button
          class="float-end mt-3 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Create new user
        </argon-button>
      </form>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import API from "@/services/api";
import { useVuelidate } from "@vuelidate/core";
import {
  validatorMessages,
  strongPassword,
  required,
  email,
  helpers,
} from "@/lib/validators";
import { showMessage } from "@/assets/js/show-message";
import { initChoices } from "@/assets/js/init-choices";

export default {
  name: "NewUserSideNavItem",
  components: {
    ArgonButton,
    ArgonInput,
    ArgonTextarea,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      roles: [],
      user: {
        name: "",
        email: "",
        username: "",
        mobile_number: "",
        bio: "",
        profile_picture: undefined,
        roles: [],
        password: "",
      },
      user_roles_list: "",
      formSubmitted: false,
    };
  },
  async mounted() {
    await this.setRoles();
    await this.initRolesChoices();
    this.loading = false;
  },
  methods: {
    selectProfilePicture() {
      this.user.profile_picture = this.$refs.file.files.item(0);
    },
    async setRoles() {
      await API.getRoles()
        .then((res) => {
          this.roles = res.data.data;
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initRolesChoices() {
      await initChoices("choices-roles", {
        choices: API.formatDataToChoicesJs(this.roles),
        allowHTML: true,
        removeItemButton: true,
        removeItems: true,
      });
    },
    async createUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect || this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const appInstance = this;
      let formData = new FormData();
      for (let key in this.user) {
        if (key != "profile_picture" && key != "roles") {
          formData.append(key, this.user[key]);
        }
      }
      for (let key in this.user.roles) {
        formData.append("roles[]", this.user.roles[key]);
      }
      if (this.user.profile_picture) {
        formData.append("profile_picture", this.user.profile_picture);
      }
      await API.createUser(formData)
        .then((response) => {
          this.$swal({
            html: '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>Success. User created.</span>',
            timer: 1500,
            position: "bottom-end",
            showConfirmButton: false,
            timerProgressBar: true,
            width: "22em",
            padding: "0.5em 0.8em",
            customClass: {
              container: "swal2-small",
            },
            backdrop: false,
            willClose: () => {
              appInstance.$router.push({
                name: "Edit User",
                params: { id: response.data.data.id },
              });
            },
          });
        })
        .catch((err) => {
          let message = err.message;
          if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          this.$swal({
            html:
              '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
              message +
              "</span>",
            timer: 3500,
            position: "bottom-end",
            showConfirmButton: false,
            timerProgressBar: true,
            width: "22em",
            padding: "0.5em 0.8em",
            customClass: {
              container: "swal2-small",
            },
            backdrop: false,
          });
        });
      this.formSubmitted = false;
    },
  },

  validations() {
    return {
      user: {
        name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        email: {
          required: helpers.withMessage(validatorMessages.required, required),
          email: helpers.withMessage(validatorMessages.email, email),
        },
        username: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        mobile_number: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        password: {
          required: helpers.withMessage(validatorMessages.required, required),
          strongPassword: helpers.withMessage(
            validatorMessages.strongPassword,
            strongPassword
          ),
        },
        roles: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>