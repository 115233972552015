<template>
  <div>
    <template v-for="(option, key) in options" :key="option.value">
      <div
        v-if="!option.guard || $can(option.guard.action, option.guard.subject)"
      >
        <input
          :id="name + key"
          type="radio"
          :value="option.value"
          :checked="option.value === modelValue"
          :name="name"
          :disabled="option.disabled || disabled"
          class="btn-check"
          @change="$emit('update:modelValue', option.value)"
        />

        <label :for="name + key" class="btn btn-outline-success me-2 mb-2">
          {{ option.label }}</label
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "radio-group",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
.radio-option {
  margin-bottom: 10px;
  display: block;
}
</style>
