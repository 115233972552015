<template>
  <div
    :id="modalId"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ dislikeExclusion.title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <loading-spinner :loading="loading" />
        <div v-show="!loading" class="modal-body">
          <div class="row">
            <div v-show="!dislikeExclusion.type" class="col-12">
              <label>Type</label>
              <select
                id="dislike-exclusion-type-choices"
                v-model="dislikeExclusion.dislikable_type"
                class="form-control"
              ></select>
            </div>
            <div v-show="!showTreeSelect" class="col-12">
              <label>Name</label>
              <select
                id="dislike-exclusion-name-choices"
                v-model="dislikeExclusion.dislikable_id"
                class="form-control"
                multiple
              ></select>
            </div>
            <div v-if="showTreeSelect" class="col-12">
              <label>Name</label>
              <Treeselect
                v-model="dislikeExclusion.dislikable_id"
                :options="treeSelectData"
                :is-single-select="isSingleSelect"
                :is-grouped-value="true"
                :is-independent-nodes="true"
                :clearable="false"
                :static-list="true"
                :always-open="true"
              >
              </Treeselect>
            </div>
            <div
              v-show="dislikeExclusion.dislikable_type != 'allergen'"
              class="col-12"
            >
              <label>Meal type</label>
              <select
                id="dislike-exclusion-meal-type-choices"
                v-model="dislikeExclusion.meal_type"
                class="form-control"
              ></select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            v-show="!loading"
            type="button"
            class="btn btn-primary"
            @click="saveDislikeExclusion"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initChoices, setChoiceByValue } from "@/assets/js/init-choices";
import API from "@/services/api";
import apiMiscList from "@/services/apiMiscList";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
import ApiClientDislikes from "@/services/apiClientDislikes";
import Treeselect from "vue-treeselectjs";
import "treeselectjs/dist/treeselectjs.css";
export default {
  name: "DislikeExclusionsModal",
  components: {
    LoadingSpinner,
    Treeselect,
  },
  props: {
    modalId: {
      type: String,
      default: "dislike-exclusion-modal",
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
    clientDislikeExclusions: {
      type: Array,
      required: true,
    },
    dislikeExclusionMealTypeChoices: {
      type: Array,
      required: true,
    },
  },
  emits: ["setClientDislikeExclusions"],

  data() {
    return {
      loading: true,
      dislikeExclusion: {},
      dislike_id: [],
      formSubmitted: false,
      clientDislikeExclusionsData: [],
    };
  },
  computed: {
    showTreeSelect() {
      return (
        !this.loading &&
        ["ingredient", "ingredient_allergen", "food_group"].includes(
          this.dislikeExclusion.dislikable_type
        )
      );
    },
    isSingleSelect() {
      return false;
      // return ["ingredient", "ingredient_allergen"].includes(
      //   this.dislikeExclusion.dislikable_type
      // );
    },
    treeSelectData() {
      if (!this.clientDislikeExclusionsData) {
        return [];
      }
      const itemsMap = new Map();

      this.clientDislikeExclusionsData.forEach((item) => {
        if (
          !(
            this.dislikeExclusion.dislikable_type == "food_group" &&
            item.id == 54
          ) &&
          !itemsMap.has(item.id)
        ) {
          itemsMap.set(item.id, {
            name: item.name,
            value: item.id,
            disabled: false,
            children: [],
          });
        }
      });

      this.clientDislikeExclusionsData.forEach((item) => {
        if (
          !(
            this.dislikeExclusion.dislikable_type == "food_group" &&
            item.id == 54
          )
        ) {
          if (item.parent_id === 54 || item.id == item.parent_id) {
            // Treat items with parent_id 54 as top level
            if (!itemsMap.has(item.id)) {
              itemsMap.set(item.id, {
                name: item.name,
                value: item.id,
                disabled: false,
                children: [],
              });
            }
          } else if (item.parent_id !== null && itemsMap.has(item.parent_id)) {
            const parent = itemsMap.get(item.parent_id);
            const child = itemsMap.get(item.id);
            if (!parent.children.includes(child)) {
              parent.children.push(child);
            }
          }
        }
      });
      let itemsArray = Array.from(itemsMap.values());

      itemsArray.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.sort((a, b) => a.name.localeCompare(b.name));
        }
      });
      return itemsArray
        .filter(
          (item) =>
            !this.clientDislikeExclusionsData.some(
              (data) =>
                data.id === item.value &&
                data.parent_id !== null &&
                data.parent_id !== 54 &&
                data.id != data.parent_id
            )
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },

  watch: {
    "dislikeExclusion.dislikable_type": function (newType) {
      if (["ingredient", "ingredient_allergen"].includes(newType)) {
        this.dislikeExclusion.dislikable_id = "";
      } else {
        this.dislikeExclusion.dislikable_id = [];
      }
    },
  },
  async mounted() {
    this.setDefaultDislikeExclusion();
    await initChoices("dislike-exclusion-meal-type-choices", {
      choices: this.dislikeExclusionMealTypeChoices,
    });
    await this.initModal();
  },
  methods: {
    setDefaultDislikeExclusion() {
      this.dislikeExclusion = {
        id: "",
        dislikable_id: [],
        dislikable_name: "",
        dislikable_type: "",
        meal_type: undefined,
        type: undefined,
      };
    },
    async initModal() {
      let appInstance = this;
      let dislikeModalEl = document.getElementById(this.modalId);
      dislikeModalEl.addEventListener("hide.bs.modal", async function () {
        appInstance.loading = true;
        setChoiceByValue("dislike-exclusion-type-choices");
        setChoiceByValue("dislike-exclusion-name-choices");
        setChoiceByValue("dislike-exclusion-meal-type-choices");
      });

      dislikeModalEl.addEventListener("shown.bs.modal", async function (event) {
        appInstance.loading = true;
        let clientDislikeId = 0;
        if (event.relatedTarget.dataset.id) {
          clientDislikeId = event.relatedTarget.dataset.id;
          appInstance.dislikeExclusion =
            appInstance.clientDislikeExclusions.find(
              (x) => x.id.toString() === clientDislikeId.toString()
            );
        } else {
          appInstance.setDefaultDislikeExclusion();
        }
        if (event.relatedTarget.dataset.disliketype) {
          appInstance.dislikeExclusion.type =
            event.relatedTarget.dataset.disliketype;
        }
        appInstance.dislikeExclusion.id = clientDislikeId;
        const dislikeTypeChoices = API.getClientDislikeTypeChoices();
        const dislikeType = dislikeTypeChoices.find(
          (x) => x.value === appInstance.dislikeExclusion.type
        );
        appInstance.dislikeExclusion.title = "New";
        if (appInstance.dislikeExclusion.id > 0) {
          appInstance.dislikeExclusion.title = "Update";
        }
        if (dislikeType) {
          appInstance.dislikeExclusion.title += " " + dislikeType.label;
        }
        appInstance.dislikeExclusion.dislikable_type =
          appInstance.dislikeExclusion.type;
        await appInstance.initDislikeExclusionTypeChoices();
        await appInstance.setDislikeExclusionChoices(
          appInstance.dislikeExclusion.dislikable_type
        );
        setChoiceByValue(
          "dislike-meal-type-choices",
          appInstance.dislikeExclusion.meal_type
        );
        appInstance.loading = false;
      });
    },
    async setDislikeExclusionChoices(dislike_type, search_value) {
      const id = "dislike-exclusion-name-choices";
      const element = document.getElementById(id);
      const appInstance = this;
      appInstance.dislikeExclusionChoices = [];
      await ApiClientDislikes.get(dislike_type, search_value)
        .then((res) => {
          const data = res.data.data;
          appInstance.clientDislikeExclusionsData = res.data.data;
          for (let i in data) {
            appInstance.dislikeExclusionChoices.push({
              id: data[i].id,
              value: data[i].id,
              label: data[i].name,
            });
          }
          initChoices(
            id,
            { choices: appInstance.dislikeExclusionChoices },
            appInstance.dislikeExclusion.dislikable_id
          );
          if (dislike_type == "recipe") {
            element.addEventListener("search", this.handleSearchRecipe, false);
          } else {
            element.removeEventListener(
              "search",
              this.handleSearchRecipe,
              false
            );
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
      if (appInstance.dislikeExclusion.id > 0) {
        element._choices.disable();
      } else {
        element._choices.enable();
      }
    },
    async handleSearchRecipe(event) {
      clearTimeout(this.searchTimeOut);
      this.searchTimeOut = setTimeout(async () => {
        await this.setDislikeExclusionChoices("recipe", event.detail.value);
      }, 500);
    },
    async initDislikeExclusionTypeChoices() {
      const id = "dislike-exclusion-type-choices";
      const element = document.getElementById(id);
      const appInstance = this;

      await initChoices(
        id,
        {
          choices: apiMiscList.getClientDislikeExclusionTypeChoices(),
          searchEnabled: false,
        },
        appInstance.dislikeExclusion.dislikable_type
      );
      element.addEventListener("addItem", async function (event) {
        await appInstance.setDislikeExclusionChoices(event.detail.value);
      });
    },
    async saveDislikeExclusion() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted = true;
      let formData = {
        dislikable_type: this.dislikeExclusion.dislikable_type,
        dislikable_ids: [],
      };
      if (Array.isArray(this.dislikeExclusion.dislikable_id)) {
        formData.dislikable_ids = this.dislikeExclusion.dislikable_id.map(
          (id) => parseInt(id)
        );
      } else {
        formData.dislikable_ids.push(
          parseInt(this.dislikeExclusion.dislikable_id)
        );
      }
      if (this.dislikeExclusion.meal_type) {
        formData.meal_type = this.dislikeExclusion.meal_type;
      }

      let saveResponse = await API.createClientDislikeExclusion(
        this.clientId,
        formData
      ).catch(handleError);
      if (saveResponse.status === 200) {
        this.hideModal();
        this.$emit("setClientDislikeExclusions");
        this.dislikeExclusion.id = saveResponse.data.id;
        showMessage("Client dislike exclusion saved successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }
      this.formSubmitted = false;
    },
    hideModal() {
      let dislikeModal = this.$store.state.bootstrap.Modal.getInstance(
        document.getElementById(this.modalId)
      );
      dislikeModal.hide();
    },
  },
};
</script>
