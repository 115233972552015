<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-6">
          <h6 class="">{{ card.title }}</h6>
        </div>
        <div
          v-if="card.date"
          class="col-md-6 d-flex justify-content-end align-items-center"
        >
          <i class="far fa-calendar-alt me-2"></i>
          <small>{{ card.date }}</small>
        </div>
      </div>
    </div>
    <div class="p-3 card-body scroll">
      <ranking-list
        v-if="item.length > 0"
        :title="card.subtitle"
        :item="item"
        :horizontal-break="horizontalBreak"
      />
      <span v-else>
        <i class="ni ni-basket"></i>
        <p>No record found!</p>
      </span>
      <slot />
    </div>
  </div>
</template>

<script>
import RankingList from "./RankingList.vue";
export default {
  name: "RankingListCard",
  components: {
    RankingList,
  },
  props: {
    card: {
      type: Object,
      title: String,
      subtitle: String,
      date: String,
      default: () => {},
    },
    item: {
      type: Array,
      default: () => {},
      title: String,
      date: String,
      amount: String,
      icon: String,
      color: String,
      payment_link: String,
      id: Number,
    },
    horizontalBreak: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style>
.scroll {
  height: 350px !important;
  overflow-y: scroll;
}
</style>
