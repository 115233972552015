<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-label">Attach Media</label>
          <div
            v-for="(fileInput, index) in fileInputs"
            :key="index"
            class="mb-2 d-flex align-items-center justify-content-center"
          >
            <input
              :ref="`file${index}`"
              type="file"
              multiple
              placeholder="Media"
              class="form-control"
              accept=".jpeg, .jpg, .png, .doc, .docx, .pdf"
              @change="selectDocument(index)"
            />
            <span v-if="fileInputs.length > 1" class="ms-2">
              <i
                class="fas fa-trash-alt text-danger"
                @click="removeFileInput(index)"
              ></i>
            </span>
          </div>
          <button
            type="button"
            class="btn btn-primary btn-xs"
            @click="addFileInput"
          >
            Add Media
          </button>
          <error-display :errors="v$.documentFiles.$errors" />
        </div>
      </div>
    </div>
    <template #footer>
      <button
        type="button"
        class="btn btn-secondary btn-sm mb-0"
        data-bs-dismiss="modal"
        @click="$emit('close')"
      >
        Close
      </button>
      <submit-form-button
        class="btn bg-gradient-success"
        :form-submitted="formSubmitted"
        @click="handleClick"
      >
        {{ buttonText }}
      </submit-form-button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import { validatorMessages, helpers } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import { showMessage } from "@/assets/js/show-message";
import { handleError, handleResponse } from "@/lib/helpers";
import apiInvoices from "@/services/apiInvoices";
export default {
  name: "BulkInviteModal",
  components: {
    SubmitFormButton,
    Modal,
    ErrorDisplay,
  },
  props: {
    title: {
      type: String,
      default: "Attach Media",
    },
    buttonText: {
      type: String,
      default: "Save",
    },
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    invoiceId: {
      type: Number,
      required: true,
    },
  },
  emits: ["saved", "close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formSubmitted: false,
      fileInputs: [{}], // Start with one file input
      documentFiles: [],
    };
  },
  methods: {
    addFileInput() {
      this.fileInputs.push({}); // Add a new file input
    },
    removeFileInput(index) {
      if (this.fileInputs.length > 1) {
        this.fileInputs.splice(index, 1);
        this.documentFiles.splice(index, 1);
      }
      this.v$.$touch();
    },
    async handleClick() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const formData = new FormData();
      Object.keys(this.documentFiles).forEach((index) => {
        this.documentFiles[index].forEach((file) => {
          formData.append(`attachments[${index}]`, file);
        });
      });
      const response = await apiInvoices
        .attachMedia(this.invoiceId, formData)
        .catch(handleError);
      await handleResponse(response, this.savedSuccessfully);

      this.formSubmitted = false;
    },
    savedSuccessfully() {
      showMessage("Media attached successfully.");
      this.$emit("saved");
      this.$emit("close");
    },
    selectDocument(index) {
      const files = this.$refs[`file${index}`][0].files;
      if (files.length > 0) {
        this.documentFiles[index] = Array.from(files);
      } else {
        this.$delete(this.documentFiles, index);
      }
      this.v$.$touch();
    },
  },
  validations() {
    return {
      documentFiles: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
    };
  },
};
</script>