<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div v-if="dashboardStatistics.userRole == 'default'">
        <div class="card">
          <div class="card-body">
            <div class="text-center card-body">
              <h3 class="text-gradient text-success">
                <span class="text-lg ms-n1"></span>
                <span>Default Dashboard</span>
              </h3>
              <h6 class="mb-0 font-weight-bolder">Dashboard in Progress ...</h6>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="dashboardStatistics.userRole == 'nutritionist'"
        class="col-lg-12"
      >
        <general-table
          :tbl-id="'nutrition_queue_list'"
          :headers="[
            'Name',
            'Plan',
            'Expiry Date',
            'Sequence',
            'Nutrition Queue Status',
            'Actions',
          ]"
          :items="nutritionQueue_list"
          :card-header="'Nutrition Queue List'"
        />
      </div>
      <div v-else class="col-lg-12">
        <div class="row user-stats">
          <div
            class="col-md-6 col-12"
            :class="{
              'col-lg-3': dashboardStatistics.userRole != 'salesAgent',
              'col-lg-4': dashboardStatistics.userRole == 'salesAgent',
            }"
          >
            <mini-statistics-card
              title="Today's Collection"
              :value="dashboardStatistics.payment_received_today_text"
              :description="
                `Yesterday: ` +
                dashboardStatistics.payment_received_yesterday_text
                // ` <span class='text-sm font-weight-bolder ` +
                // dashboardStatistics.payment_received_today_percentage_class +
                // `'>` +
                // dashboardStatistics.payment_received_today_percentage_text +
                // `%</span> since yesterday`
              "
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div
            v-if="dashboardStatistics.userRole != 'salesAgent'"
            class="col-lg-3 col-md-6 col-12"
          >
            <mini-statistics-card
              v-if="dashboardStatistics.userRole == 'accountManager'"
              title="Total deliveries"
              :value="
                dashboardStatistics.delivery_data_month_to_date_count_text
              "
              :description="
                `<span class='text-sm font-weight-bolder text-danger' >` +
                dashboardStatistics.total_delivery_text +
                `</span> deliveries today`
              "
              :icon="{
                component: 'ni ni-delivery-fast',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
            <mini-statistics-card
              v-else
              title="Today's Revenue"
              :value="dashboardStatistics.delivery_data_today_revenue_text"
              :description="
                `<span class='text-sm font-weight-bolder text-danger' >` +
                dashboardStatistics.total_delivery_text +
                `</span> deliveries today`
              "
              :icon="{
                component: 'ni ni-delivery-fast',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div
            class="col-md-6 col-12"
            :class="{
              'col-lg-3': dashboardStatistics.userRole != 'salesAgent',
              'col-lg-4': dashboardStatistics.userRole == 'salesAgent',
            }"
          >
            <mini-statistics-card
              title="Total Collection Count"
              :value="
                dashboardStatistics.payment_received_month_to_date_count_text
              "
              :description="
                'New Sign Up Count: ' +
                '<span class=\'text-sm font-weight-bolder\' >' +
                dashboardStatistics.payment_received_month_to_date_new_sign_up_count_text +
                '</span><br>' +
                'Bounce Back Count: ' +
                '<span class=\'text-sm font-weight-bolder\' >' +
                dashboardStatistics.payment_received_month_to_date_bounce_back_count_text +
                '</span>'
              "
              :icon="{
                class: 'w-50',
                component: 'ni ni-single-02',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div
            class="col-md-6 col-12"
            :class="{
              'col-lg-3': dashboardStatistics.userRole != 'salesAgent',
              'col-lg-4': dashboardStatistics.userRole == 'salesAgent',
            }"
          >
            <mini-statistics-card
              title="Total Collection"
              :value="dashboardStatistics.payment_received_month_to_date_text"
              :description="
                'New Sign Up: ' +
                '<span class=\'text-sm font-weight-bolder\' >' +
                dashboardStatistics.payment_received_month_to_date_new_sign_up_text +
                '</span><br>' +
                'Bounce Back: ' +
                '<span class=\'text-sm font-weight-bolder\' >' +
                dashboardStatistics.payment_received_month_to_date_bounce_back_text +
                '</span>'
              "
              :icon="{
                class: 'w-50',
                component: 'ni ni-cart',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div
            v-if="invoice_labels.length > 0"
            class="mb-lg"
            :class="{
              'col-lg-6': dashboardStatistics.userRole != 'salesAgent',
              'col-lg-12': dashboardStatistics.userRole == 'salesAgent',
            }"
          >
            <gradient-line-chart
              id="invoice-chart"
              title="Collection Overview"
              description=""
              :chart="{
                labels: invoice_labels,
                datasets: invoiceDataSets,
              }"
            />
          </div>
          <div
            v-if="
              dashboardStatistics.userRole != 'salesAgent' &&
              (revenue_labels.length > 0 || delivery_labels.length > 0)
            "
            class="col-lg-6 mb-lg"
          >
            <gradient-line-chart
              v-if="dashboardStatistics.userRole == 'accountManager'"
              id="delivery-chart"
              title="Deliveries Overview"
              description=""
              :chart="{
                labels: delivery_labels,
                datasets: deliveryDataSets,
              }"
            />
            <gradient-line-chart
              v-else
              id="revenue-chart"
              title="Revenue Overview"
              description=""
              :chart="{
                labels: revenue_labels,
                datasets: revenueDataSets,
              }"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 col-lg-12">
            <general-table
              :id="'bounce_back_client_list'"
              :headers="['Name', 'Plan', 'Expiry Date', 'Sequence', 'Renew']"
              :items="bounce_back_clients"
              :card-header="'Bounce Back Clients'"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-lg-6">
            <ranking-list-card
              :horizontal-break="false"
              :card="{
                title: 'Invoices',
                date: '',
                subtitle: '',
              }"
              :item="invoices_list"
            >
            </ranking-list-card>
          </div>

          <div class="col-12 col-lg-6 mt-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Bounceback</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr
                      v-for="(item, index) in bounceback_table_data"
                      :key="index"
                    >
                      <td class="w-25 px-3">
                        <div class="">
                          <p class="mb-0 text-xs font-weight-bold">
                            {{ item?.title }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="">
                          <div>
                            <h6 class="mb-0 text-sm">
                              {{ item?.current_month }}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="">
                          <div>
                            <h6 class="mb-0 text-sm">
                              {{ item?.previous_month }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import GeneralTable from "@/views/dashboards/components/GeneralTable.vue";
import RankingListCard from "@/examples/Cards/RankingListCard.vue";
import spotify from "@/assets/img/small-logos/logo-spotify.svg";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { currencyFormat, handleError } from "@/lib/helpers";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { mapGetters } from "vuex";

export default {
  name: "DashboardDefault",
  components: {
    MiniStatisticsCard,
    GradientLineChart,
    GeneralTable,
    RankingListCard,
  },
  data() {
    return {
      dashboardStatistics: {
        userRole: "",
        payment_received_today_text: "AED 0.00",
        payment_received_today_percentage_text: "0",
        payment_received_today_percentage_class: "text-success",
        payment_received_month_to_date_percentage_class: "text-success",
        payment_received_yesterday_text: "AED 0.00",
        payment_received_month_to_date_text: "AED 0.00",
        payment_received_month_to_date_new_sign_up_text: "AED 0.00",
        payment_received_month_to_date_bounce_back_text: "AED 0.00",
        payment_received_month_to_date_count_text: 0,
        payment_received_month_to_date_new_sign_up_count_text: 0,
        payment_received_month_to_date_bounce_back_count_text: 0,
        payment_received_month_to_date_percentage_text: "0",
        payment_received_last_month_to_date_text: "AED 0.00",
        total_count_this_month_to_date_text: 0,
        total_count_text: 0,
        delivery_data_today_revenue_text: "AED 0.00",
        total_delivery_text: 0,
        delivery_data_month_to_date_count_text: 0,
      },
      revenue_labels: [],
      delivery_labels: [],
      invoice_labels: [],
      invoiceDataSets: [],
      revenueDataSets: [],
      deliveryDataSets: [],
      account_manager_data: [],
      client_plans_expiring: [],
      retention_clients: [],
      bounce_back_clients: [],
      invoices_list: [],
      retention_and_bounceback: [],
      retention_and_bounceback_table_data: [],
      bounceback_table_data: [],
      nutritionQueue_list: [],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentRole: "auth/getCurrentRole",
    }),
  },
  watch: {
    async getCurrentRole() {
      await this.getDashboardData();
    },
  },
  async created() {
    await this.getInvoiceMonthToDate();
    await this.getRevenueMonthToDate();
  },
  async mounted() {
    await this.getDashboardData();
  },
  beforeUnmount() {
    if (this.nutritionQueue_list.length > 0) {
      $("#nutrition_queue_list").DataTable().destroy();
    }
  },

  methods: {
    currencyFormat,
    async getDashboardData() {
      if (this.$route.path == "/dashboard-default") {
        this.dashboardStatistics.userRole = "default";
      }
      if (this.$route.path == "/dashboard") {
        this.dashboardStatistics.userRole = "superAdmin";
        await this.getSuperAdminDashboardData();
      }
      if (this.$route.path == "/dashboard-account-admin") {
        this.dashboardStatistics.userRole = "accountAdmin";
        await this.getAccountAdminDashboardData();
      }
      if (this.$route.path == "/dashboard-account-supervisor") {
        this.dashboardStatistics.userRole = "accountSupervisor";
        await this.getSupervisorDashboardStatistics();
      }
      if (this.$route.path == "/dashboard-account-manager") {
        this.dashboardStatistics.userRole = "accountManager";
        await this.getAccountManagersData();
      }
      if (this.$route.path == "/dashboard-sales-agent") {
        this.dashboardStatistics.userRole = "salesAgent";
        await this.getSalesAgentDashboardData();
      }
      if (this.$route.path == "/dashboard-nutritionist") {
        this.dashboardStatistics.userRole = "nutritionist";
        await this.getNutritionistDashboardData().then(() =>
          this.initializeDataTable()
        );
      }
    },
    async getRevenueMonthToDate() {
      const response = await API.getRevenueMonthToDate().catch(handleError);
      if (response.status == 200) {
        this.revenue_labels = Object.values(response.data.data).map(
          (revenue) => {
            const date = new Date(revenue.date);
            return String(date.getDate()).padStart(2, "0");
          }
        );
        this.delivery_labels = Object.values(response.data.data).map((item) => {
          const date = new Date(item.date);
          return String(date.getDate()).padStart(2, "0");
        });
        this.revenueDataSets = [
          {
            label: "Revenue",
            data: Object.values(response.data.data).map((revenue) => {
              return revenue.revenue;
            }),
          },
        ];
        this.deliveryDataSets = [
          {
            label: "Deliveries",
            data: Object.values(response.data.data).map((item) => {
              return item.count;
            }),
          },
        ];
      } else {
        showMessage(response.message, "error");
      }
    },
    async getInvoiceMonthToDate() {
      const response = await API.getInvoiceMonthToDate().catch(handleError);
      if (response.status == 200) {
        this.invoice_labels = Object.values(response.data.data).map(
          (invoice) => {
            const date = new Date(invoice.date);
            return String(date.getDate()).padStart(2, "0");
          }
        );

        const dataSeatAmount = Object.values(response.data.data).map(
          (invoice) => {
            return invoice.amount;
          }
        );

        this.invoiceDataSets = [
          {
            label: "Amount",
            data: dataSeatAmount,
          },
        ];
      } else {
        showMessage(response.message, "error");
      }
    },
    setBounceBackClientsData(data) {
      data.forEach((element, index) => {
        this.bounce_back_clients.push({
          brand: {
            logo: spotify,
            name: data[index]["client.name"],
            phone: data[index]["client.mobile"],
          },
          plan: data[index]["planVariant.name"],
          expiry: data[index]["actual_end_date"],
          sequence: data[index]["sequence"],
          id: data[index]["client.id"],
        });
      });
    },
    setDashboardStatistics(response) {
      const appInstance = this;
      appInstance.dashboardStatistics.total_count_this_month_to_date_text =
        response.data.clients.total_count_this_month_to_date || "";
      appInstance.dashboardStatistics.total_count_text =
        response.data.clients.total_count || "Nil";

      appInstance.dashboardStatistics.payment_received_today_text =
        currencyFormat(response.data.payment_received_today.total);
      appInstance.dashboardStatistics.payment_received_yesterday_text =
        currencyFormat(response.data.payment_received_yesterday.total);

      if (response.data.payment_received_yesterday.total > 0) {
        appInstance.dashboardStatistics.payment_received_today_percentage_text =
          ((response.data.payment_received_today.total -
            response.data.payment_received_yesterday.total) *
            100) /
          response.data.payment_received_yesterday.total;

        if (
          appInstance.dashboardStatistics
            .payment_received_today_percentage_text > 0
        ) {
          appInstance.dashboardStatistics.payment_received_today_percentage_text =
            "+" +
            appInstance.dashboardStatistics
              .payment_received_today_percentage_text;
        } else {
          appInstance.dashboardStatistics.payment_received_today_percentage_class =
            "text-danger";
        }
      } else {
        if (
          response.data.payment_received_yesterday.total ==
          response.data.payment_received_today.total
        ) {
          appInstance.dashboardStatistics.payment_received_today_percentage_text =
            "0";
        } else {
          appInstance.dashboardStatistics.payment_received_today_percentage_text =
            "+100";
        }
      }

      appInstance.dashboardStatistics.payment_received_month_to_date_text =
        currencyFormat(response.data.payment_received_month_to_date.total);
      appInstance.dashboardStatistics.payment_received_month_to_date_new_sign_up_text =
        currencyFormat(
          response.data.payment_received_month_to_date.new_sign_up_total
        );
      appInstance.dashboardStatistics.payment_received_month_to_date_bounce_back_text =
        currencyFormat(
          response.data.payment_received_month_to_date.bounce_back_total
        );
      appInstance.dashboardStatistics.payment_received_month_to_date_count_text =
        response.data.payment_received_month_to_date.count;
      appInstance.dashboardStatistics.payment_received_month_to_date_new_sign_up_count_text =
        response.data.payment_received_month_to_date.new_sign_up_count;
      appInstance.dashboardStatistics.payment_received_month_to_date_bounce_back_count_text =
        response.data.payment_received_month_to_date.bounce_back_count;
      appInstance.dashboardStatistics.payment_received_last_month_to_date_text =
        currencyFormat(response.data.payment_received_last_month_to_date.total);
      if (response.data.payment_received_last_month_to_date.total > 0) {
        appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
          ((response.data.payment_received_month_to_date.total -
            response.data.payment_received_last_month_to_date.total) *
            100) /
          response.data.payment_received_last_month_to_date.total;
        if (
          appInstance.dashboardStatistics
            .payment_received_month_to_date_percentage_text > 0
        ) {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
            "+" +
            appInstance.dashboardStatistics
              .payment_received_month_to_date_percentage_text;
        } else {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_class =
            "text-danger";
        }
      } else {
        if (
          response.data.payment_received_month_to_date.total ==
          response.data.payment_received_last_month_to_date.total
        ) {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
            "0";
        } else {
          appInstance.dashboardStatistics.payment_received_month_to_date_percentage_text =
            "+100";
        }
      }

      if (response.data.invoices.length > 0) {
        response.data.invoices[0].data.forEach((invoice) => {
          let ino =
            invoice.payment_status == "Pending"
              ? invoice.invoice_number
              : "#" + invoice.invoice_number;

          appInstance.invoices_list.push({
            id: invoice.client_id,
            title: invoice.client_name,
            date:
              invoice.payment_time == null
                ? "" + ino
                : invoice.payment_time + ino,

            amount: invoice.amount,
            status: invoice.status,
            icon:
              invoice.status == "Paid"
                ? "fa-arrow-up"
                : invoice.payment_status == "Pending"
                ? "fa-arrow-down"
                : invoice.payment_status == "Cancel"
                ? "fa fa-close"
                : "fa fa-info",

            color:
              invoice.status == "Paid"
                ? "success"
                : invoice.payment_status == "Pending"
                ? "danger"
                : invoice.payment_status == "Cancel"
                ? "secondary"
                : "info",
            payment_link: invoice.payment_link,
          });
        });
      }

      appInstance.bounceback_table_data = [
        {
          title: "",
          current_month: "Current Month",
          previous_month: "Previous Month",
        },
        {
          title: "Bounce Back",
          current_month:
            response.data.current_month_to_date?.bounce_back || "Nil",
          previous_month: response.data.previous_month?.bounce_back || "Nil",
        },
        {
          title: "Bounce back collection",
          current_month: currencyFormat(
            response.data.current_month_to_date?.bounce_back_collection || "Nil"
          ),
          previous_month: currencyFormat(
            response.data.previous_month?.bounce_back_collection || "Nil"
          ),
        },
        {
          title: "Bounce back %",
          current_month:
            response.data.current_month_to_date?.bounce_back_percent || "Nil",
          previous_month:
            response.data.previous_month?.bounce_back_percent || "Nil",
        },
      ];

      this.setBounceBackClientsData(
        response.data.client_plans_expired.bounce_back_clients.data
      );
    },
    async getSalesAgentDashboardData() {
      const response = await API.getSalesAgentDashboardData().catch(
        handleError
      );
      if (response.status == 200) {
        this.setDashboardStatistics(response, true);
      } else {
        showMessage(response.message, "error");
      }
    },
  },
};
</script>
<style>
.user-stats .card {
  min-height: 11rem;
}

@media (max-width: 992px) {
  .user-stats .card {
    min-height: 9rem;
  }
}
</style>
