const state = {
  planId: null,
  date: null,
};

const mutations = {
  setSelectedPlanId(state, planId) {
    state.planId = planId;
  },
  setSelectedDate(state, date) {
    state.date = date;
  },
};

const actions = {
  updateSelectedPlanId({ commit }, planId) {
    commit("setSelectedPlanId", planId);
  },
  updateSelectedDate({ commit }, date) {
    commit("setSelectedDate", date);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
