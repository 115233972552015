import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";
class apiFinancialReport {
  async revenueReport(options) {
    let baseUrl = `/api/v1/financial-reports/revenue-report`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
  async collectionsReport(options) {
    let baseUrl = `/api/v1/financial-reports/collections-report`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
}

export default new apiFinancialReport();
