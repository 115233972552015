<template>
  <div class="mt-1 text-small text-muted">
    Available Timings:
    <span
      v-for="(el, index) of timings"
      :key="index"
      class="ms-1 badge badge-secondary"
      >{{ el.delivery_schedule }} ({{ timeFormat(el.start_time) }}-{{
        timeFormat(el.end_time)
      }})</span
    >
  </div>
</template>

<script>
import { timeFormat } from "@/lib/dateHelper";
export default {
  name: "AreaAvailableTimings",
  props: {
    timings: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    timeFormat,
  },
};
</script>
