<template>
  <div class="py-4 container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <plan-amendment-queue-list />
      </div>
    </div>
  </div>
</template>
<script>
import PlanAmendmentQueueList from "./components/PlanAmendmentQueueList";
export default {
  name: "NutritionistDashboard",
  components: {
    PlanAmendmentQueueList,
  },
};
</script>
