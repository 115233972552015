<template>
  <div
    :id="modalId"
    class="modal fade"
    :aria-labelledby="modalId"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="dialogClass">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
          <button
            v-if="showCloseButton"
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div v-if="$slots.footer" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
  <div
    :id="modalId + '-backdrop'"
    class="fixed inset-0 z-40 hidden bg-black opacity-50"
  ></div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "modal-lg",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close"],
};
</script>
