import { httpApi } from "@/services/httpApi";

class apiClientDeliveryFreeze {
  async longFreeze(clientId, data) {
    return await httpApi.post(
      "api/v1/clients/" + clientId + "/deliveries/long-freeze",
      data
    );
  }
  async longUnFreeze(clientId, data) {
    return await httpApi.post(
      "api/v1/clients/" + clientId + "/deliveries/long-unfreeze",
      data
    );
  }
}

export default new apiClientDeliveryFreeze();
