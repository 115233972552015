<template>
  <div v-if="showCard" class="card">
    <div class="card-body">
      <h5 class="font-weight-bolder">Recipe Information</h5>
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Name<required-input /></label>
          <input
            id="recipe-name"
            v-model="recipe.name.en"
            class="form-control"
            type="text"
            placeholder="Recipe name"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Name (Arabic)<required-input /></label>
          <input
            id="recipe-name-ar"
            v-model="recipe.name.ar"
            class="form-control"
            type="text"
            placeholder="Recipe name in Arabic"
            dir="rtl"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Description<required-input /></label>
          <textarea
            id="recipe-description"
            v-model="recipe.description.en"
            class="form-control"
            rows="4"
          >
          </textarea>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Description (Arabic)<required-input /></label>
          <textarea
            id="recipe-description-ar"
            v-model="recipe.description.ar"
            class="form-control"
            rows="4"
            dir="rtl"
          >
          </textarea>
        </div>
        <div class="col-12 col-sm-6 mt-sm-0">
          <label class="mt-4">Type<required-input /></label>
          <select id="type" v-model="recipe.type" class="form-control"></select>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Kitchen<required-input /></label>
          <select
            id="recipe-kitchen"
            v-model="recipe.kitchen_id"
            class="form-control"
          ></select>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Kitchen Recipe Name<required-input /></label>
          <input
            id="kitchen-recipe-name"
            v-model="recipe.kitchen_recipe_name"
            class="form-control"
            type="text"
            placeholder="Kitchen recipe name"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Grade<required-input /></label>
          <select id="recipe-grade" v-model="recipe.grade" class="form-control">
            <option v-for="(n, index) in 20" :key="index" :value="n">
              {{ n }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Erp Reference<required-input /></label>
          <input
            v-model="recipe.erp_reference"
            class="form-control"
            type="text"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Cuisine<required-input /></label>
          <select
            id="recipe-cuisine"
            v-model="recipe.cuisine_id"
            class="form-control"
          ></select>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Weight<required-input /></label>
          <input v-model="recipe.weight" class="form-control" type="text" />
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-4">Serving instruction<required-input /></label>
          <select
            id="serving-instruction"
            v-model="recipe.serving_instruction"
            class="form-control"
          ></select>
        </div>

        <div class="col-12 col-sm-6">
          <label class="mt-4">Notes</label>
          <textarea
            id="recipe-notes"
            v-model="recipe.notes"
            class="form-control"
            rows="4"
          ></textarea>
        </div>

        <div class="row col-12 col-sm-6">
          <div class="col-12 col-sm-4">
            <label class="mt-4">Status</label>
            <div class="d-flex">
              <label class="form-check-label mb-0 me-2"> Active </label>
              <argon-switch
                id="recipe-status-switch"
                v-model:checked="recipe.status"
                name="recipe_status"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <label class="mt-4">Is public</label>
            <div class="d-flex">
              <label class="form-check-label mb-0 me-2"> Yes </label>
              <argon-switch
                id="recipe-is-public-switch"
                v-model:checked="recipe.is_public"
                name="recipe_is_public"
              />
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <label class="mt-4">Is premium</label>
            <div class="d-flex">
              <label class="form-check-label mb-0 me-2"> Yes </label>
              <argon-switch
                id="recipe-is-premium"
                v-model:checked="recipe.is_premium"
                name="recipe_is_premium"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="mt-4">Food groups</label>
          <select
            id="recipe-food-groups"
            v-model="foodGroups"
            class="form-control"
            multiple
          ></select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ApiMiscList from "@/services/apiMiscList";
import API from "@/services/api";
import apiFoodGroups from "@/services/apiFoodGroups";
import { initChoices } from "@/assets/js/init-choices";
import { showMessage } from "@/assets/js/show-message";
import RequiredInput from "@/components/RequiredInput.vue";
export default {
  name: "RecipeInformationCard",
  components: {
    ArgonSwitch,
    RequiredInput,
  },
  props: {
    initialRecipeData: {
      type: Object,
      default: () => ({}),
    },
    initialFoodGroupsData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["changeRecipe", "changeFoodGroups"],
  data() {
    return {
      recipe: {},
      foodGroups: [],
      showCard: false,
    };
  },
  watch: {
    recipe: {
      deep: true,
      handler(newVal) {
        this.$emit("changeRecipe", newVal);
      },
    },
    foodGroups: {
      deep: true,
      handler(newVal) {
        this.$emit("changeFoodGroups", newVal);
      },
    },
  },
  async mounted() {
    this.recipe = this.initialRecipeData;
    this.foodGroups = this.initialFoodGroupsData;
    this.showCard = true;
    await this.initRecipeTypesChoices();
    await this.initRecipeKitchenChoices();
    await this.initRecipeCuisineChoices();
    await this.initRecipeFoodGroupsChoices();
    await this.initServingInstructionChoices();
  },
  methods: {
    async initRecipeFoodGroupsChoices() {
      await apiFoodGroups
        .get("start=0&length=-1")
        .then((res) => {
          initChoices(
            "recipe-food-groups",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.foodGroups
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initServingInstructionChoices() {
      initChoices(
        "serving-instruction",
        {
          choices: API.formatDataToChoicesJs(ApiMiscList.servingInstructions()),
        },
        this.recipe.serving_instruction
      );
    },
    async initRecipeCuisineChoices() {
      await API.getCuisines()
        .then((res) => {
          initChoices(
            "recipe-cuisine",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.recipe.cuisine_id
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initRecipeKitchenChoices() {
      await API.getKitchens()
        .then((res) => {
          initChoices(
            "recipe-kitchen",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.recipe.kitchen_id
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initRecipeTypesChoices() {
      await API.getRecipeTypes()
        .then((res) => {
          let choices = [
            {
              value: "",
              label: "Select",
              id: "",
            },
          ];
          for (let i in res.data.data) {
            choices.push({
              value: res.data.data[i].value,
              label: res.data.data[i].name,
              id: res.data.data[i].value,
            });
          }
          initChoices("type", { choices: choices }, this.recipe.type);
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
  },
};
</script>
