<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            type="button"
            class="btn btn-white text-success"
            @click="save"
          >
            Save Bag Collection Request
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">
              Bag Collection Request - {{ bagCollectionRequest["client.name"] }}
            </h5>
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label">City</label>
                <div class="form-group">
                  <select
                    id="address-city"
                    v-model="bagCollectionRequest.city_id"
                    class="form-control address-city"
                  ></select>
                  <div
                    v-for="(err, index) of v$.bagCollectionRequest.city_id
                      .$errors"
                    :key="index"
                    class="text-danger text-sm"
                  >
                    {{ err.$message }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label">Area</label>
                <div class="form-group">
                  <select
                    id="address-area"
                    v-model="bagCollectionRequest.area_id"
                    class="form-control"
                  ></select>
                  <div
                    v-for="(err, index) of v$.bagCollectionRequest.area_id
                      .$errors"
                    :key="index"
                    class="text-danger text-sm"
                  >
                    {{ err.$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label for="collection-date">Collection date </label>
                <flat-pickr
                  id="collection-date"
                  v-model="bagCollectionRequest.collection_date"
                  class="form-control"
                  placeholder=""
                />
                <div
                  v-for="(err, index) of v$.bagCollectionRequest.collection_date
                    .$errors"
                  :key="index"
                  class="text-danger text-sm"
                >
                  {{ err.$message }}
                </div>
              </div>
              <div class="col-12 col-md-6">
                <label for="number-of-bags">Number of bags</label>
                <div class="form-group">
                  <input
                    id="number-of-bags"
                    v-model="bagCollectionRequest.number_of_bags"
                    class="form-control"
                    type="text"
                  />
                  <div
                    v-for="(err, index) of v$.bagCollectionRequest
                      .number_of_bags.$errors"
                    :key="index"
                    class="text-danger text-sm"
                  >
                    {{ err.$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label>Address</label>
                <div class="form-group">
                  <textarea
                    id="bagCollectionRequest-address"
                    v-model="bagCollectionRequest.address_text"
                    class="form-control"
                    rows="4"
                  >
                  </textarea>
                  <div
                    v-for="(err, index) of v$.bagCollectionRequest.address_text
                      .$errors"
                    :key="index"
                    class="text-danger text-sm"
                  >
                    {{ err.$message }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label">Status</label>
                <div class="form-group">
                  <select
                    id="status"
                    v-model="bagCollectionRequest.status"
                    class="form-control"
                  ></select>
                  <div
                    v-for="(err, index) of v$.bagCollectionRequest.status
                      .$errors"
                    :key="index"
                    class="text-danger text-sm"
                  >
                    {{ err.$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label>Notes</label>
                <div class="form-group">
                  <textarea
                    id="bagCollectionRequest-notes"
                    v-model="bagCollectionRequest.notes"
                    class="form-control"
                    rows="4"
                  >
                  </textarea>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <label>Instruction</label>
                <div class="form-group">
                  <textarea
                    id="bagCollectionRequest-instruction"
                    v-model="bagCollectionRequest.instruction"
                    class="form-control"
                    rows="4"
                  >
                  </textarea>
                  <div
                    v-for="(err, index) of v$.bagCollectionRequest.instruction
                      .$errors"
                    :key="index"
                    class="text-danger text-sm"
                  >
                    {{ err.$message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link :to="{ name: 'Bag Collection Requests List' }">
            <argon-button
              class="mb-0"
              color="success"
              variant="gradient"
              size="sm"
              >Back
            </argon-button>
          </router-link>
          &nbsp;&nbsp;
          <argon-button
            class="mb-0 text-black"
            color="success"
            variant="gradient"
            size="sm"
            @click="save"
            >Save Bag Collection Request
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ApiBagCollectionRequests from "@/services/apiBagCollectionRequests";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import { useVuelidate } from "@vuelidate/core";
import {
  helpers,
  required,
  greaterThanZero,
  validatorMessages,
} from "@/lib/validators";
import flatPickr from "vue-flatpickr-component";
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import ApiAddresses from "@/services/apiAddresses";
import ApiMiscList from "@/services/apiMiscList";
export default {
  name: "Edit",
  components: {
    ArgonButton,
    flatPickr,
  },
  emits: ["bagCollectionRequest.number_of_bags"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      bagCollectionRequest: {
        id: this.$route.params.id,
        area_id: "",
        "client.name": "",
        city_id: "",
        address_text: "",
        notes: "",
        collection_date: "",
        instruction: "",
        number_of_bags: undefined,
        status: "",
      },
      addressCities: [],
      addressAreas: [],
      formSubmitted: false,
    };
  },
  async mounted() {
    await this.setBagCollectionRequest();
    this.addressCities = await ApiAddresses.getCities("start=0&length=-1");
    this.addressAreas = await ApiAddresses.getAreas("start=0&length=-1");
    await this.initAddressCitiesChoices();
    await this.initAddressAreasChoices(this.bagCollectionRequest.city_id);
    await this.initStatusChoices();
  },
  methods: {
    async setBagCollectionRequest() {
      const response = await ApiBagCollectionRequests.show(
        this.bagCollectionRequest.id
      ).catch(handleError);
      if (response.status == 200) {
        this.bagCollectionRequest = response.data.data;
      } else if (response.status == 404) {
        showMessage("Bag collection request not found", "error");
        this.$router.push({ name: "Bag Collection Requests List" });
      } else {
        showMessage(response.message, "error");
      }
    },
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const formData = {
        area_id: this.bagCollectionRequest.area_id,
        city_id: this.bagCollectionRequest.city_id,
        address_text: this.bagCollectionRequest.address_text,
        notes: this.bagCollectionRequest.notes,
        collection_date: this.bagCollectionRequest.collection_date,
        instruction: this.bagCollectionRequest.instruction,
        number_of_bags: this.bagCollectionRequest.number_of_bags,
        status: this.bagCollectionRequest.status,
        _method: "patch",
      };
      const response = await ApiBagCollectionRequests.update(
        this.bagCollectionRequest.id,
        formData
      ).catch(handleError);
      if (response.status == 200) {
        showMessage("Bag collection request saved successfully");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    async initAddressCitiesChoices() {
      await initChoices(
        "address-city",
        { choices: formatDataToChoicesJs(this.addressCities) },
        this.bagCollectionRequest.city_id
      );
      document
        .getElementById("address-city")
        .addEventListener("change", async (event) => {
          this.bagCollectionRequest.area_id = "";
          await this.initAddressAreasChoices(event.detail.value);
        });
    },
    async initAddressAreasChoices(city_id) {
      const elementId = "address-area";
      let selected = this.bagCollectionRequest.area_id;
      let addressAreasChoices = [];
      if (city_id > 0) {
        addressAreasChoices = formatDataToChoicesJs(
          this.addressAreas.filter(function (el) {
            return el.city_id == city_id;
          }),
          "no empty option"
        );
      }
      if (addressAreasChoices.length === 0) {
        addressAreasChoices = [
          {
            value: "",
            label: "Select city",
            id: "",
          },
        ];
        selected = "";
      }
      await initChoices(elementId, {
        choices: addressAreasChoices,
        searchEnabled: true,
      });
      setChoiceByValue(elementId, selected);
    },
    async initStatusChoices() {
      const id = "status";
      const response = await ApiMiscList.bagCollectionRequestStatus().catch(
        handleError
      );
      if (response.status == 200) {
        await initChoices(
          id,
          {
            choices: formatDataToChoicesJs(
              response.data.data,
              "no empty option",
              { id: "value", value: "value", label: "value" }
            ),
            placeholder: true,
            placeholderValue: "Select bag collection request status",
            duplicateItemsAllowed: false,
          },
          this.bagCollectionRequest.status
        );
      } else {
        showMessage(response.message, "error");
      }
    },
  },
  validations() {
    return {
      bagCollectionRequest: {
        city_id: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        area_id: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        collection_date: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        number_of_bags: {
          greaterThanZero: helpers.withMessage(
            validatorMessages.greaterThanZero,
            greaterThanZero
          ),
          required: helpers.withMessage(validatorMessages.required, required),
        },
        address_text: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        instruction: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        status: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>
<style>
.text-black {
  color: black;
}
</style>
