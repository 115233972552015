<template>
  <div class="card h-100">
    <div class="card-body">
      <h5 class="font-weight-bolder">{{ title }}</h5>
      <div class="row">
        <div class="col-12">
          <img
            class="mt-3 shadow-lg w-100 border-radius-lg"
            :src="imageUrl"
            alt=""
            @click="showImagePicker"
          />

          <input
            ref="file"
            type="file"
            style="display: none"
            accept="image/png, image/gif, image/jpeg"
            @change="selectImage"
          />
        </div>
        <div class="mt-5 col-12">
          <div class="d-flex">
            <button
              class="mb-0 btn bg-gradient-success btn-sm me-2"
              type="button"
              name="button"
              @click="showImagePicker"
            >
              Upload
            </button>
            <button
              v-show="false"
              class="mb-0 btn btn-outline-dark btn-sm"
              type="button"
              name="button"
              @click="removeImage"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageDefault from "@/assets/img/pixel.png";
export default {
  name: "ImagePicker",
  props: {
    title: {
      type: String,
      default: "Image",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    defaultImage: {
      type: String,
      default: imageDefault,
    },
  },
  emits: ["selectImage", "removeImage"],
  data() {
    return {
      image: undefined,
      imageUrl: this.defaultImage,
    };
  },
  mounted() {
    if (this.imageSrc) {
      this.imageUrl = this.imageSrc;
    }
  },
  methods: {
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.imageUrl = URL.createObjectURL(this.$refs.file.files.item(0));
      this.$emit("selectImage", this.$refs.file.files.item(0));
    },
    removeImage() {
      this.$emit("removeImage");
      this.imageUrl = this.defaultImage;
    },
  },
};
</script>
