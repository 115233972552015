<template>
  <div class="card">
    <div class="card-body">
      <h5 class="font-weight-bolder">Meta Information</h5>
      <!-- Items -->
      <div class="mb-3">
        <h6>Items</h6>
        <div class="row g-2">
          <div v-for="(value, key) in meta.items" :key="key" class="col-md-4">
            <label class="form-label">{{
              key.charAt(0).toUpperCase() + key.slice(1)
            }}</label>
            <input
              v-model.number="meta.items[key]"
              type="number"
              class="form-control"
              @input="emitChanges"
            />
          </div>
        </div>
      </div>
      <!-- Category -->
      <div class="mb-3">
        <h6>Category</h6>
        <input
          v-model="categoriesString"
          type="text"
          class="form-control"
          placeholder="Enter categories separated by commas"
          @input="updateCategories"
        />
      </div>
      <!-- Ribbon Tag -->
      <div class="mb-3">
        <h6>Ribbon Tag</h6>
        <div class="row g-2">
          <div class="col-md-6">
            <label class="form-label">Text</label>
            <input
              v-model="meta.ribbon_tag.text"
              type="text"
              class="form-control"
              @input="emitChanges"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Color Key</label>
            <select
              v-model="meta.ribbon_tag.color_key"
              class="form-control"
              @change="emitChanges"
            >
              <option value="">Select a color</option>
              <option v-for="key in colorKeys" :key="key" :value="key">
                {{ key }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Suitable For -->
      <div class="mb-3">
        <h6>Suitable For</h6>
        <input
          v-model="suitableForString"
          type="text"
          class="form-control"
          placeholder="Enter suitable for options separated by commas"
          @input="updateSuitableFor"
        />
      </div>
      <!-- Available Sizes -->
      <div class="mb-3">
        <h6>Available Sizes</h6>
        <div v-for="(size, sizeKey) in meta.available_sizes" :key="sizeKey">
          <h6>{{ sizeKey.charAt(0).toUpperCase() + sizeKey.slice(1) }}</h6>
          <div class="row g-2">
            <div
              v-for="(value, valueKey) in size"
              :key="valueKey"
              class="col-md-6"
            >
              <label class="form-label">{{
                valueKey.charAt(0).toUpperCase() + valueKey.slice(1)
              }}</label>
              <input
                v-model.number="meta.available_sizes[sizeKey][valueKey]"
                type="number"
                class="form-control"
                @input="emitChanges"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { merge } from "lodash";

export default {
  name: "MetaEditor",
  props: {
    initialMeta: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:meta"],
  data() {
    return {
      colorKeys: ["blue", "purple", "orange"],
      meta: {
        items: { meals: 0, snacks: 0, sides: 0 },
        category: [],
        suitable_for: [],
        ribbon_tag: { text: "", color_key: "" },
        available_sizes: {
          regular: { min: 0, max: 0 },
          upsized: { min: 0, max: 0 },
        },
      },
      categoriesString: "",
      suitableForString: "",
    };
  },
  created() {
    this.meta = merge(
      {},
      this.meta,
      JSON.parse(JSON.stringify(this.initialMeta))
    );
    this.categoriesString = this.meta.category.join(", ");
    this.suitableForString = this.meta.suitable_for.join(", ");
  },
  methods: {
    emitChanges() {
      this.$emit("update:meta", JSON.parse(JSON.stringify(this.meta)));
    },
    updateCategories() {
      this.meta.category = this.categoriesString
        .split(",")
        .map((cat) => cat.trim())
        .filter((cat) => cat !== "");
      this.emitChanges();
    },
    updateSuitableFor() {
      this.meta.suitable_for = this.suitableForString
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item !== "");
      this.emitChanges();
    },
  },
};
</script>
