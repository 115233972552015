<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>Plan Amendment Queue</h6>
    </template>
    <plan-amendment-queue-form
      :initial-data="initialData"
      :client-plan-detail-id="initialData.client_plan_detail_id"
      :show-edit-plan-amendment-queue="true"
      :show-back-button="false"
      footer-class="modal-footer"
      @saved="$emit('saved')"
      @close="$emit('close')"
    />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import PlanAmendmentQueueForm from "@/views/pages/Clients/components/PlanAmendmentQueueForm.vue";

export default {
  name: "PlanAmendmentQueueModal",
  components: {
    Modal,
    PlanAmendmentQueueForm,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saved", "close"],
  mounted() {},
  methods: {},
};
</script>
