<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="client-name">Client name</label>
          <input
            id="client-name"
            v-model="filter['client_name']"
            type="search"
            class="form-control"
            placeholder="Client name"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="status">Amender</label>
          <select
            id="amender-id"
            v-model="filter.amender_id"
            class="form-control"
          ></select>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="status">Status</label>
          <select
            id="status"
            v-model="filter.status"
            class="form-control"
            multiple
          ></select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Amended until between</div>
        <div class="col-md-auto col-12 mt-3">
          <flat-pickr
            id="created-after"
            v-model="filter.start_date"
            class="form-control"
            placeholder="start date"
            :config="configs.start_date"
            @on-change="onStartDateChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <flat-pickr
            id="created-before"
            v-model="filter.end_date"
            class="form-control"
            placeholder="end date"
            :config="configs.end_date"
            @on-change="onEndDateChange"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          ref="listTable"
          class="table align-items-center mb-5"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Customer
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amended until
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amender
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Start date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                End date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client plan
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Notes
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <loading-spinner :loading="loading" />
    </div>
    <plan-amendment-queue-modal
      v-if="modal.show"
      :modal-id="modal.id"
      :initial-data="modal.initialData"
      @close="closeModalObject(modal)"
      @saved="handleSaved(modal)"
    />
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import ApiMiscList from "@/services/apiMiscList";
import apiPlanAmendmentQueue from "@/services/apiPlanAmendmentQueue";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import { flatPickrConfig } from "@/lib/flatPickrHelper";
import LoadingSpinner from "@/components/LoadingSpinner";
import { showModal, closeModal } from "@/lib/bootstrap";
import PlanAmendmentQueueModal from "./PlanAmendmentQueueModal.vue";
export default {
  name: "ListTable",
  components: {
    flatPickr,
    LoadingSpinner,
    PlanAmendmentQueueModal,
  },
  data() {
    return {
      removeRows: false,
      loading: true,
      planAmendmentQueueStatus: [],
      filter: {
        client_name: "",
        amender_id: "",
        status: [],
        start_date: "",
        end_date: "",
      },
      configs: {
        start_date: {
          ...flatPickrConfig,
          maxDate: null,
        },
        end_date: {
          ...flatPickrConfig,
          minDate: null,
        },
      },
      modal: {
        show: false,
        id: "plan-amendment-queue-modal",
        initialData: {},
      },
    };
  },
  async mounted() {
    await this.initStatusChoices();
    await this.initAmenderChoices();
    this.initDataTable();
    this.initHandleShowModal();
  },
  beforeUnmount() {
    this.destroyDataTable();
  },
  methods: {
    dateFormat,
    initHandleShowModal() {
      let appInstance = this;
      $(document)
        .off("click")
        .on("click", ".show-modal", async function () {
          let table = $(appInstance.$refs.listTable).DataTable();
          let row = $(this).closest("tr");
          let data = table.row(row).data();
          data.amendedUntil = dateFormat(data.amended_until, "YYYY-MM-DD");
          appInstance.modal.initialData = data;
          appInstance.modal.show = true;
          await appInstance.$nextTick();
          showModal(appInstance.modal.id);
        });
    },
    destroyDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.listTable)) {
        $(this.$refs.listTable).DataTable().destroy();
      }
    },
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $(this.$refs.listTable).DataTable({
        serverSide: true,
        searching: false,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "created_at",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                dateFormat(data) +
                "</h6></div>"
              );
            },
            orderable: false,
          },
          {
            data: null,
            render: function (data) {
              let button =
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">';
              if (appInstance.$ability.can("view", "clients")) {
                button +=
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Client Profile",
                    params: {
                      id: data.client_id,
                    },
                  }).href +
                  '">' +
                  data["client_name"] +
                  "</a>";
              } else {
                button += data["client_name"];
              }
              button += "</h6></div>";
              return button;
            },
            orderable: false,
          },
          {
            data: "amended_until",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data ? dateFormat(data) : "") +
                "</h6></div>"
              );
            },
            orderable: false,
          },
          {
            data: "amender_name",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            orderable: false,
          },
          {
            data: "start_date",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data ? dateFormat(data) : "") +
                "</h6></div>"
              );
            },
            orderable: false,
          },
          {
            data: "end_date",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                (data ? dateFormat(data) : "") +
                "</h6></div>"
              );
            },
            orderable: false,
          },
          {
            data: "status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4 cursor-pointer"><i class="' +
                (data == "Active" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs show-modal">' +
                data +
                "</span></span>"
              );
            },
            orderable: false,
            width: "100px",
          },
          {
            data: null,
            render: function (data) {
              let button =
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm text-center">';
              if (appInstance.$ability.can("view", "client_plan_details")) {
                button +=
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Client Plan",
                    params: {
                      id: data.client_id,
                      planId: data.client_plan_detail_id,
                    },
                  }).href +
                  '">' +
                  data["client_plan_detail_id"] +
                  "</a>";
              } else {
                button += data["client_plan_detail_id"];
              }
              button += "</h6></div>";
              return button;
            },
            orderable: false,
          },
          {
            data: "notes",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center "><h6 class="mb-0 text-sm">' +
                '<textarea readonly rows="3" class="plan-queue-notes">' +
                data +
                "</textarea>" +
                "</h6></div>"
              );
            },
            orderable: false,
          },
        ],
        ajax: function (data, callback) {
          if (appInstance.removeRows) {
            callback({ data: [], recordsTotal: 0, recordsFiltered: 0 });
          }
          data.filter = {};
          if (appInstance.filter["client_name"]) {
            data.client_name = appInstance.filter["client_name"];
          }
          if (appInstance.filter.status) {
            data.status = appInstance.filter.status;
          }
          if (appInstance.filter.amender_id) {
            data.amender_id = appInstance.filter.amender_id;
          }
          if (appInstance.filter.start_date) {
            data.amended_until_start = appInstance.filter.start_date;
          }
          if (appInstance.filter.end_date) {
            data.amended_until_end = appInstance.filter.end_date;
          }
          appInstance.loading = true;
          apiPlanAmendmentQueue
            .index($.param(data))
            .then((res) => {
              appInstance.loading = false;
              callback(res.data);
            })
            .catch((err) => {
              appInstance.loading = false;
              const response = handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    tableDraw() {
      this.removeRows = true;
      $(this.$refs.listTable).DataTable().draw();
      this.removeRows = false;
      $(this.$refs.listTable).DataTable().draw();
    },
    onStartDateChange(selectedDates, dateStr) {
      this.configs.end_date.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.start_date.maxDate = dateStr;
    },
    async initStatusChoices() {
      const id = "status";
      const response = await ApiMiscList.planAmendmentQueueStatus().catch(
        handleError
      );
      if (response.status == 200) {
        this.planAmendmentQueueStatus = response.data.data;
        await initChoices(id, {
          choices: formatDataToChoicesJs(response.data.data, [], "enum"),
          placeholder: true,
          placeholderValue: "Select status",
          duplicateItemsAllowed: false,
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    async initAmenderChoices() {
      const id = "amender-id";
      const response = await API.getNutritionists().catch(handleError);
      if (response.status == 200) {
        await initChoices(id, {
          choices: formatDataToChoicesJs(response.data.data, []),
          placeholder: true,
          placeholderValue: "Select amender",
          duplicateItemsAllowed: false,
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    async closeModalObject(modalObject) {
      modalObject.show = false;
      closeModal(modalObject.id);
    },
    async handleSaved(modalObject) {
      modalObject.show = false;
      closeModal(modalObject.id);
      this.tableDraw();
    },
  },
};
</script>
<style>
.plan-queue-notes {
  width: 100%;
  height: 65px;
  background: #ffffff;
}
</style>
