<template>
  <Popper content="Copied!" placement="right" :show="showPopper">
    <button
      class="btn btn-outline-secondary btn-xs mb-0 mx-xl-2 py-0"
      @click="copy_clipboard(copyLink)"
    >
      Copy Payment Link
    </button>
  </Popper>
</template>

<script>
import Popper from "vue3-popper";

export default {
  name: "CopyLinkCard",
  components: {
    Popper,
  },
  props: {
    copyLink: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      showPopper: false,
    };
  },
  methods: {
    copy_clipboard(value) {
      navigator.clipboard.writeText(value);
      this.showPopper = true;
      setTimeout(() => {
        this.showPopper = false;
      }, 3000);
    },
  },
};
</script>

<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #000000;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
