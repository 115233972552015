<template>
  <div
    :id="'tab-content-address-' + addressKey"
    :class="'tab-pane fade' + active"
    role="tabpanel"
  >
    <div class="row mt-3">
      <div class="col-6">
        <label class="form-label">City</label>
        <div class="form-group">
          <select
            :id="'address-city-' + addressKey"
            :value="cityId"
            class="form-control address-city"
            @change="$emit('update:cityId', $event.target.value)"
          ></select>
          <div
            v-for="(err, index) of v$.cityId.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <label class="form-label">Area</label>
        <div class="form-group">
          <select
            :id="'address-area-' + addressKey"
            :value="areaId"
            class="form-control"
            @change="$emit('update:areaId', $event.target.value)"
          ></select>
          <div
            v-for="(err, index) of v$.areaId.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
          <area-available-timings :timings="areaTimings" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="form-label">Street</label>
        <argon-input
          :id="'address-street-' + addressKey"
          type="text"
          :model-value="street"
          :errors="v$.street.$errors"
          @update:model-value="$emit('update:street', $event)"
        />
      </div>
      <div class="col-6">
        <label class="form-label">Landmark</label>
        <argon-input
          :id="'address-landmark-' + addressKey"
          type="text"
          :model-value="landmark"
          :errors="v$.landmark.$errors"
          @update:model-value="$emit('update:landmark', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="form-label">Building name</label>
        <argon-input
          :id="'building-name-' + addressKey"
          type="text"
          :model-value="buildingName"
          :errors="v$.buildingName.$errors"
          @update:model-value="$emit('update:buildingName', $event)"
        />
      </div>
      <div class="col-6">
        <label class="form-label">Villa / Flat number</label>
        <argon-input
          :id="'flat-number-' + addressKey"
          type="text"
          :model-value="flatNumber"
          :errors="v$.flatNumber.$errors"
          @update:model-value="$emit('update:flatNumber', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <argon-textarea
          :id="'address-notes-' + addressKey"
          placeholder="Address notes"
          :model-value="notes"
          @update:model-value="$emit('update:notes', $event)"
          >Notes
        </argon-textarea>
      </div>
      <div class="col-6">
        <label class="form-label">Type</label>
        <div class="form-group">
          <select
            :id="'address-type-' + addressKey"
            :value="type"
            class="form-control"
            name="type"
            @change="$emit('update:type', $event.target.value)"
          ></select>
          <div
            v-for="(err, index) of v$.type.$errors"
            :key="index"
            class="text-danger text-sm"
          >
            {{ err.$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="form-label">Latitude</label>
        <argon-input
          :id="'latitude-' + addressKey"
          type="text"
          :model-value="latitude"
          :errors="v$.latitude.$errors"
          @update:model-value="updateMarker('update:latitude', $event)"
        />
      </div>
      <div class="col-6">
        <label class="form-label">Longitude</label>
        <argon-input
          :id="'longitude-' + addressKey"
          type="text"
          :model-value="longitude"
          :errors="v$.longitude.$errors"
          @update:model-value="updateMarker('update:longitude', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <GoogleMap
          ref="mapRef"
          api-key="AIzaSyD3jozK0yxYvLEomAvtsQca5F0FH-kHcoo"
          :libraries="['places', 'geometry']"
          class="map"
          :center="center"
          :zoom="12"
          style="width: 100%; height: 500px"
          @click="setLatLng"
        >
          <GoogleMapMarker ref="markerRef" :options="markerOptions" />
        </GoogleMap>
      </div>
    </div>
    <div class="mt-4 mb-4 d-flex justify-content-end">
      <argon-button
        v-if="id > 0 && showDeleteButton"
        class="mt-2 mb-0"
        color="danger"
        variant="gradient"
        size="sm"
        @click="deleteClientAddress"
        >Delete
      </argon-button>
      <argon-button
        v-if="id > 0"
        class="mt-2 mb-0 ms-2"
        color="success"
        variant="gradient"
        size="xs"
        @click="updateClientAddress"
        >Update
      </argon-button>
      <argon-button
        v-else
        class="mt-2 mb-0"
        color="success"
        variant="gradient"
        size="xs"
        @click="createClientAddress"
        >Create
      </argon-button>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { validatorMessages, helpers, required } from "@/lib/validators";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import AreaAvailableTimings from "@/components/AreaAvailableTimings";
import { ref } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import ApiAddresses from "@/services/apiAddresses";

export default {
  name: "ClientAddress",
  components: {
    ArgonInput,
    ArgonButton,
    ArgonTextarea,
    AreaAvailableTimings,
    GoogleMap,
    GoogleMapMarker: Marker,
  },
  props: {
    active: {
      type: String,
      default: "",
    },
    addressKey: {
      type: Number,
      default: 0,
    },
    clientId: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      default: 0,
    },
    cityId: {
      type: [Number, String],
      default: 0,
    },
    cityName: {
      type: String,
      default: "",
    },
    areaId: {
      type: [Number, String],
      default: 0,
    },
    areaName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    street: {
      type: String,
      default: "",
    },
    landmark: {
      type: String,
      default: "",
    },
    buildingName: {
      type: String,
      default: "",
    },
    flatNumber: {
      type: String,
      default: "",
    },
    latitude: {
      type: String,
      default: process.env.VUE_APP_ADDRESS_LATITUDE,
    },
    longitude: {
      type: String,
      default: process.env.VUE_APP_ADDRESS_LONGITUDE,
    },
    notes: {
      type: String,
      default: "",
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    addressAreas: {
      type: Array,
      default: () => [],
    },
    addressCities: {
      type: Array,
      default: () => [],
    },
    addressTypes: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    "createClientAddress",
    "updateClientAddress",
    "deleteClientAddress",
    "update:cityId",
    "update:areaId",
    "update:type",
    "update:street",
    "update:landmark",
    "update:buildingName",
    "update:flatNumber",
    "update:latitude",
    "update:longitude",
    "update:notes",
  ],
  setup() {
    return { v$: useVuelidate(), mapRef: ref(null), markerRef: ref(null) };
  },
  data() {
    return {
      center: {
        lat: parseFloat(this.latitude),
        lng: parseFloat(this.longitude),
      },
      areaCoordinates: [],
    };
  },
  computed: {
    lng() {
      return this.latitude;
    },
    lat() {
      return this.longitude;
    },
    markerOptions() {
      return {
        position: this.center,
        title: "Address Location",
      };
    },
    mapReady() {
      return this.mapRef && this.mapRef.ready;
    },
    addressAreaId() {
      return "address-area-" + this.addressKey;
    },
    addressCityId() {
      return "address-city-" + this.addressKey;
    },
    areaTimings() {
      const area = this.addressAreas.find((el) => el.id == this.areaId);
      if (area) {
        return area.areaTimings.data;
      }
      return [];
    },
  },
  watch: {
    async mapReady(ready) {
      if (ready) {
        const appInstance = this;
        this.mapRef.api.Polygon.prototype.getBounds = function () {
          var bounds = new appInstance.mapRef.api.LatLngBounds();

          var path = this.getPath() || [
            {
              lat: parseFloat(process.env.VUE_APP_ADDRESS_LATITUDE),
              lng: parseFloat(process.env.VUE_APP_ADDRESS_LONGITUDE),
            },
          ];
          if (path) {
            path.forEach(function (element) {
              bounds.extend(element);
            });
          }

          return bounds;
        };
      }
    },
  },
  async mounted() {
    await this.initAddressCitiesChoices();
    await this.initAddressAreasChoices(this.cityId);
    await this.initAddressTypeChoices();
  },
  methods: {
    async setLatLng(e) {
      await this.updateMarker("update:latitude", e.latLng.lat());
      await this.updateMarker("update:longitude", e.latLng.lng());
    },
    async updateMarker(event, value) {
      if (event === "update:latitude") {
        if (parseFloat(value) > 0) {
          this.center.lat = parseFloat(value);
        }
      }
      if (event === "update:longitude") {
        if (parseFloat(value) > 0) {
          this.center.lng = parseFloat(value);
        }
      }
      this.$emit(event, value.toString());
      this.markerRef.marker.setPosition(this.center);
      this.mapRef.map.panTo(this.center);

      await this.setAreaByMarkerPosition();
    },
    async deleteClientAddress() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          ApiAddresses.delete(this.clientId, this.id)
            .then(() => {
              this.$emit("deleteClientAddress");
            })
            .catch((err) => {
              showMessage(handleError(err), {
                name: "fa-exclamation-triangle",
                class: "text-danger",
              });
            });
        }
      });
    },
    async updateClientAddress() {
      let formData = new FormData();
      formData.append("type", this.type);
      formData.append("city_id", this.cityId);
      formData.append("area_id", this.areaId);
      formData.append("flat_number", this.flatNumber);
      formData.append("building_name", this.buildingName);
      formData.append("landmark", this.landmark);
      formData.append("street", this.street);
      formData.append("latitude", this.latitude);
      formData.append("longitude", this.longitude);
      formData.append("notes", this.notes);
      formData.append("_method", "PATCH");
      let response = await ApiAddresses.update(
        this.clientId,
        this.id,
        formData
      ).catch(handleError);
      if (response.status === 200) {
        if (response.data.success) {
          showMessage("Success. Client address updated.", {
            name: "fa-check-circle",
            class: "text-success",
          });
        } else {
          showMessage(response.data.message, "error");
        }
      } else {
        showMessage(response.message, "error");
      }
      this.$emit("updateClientAddress", this.addressKey);
    },
    async emitEvent(event) {
      this.$emit(event);
    },
    async createClientAddress() {
      let formData = new FormData();
      formData.append("type", this.type);
      formData.append("city_id", this.cityId);
      formData.append("area_id", this.areaId);
      formData.append("flat_number", this.flatNumber);
      formData.append("building_name", this.buildingName);
      formData.append("landmark", this.landmark);
      formData.append("street", this.street);
      formData.append("latitude", this.latitude);
      formData.append("longitude", this.longitude);
      formData.append("notes", this.notes);
      const response = await ApiAddresses.create(this.clientId, formData).catch(
        handleError
      );
      if (response.status === 200) {
        showMessage("Success. Client address created.", "success");
        this.$emit("createClientAddress", response.data.data);
      } else {
        showMessage(response.message, "error");
      }
    },
    async initAddressCitiesChoices() {
      this.addressCitiesChoices = formatDataToChoicesJs(this.addressCities);
      await initChoices(
        this.addressCityId,
        { choices: this.addressCitiesChoices },
        this.cityId
      );
      document
        .getElementById(this.addressCityId)
        .addEventListener("addItem", async (event) => {
          await this.initAddressAreasChoices(event.detail.value);
        });
      document
        .getElementById(this.addressCityId)
        .addEventListener("removeItem", async () => {
          await this.initAddressAreasChoices();
        });
    },
    async initAddressAreasChoices(city_id) {
      const appInstance = this;
      let selected = this.areaId;
      let addressAreasChoices = [];
      if (city_id > 0) {
        addressAreasChoices = formatDataToChoicesJs(
          this.addressAreas.filter(function (el) {
            return el.city_id == city_id;
          }),
          "no empty option"
        );
      }
      if (addressAreasChoices.length === 0) {
        addressAreasChoices = [
          {
            value: "",
            label: "Select city",
            id: "",
          },
        ];
        selected = "";
      }
      await initChoices(this.addressAreaId, {
        choices: addressAreasChoices,
        searchEnabled: true,
      });
      document
        .getElementById(this.addressAreaId)
        .addEventListener("change", async (event) => {
          this.areaCoordinates = appInstance.getAreaCoordinates(
            event.detail.value
          );
          const polygon = await new appInstance.mapRef.api.Polygon({
            paths: this.areaCoordinates,
          });
          appInstance.mapRef.map.fitBounds(polygon.getBounds());
          const mapCenter = appInstance.mapRef.map.getCenter();
          await this.updateMarker("update:latitude", mapCenter.lat());
          await this.updateMarker("update:longitude", mapCenter.lng());
        });
      setChoiceByValue(this.addressAreaId, selected);
    },
    getAreaCoordinates(area_id) {
      const area = this.addressAreas.find((el) => el.id == area_id);

      let result = [];
      if (area && area.position && area.position.coordinates) {
        for (let i in area.position.coordinates[0]) {
          result.push({
            lat: area.position.coordinates[0][i][1],
            lng: area.position.coordinates[0][i][0],
          });
        }
      }
      return result;
    },
    async setAreaByMarkerPosition() {
      const point = this.markerRef.marker.getPosition();
      const response = await ApiAddresses.getAreaByCoordinates({
        latitude: point.lat(),
        longitude: point.lng(),
      }).catch(handleError);
      let area;
      if (response.status == 200) {
        area = response.data.data;
      } else {
        area = {
          city_id: "",
          id: "",
        };
      }
      this.$emit("update:cityId", area.city_id);
      this.$emit("update:areaId", area.id);
      setChoiceByValue(this.addressCityId, area.city_id);
      await this.initAddressAreasChoices(area.city_id);
      setChoiceByValue(this.addressAreaId, area.id);
    },
    async initAddressTypeChoices() {
      await initChoices(
        "address-type-" + this.addressKey,
        { choices: this.addressTypes },
        this.type,
        this.type
      );
    },
  },
  validations() {
    return {
      cityId: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      areaId: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      buildingName: {
        required: helpers.withMessage(validatorMessages.required, required),
      },

      flatNumber: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      landmark: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      latitude: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      longitude: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      street: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
      type: {
        required: helpers.withMessage(validatorMessages.required, required),
      },
    };
  },
};
</script>
