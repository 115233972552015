<template>
  <div class="py-4 container-fluid">
    <div class="row mt-4">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            class="btn btn-white text-success"
            :to="{ name: 'Invoice Refund Requests List' }"
            >Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Refund request</h5>
            <LoadingSpinner :loading="!showRefundRequestForm" />
            <refund-request-form
              v-if="showRefundRequestForm"
              :invoice-payment-source="invoice.payment_source"
              :invoice-id="invoice.id"
              :initial-data="refundRequest"
              footer-class="card-footer"
              :show-close-button="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleError } from "@/lib/helpers";
import RefundRequestForm from "./components/RefundRequestForm.vue";
import apiInvoiceRefundRequests from "@/services/apiInvoiceRefundRequests";
import apiInvoices from "@/services/apiInvoices";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  name: "RefundRequestEdit",
  components: {
    RefundRequestForm,
    LoadingSpinner,
  },
  data() {
    return {
      invoice: {
        id: this.$route.params.invoice_id,
      },
      refundRequest: {
        id: this.$route.params.id,
      },
      showRefundRequestForm: false,
    };
  },
  async mounted() {
    await this.setRefundRequest();
    await this.setInvoice();
    this.showRefundRequestForm = true;
  },
  methods: {
    async setRefundRequest() {
      const response = await apiInvoiceRefundRequests
        .get(this.invoice.id, this.refundRequest.id)
        .catch(handleError);
      if (response.status == 200) {
        this.refundRequest = response.data.data;
      } else {
        this.$swal({
          icon: "error",
          title: response.message,
          willClose: () => {
            this.$router.push({ name: "Invoice Refund Requests List" });
          },
        });
      }
    },
    async setInvoice() {
      const response = await apiInvoices
        .get(this.invoice.id)
        .catch(handleError);
      if (response.status == 200) {
        this.invoice = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
  },
};
</script>
