<template>
  <div class="card">
    <reports-filter
      class="card-header"
      :initial-start-date="dateFormat('yesterday', 'YYYY-MM-DD')"
      :initial-end-date="dateFormat('yesterday', 'YYYY-MM-DD')"
      :configs="datePickerConfigs"
      @download="downloadExcel"
    />
  </div>
</template>

<script>
import apiFinancialReport from "@/services/apiFinancialReport";
import { showMessage } from "@/assets/js/show-message";
import { handleError, downloadFile } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import ReportsFilter from "../../components/ReportsFilter.vue";
export default {
  name: "ListTable",
  components: {
    ReportsFilter,
  },
  data() {
    return {
      formSubmitted: false,
      datePickerConfigs: {
        startDate: {
          maxDate: dateFormat("yesterday", "YYYY-MM-DD"),
        },
        endDate: {
          minDate: null,
          maxDate: dateFormat("yesterday", "YYYY-MM-DD"),
        },
      },
    };
  },
  methods: {
    dateFormat,

    async downloadExcel(filter) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let response = await apiFinancialReport
        .revenueReport({ ...filter, ...{ action: "download" } })
        .catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          "revenue-report-" + dateFormat("current", "timestamp") + ".xlsx",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
