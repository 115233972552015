<template>
  <div class="py-4 container-fluid">
    <loading-spinner :loading="loading.planChoices" />
    <div v-show="!loading.planChoices" class="row">
      <div class="col-xl-12">
        <div class="card widget-calendar widget-calendar-week">
          <div class="p-3 pb-0 card-header">
            <div class="row">
              <div class="col-sm-4">
                <select id="select-plan" v-model="selectedPlan.id"></select>
              </div>
              <div class="col-sm-8">
                <router-link
                  v-if="$can('create', 'cycle_menus')"
                  :to="{
                    name: 'Cycle Menus Copy',
                    params: { id: selectedPlan.id },
                  }"
                  class="btn btn-outline-success btn-sm"
                >
                  Copy {{ selectedPlan.name }} Cycle Menu
                </router-link>
                <span
                  v-if="$can('export', 'cycle_menus')"
                  class="btn btn-outline-success btn-sm ms-3"
                  @click="downloadExcel"
                >
                  Download Excel
                </span>
                <span
                  v-if="$can('export', 'cycle_menus')"
                  class="btn btn-outline-success btn-sm ms-3"
                  @click="downloadPDF"
                >
                  Download PDF
                </span>
                <span
                  class="btn btn-outline-success btn-sm ms-3"
                  @click="showModalObject(changeStatusModal)"
                >
                  Change Status
                </span>
              </div>
            </div>
          </div>
          <div class="p-3 card-body">
            <full-calendar
              v-if="!loading.recipes"
              id="calendar-cycle-menu"
              ref="fullCalendar"
              :options="calendarOptions"
            >
              <template #eventContent="info">
                <div
                  v-if="info.view.type == 'dayGridWeek'"
                  :class="
                    'fc-event-main fc-event-main-' +
                    info.event.extendedProps.mealTypeCode
                  "
                >
                  <div class="fc-event-main-frame">
                    <div class="fc-event-title-container">
                      <div class="fc-event-title fc-sticky">
                        <recipe-macros-info
                          v-if="info.event.extendedProps.macros"
                          class="bg-white text-dark"
                          :calories="info.event.extendedProps.macros.calories"
                          calories-text="Kcal"
                          :two-lines="true"
                          :carbs="info.event.extendedProps.macros.carbs"
                          :fat="info.event.extendedProps.macros.fat"
                          :protein="info.event.extendedProps.macros.protein"
                          protein-text="Pro"
                        />
                        <div v-else>
                          <div class="text-xs">
                            {{ info.event.extendedProps.mealTypeName }}
                          </div>
                          <div>
                            {{ info.event.title }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="info.view.type == 'dayGridMonth'"
                  :class="
                    'fc-event-main fc-event-main-' +
                    info.event.extendedProps.mealTypeCode
                  "
                >
                  <recipe-macros-info
                    v-if="info.event.extendedProps.macros"
                    class="bg-white text-dark"
                    :calories="info.event.extendedProps.macros.calories"
                    calories-text="Kcal"
                    :two-lines="true"
                    :carbs="info.event.extendedProps.macros.carbs"
                    :fat="info.event.extendedProps.macros.fat"
                    :protein="info.event.extendedProps.macros.protein"
                    protein-text="Pro"
                  />
                </div>
                <div v-else class="fc-event-main">
                  <div class="fc-event-main-frame">
                    <div class="fc-event-title-container">
                      <div class="fc-event-title fc-sticky">
                        {{ info.event.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <loading-spinner
          :loading="
            !loading.planChoices &&
            selectedDate.length > 0 &&
            loading.cycleMenuItems
          "
        />
        <div v-show="!loading.cycleMenuItems" class="card mt-4">
          <div class="py-3 card-header d-flex align-items-center">
            <div class="d-flex align-items-center">
              <h5>Cycle Menu for {{ dateFormat(selectedDate) }}</h5>
              <recipe-macros-badge
                v-if="selectedDateMacros"
                class="ms-2"
                :calories="selectedDateMacros.calories"
                :carbs="selectedDateMacros.carbs"
                :fat="selectedDateMacros.fat"
                :protein="selectedDateMacros.protein"
              />
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="nav-wrapper position-relative">
              <ul
                id="cycle-nav"
                class="p-1 bg-transparent nav nav-pills"
                role="tablist"
              >
                <li
                  v-for="(el, key) in mealTypes"
                  :id="'nav-item-' + el.name"
                  :key="key"
                  class="nav-item"
                >
                  <a
                    :id="'nav-' + el.name"
                    :class="
                      'px-0 py-1 mb-0 nav-link' +
                      (el.name === 'BF' ? ' active' : '')
                    "
                    data-bs-toggle="tab"
                    :data-bs-target="'#tab-content-items-' + el.name"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="ms-1">{{ el.value }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div id="items-tab-content" class="tab-content">
              <div
                v-for="(items, key) in cycleMenuItemsGroup"
                :id="'tab-content-items-' + key"
                :key="key"
                :class="'tab-pane fade' + (key === 'BF' ? ' active show' : '')"
                role="tabpanel"
              >
                <div
                  v-for="(el, mealCode) in items"
                  :key="mealCode"
                  class="row"
                >
                  <div class="col-12 col-lg-6">
                    <label class="form-label"
                      >Item {{ mealCode.slice(-1) }}
                    </label>
                    <div class="form-group">
                      <select
                        :id="getSelectRecipeId(mealCode)"
                        v-model="el.recipe_id"
                        :data-meal_code="mealCode"
                        class="form-control item"
                      ></select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer py-1">
            <button
              class="btn btn-outline-success btn-sm"
              @click="saveCycleMenu()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <change-status-modal
    v-if="changeStatusModal.show"
    :modal-id="changeStatusModal.id"
    :plan-id="selectedPlan.id"
    :title="`Change status for cycle menu ${selectedPlan.name}`"
    @change="updateCalendar()"
    @close="closeModalObject(changeStatusModal)"
  />
</template>

<script>
import API from "@/services/api";
import ApiMiscList from "@/services/apiMiscList";
import ApiCycleMenu from "@/services/apiCycleMenu";
import ApiRecipes from "@/services/apiRecipes";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner";
import "flatpickr/dist/flatpickr.css";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";

import { downloadFile, handleError } from "@/lib/helpers";
import { dateFormat, previousDate } from "@/lib/dateHelper";
import setNavPills from "@/assets/js/nav-pills";
import { nextTick } from "vue";
import RecipeMacrosBadge from "@/components/RecipeMacrosBadge";
import RecipeMacrosInfo from "@/components/RecipeMacrosInfo";
import {
  getSlotLabelsByHour,
  getHourFromSlotLabelsByName,
} from "@/lib/fullCalendarHelper";
import { mapState, mapActions } from "vuex";
import ChangeStatusModal from "@/views/pages/Config/CycleMenus/components/ChangeStatusModal.vue";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
export default {
  name: "CycleMenu",
  components: {
    ChangeStatusModal,
    LoadingSpinner,
    RecipeMacrosBadge,
    RecipeMacrosInfo,
    FullCalendar,
  },
  data() {
    return {
      loading: {
        recipes: true,
        planChoices: true,
        cycleMenuItems: true,
        cycleMenuItemsGroup: true,
      },
      formSubmitted: false,
      plansChoices: [],
      selectedPlan: {
        id: 3,
        name: "Athlete",
      },
      selectedDate: dateFormat(new Date().toString(), "YYYY-MM-DD"),
      cycleMenuItems: [],
      cycleMenuItemsGroup: {},
      mealTypes: [],
      recipes: [],
      weekStartDate: "",
      weekEndDate: "",
      weekEvents: [],
      weekMenuItems: {},
      monthStartDate: "",
      monthEndDate: "",
      monthEvents: [],
      monthMenuItems: {},
      slotLabelsByHour: [],
      calendarOptions: {
        firstDay: 1,
        showNonCurrentDates: false,
        contentHeight: "auto",
        plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
        initialView: "dayGridMonth",
        initialDate: this.selectedDate,
        selectable: true,
        editable: true,
        events: [],
        eventClick: this.calendarEventClick,
        dateClick: this.calendarDateClick,
        datesSet: this.datesSet,
        eventsSet: this.eventsSet,
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "today prev,next dayGridWeek,dayGridMonth,multiMonthYear", // will normally be on the right. if RTL, will be on the left
        },
        multiMonthMaxColumns: 6,
        multiMonthMinWidth: 180,
      },
      changeStatusModal: {
        show: false,
        id: "change-status-modal",
      },
      searchTimeOut: null,
    };
  },
  computed: {
    ...mapState("cycleMenus", ["planId", "date"]),
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    selectedDateMacros() {
      return this.getTotalMacros(this.cycleMenuItems);
    },
  },
  async mounted() {
    this.setDataFromStore();
    await this.setPlansChoices();
    await this.setRecipes();
    this.loading.planChoices = false;
    await this.initPlansChoices();
    this.mealTypes = ApiMiscList.mealTypes();
    this.slotLabelsByHour = await getSlotLabelsByHour(this.mealTypes);
    await this.setCycleMenuItemsGroupDefault();
    await this.initRecipeChoices();
    setNavPills();
  },

  methods: {
    showModalObject,
    closeModalObject,
    ...mapActions("cycleMenus", ["updateSelectedPlanId", "updateSelectedDate"]),
    dateFormat,
    getSlotLabelsByHour,
    getHourFromSlotLabelsByName,
    setDataFromStore() {
      const selectedPlanIdFromStore = this.planId;
      const selectedDateFromStore = this.date;
      if (
        selectedPlanIdFromStore !== null &&
        selectedPlanIdFromStore !== undefined
      ) {
        this.selectedPlan.id = selectedPlanIdFromStore;
      }

      if (
        selectedDateFromStore !== null &&
        selectedDateFromStore !== undefined
      ) {
        this.selectedDate = selectedDateFromStore;
      }
    },
    async setRecipes() {
      this.loading.recipes = true;
      this.recipes = [];
      let response = await ApiRecipes.dropdown("start=0&length=10").catch(
        handleError
      );
      if (response.status === 200) {
        this.recipes = ApiRecipes.format(response.data.data);
      } else {
        showMessage(response.message, "error", 5000);
      }
      this.loading.recipes = false;
    },
    async getRecipes(search_value) {
      let params =
        "?draw=10&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true" +
        "&columns[0][search][value]=&columns[0][search][regex]=false" +
        "&columns[1][data]=name&columns[1][name]=name&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=" +
        "&columns[1][search][regex]=false&start=0&length=10&search[value]=" +
        search_value;
      return await ApiRecipes.dropdown(params).catch(handleError);
    },
    async initRecipeChoices() {
      let appInstance = this;
      for (let i in this.cycleMenuItemsGroup) {
        for (let mealCode in this.cycleMenuItemsGroup[i]) {
          let mealType = mealCode.slice(0, 2);
          let id = this.getSelectRecipeId(mealCode);
          let element = document.getElementById(id);
          await initChoices(this.getSelectRecipeId(mealCode), {
            choices: formatDataToChoicesJs(this.recipes, []),
            placeholder: false,
          });
          if (this.cycleMenuItemsGroup[i][mealCode].recipe_id) {
            await element._choices.setChoices(
              [
                {
                  value: this.cycleMenuItemsGroup[i][mealCode].recipe_id,
                  label: ApiRecipes.getRecipeLabel(
                    this.cycleMenuItemsGroup[i][mealCode]["recipe.name"],
                    this.cycleMenuItemsGroup[i][mealCode]["recipe.allergens"],
                    this.cycleMenuItemsGroup[i][mealCode]["recipe.macros"]
                  ),
                },
              ],
              "value",
              "label",
              true
            );
          }
          setChoiceByValue(id, this.cycleMenuItemsGroup[i][mealCode].recipe_id);

          element.addEventListener(
            "search",
            (event) => this.handleSearchRecipe(event, id),
            false
          );
          element.addEventListener(
            "change",
            async function (event) {
              let item = appInstance.recipes.find(
                (el) => el.id == event.detail.value
              );
              if (!item) {
                item = { "recipe.macros": "" };
              }
              let mealCode = event.target.dataset.meal_code;

              appInstance.cycleMenuItemsGroup[mealType][mealCode][
                "recipe.macros"
              ] = item["recipe.macros"];
            },
            false
          );
        }
      }
    },
    async handleSearchRecipe(event, id) {
      clearTimeout(this.searchTimeOut);

      this.searchTimeOut = setTimeout(async () => {
        const response = await this.getRecipes(event.detail.value).catch(
          handleError
        );
        if (response.status === 200) {
          this.recipes = ApiRecipes.format(response.data.data);
          await initChoices(id, {
            choices: formatDataToChoicesJs(this.recipes),
          });
        } else {
          showMessage(response.message, "error");
        }
      }, 1000);
    },
    async setPlansChoices() {
      await API.getPlans("start=0&length=-1")
        .then((res) => {
          this.plansChoices = formatDataToChoicesJs(
            res.data.data,
            "no empty option"
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initPlansChoices() {
      const id = "select-plan";
      const element = document.getElementById(id);
      await initChoices(
        id,
        {
          choices: this.plansChoices,
          placeholder: false,
          removeItemButton: false,
        },
        this.selectedPlan.id
      );
      element.addEventListener("change", async (event) => {
        let plan = this.plansChoices.find(
          (x) => x.value === event.detail.value
        );
        this.updateSelectedPlanId(this.selectedPlan.id);
        this.selectedPlan.name = plan.label;
        if (this.selectedDate.length > 0) {
          await this.setCycleMenuItems(
            this.selectedPlan.id,
            this.selectedDate,
            this.selectedDate
          );
        }
      });
    },
    async getMonthEvents(plan_id, startDate, endDate) {
      this.loading.events = true;

      const response = await ApiCycleMenu.get(
        plan_id,
        startDate,
        endDate
      ).catch((err) => handleError(err));
      let events = [];
      this.monthMenuItems = {};
      if (response.status === 200) {
        for (let i in response.data.data) {
          let menuItem = response.data.data[i];
          if (!this.monthMenuItems[menuItem.menu_date]) {
            this.monthMenuItems[menuItem.menu_date] = [];
          }
          this.monthMenuItems[menuItem.menu_date].push(menuItem);
        }
        for (let i in this.monthMenuItems) {
          events.push({
            title: "Total",
            start: i,
            end: i,
            className: "py-1 bg-white text-dark  ",
            extendedProps: {
              type: "event",
              macros: this.getTotalMacros(this.monthMenuItems[i]),
              mealTypeCode: "BF",
            },
          });
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.events = false;
      return events;
    },
    getTotalMacros(items) {
      let result = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };
      for (let i in items) {
        result.calories += parseFloat(items[i]["recipe.macros"].calories);
        result.carbs += parseFloat(items[i]["recipe.macros"].carbs);
        result.fat += parseFloat(items[i]["recipe.macros"].fat);
        result.protein += parseFloat(items[i]["recipe.macros"].protein);
      }
      return result;
    },
    async getWeekEvents(plan_id, startDate, endDate) {
      this.loading.events = true;

      const response = await ApiCycleMenu.get(
        plan_id,
        startDate,
        endDate
      ).catch((err) => handleError(err));
      let events = [];
      if (response.status === 200) {
        this.weekMenuItems = [];
        for (let i in response.data.data) {
          let menuItem = response.data.data[i];
          if (!this.weekMenuItems[menuItem.menu_date]) {
            this.weekMenuItems[menuItem.menu_date] = [];
          }
          this.weekMenuItems[menuItem.menu_date].push(menuItem);
        }
        for (let menu_date in this.weekMenuItems) {
          let dayMenuItems = this.weekMenuItems[menu_date];
          events.push({
            title: "Total",
            start: menu_date,
            end: menu_date,
            className: "py-1 bg-white text-dark ",
            extendedProps: {
              type: "event",
              macros: this.getTotalMacros(dayMenuItems),
              mealTypeCode: "BF",
            },
          });
          for (let i in dayMenuItems) {
            let menuItem = dayMenuItems[i];
            for (let mealTypeKey in this.mealTypes) {
              const mealType = this.mealTypes[mealTypeKey];
              const mealTypeCode = menuItem.meal_code.slice(0, 2);
              if (mealTypeCode != mealType.name) {
                continue;
              }
              const mealTypeName = this.getMealTypeValueByName(mealTypeCode);
              const hour = getHourFromSlotLabelsByName(
                mealTypeName,
                this.slotLabelsByHour
              );
              const menu_date = new Date(
                menuItem.menu_date +
                  " " +
                  hour +
                  ":0" +
                  menuItem.meal_code.slice(2, 3)
              );
              let event = {
                id: menuItem.id,
                title: menuItem["recipe.name"],
                start: menu_date,
                end: menu_date,
                className: "py-1  bg-white text-dark",
                extendedProps: {
                  type: "event",
                  mealTypeName: mealTypeName,
                  mealTypeCode: mealTypeCode,
                  menuItem: menuItem,
                },
              };
              events.push(event);
            }
          }
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.events = false;
      return events;
    },
    getMealTypeValueByName(name) {
      let item = this.mealTypes.find((x) => x.name === name);
      if (item) {
        return item.value;
      }
      return name;
    },

    async calendarDateClick(info) {
      this.calendarApi.gotoDate(info.dateStr);
      if (info.view.type == "multiMonthYear") {
        this.calendarApi.changeView("dayGridMonth");
      }
      this.selectedDate = info.dateStr;
      this.updateSelectedDate(this.selectedDate);
      await this.setCycleMenuItems(
        this.selectedPlan.id,
        this.selectedDate,
        this.selectedDate
      );
    },
    async calendarEventClick(info) {
      let eventDate = dateFormat(info.event.start, "YYYY-MM-DD");
      if (eventDate != this.selectedDate) {
        this.calendarApi.gotoDate(eventDate);
        this.selectedDate = eventDate;
        this.updateSelectedDate(this.selectedDate);
        this.cycleMenuItems = this.weekMenuItems[this.selectedDate];
        if (!this.cycleMenuItems) {
          await this.setCycleMenuItems(
            this.selectedPlan.id,
            this.selectedDate,
            this.selectedDate
          );
        } else {
          await this.setCycleMenuItemsGroup(this.cycleMenuItems);
        }
        this.loading.cycleMenuItems = false;
      }
      let navItemElement = document.getElementById(
        "nav-" + info.event.extendedProps.mealTypeCode
      );
      let navElement = document.getElementById("cycle-nav");

      setTimeout(function () {
        navItemElement.click();
        navElement.scrollIntoView();
      }, 10);
    },
    async eventsSet() {
      await this.$nextTick();
      this.mealTypes.forEach((mealType) => {
        let elements = document.querySelectorAll(
          ".fc-event-main-" + mealType.name
        );
        let maxHeight = 0;
        elements.forEach((element) => {
          maxHeight = Math.max(maxHeight, element.offsetHeight);
        });
        elements.forEach((element) => {
          element.style.height = maxHeight + "px";
        });
      });
    },
    async datesSet(info) {
      this.loading.cycleMenuItems = true;
      this.calendarApi.getEventSources().forEach((eventSource) => {
        eventSource.remove();
      });
      let startDate = dateFormat(info.view.currentStart, "YYYY-MM-DD");
      let endDate = dateFormat(
        previousDate(info.view.currentEnd),
        "YYYY-MM-DD"
      );
      if (info.view.type == "dayGridWeek") {
        if (startDate != this.weekStartDate || endDate != this.weekEndDate) {
          this.weekStartDate = startDate;
          this.weekEndDate = endDate;
          this.weekEvents = await this.getWeekEvents(
            this.selectedPlan.id,
            this.weekStartDate,
            this.weekEndDate
          );
        }
        this.calendarApi.addEventSource(this.weekEvents);
      }
      if (info.view.type == "dayGridMonth") {
        if (startDate != this.monthStartDate || endDate != this.monthEndDate) {
          this.monthStartDate = startDate;
          this.monthEndDate = endDate;
          this.monthEvents = await this.getMonthEvents(
            this.selectedPlan.id,
            this.monthStartDate,
            this.monthEndDate
          );
          if (
            Object.keys(this.cycleMenuItems).length === 0 &&
            this.monthMenuItems[this.selectedDate]
          ) {
            this.cycleMenuItems = this.monthMenuItems[this.selectedDate];
            await this.setCycleMenuItemsGroup(this.cycleMenuItems);
          }
        }
        this.calendarApi.addEventSource(this.monthEvents);
      }
      this.loading.cycleMenuItems = false;
    },
    async setCycleMenuItems(plan_id, startDate, endDate) {
      this.loading.cycleMenuItems = true;
      let response = await ApiCycleMenu.get(plan_id, startDate, endDate).catch(
        handleError
      );
      if (response.status === 200) {
        this.cycleMenuItems = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
      await this.setCycleMenuItemsGroup(this.cycleMenuItems);
      this.loading.cycleMenuItems = false;
    },
    async setCycleMenuItemsGroupDefault() {
      for (let k in this.mealTypes) {
        this.cycleMenuItemsGroup[this.mealTypes[k].name] = {};
        for (
          let i = 1;
          i <= process.env.VUE_APP_MAX_RECIPES_IN_MENU_CATEGORY;
          i++
        ) {
          this.cycleMenuItemsGroup[this.mealTypes[k].name][
            this.mealTypes[k].name + i
          ] = {
            recipe_id: "",
          };
        }
      }
    },
    async setCycleMenuItemsGroup(items) {
      this.loading.cycleMenuItemsGroup = true;
      await this.setCycleMenuItemsGroupDefault();
      for (let k in this.mealTypes) {
        for (
          let i = 1;
          i <= process.env.VUE_APP_MAX_RECIPES_IN_MENU_CATEGORY;
          i++
        ) {
          let mealCode = this.mealTypes[k].name + i;
          let mealData = items.find((el) => el.meal_code == mealCode);
          if (mealData) {
            this.cycleMenuItemsGroup[this.mealTypes[k].name][mealCode] =
              mealData;
          }
          setChoiceByValue(
            this.getSelectRecipeId(mealCode),
            this.cycleMenuItemsGroup[this.mealTypes[k].name][mealCode].recipe_id
          );
        }
      }
      await nextTick();
      await this.initRecipeChoices();
      this.loading.cycleMenuItemsGroup = false;
    },
    async saveCycleMenu() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      formData.append("menu_date", this.selectedDate);
      let index = 0;
      for (let group in this.cycleMenuItemsGroup) {
        let groupNumber = 1;
        for (let mealCode in this.cycleMenuItemsGroup[group]) {
          if (this.cycleMenuItemsGroup[group][mealCode].recipe_id > 0) {
            formData.append(
              "cycle_menu[" + index + "][meal_code]",
              group + groupNumber++
            );
            formData.append(
              "cycle_menu[" + index + "][recipe_id]",
              this.cycleMenuItemsGroup[group][mealCode].recipe_id
            );
            index++;
          }
        }
      }

      let saveResponse = await ApiCycleMenu.save(
        this.selectedPlan.id,
        formData
      ).catch(API.handleError);

      if (saveResponse.status === 200) {
        showMessage("Cycle Menu saved successfully.", "success");
        await this.updateCalendar();
      } else {
        showMessage(saveResponse.message, "error");
      }
      this.formSubmitted = false;
    },
    getSelectRecipeId(mealCode) {
      return "item-" + mealCode;
    },
    async downloadPDF() {
      let startDate = dateFormat(
        this.calendarApi.view.currentStart,
        "YYYY-MM-DD"
      );
      let endDate = dateFormat(
        previousDate(this.calendarApi.view.currentEnd),
        "YYYY-MM-DD"
      );
      let response = await ApiCycleMenu.downloadPDF(
        this.selectedPlan.id,
        startDate,
        endDate
      ).catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          "Cycle-Menu-" +
            this.selectedPlan.name +
            "-" +
            startDate +
            "-" +
            endDate +
            ".pdf",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async downloadExcel() {
      let info = this.$refs.fullCalendar.getApi();
      let startDate = dateFormat(info.view.currentStart, "YYYY-MM-DD");
      let endDate = dateFormat(
        previousDate(info.view.currentEnd),
        "YYYY-MM-DD"
      );
      let response = await ApiCycleMenu.downloadExcel(
        this.selectedPlan.id,
        startDate,
        endDate
      ).catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          "Cycle-Menu-" +
            this.selectedPlan.name +
            "-" +
            startDate +
            "-" +
            endDate +
            ".xlsx",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async updateCalendar() {
      this.monthStartDate = "";
      this.monthEndDate = "";
      this.weekStartDate = "";
      this.weekEndDate = "";
      await this.datesSet(this.calendarApi);
    },
  },
};
</script>