<template>
  <div>
    <form @submit.prevent="planAmendmentQueueSubmit">
      <div class="row">
        <div v-show="showEditPlanAmendmentQueue" class="col-12">
          <label class="form-label">Status</label>
          <select
            id="plan-amendment-queue-status"
            v-model="form.status"
          ></select>
        </div>
        <div v-if="showAddPlanAmendmentQueue" class="col-12 mt-2">
          <label class="form-label">Start Date</label>

          <flat-pickr
            v-model="form.startDate"
            class="form-control"
            placeholder="Please select start date"
            :config="configs.startDate"
            @on-change="onStartDateChange"
          ></flat-pickr>
        </div>
        <div v-else class="col-12 mt-2">
          <label class="form-label">Amended Until Date</label>
          <flat-pickr
            v-model="form.amendedUntil"
            class="form-control"
            placeholder="Please select amended until date"
            :config="configs.amendedUntil"
          ></flat-pickr>
        </div>
        <div v-show="showAddPlanAmendmentQueue" class="col-6 mt-2">
          <label class="form-label">End Date</label>
          <flat-pickr
            v-model="form.endDate"
            class="form-control"
            placeholder="Please select end date"
            :config="configs.endDate"
            @on-change="onEndDateChange"
          ></flat-pickr>
        </div>
        <div v-show="showEditPlanAmendmentQueue" class="col-12 mt-2">
          <label class="form-label">Amender</label>
          <select
            id="plan-amendment-queue-amender"
            v-model="form.amender_id"
            class="form-control"
          ></select>
        </div>
        <div class="col-12 mt-2">
          <argon-textarea
            id="notes"
            placeholder=""
            :model-value="form.notes"
            @update:model-value="form.notes = $event"
            >Notes
          </argon-textarea>
        </div>
        <div class="col-12 mt-2" :class="footerClass">
          <button
            v-if="
              $can('update', 'plan_amendment_queues') ||
              $can('create', 'plan_amendment_queues')
            "
            class="btn bg-gradient-success btn-sm mb-0"
          >
            Save
          </button>
          <span
            v-if="showBackButton"
            class="btn bg-gradient-secondary btn-sm ms-3 mb-0"
            @click="$emit('back')"
            >Back</span
          >
          <span
            v-if="showCloseButton"
            class="btn bg-gradient-secondary btn-sm ms-3 mb-0"
            @click="$emit('close')"
            >Close</span
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import API from "@/services/api";
import apiPlanAmendmentQueue from "@/services/apiPlanAmendmentQueue";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import { flatPickrConfig } from "@/lib/flatPickrHelper";
export default {
  name: "PlanAmendmentQueueForm",
  components: {
    flatPickr,
    ArgonTextarea,
  },
  props: {
    footerClass: {
      type: String,
      default: "",
    },
    clientPlanDetailId: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    showAddPlanAmendmentQueue: {
      type: Boolean,
      default: false,
    },
    showEditPlanAmendmentQueue: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saved", "back", "close"],

  data() {
    return {
      formSubmiited: false,
      form: {
        startDate: "",
        endDate: "",
        amendedUntil: "",
        amender_id: "",
        notes: "",
        status: "Pending",
      },
      configs: {
        startDate: {
          ...flatPickrConfig,
          maxDate: null,
        },
        endDate: {
          ...flatPickrConfig,
          minDate: null,
        },
        amendedUntil: {
          ...flatPickrConfig,
          minDate: null,
        },
      },
    };
  },
  computed: {},
  async mounted() {
    this.form = this.initialData;
    this.initPlanAmendmentQueueStatus();
    await this.initPlanAmendmentQueueAmender();
  },
  methods: {
    onStartDateChange(selectedDates, dateStr) {
      this.configs.endDate.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.startDate.maxDate = dateStr;
    },
    async planAmendmentQueueSubmit() {
      if (
        this.form.id &&
        this.$ability.can("update", "plan_amendment_queues")
      ) {
        this.updatePlanAmendmentQueue();
      } else if (this.$ability.can("create", "plan_amendment_queues")) {
        this.createPlanAmendmentQueue();
      } else {
        showMessage(
          "You are not allowed to add or update plans in queue",
          "error"
        );
      }
    },
    async updatePlanAmendmentQueue() {
      const data = {
        status: this.form.status,
      };
      if (this.form.notes) {
        data.notes = this.form.notes;
      }
      if (this.form.amender_id) {
        data.amender_id = this.form.amender_id;
      }
      if (this.form.amendedUntil) {
        data.amended_until = this.form.amendedUntil;
      }
      let response = await apiPlanAmendmentQueue
        .update(this.form.id, data)
        .catch(handleError);

      if (response.status == 200) {
        showMessage("Plan in queue updated successfully", "success");

        this.$emit("saved");
      } else {
        showMessage(response.message, "error");
      }
    },
    async createPlanAmendmentQueue() {
      if (!this.form.startDate) {
        showMessage("Please select start date", "error");
        return;
      }
      if (!this.form.endDate) {
        showMessage("Please select end date", "error");
        return;
      }
      let data = {
        client_plan_detail_id: this.clientPlanDetailId,
        start_date: this.form.startDate,
        end_date: this.form.endDate,
      };

      if (this.form.notes) {
        data.notes = this.form.notes;
      }

      let response = await apiPlanAmendmentQueue
        .create(data)
        .catch(handleError);
      if (response.status == 200) {
        showMessage("Plan added to the queue", "success");

        this.$emit("saved");
      } else {
        showMessage(response.message, "error");
      }
    },
    initPlanAmendmentQueueStatus() {
      initChoices(
        "plan-amendment-queue-status",
        {
          choices: apiPlanAmendmentQueue.getStatusChoices(),
          searchEnabled: false,
        },
        this.initialData.status
      );
    },
    async initPlanAmendmentQueueAmender() {
      let choices = await this.getAmenderChoices();
      initChoices(
        "plan-amendment-queue-amender",
        {
          choices: choices,
        },
        this.initialData.amender_id
      );
    },
    async getAmenderChoices() {
      const response = await API.getNutritionists().catch(handleError);
      if (response.status == 200) {
        return formatDataToChoicesJs(response.data.data);
      } else {
        showMessage(response.message, "error");
        return [];
      }
    },
  },
};
</script>