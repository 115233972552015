import { httpApi } from "@/services/httpApi";

class ApiInvoices {
  async get(id) {
    return await httpApi.get("api/v1/invoices/" + id);
  }
  async applyDiscountCode(id, data) {
    return await httpApi.post(
      "api/v1/invoices/" + id + "/apply-discount-code",
      data
    );
  }
  async includeAddon(id, data) {
    return await httpApi.post("api/v1/invoices/" + id + "/include-addon", data);
  }
  async reset(id) {
    return await httpApi.post("api/v1/invoices/" + id + "/reset");
  }

  async removeLoyaltyDiscount(id) {
    return await httpApi.post(
      "api/v1/invoices/" + id + "/remove-loyalty-discount"
    );
  }

  async setStatus(id, status) {
    return await httpApi.post("api/v1/invoices/" + id + "/" + status);
  }

  getStatusButton(status) {
    let result = { class: "dark", icon: "fa fa-file-o" };
    if (status === "Paid" || status === "Completed") {
      result = { class: "success", icon: "fa fa-check" };
    } else if (status === "Cancelled") {
      result = { class: "danger", icon: "fa fa-times" };
    } else if (status === "Published") {
      result = { class: "info", icon: "fa fa-arrow-up" };
    } else if (status === "Rejected") {
      result = { class: "danger", icon: "fa fa-ban" };
    }
    return result;
  }
  async addWalletPayment(invoice_number, data) {
    return await httpApi.post(
      "api/v1/invoices/" + invoice_number + "/add-wallet-payment",
      data
    );
  }
  async attachMedia(invoice_number, data) {
    return await httpApi.post(
      "api/v1/invoices/" + invoice_number + "/attach-media",
      data
    );
  }
}

export default new ApiInvoices();