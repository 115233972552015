<template>
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-6 col-sm-auto">
        <label class="form-label">Delivery Date</label>
        <flat-pickr
          v-model="filter.delivery_date"
          class="form-control"
          placeholder="Please select delivery date"
        ></flat-pickr>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <submit-form-button
          v-if="showFilterButton"
          :form-submitted="formSubmitted"
          default-button-text="Filter"
          @click="emitFilterEvent"
        />
        <submit-form-button
          :form-submitted="formSubmitted"
          default-button-text="Download Excel"
          :class="showFilterButton ? 'ms-3' : ''"
          @click="emitDownloadEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "FilterTable",
  components: {
    flatPickr,
    SubmitFormButton,
  },
  props: {
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    initialDeliveryDate: {
      type: String,
      default: null,
    },
    initialEndDate: {
      type: String,
      default: null,
    },
    configs: {
      type: Object,
      default: () => ({}),
    },
    showFilterButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["start_dateChange", "download", "filter"],
  data() {
    return {
      filter: {
        delivery_date: "",
      },
    };
  },
  mounted() {
    this.filter = {
      delivery_date: this.initialDeliveryDate,
    };
  },
  methods: {
    emitDownloadEvent() {
      this.$emit("download", this.filter);
    },
    emitFilterEvent() {
      this.$emit("filter", this.filter);
    },
  },
};
</script>
