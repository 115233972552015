<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex">
        <h6 class="mb-1">{{ title }}</h6>
      </div>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Start date:</strong> &nbsp;
          {{ dateFormat(info.startDate) }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">End date:</strong> &nbsp;
          {{ dateFormat(info.endDate) }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Pending days:</strong> &nbsp;
          {{ info.pendingDays }}
        </li>
        <li v-if="info.notes" class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Notes:</strong> &nbsp;
          {{ info.notes }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0 text-success">
          <strong>Status: &nbsp; {{ info.status }}</strong>
        </li>
      </ul>
      <button
        v-if="
          (info.status != 'Pushed' && info.status != 'Completed') ||
          (info.status == 'Completed' && info.pendingDays > 0)
        "
        class="btn bg-gradient-success btn-sm mt-3"
        @click="setStatus('push')"
      >
        Push
      </button>
      <hr />
      <div class="d-flex my-3 align-items-center">
        <h6 class="text-dark mb-0">Plan Queue:</h6>
        <span
          v-if="
            showPlanAmendmentQueuesTable &&
            $can('create', 'plan_amendment_queues')
          "
          class="btn bg-gradient-success btn-sm ms-auto mb-auto"
          @click="addPlanAmendmentQueue"
          >Add to queue</span
        >
      </div>
      <data-table
        v-if="showPlanAmendmentQueuesTable"
        class="overflow-auto max-height-300"
        :options="planAmendmentQueues.options"
        :data="planAmendmentQueues.data"
        @row-clicked="editPlanAmendmentQueue"
      />
      <plan-amendment-queue-form
        v-if="showAddPlanAmendmentQueue || showEditPlanAmendmentQueue"
        :initial-data="planAmendmentQueueForm"
        :client-plan-detail-id="info.id"
        :show-add-plan-amendment-queue="showAddPlanAmendmentQueue"
        :show-edit-plan-amendment-queue="showEditPlanAmendmentQueue"
        :show-close-button="false"
        @saved="planAmendmentQueueSaved"
        @back="planAmendmentQueueBack"
      />
      <hr />
      <plan-auto-amendments
        :plan-details-id="info.id"
        @auto-amendments-changed="$emit('auto-amendments-changed')"
      />
    </div>
  </div>
</template>

<script>
import { handleError } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";

import ApiClientPlanDetails from "@/services/apiClientPlanDetails";
import DataTable from "@/components/DataTable";
import PlanAmendmentQueueForm from "./PlanAmendmentQueueForm.vue";
import { showMessage } from "@/assets/js/show-message";
import PlanAutoAmendments from "@/views/pages/Clients/components/PlanAutoAmendments.vue";
export default {
  name: "PlanDetailsCard",
  components: {
    PlanAutoAmendments,
    DataTable,
    PlanAmendmentQueueForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
    info: {
      type: Object,
      id: String,
      name: String,
      startDate: String,
      endDate: String,
      notes: String,
      status: String,
      default: () => {},
    },
    planAmendmentQueuesData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["plan-amendment-queues-changed", 'auto-amendments-changed'],
  data() {
    return {
      statusDisplayed: false,
      statusButton: {
        activeClass: "btn-success",
        inactiveClass: "opacity-5",
      },
      planAmendmentQueueForm: {
        id: "",
        startDate: "",
        endDate: "",
        notes: "",
        status: "Pending",
        amender_id: "",
        amendedUntil: "",
      },
      planAmendmentQueues: {},
      showAddPlanAmendmentQueue: false,
      showEditPlanAmendmentQueue: false,
    };
  },
  computed: {
    showPlanAmendmentQueuesTable() {
      return (
        this.$ability.can("view", "plan_amendment_queues") &&
        this.planAmendmentQueues.data &&
        !this.showAddPlanAmendmentQueue &&
        !this.showEditPlanAmendmentQueue
      );
    },
  },
  async mounted() {
    this.setPlanAmendmentQueues(this.planAmendmentQueuesData);
  },
  methods: {
    dateFormat,
    getButtonClass(el, status) {
      let result = "btn-" + (el.action ? el.action : "draft");
      if (el.label === status) {
        result += " " + this.statusButton.activeClass;
        this.statusDisplayed = true;
      }
      if (!this.statusDisplayed && el.show) {
        result += " " + this.statusButton.activeClass;
      }
      if (this.statusDisplayed) {
        result +=
          el.label === status || !el.requires || el.requires === status
            ? ""
            : " " + this.statusButton.inactiveClass;
      }
      if (!el.show && el.label !== status) {
        result += " " + this.statusButton.inactiveClass;
      }
      return result;
    },
    async setStatus(action) {
      if (this.info.id > 0 && action) {
        let response = await ApiClientPlanDetails.setStatus(
          this.clientId,
          this.info.id,
          action
        ).catch(handleError);
        if (response.status === 200) {
          showMessage("Status Updated", "success");
          let elements = document.getElementsByClassName("btn-" + action);
          for (let i in elements) {
            if (elements[i].classList) {
              elements[i].classList.add(this.statusButton.activeClass);
              elements[i].classList.remove(this.statusButton.inactiveClass);
            }
          }
        } else {
          showMessage(response.message, "error");
        }
      }
    },
    editPlanAmendmentQueue(data) {
      if (!this.$ability.can("update", "plan_amendment_queues")) {
        return;
      }
      this.planAmendmentQueueForm.status = data.status;
      this.planAmendmentQueueForm.id = data.id;
      this.planAmendmentQueueForm.amender_id = data.amender_id;
      this.planAmendmentQueueForm.notes = data.notes;
      this.planAmendmentQueueForm.amendedUntil = dateFormat(
        data.amended_until,
        "YYYY-MM-DD"
      );
      this.showAddPlanAmendmentQueue = false;
      this.showEditPlanAmendmentQueue = true;
    },
    addPlanAmendmentQueue() {
      this.planAmendmentQueueForm.status = "Queued";
      this.showAddPlanAmendmentQueue = true;
      this.showEditPlanAmendmentQueue = false;
    },
    planAmendmentQueueSaved() {
      this.showAddPlanAmendmentQueue = false;
      this.showEditPlanAmendmentQueue = false;
      this.$emit("plan-amendment-queues-changed");
    },
    planAmendmentQueueBack() {
      this.showAddPlanAmendmentQueue = false;
      this.showEditPlanAmendmentQueue = false;
    },
    setPlanAmendmentQueues(data) {
      this.planAmendmentQueues = {
        options: {
          columns: [
            {
              title: "Start",
              data: "start_date",
              class: "text-xs",
            },
            {
              title: "End",
              data: "end_date",
              class: "text-xs",
            },
            {
              title: "Amended until",
              data: "amended_until",
              class: "text-xs",
            },
            {
              title: "Status",
              data: "status",
              class: "text-xs",
            },
          ],
        },
        data: [],
      };
      data.forEach((item) => {
        this.planAmendmentQueues.data.push({
          id: item.id,
          amender_id: item.amender_id,
          amender_name: item.amender_name,
          start_date: dateFormat(item.start_date),
          end_date: dateFormat(item.end_date),
          amended_until: dateFormat(item.amended_until),
          status: item.status,
          notes: item.notes,
        });
      });
    },
  },
};
</script>
<style>
.stage-container {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
}
.stage-container .btn {
  border-radius: 0;
}
.max-height-300 {
  max-height: 300px;
}
</style>