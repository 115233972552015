export function dateFormat(date, options, locales, clean) {
  if (!date){
    return;
  }
  if (typeof date === "string" && /^[-+]\d+\s+days?$/.test(date.trim())) {
    const match = date.match(/^([-+])(\d+)\s+days?$/);
    const direction = match[1] === "+" ? 1 : -1;
    const days = parseInt(match[2], 10);
    const today = new Date();
    today.setDate(today.getDate() + days * direction);
    date = today;
  } else if (date === "current" || date === "today") {
    date = new Date();
  }
  if (date === "yesterday") {
    date = new Date();
    date.setDate(date.getDate() - 1);
  }

  if (!locales) {
    locales = "en-UK";
  }
  if (options === "YYYY-MM-DD") {
    options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    locales = "fr-CA";
  }
  if (options === "dateTime") {
    options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  }
  if (options === "time") {
    options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  }
  if (options === "timestamp") {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    clean = true;
  }
  if (!options) {
    options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
  }
  let result = date;
  if (!isNaN(Date.parse(date))) {
    result = new Date(date);
  }
  if (options === "Do MMMM") {
    const day = result.getDate();
    const month = result.toLocaleString(locales, { month: "long" });
    result = `${ordinalSuffix(day)} ${month}`;
  } else {
    result = result.toLocaleString(locales, options);
  }
  if (clean) {
    result = result.replace(/\/|,|:|\s/g, "");
  }
  return result;
}
export function dateDiff(start_date, end_date, unit) {
  if (!start_date || !end_date) {
    return "";
  }
  let d1 = new Date(start_date);
  let d2 = new Date(end_date);

  let diff = d2.getTime() - d1.getTime();
  if (!unit) {
    unit = "d";
  }
  let miliseconds = 1;
  if (unit == "d") {
    miliseconds = 1000 * 60 * 60 * 24;
  }
  return Math.ceil(diff / miliseconds) + 1;
}
export function timeFormat(date, options, locales) {
  if (!locales) {
    locales = "en-UK";
  }
  if (!options) {
    options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  }
  return dateFormat(date, options, locales);
}
export function previousDate(date_str) {
  const date = new Date(date_str);
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return previous.toString();
}
export function isDateBetween(dateToCheck, startDate, endDate) {
  const checkDate = new Date(dateToCheck);
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  return checkDate >= startDateObj && checkDate <= endDateObj;
}
function ordinalSuffix(day) {
  const j = day % 10,
    k = day % 100;
  if (j == 1 && k != 11) {
    return day + "st";
  }
  if (j == 2 && k != 12) {
    return day + "nd";
  }
  if (j == 3 && k != 13) {
    return day + "rd";
  }
  return day + "th";
}