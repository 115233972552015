<template>
  <div class="card">
    <div class="card-header p-3 pb-0">
      <h6 class="mb-1">Macro calculator</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Start Date</label>
          <flat-pickr
            v-model="startDate"
            class="form-control"
            placeholder="Please select start date"
            :config="configs.startDate"
            @on-change="onStartDateChange"
          ></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">End Date</label>
          <flat-pickr
            v-model="endDate"
            class="form-control"
            placeholder="Please select end date"
            :config="configs.endDate"
            @on-change="onEndDateChange"
          ></flat-pickr>
        </div>
        <div class="col-12 mt-3">
          <recipe-macros-badge
            v-if="averageMacros.average_calories"
            :calories="averageMacros.average_calories"
            :carbs="averageMacros.average_carbs"
            :fat="averageMacros.average_fat"
            :protein="averageMacros.average_protein"
          />
        </div>
      </div>
    </div>
    <div class="card-footer py-1">
      <button class="btn bg-gradient-success btn-sm" @click="calculate()">
        Calculate
      </button>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import ApiClientPlanDetails from "@/services/apiClientPlanDetails";
import { showMessage } from "@/assets/js/show-message";
import RecipeMacrosBadge from "@/components/RecipeMacrosBadge";
export default {
  name: "MacroCalculatorCard",
  components: {
    RecipeMacrosBadge,
    flatPickr,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    defaultStartDate: {
      type: String,
      default: "",
    },
    defaultEndDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      averageMacros: {},
      startDate: "",
      endDate: "",
      configs: {
        startDate: {
          maxDate: null,
        },
        endDate: {
          minDate: null,
        },
      },
    };
  },
  mounted() {
    this.startDate = this.defaultStartDate;
    this.endDate = this.defaultEndDate;
  },
  methods: {
    onStartDateChange(selectedDates, dateStr) {
      this.configs.endDate.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.startDate.maxDate = dateStr;
    },
    async calculate() {
      this.averageMacros = {};
      const response = await ApiClientPlanDetails.getAverageMacros(
        this.clientId,
        this.startDate,
        this.endDate
      );
      if (response.status === 200) {
        this.averageMacros = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
  },
};
</script>
