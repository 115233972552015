<template>
  <loading-spinner :loading="loading" />
  <div v-show="!loading" id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <argon-avatar
          :img="user.image"
          alt="team-3"
          size="xl"
          shadow="sm"
          border-radius="lg"
          class="cursor-pointer"
          @click="showProfilePicturePicker"
        />
        <input
          ref="file"
          type="file"
          style="display: none"
          accept="image/png, image/gif, image/jpeg"
          @change="selectProfilePicture"
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ userName }}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ user_roles_list }}</p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <label class="form-check-label mb-0 me-2">
          <small id="profileVisibility">Switch to invisible</small>
        </label>
        <argon-switch
          id="switch-invisible"
          name="Switch to Invisible"
          checked
        />
      </div>
    </div>
  </div>
  <div v-show="!loading" id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="updateUserBasicInfo">
        <div class="row">
          <div class="col-6">
            <label class="form-label">Name</label>
            <argon-input
              id="name"
              type="text"
              placeholder="Full Name"
              :model-value="user.name"
              :errors="v$.user.name.$errors"
              @update:model-value="user.name = $event"
            />
          </div>
          <div class="col-6">
            <label class="form-label">Email</label>
            <argon-input
              id="email"
              type="email"
              placeholder="example@email.com"
              :model-value="user.email"
              :errors="v$.user.email.$errors"
              @update:model-value="user.email = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label">Username</label>
            <argon-input
              id="username"
              type="text"
              placeholder="username"
              :model-value="user.username"
              :errors="v$.user.username.$errors"
              @update:model-value="user.username = $event"
            />
          </div>
          <div class="col-6">
            <label class="form-label">Mobile Number</label>
            <argon-input
              id="mobile_number"
              type="text"
              placeholder="+971551234567"
              :model-value="user.mobile_number"
              :errors="v$.user.mobile_number.$errors"
              @update:model-value="user.mobile_number = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 align-self-center">
            <argon-textarea
              id="bio"
              placeholder="Say a few words about who you are or what you're working on."
              :model-value="user.bio"
              @update:model-value="user.bio = $event"
              >Bio
            </argon-textarea>
          </div>
          <div class="col-md-6">
            <label class="form-label">Roles</label>
            <select
              id="choices-roles"
              v-model="user.roles"
              class="form-control"
              multiple
            ></select>
          </div>
        </div>
        <argon-button
          class="float-end mt-3 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Update
        </argon-button>
      </form>
    </div>
  </div>
  <div id="update-password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="updateUserPassword">
        <label class="form-label">New password</label>
        <argon-input
          id="new-password"
          type="password"
          placeholder="New Password"
          :model-value="userPassword.password"
          :errors="v$.userPassword.password.$errors"
          @update:model-value="userPassword.password = $event"
        />
        <label class="form-label">Confirm new password</label>
        <argon-input
          id="confirm-password"
          type="password"
          placeholder="Confirm password"
          :model-value="userPassword.confirm_password"
          :errors="v$.userPassword.confirm_password.$errors"
          @update:model-value="userPassword.confirm_password = $event"
        />
        <h5 class="mt-5">Password requirements</h5>
        <p class="text-muted mb-2">
          Please follow this guide for a strong password:
        </p>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">One special characters</span>
          </li>
          <li>
            <span class="text-sm">Min 6 characters</span>
          </li>
          <li>
            <span class="text-sm">One number (2 are recommended)</span>
          </li>
          <li>
            <span class="text-sm">Change it often</span>
          </li>
        </ul>
        <argon-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Update password
        </argon-button>
      </form>
    </div>
  </div>
  <div id="2fa" class="card mt-4">
    <div class="card-header d-flex">
      <h5 class="mb-0">Two-factor authentication</h5>
      <argon-badge color="danger" class="ms-auto">Disabled</argon-badge>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <p class="my-auto">Security keys</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          No Security Keys
        </p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Add
        </argon-button>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">SMS number</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Edit
        </argon-button>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">Authenticator app</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          Not Configured
        </p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Set up
        </argon-button>
      </div>
    </div>
  </div>
  <div id="accounts" class="card mt-4">
    <div class="card-header">
      <h5>Accounts</h5>
      <p class="text-sm">
        Here you can setup and manage your integration settings.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img1" alt="logo_slack" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Slack</h5>
            <a class="text-sm text-body" href="javascript:;">
              Show less
              <i class="fas fa-chevron-up text-xs ms-1" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-slack" name="Enabled" checked />
        </div>
      </div>
      <div class="ps-5 pt-3 ms-3">
        <p class="mb-0 text-sm">
          You haven't added your Slack yet or you aren't authorized. Please add
          our Slack Bot to your account by clicking on
          <a href="javascript">here</a>. When you've added the bot, send your
          verification code that you have received.
        </p>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Verification Code
          </p>
          <argon-input
            id="verification-code"
            type="text"
            value="1172913"
            class="my-auto w-20 ms-sm-auto"
          />
        </div>
        <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Connected account
          </p>
          <h6 class="text-sm ms-auto me-3 my-auto">hello@creative-tim.com</h6>
          <argon-button
            color="danger"
            variant="gradient"
            size="sm"
            class="my-sm-auto mt-2 mb-0"
            type="button"
            name="button"
            >Delete
          </argon-button>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img2" alt="logo_spotify" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Spotify</h5>
            <p class="mb-0 text-sm">Music</p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-spotify" name="Spotify" checked />
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img3" alt="logo_atlassian" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Atlassian</h5>
            <p class="mb-0 text-sm">Payment vendor</p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-atlassian" name="Atlassian" checked />
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img4" alt="logo_asana" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Asana</h5>
            <p class="mb-0 text-sm">Organize your team</p>
          </div>
        </div>
        <div class="my-auto ms-auto">
          <argon-switch id="enable-asana" name="Asana" />
        </div>
      </div>
    </div>
  </div>
  <div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Activity</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user mentions you in a comment</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-email"
                  name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-push"
                  name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
              <td>
                <argon-switch
                  id="notify-sms"
                  name="Sms"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user comments your item.</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-comment-email"
                  name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-comment-push"
                  name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-comment-sms"
                  name="SMS"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user follows you.</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-follow-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-follow-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-follow-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-login-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-login-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-login-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <argon-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Active
        </argon-badge>
        <p class="text-secondary text-sm my-auto me-3">EU</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Safari on iPhone</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
  <div v-show="!loading" id="account-status" class="card mt-4">
    <div class="card-header">
      <h5>Deactivate Account</h5>
      <p class="text-sm mb-0">You can deactivate account here.</p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <p class="text-sm text-secondary ms-auto me-3 my-auto">Active</p>
      <div class="my-auto">
        <argon-switch
          id="account-status-switch"
          v-model:checked="user_account_status"
          name="Active"
          @change="updateUserAccountStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import img from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/small-logos/logo-slack.svg";
import img2 from "@/assets/img/small-logos/logo-spotify.svg";
import img3 from "@/assets/img/small-logos/logo-atlassian.svg";
import img4 from "@/assets/img/small-logos/logo-asana.svg";
import profileImage from "@/assets/img/user-profile-icon.svg";
import API from "@/services/api";
import LoadingSpinner from "@/components/LoadingSpinner";
import { useVuelidate } from "@vuelidate/core";
import {
  validatorMessages,
  strongPassword,
  sameAsPassword,
  required,
  email,
  helpers,
} from "@/lib/validators";
import { showMessage } from "@/assets/js/show-message";
import { initChoices } from "@/assets/js/init-choices";

export default {
  name: "EditUserSideNavItem",
  components: {
    ArgonButton,
    ArgonSwitch,
    ArgonBadge,
    ArgonAvatar,
    ArgonInput,
    ArgonTextarea,
    LoadingSpinner,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
      profileImage,
      roles: [],
      user: {
        name: "",
        email: "",
        username: "",
        mobile_number: "",
        bio: "",
        image: "",
        roles: [],
      },
      user_roles_list: "",
      selected_user_roles: [],
      loading: true,
      spinner: {
        color: "#3AB982",
        size: "48px",
      },
      userPassword: {
        password: "",
        confirm_password: "",
      },
      user_account_status: false,
      formSubmitted: {
        userBasicInfo: false,
        userPassword: false,
      },
      profilePicture: undefined,
    };
  },
  computed: {
    userRoles() {
      return this.user.roles;
    },
    userName() {
      return this.user.name;
    },
  },
  watch: {
    userRoles() {
      this.updateUserRoles();
    },
  },
  async mounted() {
    await this.setRoles();
    await this.setUser();
    await this.initRolesChoices();
    this.loading = false;
  },
  methods: {
    async setRoles() {
      await API.getRoles()
        .then((res) => {
          this.roles = res.data.data;
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        })
        .finally(() => {});
    },
    async setUser() {
      const appInstance = this;
      await API.getUser(appInstance.$route.params.id)
        .then((res) => {
          this.user = API.formatUserDataForEditUser(res.data.data);
          if (!this.user.image.length) {
            this.user.image = this.profileImage;
          }
          this.selected_user_roles = API.formatDataToChoicesJs(
            res.data.data.roles.data
          );
          this.user_account_status = res.data.data.status === "Active";
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "User not found",
              willClose: () => {
                appInstance.$router.push({ name: "Users List" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        })
        .finally(() => {});
    },
    async initRolesChoices() {
      await initChoices("choices-roles", {
        choices: API.formatDataToChoicesJs(this.roles),
        allowHTML: true,
        removeItemButton: true,
        removeItems: true,
      });
      document
        .getElementById("choices-roles")
        ._choices.setValue(this.selected_user_roles);
    },

    async updateUserBasicInfo() {
      await this.v$.$validate();
      if (
        this.v$.$validationGroups.user.$invalid ||
        this.formSubmitted.userBasicInfo
      ) {
        return;
      }
      this.formSubmitted.userBasicInfo = true;

      this.v$.$reset();
      let formData = new FormData();
      for (let key in this.user) {
        if (key != "roles") {
          formData.append(key, this.user[key]);
        }
      }
      for (let key in this.user.roles) {
        formData.append("roles[]", this.user.roles[key]);
      }
      if (this.profilePicture) {
        formData.append("profile_picture", this.profilePicture);
      }
      await this.apiUpdateUser(formData);
      this.formSubmitted.userBasicInfo = false;
    },
    async updateUserPassword() {
      await this.v$.$validate();
      if (
        this.v$.$validationGroups.userPassword.$invalid ||
        this.formSubmitted.userPassword
      ) {
        return;
      }
      this.formSubmitted.userPassword = true;

      this.v$.$reset();
      let formData = new FormData();
      formData.append("password", this.userPassword.password);
      await this.apiUpdateUser(formData);
      this.formSubmitted.userPassword = false;
    },
    async updateUserAccountStatus() {
      let formData = new FormData();
      formData.append(
        "status",
        this.user_account_status ? "Active" : "Inactive"
      );
      await this.apiUpdateUser(formData);
    },
    updateUserRoles() {
      if (this.roles.length) {
        let roles_data = [];
        for (let i in this.user.roles) {
          roles_data.push(this.roles.find((s) => s.id == this.user.roles[i]));
        }
        this.user_roles_list = roles_data.map((role) => role.name).join(", ");
      }
    },
    async apiUpdateUser(data) {
      if (this.loading) {
        return;
      }
      const appInstance = this;
      data.append("_method", "PATCH");
      API.updateUser(appInstance.$route.params.id, data)
        .then(() => {
          this.$swal({
            html: '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>Success. User updated.</span>',
            timer: 1500,
            position: "bottom-end",
            showConfirmButton: false,
            timerProgressBar: true,
            width: "22em",
            padding: "0.5em 0.8em",
            customClass: {
              container: "swal2-small",
            },
            backdrop: false,
          });
        })
        .catch((err) => {
          let message = err.message;
          if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          this.$swal({
            html:
              '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
              message +
              "</span>",
            timer: 3500,
            position: "bottom-end",
            showConfirmButton: false,
            timerProgressBar: true,
            width: "22em",
            padding: "0.5em 0.8em",
            customClass: {
              container: "swal2-small",
            },
            backdrop: false,
          });
        });
    },
    showProfilePicturePicker() {
      this.$refs.file.click();
    },
    selectProfilePicture() {
      this.profilePicture = this.$refs.file.files.item(0);
      this.user.image = URL.createObjectURL(this.profilePicture);
    },
  },

  validations() {
    return {
      user: {
        name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        email: {
          required: helpers.withMessage(validatorMessages.required, required),
          email: helpers.withMessage(validatorMessages.email, email),
        },
        username: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        mobile_number: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
      userPassword: {
        password: {
          required: helpers.withMessage(validatorMessages.required, required),
          strongPassword: helpers.withMessage(
            validatorMessages.strongPassword,
            strongPassword
          ),
        },
        confirm_password: {
          sameAsPassword: helpers.withMessage(
            validatorMessages.sameAsPassword,
            sameAsPassword
          ),
        },
      },
      $validationGroups: {
        user: ["user"],
        userPassword: ["userPassword"],
      },
    };
  },
};
</script>
