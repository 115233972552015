<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table :id="tableId" class="table align-items-center mb-0" width="100%">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Start date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                End date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amended until date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amender name
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ApiPlanAmendmentQueue from "@/services/apiPlanAmendmentQueue";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import apiPlanAmendmentQueue from "@/services/apiPlanAmendmentQueue";
export default {
  name: "PlanAmendmentQueueList",
  components: {},
  data() {
    return {
      tableId: "list-table",
      amenderChoices: [],
    };
  },
  async mounted() {
    this.amenderChoices = await this.getAmenderChoices();
    $.fn.dataTable.ext.classes.sWrapper =
      "dataTables_wrapper dataTable-wrapper";
    $.fn.DataTable.ext.pager.numbers_length = 5;
    await this.initDataTable();
  },
  beforeUnmount() {
    this.destroyDataTable();
  },
  methods: {
    dateFormat,
    async initDataTable() {
      this.destroyDataTable();
      this.initDropdownHandle();
      const appInstance = this;
      $("#" + this.tableId).DataTable({
        serverSide: true,
        pagingType: "simple_numbers",
        searching: false,
        ordering: false,
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: null,
            render: function (data) {
              return (
                '<div class="d-flex px-2">' +
                '<div class="avatar avatar-sm me-3">' +
                '<img src="' +
                data.client_profile_picture +
                '"/>' +
                "</div>" +
                '<div class="my-auto">' +
                '<h6 class="mb-0 text-xs">' +
                data.client_name +
                "</h6>" +
                '<p class="text-xs text-secondary mb-0">' +
                data.client_mobile_number +
                "</p>" +
                "</div>" +
                "</div>"
              );
            },
            searchable: false,
          },
          {
            data: "start_date",
            render: function (data) {
              return (
                '<p class="text-secondary mb-0 text-sm">' +
                dateFormat(data) +
                "</p>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "end_date",
            render: function (data) {
              return (
                '<p class="text-secondary mb-0 text-sm">' +
                dateFormat(data) +
                "</p>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "amended_until",
            render: function (data) {
              return (
                '<p class="text-secondary mb-0 text-sm">' +
                (dateFormat(data) || "") +
                "</p>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: null,
            width: "100px",
            render: function (data) {
              let html =
                '<div class="d-flex flex-column justify-content-center">' +
                '<div class="dropdown">' +
                '<button id="btn-for-' +
                data.id +
                '" class="btn btn-sm btn-outline-dark dropdown-toggle mb-0 w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">' +
                data.amender_name +
                "</button>" +
                '<ul class="dropdown-menu overflow-auto max-height-300">';
              appInstance.amenderChoices.forEach((item) => {
                html +=
                  '<li><a class="dropdown-item update-amender" data-id="' +
                  data.id +
                  '" data-amender_id="' +
                  item.id +
                  '" href="#">' +
                  item.name +
                  "</a></li>";
              });
              html += "</ul>" + "</div>" + "</div>";
              return html;
            },
          },
          {
            data: "status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "Completed" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                data +
                "</span></span>"
              );
            },
            className: "align-middle text-center text-sm",
          },

          {
            data: null,
            render: function (data) {
              let button = "";
              button =
                '<a href="' +
                appInstance.$router.resolve({
                  name: "Client Plan",
                  params: {
                    id: data.client_id,
                    planId: data.client_plan_detail_id,
                  },
                }).href +
                '"><i class="fas fa-eye text-secondary"></i></a> ';

              return button;
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          data.columns[1].data = "client_name";
          ApiPlanAmendmentQueue.index($.param(data))
            .then((res) => {
              callback(res.data);
            })
            .catch((err) => {
              const response = handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    destroyDataTable() {
      if ($.fn.DataTable.isDataTable($("#" + this.tableId))) {
        $("#" + this.tableId)
          .DataTable()
          .destroy();
      }
    },
    tableDraw() {
      $("#" + this.tableId)
        .DataTable()
        .draw();
    },
    async getAmenderChoices() {
      const response = await API.getNutritionists().catch(handleError);
      if (response.status == 200) {
        return response.data.data;
      } else {
        showMessage(response.message, "error");
        return [];
      }
    },
    initDropdownHandle() {
      const appInstance = this;
      $(document)
        .off("click")
        .on("click", ".update-amender", async function () {
          const $this = $(this);
          const id = $this.attr("data-id");
          const amender_id = $this.attr("data-amender_id");
          const amender = appInstance.amenderChoices.find(
            (el) => el.id == amender_id
          );
          const $btn = $("#btn-for-" + id);
          if ($btn.text() == amender.name) {
            return;
          }

          let data = {
            amender_id: amender_id,
          };

          const response = await apiPlanAmendmentQueue
            .update(id, data)
            .catch(handleError);
          if (response.status == 200) {
            $btn.text(amender.name);
            showMessage("Success, Amender changed successfully", "success");
          } else {
            showMessage(response.message, "error");
          }
        });
    },
  },
};
</script>
