<template>
  <div class="mt-4 row">
    <div class="col-12">
      <loading-spinner :loading="loading" />
      <form @submit.prevent="setClientActivity">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-3">
            <label for="filter-event">Event</label>
            <select
              id="filter-event"
              v-model="filter.event"
              class="form-control"
              placeholder="Event"
            ></select>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-3">
            <label for="filter-keyword">Keyword</label>
            <input
              id="invoice-keyword"
              v-model="filter.keyword"
              type="search"
              class="form-control"
              placeholder="Keyword"
            />
          </div>
          <div class="col-12 mt-3 mb-5">
            <argon-button
              class="mt-2 mb-0 ms-2"
              color="success"
              variant="gradient"
              size="sm"
              >Filter
            </argon-button>
          </div>
        </div>
      </form>
      <div class="row">
        <div v-if="!loading" class="col-12">
          <timeline-list>
            <timeline-item
              v-for="(el, index) in clientActivity"
              :key="index"
              :icon="{
                component: 'ni ni-check-bold',
                color: 'success',
              }"
              :title="el.event"
              :date-time="dateFormat(el.created_at, 'dateTime')"
              :description="el.description"
              :badges="[{ title: el['causer.name'], color: 'success' }]"
            />
          </timeline-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showMessage } from "@/assets/js/show-message";
import apiClientActivity from "@/services/apiClientActivity";
import apiMiscList from "@/services/apiMiscList";
import TimelineList from "@/examples/Cards/TimelineList.vue";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import { dateFormat } from "@/lib/dateHelper";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "ClientActivity",
  components: {
    ArgonButton,
    TimelineList,
    TimelineItem,
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      clientActivity: [],
      filter: {
        event: "",
        keyword: "",
      },
    };
  },
  async mounted() {
    await this.setClientActivity();
    await this.initEventChoices();
  },
  methods: {
    dateFormat,
    async initEventChoices() {
      const choices = await apiMiscList.activityEventList();
      await initChoices(
        "filter-event",
        {
          choices: formatDataToChoicesJs(choices.data.data, [], "enum"),
          placeholder: true,
          placeholderValue: "Select event",
          duplicateItemsAllowed: false,
        },
        ""
      );
    },
    async setClientActivity() {
      this.loading = true;
      let data = {
        start: 0,
        length: 500,
      };
      if (this.filter.event) {
        data.event = this.filter.event;
      }
      if (this.filter.keyword) {
        data.keyword = this.filter.keyword;
      }
      const response = await apiClientActivity.get(this.clientId, data);

      if (response.status == 200) {
        this.clientActivity = response.data.data;
      } else {
        this.clientActivity = [];
        showMessage(response.message, "error");
      }
      this.loading = false;
    },
  },
};
</script>