<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <filter-table
            class="card-header"
            :initial-delivery-date="dateFormat('yesterday', 'YYYY-MM-DD')"
            :show-filter-button="true"
            :form-submitted="formSubmitted"
            @download="downloadExcel"
            @filter="handleFilterChange"
          />
          <list-table :filter="currentFilter" class="card-body" @loaded="formSubmitted=false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "./components/ListTable.vue";
import FilterTable from "./components/FilterTable.vue";
import apiBagMovement from "@/services/apiBagMovement";
import { showMessage } from "@/assets/js/show-message";
import { handleError, downloadFile } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";

export default {
  name: "List",
  components: {
    ListTable,
    FilterTable,
  },
  data() {
    return {
      formSubmitted: false,
      currentFilter: {
        delivery_date: dateFormat("yesterday", "YYYY-MM-DD"),
      },
    };
  },
  methods: {
    dateFormat,

    async downloadExcel(filter) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let response = await apiBagMovement
        .deliveryBagMovements({ ...filter, ...{ action: "download" } })
        .catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          "delivery-bag-movements-" +
            dateFormat(filter.delivery_date, "YYYY-MM-DD") +
            ".xlsx"
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    async handleFilterChange(filter) {
      this.formSubmitted = true;
      this.currentFilter = { ...filter };
    },
  },
};
</script>
