import { httpApi } from "@/services/httpApi";
import { handleError } from "@/lib/helpers";

class ApiBagCollectionRequests {
  async index(params) {
    return await httpApi
      .get("api/v1/bag-collection-requests?" + params)
      .catch(handleError);
  }
  async show(id) {
    return await httpApi
      .get("api/v1/bag-collection-requests/" + id)
      .catch(handleError);
  }
  async update(id, data) {
    return await httpApi
      .post("api/v1/bag-collection-requests/" + id, data)
      .catch(handleError);
  }
  async store(data) {
    return await httpApi
      .post("api/v1/bag-collection-requests", data)
      .catch(handleError);
  }
}

export default new ApiBagCollectionRequests();
