<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{
              name: 'Client Plan',
              params: { id: client.id, planId: planDetails.id },
            }"
            class="btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <loading-spinner :loading="loading" />
    <div v-show="!loading" class="row">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Transfer client plan</h5>
            <div class="row align-items-center mt-3">
              <div class="col-12">
                <ul class="list-group">
                  <li class="text-sm border-0 list-group-item p-0 mt-1">
                    <strong class="text-dark">Client name:</strong>
                    {{ client.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark">Client plan variant name:</strong>
                    {{ planDetails["planVariant.name"] }}
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="destination-client-id" class="form-label"
                  >Transfer to client
                </label>
                <select
                  id="destination-client-id"
                  v-model="destinationClientId"
                ></select>
              </div>
              <div class="col-12 col-md-6">
                <label for="transfer-charge-percent" class="form-label"
                  >Transfer charge percent
                </label>
                <input
                  id="transfer-charge-percent"
                  v-model="transferChargePercentage"
                  class="form-control"
                  type="number"
                />
              </div>
              <div class="col-12 col-md-6">
                <label for="destination-plan-id" class="form-label"
                  >Transfer to plan
                </label>
                <select
                  id="destination-plan-id"
                  v-model="destinationPlanId"
                ></select>
              </div>
              <div class="col-12 col-md-6">
                <label for="destination-plan-id" class="form-label"
                  >Transfer to plan variant
                </label>
                <select
                  id="destination-plan-variant-id"
                  v-model="transferToPlanVariantId"
                ></select>
              </div>
              <div class="col-12 col-md-6">
                <label for="source-start-date">Start date </label>
                <flat-pickr
                  id="source-start-date"
                  v-model="startDate"
                  class="form-control"
                  placeholder=""
                />
              </div>
              <div
                v-show="transferDetails.source_plan_variant_name"
                class="col-12 mt-4"
              >
                <h6>Transfer details</h6>
                <ul class="list-group">
                  <li class="text-sm border-0 list-group-item p-0 mt-1">
                    <strong class="text-dark">Source plan variant:</strong>
                    {{ transferDetails.source_plan_variant_name }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark"
                      >Source plan per day value:</strong
                    >
                    {{ currencyFormat(transferDetails.source_per_day_value) }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark">Source pending days:</strong>
                    {{ transferDetails.pending_days }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark">Source pending value:</strong>
                    {{ currencyFormat(transferDetails.pending_amount) }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0 my-2">
                    <strong class="text-dark"
                      >Transfer charge percentage:</strong
                    >
                    {{ transferDetails.transfer_charge_percentage }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark">Destination plan variant:</strong>
                    {{ transferDetails.destination_plan_variant_name }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark"
                      >Destination plan per day value:</strong
                    >
                    {{
                      currencyFormat(transferDetails.destination_per_day_value)
                    }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark"
                      >Destination available days:</strong
                    >
                    {{ transferDetails.available_destination_days }}
                  </li>
                  <li class="text-sm border-0 list-group-item p-0">
                    <strong class="text-dark"
                      >Destination available days with fraction:</strong
                    >
                    {{
                      transferDetails.available_destination_days_with_fraction
                    }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-4">
              <div
                class="text-right col-lg-12 d-flex flex-column justify-content-center"
              >
                <div class="mb-0 ms-lg-auto me-lg-0 me-auto">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="viewTransferDetails"
                  >
                    View transfer details
                  </button>
                  <button
                    type="button"
                    class="btn btn-success btn-sm ms-3"
                    @click="transfer"
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import ApiClientPlanDetails from "@/services/apiClientPlanDetails";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import { currencyFormat } from "@/lib/helpers";
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import LoadingSpinner from "@/components/LoadingSpinner";
import flatPickr from "vue-flatpickr-component";
export default {
  name: "PlanTransfer",
  components: {
    LoadingSpinner,
    flatPickr,
  },
  data() {
    return {
      client: { id: this.$route.params.id },
      loading: true,
      planDetails: { id: this.$route.params.planId },
      destinationClientId: this.$route.params.id,
      destinationPlanId: "",
      transferToPlanVariantId: "",
      transferChargePercentage: 0,
      plans: [],
      transferDetails: {},
      startDate: "",
    };
  },
  async mounted() {
    await this.initClientChoices();
    await this.initPlanChoices();
    await this.setPlanVariantsChoices(0);
    await this.setClientPlanDetail();
    this.loading = false;
  },
  methods: {
    currencyFormat,
    async getPlans() {
      const response = await API.getPlans("start=0&length=-1").catch(
        handleError
      );
      if (response.status === 200) {
        return response.data.data;
      } else {
        showMessage(response.message, "error");
        return [];
      }
    },
    async initPlanChoices() {
      this.plans = await this.getPlans();
      const appInstance = this;
      const id = "destination-plan-id";
      const element = document.getElementById(id);
      await initChoices(id, {
        choices: formatDataToChoicesJs(this.plans),
      });
      element.addEventListener(
        "change",
        async function (event) {
          await appInstance.setPlanVariantsChoices(event.detail.value);
        },
        false
      );
    },
    async setPlanVariantsChoices(plan_id) {
      const id = "destination-plan-variant-id";
      if (plan_id > 0) {
        await API.getPlanVariants(plan_id)
          .then((res) => {
            initChoices(id, {
              choices: formatDataToChoicesJs(res.data.data, "no empty choice", {
                id: "id",
                value: "id",
                label: "name",
              }),
            });
          })
          .catch((err) => {
            const response = handleError(err);
            showMessage(response.message, "error");
          });
      } else {
        await initChoices(id, {
          choices: [
            {
              value: "",
              label: "Select plan first",
              id: "",
            },
          ],
        });
      }
    },
    async initClientChoices() {
      const appInstance = this;
      const id = "destination-client-id";
      const element = document.getElementById(id);
      await initChoices(id, {
        choices: [],
        searchEnabled: true,
        searchFloor: 4,
      });
      await element._choices.setChoices(
        async () => {
          try {
            const result = await appInstance.getClients(appInstance.client.id);
            const client = result.find(
              (x) => x.id.toString() === appInstance.client.id.toString()
            );
            appInstance.client.name = client.name;
            return formatDataToChoicesJs(result, "no empty option");
          } catch (err) {
            console.error(err);
          }
        },
        "value",
        "label",
        true
      );
      setChoiceByValue(id, appInstance.client.id);
      let myTimeOut = null;
      element.addEventListener(
        "search",
        async function (event) {
          clearTimeout(myTimeOut);
          myTimeOut = setTimeout(async () => {
            await element._choices.setChoices(
              async () => {
                try {
                  return formatDataToChoicesJs(
                    await appInstance.getClients(event.detail.value),
                    "no empty option"
                  );
                } catch (err) {
                  console.error(err);
                }
              },
              "value",
              "label",
              true
            );
            element.parentElement.nextSibling.querySelector("input").focus();
          }, 500);
        },
        false
      );
    },
    async getClients(search_value) {
      let params =
        "?draw=10&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true" +
        "&columns[0][search][value]=&columns[0][search][regex]=false" +
        "&columns[1][data]=name&columns[1][name]=name&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=" +
        "&columns[1][search][regex]=false&start=0&length=10&search[value]=" +
        search_value;
      const response = await API.getClients(params).catch(handleError);
      if (response.status === 200) {
        return response.data.data;
      } else {
        showMessage(response.message, "error");
        return [];
      }
    },
    async setClientPlanDetail() {
      const response = await API.getClientPlanDetailsShow(
        this.client.id,
        this.planDetails.id
      ).catch(handleError);
      if (response.status === 200) {
        this.planDetails = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async transfer() {
      let formData = {};
      if (this.destinationClientId != this.client.id) {
        formData["destination_client_id"] = this.destinationClientId;
      }
      formData["transfer_to_plan_variant_id"] = this.transferToPlanVariantId;
      formData["transfer_charge_percentage"] = this.transferChargePercentage;
      formData["start_date"] = this.startDate;
      let saveResponse = await ApiClientPlanDetails.transfer(
        this.client.id,
        this.planDetails.id,
        formData
      ).catch(handleError);

      if (saveResponse.status === 200) {
        showMessage("Client Plan transferred successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }
    },
    async viewTransferDetails() {
      let saveResponse = await ApiClientPlanDetails.getTransferDetails(
        this.client.id,
        this.planDetails.id,
        this.transferToPlanVariantId,
        this.transferChargePercentage
      ).catch(handleError);

      if (saveResponse.status === 200) {
        this.transferDetails = saveResponse.data.data;
      } else {
        this.transferDetails = {};
        showMessage(saveResponse.message, "error");
      }
    },
  },
};
</script>
