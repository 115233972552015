import { httpApi } from "@/services/httpApi";

class apiBodyAssesment {
  async show(hashId) {
    let url = "api/v1/ba/" + hashId;
    return await httpApi.get(url);
  }
}

export default new apiBodyAssesment();
