import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";
class ApiInvoiceRefundRequests {
  async get(invoice_id, invoice_refund_request_id) {
    return await httpApi.get(
      "api/v1/invoices/" +
        invoice_id +
        "/refund-requests/" +
        invoice_refund_request_id
    );
  }
  async list(options) {
    let baseUrl = `api/v1/lists/invoice-refund-requests`;
    const queryParams = buildQueryParams(options);
    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url, { responseType: responseType });
  }
  async create(invoice_id, data) {
    return await httpApi.post(
      "/api/v1/invoices/" + invoice_id + "/refund-requests",
      data
    );
  }
  async update(invoice_id, refundRequestId, data) {
    return await httpApi.post(
      "/api/v1/invoices/" + invoice_id + "/refund-requests/" + refundRequestId,
      data
    );
  }
  async approve(invoice_id, invoice_refund_request_id) {
    return await httpApi.post(
      "/api/v1/invoices/" +
        invoice_id +
        "/refund-requests/" +
        invoice_refund_request_id +
        "/status/approve"
    );
  }
  async reject(invoice_id, invoice_refund_request_id) {
    return await httpApi.post(
      "/api/v1/invoices/" +
        invoice_id +
        "/refund-requests/" +
        invoice_refund_request_id +
        "/status/reject"
    );
  }
}

export default new ApiInvoiceRefundRequests();
