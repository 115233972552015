import { httpApi } from "@/services/httpApi";

class apiRoles {
  async users(roleId) {
    return await httpApi.get("api/v1/roles/" + roleId + "/users");
  }
  async salesOwners() {
    return this.users(8);
  }
}

export default new apiRoles();
