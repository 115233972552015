<template>
  <popper-hover :list-items="computedDislikes" class="ms-2">
    <i class="fas fa-exclamation-circle text-yellow"></i>
  </popper-hover>

  <popper-hover :list-items="computedAllergens" class="ms-2">
    <i class="fas fa-exclamation-circle text-danger"></i>
  </popper-hover>
</template>

<script>
import PopperHover from "@/components/PopperHover.vue";
export default {
  name: "DislikesAllergensIcons",
  components: {
    PopperHover,
  },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    computedDislikes() {
      let dislikes = [];
      if (this.menuItem.recipe.data) {
        if (this.menuItem.recipe.data.client_food_group_dislikes) {
          dislikes = dislikes.concat(
            this.menuItem.recipe.data.client_food_group_dislikes
          );
        }
        if (this.menuItem.recipe.data.client_ingredient_dislikes) {
          dislikes = dislikes.concat(
            this.menuItem.recipe.data.client_ingredient_dislikes
          );
        }
        if (this.menuItem.recipe.data.client_recipe_dislikes) {
          dislikes = dislikes.concat(
            this.menuItem.recipe.data.client_recipe_dislikes
          );
        }
      }
      return dislikes;
    },
    computedAllergens() {
      let allergens = [];
      if (this.menuItem.recipe.data) {
        if (this.menuItem.recipe.data.client_allergens) {
          allergens = allergens.concat(
            this.menuItem.recipe.data.client_allergens
          );
        }
        if (this.menuItem.recipe.data.client_ingredient_allergens) {
          allergens = allergens.concat(
            this.menuItem.recipe.data.client_ingredient_allergens
          );
        }
      }
      return allergens;
    },
    hasAllergens() {
      let result =
        this.menuItem.recipe.data &&
        ((this.menuItem.recipe.data.client_allergens &&
          this.menuItem.recipe.data.client_allergens.length > 0) ||
          (this.menuItem.recipe.data.client_ingredient_allergens &&
            this.menuItem.recipe.data.client_ingredient_allergens.length > 0));
      return result;
    },
    hasDislikes() {
      let result =
        this.menuItem.recipe.data &&
        ((this.menuItem.recipe.data.client_food_group_dislikes &&
          this.menuItem.recipe.data.client_food_group_dislikes.length > 0) ||
          (this.menuItem.recipe.data.client_ingredient_dislikes &&
            this.menuItem.recipe.data.client_ingredient_dislikes.length > 0) ||
          (this.menuItem.recipe.data.client_recipe_dislikes &&
            this.menuItem.recipe.data.client_recipe_dislikes.length > 0));
      return result;
    },
  },
};
</script>
