<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>Wallet Payment</h6>
    </template>
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <label>Amount</label>
        <argon-input
          id="amount"
          type="text"
          placeholder="AED"
          :errors="v$.form.amount.$errors"
          :model-value="form.amount"
          @update:model-value="form.amount = $event"
        />
      </div>
      <div class="col-12">
        <argon-textarea
          id="description"
          placeholder=""
          :model-value="form.description"
          :errors="v$.form.description.$errors"
          @update:model-value="form.description = $event"
          >Description
        </argon-textarea>
      </div>

      <div class="col-12">
        <label>Reference</label>
        <argon-input
          id="reference"
          type="text"
          :model-value="form.reference"
          :errors="v$.form.reference.$errors"
          @update:model-value="form.reference = $event"
        />
      </div>
    </div>
    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">
        Close
      </button>
      <button type="button" class="btn btn-primary" @click="savePayment">
        Save
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { validatorMessages, helpers } from "@/lib/validators";
import { required, numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { handleError, handleResponse } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import apiInvoices from "@/services/apiInvoices";
export default {
  name: "WalletPaymentModal",
  components: {
    Modal,
    ArgonTextarea,
    ArgonInput,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    invoiceId: {
      type: [String, Number],
      default: "",
    },
  },
  emits: ["saved", "close"],
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      form: {
        payment_source: "Wallet",
        amount: "",
        description: "",
        reference: "",
      },
      formSubmitted: false,
    };
  },

  methods: {
    async savePayment() {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      const saveResponse = await apiInvoices.addWalletPayment(
        this.invoiceId,
        formData
      ).catch(handleError);
      await handleResponse(saveResponse, this.savedSuccessfully);
      this.formSubmitted = false;
    },
    savedSuccessfully() {
      showMessage("Wallet payment added successfully.","success");
      this.$emit("saved");
      this.$emit("close");
    },
  },

  validations() {
    return {
      form: {
        amount: {
          required: helpers.withMessage(validatorMessages.required, required),
          numeric: helpers.withMessage(validatorMessages.numeric, numeric),
        },
        description: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        reference: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>