import { httpApi } from "@/services/httpApi";

class ApiPlanAmendmentQueue {
  async index(params) {
    return await httpApi.get("api/v1/plan-amendment-queue?" + params);
  }
  async create(data) {
    return await httpApi.post("api/v1/plan-amendment-queue", data);
  }
  async update(id, data) {
    return await httpApi.post("api/v1/plan-amendment-queue/" + id, data);
  }
  getStatusChoices() {
    let options = [
      {
        value: "Queued",
        label: "Queued",
        id: "Queued",
      },
      {
        value: "On Hold",
        label: "On Hold",
        id: "On Hold",
      },
      {
        value: "Completed",
        label: "Completed",
        id: "Completed",
      },
      {
        value: "Rejected",
        label: "Rejected",
        id: "Rejected",
      },
    ];
    return options;
  }
}

export default new ApiPlanAmendmentQueue();
