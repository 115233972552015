<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/signin-basic.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <div class="text-center mt-2">
                  <img
                    :src="logo"
                    class="navbar-brand-img"
                    alt="main_logo"
                    width="60"
                  />
                </div>
                <h5 class="text-dark text-center mt-3 mb-3">Sign in</h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <form
                  role="form"
                  class="text-start pb-4"
                  @submit.prevent="login"
                >
                  <div class="mb-3">
                    <argon-input
                      id="username"
                      :model-value="auth.username"
                      type="text"
                      placeholder="Username"
                      aria-label="username"
                      @update:model-value="auth.username = $event"
                    />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      :model-value="auth.password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      @update:model-value="auth.password = $event"
                    />
                  </div>
                  <div v-show="errorMessage.length > 0" class="mb-3">
                    <argon-alert color="danger">{{ errorMessage }}</argon-alert>
                  </div>
                  <argon-switch id="rememberMe" name="rememberMe">
                    Remember me
                  </argon-switch>

                  <div class="text-center">
                    <argon-button
                      color="success"
                      variant="gradient"
                      full-width
                      class="my-4 mb-2"
                      >Sign in
                    </argon-button>
                  </div>
                  <div
                    v-if="showSignUp"
                    class="mb-2 position-relative text-center"
                  >
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                    >
                      or
                    </p>
                  </div>
                  <div v-if="showSignUp" class="text-center d-grid">
                    <router-link
                      class="btn bg-gradient-dark btn-md w-100 mt-2"
                      :to="{ name: 'SignUp' }"
                      >Sign up
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import logo from "@/assets/img/kcal-logo.svg";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import { mapActions, mapMutations } from "vuex";
import API from "@/services/api";

export default {
  name: "SigninBasic",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
    ArgonAlert,
  },
  data() {
    return {
      auth: {
        username: "",
        password: "",
      },
      errorMessage: "",
      logo,
      showSignUp: false,
    };
  },
  beforeUnmount() {
    this.showDefaultLayout();
  },
  created() {
    this.hideDefaultLayout();
  },
  methods: {
    ...mapMutations(["showDefaultLayout", "hideDefaultLayout"]),
    ...mapActions({
      signIn: "auth/login",
    }),
    async login() {
      await API.login(this.auth)
        .then(({ data }) => {
          if (data.success) {
            this.errorMessage = "";
            this.signIn();
            this.$store
              .dispatch("auth/setUser")
              .then(() => {
                const dashboard = this.findDashboard(
                  this.$store.state.auth.current_role
                );
                this.$router.push(dashboard);
              })
              .catch((err) => {
                if (err) {
                  this.errorMessage = err.response.data.message;
                }
              });
          } else {
            this.errorMessage = data.message;
          }
        })
        .catch((err) => {
          if (err) {
            this.errorMessage = err.response.data.message;
          }
        })
        .finally(() => {});
    },

    findDashboard(userRole) {
      switch (userRole) {
        case 1:
          return { name: "Dashboard" };
        case 2:
          return { name: "Account Admin Dashboard" };
        case 3:
          return { name: "Account Manager Dashboard" };
        case 4:
          return { name: "Account Supervisor Dashboard" };
        case 5:
          return { name: "Nutritionist Dashboard" };
        case 8:
          return { name: "Sales Agent Dashboard" };
        default:
          return { name: "Default" };
      }
    },
  },
};
</script>
