<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            v-if="$can('view', 'invoice_refund_requests')"
            :to="{ name: 'Invoice Refund Requests List' }"
            class="btn btn-white text-success"
          >
            Refund requests
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <list-table />
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "./components/ListTable.vue";

export default {
  name: "List",
  components: {
    ListTable,
  },
};
</script>
