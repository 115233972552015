<template>
  <div class="">
    <div class="d-flex my-3 align-items-center">
      <h6 class="text-dark mb-0">Auto amendments:</h6>
      <span
        v-if="!showForm"
        class="btn bg-gradient-success btn-sm ms-auto mb-auto"
        @click="add"
        >Add to queue</span
      >
    </div>
    <data-table
      v-if="showTable"
      class="overflow-auto max-height-300"
      :options="datatable.options"
      :data="datatable.data"
    />
    <plan-auto-amendments-form
      v-if="showForm"
      :initial-data="autoAmendmentForm"
      :plan-details-id="planDetailsId"
      :show-back-button="false"
      @saved="saved"
      @back="showForm = false"
      @close="showForm = false"
    />
  </div>
</template>

<script>
import { dateFormat } from "@/lib/dateHelper";

import DataTable from "@/components/DataTable";
import apiPlanAutoAmendments from "@/services/apiPlanAutoAmendments";
import { handleError } from "@/lib/helpers";
import { handleResponse } from "@/lib/helpers";
import PlanAutoAmendmentsForm from "@/views/pages/Clients/components/PlanAutoAmendmentsForm.vue";
export default {
  name: "PlanAutoAmendments",
  components: {
    PlanAutoAmendmentsForm,
    DataTable,
  },
  props: {
    planDetailsId: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ["auto-amendments-changed"],
  data() {
    return {
      showForm: false,
      autoAmendmentForm: {
        startDate: "",
        endDate: "",
      },
      autoAmendments: [],
      datatable: {
        options: {},
        data: [],
      },
    };
  },
  computed: {
    showTable() {
      return this.datatable.data.length > 0 && !this.showForm;
    },
  },
  async mounted() {
    await this.setAutoAmendments();
    this.setDatatable(this.autoAmendments);
  },
  methods: {
    dateFormat,
    async setAutoAmendments() {
      const response = await apiPlanAutoAmendments
        .index({
          client_plan_detail_id: this.planDetailsId,
        })
        .catch(handleError);
      this.autoAmendments = await handleResponse(response);
    },

    add() {
      this.showForm = true;
    },
    async saved() {
      this.showForm = false;
      await this.setAutoAmendments();
      this.$emit("auto-amendments-changed");
    },
    setDatatable(data) {
      this.datatable = {
        options: {
          columns: [
            {
              title: "Start",
              data: "start_date",
              class: "text-xs",
            },
            {
              title: "End",
              data: "end_date",
              class: "text-xs",
            },
            {
              title: "Status",
              data: "status",
              class: "text-xs",
            },
          ],
        },
        data: [],
      };
      data.forEach((item) => {
        this.datatable.data.push({
          id: item.id,
          start_date: dateFormat(item.start_date),
          end_date: dateFormat(item.end_date),
          status: item.status,
        });
      });
    },
  },
};
</script>
<style>
.stage-container {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
}
.stage-container .btn {
  border-radius: 0;
}
.max-height-300 {
  max-height: 300px;
}
</style>