import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";
class ApiBagMovement {
  async checkin(data) {
    return await httpApi.post("/api/v1/bag-movements/checkin", data);
  }
  async checkout(data) {
    return await httpApi.post("/api/v1/bag-movements/checkout", data);
  }
  async deliveryBagMovements(options) {
    let baseUrl = `/api/v1/bag-movements/client-delivery-bag-movements`;
    const queryParams = buildQueryParams(options);

    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;

    return await httpApi.get(url, { responseType: responseType });
  }
}

export default new ApiBagMovement();
