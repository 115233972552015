<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="form-group">
          <label class="form-label">Start Date</label>
          <flat-pickr
            v-model="form.startDate"
            class="form-control"
            placeholder="Please select start date"
            :config="configs.startDate"
            @on-change="onStartDateChange"
          ></flat-pickr>
          <error-display :errors="v$.form.startDate.$errors" />
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="form-group">
          <label class="form-label">End Date</label>
          <flat-pickr
            v-model="form.endDate"
            class="form-control"
            placeholder="Please select end date"
            :config="configs.endDate"
            @on-change="onEndDateChange"
          ></flat-pickr>
          <error-display :errors="v$.form.startDate.$errors" />
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="form-group">
          <label class="form-label">Status</label>
          <select id="status" v-model="form.status"></select>
          <error-display :errors="v$.form.startDate.$errors" />
        </div>
      </div>
    </div>
    <template #footer>
      <button
        type="button"
        class="btn btn-secondary btn-sm mb-0"
        data-bs-dismiss="modal"
        @click="$emit('close')"
      >
        Close
      </button>
      <submit-form-button
        class="btn bg-gradient-success"
        :form-submitted="formSubmitted"
        @click="handleClick"
      >
        {{ buttonText }}
      </submit-form-button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import flatPickr from "vue-flatpickr-component";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import { validatorMessages, helpers } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { handleError, handleResponse } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import apiMiscList from "@/services/apiMiscList";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { flatPickrConfig } from "@/lib/flatPickrHelper";
import apiCycleMenu from "@/services/apiCycleMenu";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "ChangeStatusModal",
  components: {
    SubmitFormButton,
    Modal,
    ErrorDisplay,
    flatPickr,
  },
  props: {
    title: {
      type: String,
      default: "Change status for cycle menu",
    },
    buttonText: {
      type: String,
      default: "Save",
    },
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    planId: {
      type: Number,
      required: true,
    },
  },
  emits: ["change", "close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formSubmitted: false,
      form: { startDate: "", endDate: "", status: "" },
      configs: {
        startDate: {
          ...flatPickrConfig,
          maxDate: null,
        },
        endDate: {
          ...flatPickrConfig,
          minDate: null,
        },
      },
    };
  },
  mounted() {
    this.initStatusChoices();
  },
  methods: {
    onStartDateChange(selectedDates, dateStr) {
      this.configs.endDate.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.startDate.maxDate = dateStr;
    },
    async initStatusChoices() {
      const response = await apiMiscList.menuStatus().catch(handleError);
      const data = await handleResponse(response);
      await initChoices("status", {
        choices: formatDataToChoicesJs(data, [], "enum"),
      });
    },
    async handleClick() {
      const isValid = await this.v$.$validate();
      if (!isValid) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const data = {
        start_date: this.form.startDate,
        end_date: this.form.endDate,
        status: this.form.status,
      };
      const response = await apiCycleMenu
        .changeStatus(this.planId, data)
        .catch(handleError);
      await handleResponse(response, this.savedSuccessfully);

      this.formSubmitted = false;
    },
    savedSuccessfully() {
      this.$emit("close");
      this.$emit("change");
    },
  },
  validations() {
    return {
      form: {
        startDate: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        endDate: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        status: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>