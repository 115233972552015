<template>
  <div v-show="loading" class="row justify-content-center align-items-center">
    <div class="col-sm-auto col-12">
      <pulse-loader
        :loading="loading"
        :color="color"
        :size="size"
      ></pulse-loader>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "LoadingSpinner",
  components: {
    PulseLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#3AB982",
    },
    size: {
      type: String,
      default: "48px",
    },
  },
};
</script>
