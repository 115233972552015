<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{ name: 'Bag Movements' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Bag Movement Information</h5>

            <div v-if="bagMovement.bag">
              <ul class="list-group">
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Client Name:</strong> &nbsp;
                  {{ bagMovement.client_name ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">ID:</strong> &nbsp;
                  {{ bagMovement.bag.data.uid ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Type:</strong> &nbsp;
                  {{ bagMovement.bag.data.type ?? "" }}
                </li>

                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Movement Status:</strong> &nbsp;
                  {{ bagMovement.bag.data.movement_status ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Bag Delivery Status:</strong>
                  &nbsp;
                  {{ bagMovement.bag_delivery_status ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Checkin Time:</strong> &nbsp;
                  {{ bagMovement.checkin_time ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Checkout Time:</strong> &nbsp;
                  {{ bagMovement.checkout_time ?? "" }}
                </li>
                <li class="pt-0 text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Number of Days:</strong> &nbsp;
                  {{ bagMovement.number_of_days ?? "" }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";

export default {
  name: "ViewBagMovement",
  data() {
    return {
      bagMovement: {},
      loading: {},
    };
  },
  async mounted() {
    await this.setBagMovement();
  },
  methods: {
    async setBagMovement() {
      const appInstance = this;
      await API.getBagMovement(appInstance.$route.params.id)
        .then((res) => {
          this.bagMovement = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Bag Movement not found",
              willClose: () => {
                appInstance.$router.push({ name: "Bag Movements" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
  },
};
</script>
