import { httpApi } from "./httpApi.js";
import { handleError } from "@/lib/helpers";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";

class API {
  async createUser(data) {
    return await httpApi.post("api/v1/users", data);
  }

  async login(data) {
    await httpApi.get("/sanctum/csrf-cookie");
    return await httpApi.post("/login", data);
  }

  async logout() {
    return await httpApi.post("/logout");
  }

  async getPermissions(params) {
    return await httpApi.get("/api/v1/permissions?" + params);
  }

  async getRoles() {
    return await httpApi.get("api/v1/roles");
  }

  async getRole(id) {
    return await httpApi.get("api/v1/roles/" + id);
  }

  async getUser(id) {
    return await httpApi.get("api/v1/users/" + id);
  }

  async getUsers(params) {
    return await httpApi.get("api/v1/users?" + params);
  }

  async getUsersChoices() {
    return await httpApi.get("api/v1/users/dropdown?start=0&length=-1");
  }

  async updateUser(id, data) {
    return await httpApi.post("/api/v1/users/" + id, data);
  }

  async updateUserRole(id, data) {
    return await httpApi.post("/api/v1/roles/" + id, data);
  }

  async getClients(params) {
    return await httpApi.get("api/v1/clients?" + params);
  }

  async getClientsPlans(params) {
    return await httpApi.get("api/v1/lists/plan-details?" + params);
  }

  async getClient(id) {
    return await httpApi.get("api/v1/clients/" + id);
  }

  async getClientDeliveries(id) {
    return await httpApi.get("api/v1/clients/" + id + "/deliveries");
  }

  async getWalletTransactions(id) {
    return await httpApi.get(
      "api/v1/clients/" + id + "/wallets/all-transactions"
    );
  }

  async getWallets(id) {
    return await httpApi.get("api/v1/clients/" + id + "/wallets");
  }

  async getClientDelivery(id, deliveryId) {
    return await httpApi.get(
      "api/v1/clients/" + id + "/deliveries/" + deliveryId
    );
  }

  async createClientDelivery(id, data) {
    return await httpApi.post("/api/v1/clients/" + id + "/deliveries", data);
  }

  async updateClientDelivery(id, delivery_id, data) {
    return await httpApi.post(
      "/api/v1/clients/" + id + "/deliveries/" + delivery_id,
      data
    );
  }

  async getClientPlanDeliveries(id, plan_id, start_date, end_date) {
    let url =
      "api/v1/clients/" + id + "/plan-details/" + plan_id + "/deliveries";
    if (start_date && end_date) {
      url +=
        "?include[0]=clientDailyMenus&start_date=" +
        start_date +
        "&end_date=" +
        end_date;
    }
    return await httpApi.get(url);
  }

  async getClientDeliveryMenu(id, delivery_id) {
    return await httpApi.get(
      "api/v1/clients/" + id + "/deliveries/" + delivery_id + "/menu"
    );
  }

  async updateClientDeliveryMenu(id, delivery_id, data) {
    return await httpApi.post(
      "/api/v1/clients/" + id + "/deliveries/" + delivery_id + "/menu",
      data
    );
  }

  async getClientDislikes(id) {
    return await httpApi.get("api/v1/clients/" + id + "/dislikes");
  }

  async getClientDislikeExclusions(id) {
    return await httpApi.get("api/v1/clients/" + id + "/dislike-exclusions");
  }

  async getValueAddedServices(params) {
    return await httpApi.get("api/v1/value-added-services?" + params);
  }

  getClientDislikeTypeChoices() {
    return [
      {
        value: "",
        label: "Select",
        id: "",
      },
      {
        value: "allergen",
        label: "Allergen",
        id: "",
      },
      {
        value: "food_group",
        label: "Food Group",
        id: "",
      },
      {
        value: "ingredient",
        label: "Ingredient",
        id: "",
      },
      {
        value: "ingredient_allergen",
        label: "Ingredient allergen",
        id: "",
      },
      {
        value: "recipe",
        label: "Recipe",
        id: "",
      },
    ];
  }

  async updateClientDislike(id, dislike_id, data) {
    return await httpApi.post(
      "api/v1/clients/" + id + "/dislikes/" + dislike_id,
      data
    );
  }

  async createClientDislike(id, data) {
    return await httpApi.post("api/v1/clients/" + id + "/dislikes", data);
  }

  async deleteClientDislike(id, dislike_id) {
    return await httpApi.delete(
      "api/v1/clients/" + id + "/dislikes/" + dislike_id
    );
  }

  async updateClientDislikeExclusion(id, dislike_id, data) {
    return await httpApi.post(
      "api/v1/clients/" + id + "/dislike-exclusions/" + dislike_id,
      data
    );
  }

  async createClientDislikeExclusion(id, data) {
    return await httpApi.post(
      "api/v1/clients/" + id + "/dislike-exclusions",
      data
    );
  }

  async deleteClientDislikeExclusion(id, dislike_id) {
    return await httpApi.delete(
      "api/v1/clients/" + id + "/dislike-exclusions/" + dislike_id
    );
  }

  async getClientPlanDetails(id) {
    return await httpApi.get("api/v1/clients/" + id + "/plan-details");
  }

  async getClientPlanDetailsShow(id, plan_id) {
    return await httpApi.get(
      "api/v1/clients/" + id + "/plan-details/" + plan_id
    );
  }

  async updateClientPlanDetails(id, plan_id, data) {
    return await httpApi.post(
      "api/v1/clients/" + id + "/plan-details/" + plan_id,
      data
    );
  }

  async createClientPlanDetails(id, data) {
    return await httpApi.post("api/v1/clients/" + id + "/plan-details", data);
  }

  getClientPlanTypeChoices() {
    let options = ["Regular", "Complimentary"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/ClientPlanType.php
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }

  async getClientInvoices(id) {
    return await httpApi.get("api/v1/clients/" + id + "/invoices");
  }

  async updateClient(id, data) {
    return await httpApi.post("/api/v1/clients/" + id, data);
  }

  async getAccountManagers() {
    return await httpApi.get("api/v1/roles/3/users/");
  }

  async getNutritionists() {
    return await httpApi.get("api/v1/roles/5/users/");
  }

  async getIngredient(id) {
    return await httpApi.get("api/v1/ingredients/" + id);
  }

  getIngredientTypeOptions() {
    let options = ["Protein"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/IngredientType.php
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  async updateIngredient(id, data) {
    return await httpApi.post("/api/v1/ingredients/" + id, data);
  }

  async createIngredient(data) {
    return await httpApi.post("/api/v1/ingredients", data);
  }

  async deleteIngredient(id) {
    return await httpApi.delete("/api/v1/ingredients/" + id);
  }

  async getRecipeTypes() {
    return await httpApi.get("api/v1/misc/lists/recipe-types");
  }

  async getKitchens() {
    return await httpApi.get("api/v1/kitchens?start=0&length=100");
  }

  async getCuisines() {
    return await httpApi.get("api/v1/cuisines?start=0&length=100");
  }

  async getRecipe(id, client_id) {
    let url = "api/v1/recipes/" + id;
    if (client_id) {
      url += "?client_id=" + client_id;
    }
    return await httpApi.get(url);
  }

  async updateRecipe(id, data) {
    return await httpApi.post("/api/v1/recipes/" + id, data);
  }

  async createRecipe(data) {
    return await httpApi.post("/api/v1/recipes", data);
  }

  async deleteRecipe(id) {
    return await httpApi.delete("/api/v1/recipes/" + id);
  }

  async updateRecipeFoodGroups(id, data) {
    return await httpApi.post("/api/v1/recipes/" + id + "/food-groups", data);
  }

  async updateRecipeIngredients(id, data) {
    return await httpApi.post("/api/v1/recipes/" + id + "/ingredients", data);
  }

  async getPlans(params) {
    return await httpApi.get("api/v1/plans?" + params);
  }

  async getPlan(id) {
    return await httpApi.get("api/v1/plans/" + id);
  }

  async getPlanVariants(id) {
    return await httpApi.get("api/v1/plans/" + id + "/variants");
  }

  async getPlanVariant(planId, variantId) {
    return await httpApi.get(
      "api/v1/plans/" + planId + "/variants/" + variantId
    );
  }

  async getPlanTypes() {
    return await httpApi.get("api/v1/misc/lists/plan-types");
  }

  async getInvoices(params) {
    return await httpApi.get("api/v1/invoices?" + params);
  }

  async getListsInvoices(params) {
    return await httpApi.get("api/v1/lists/invoices?" + params);
  }

  async updateAddons(id, data) {
    return await httpApi.post("/api/v1/addons/" + id, data);
  }

  async createAddons(data) {
    return await httpApi.post("/api/v1/addons", data);
  }

  async getAddonsDetail(id) {
    return await httpApi.get("api/v1/addons/" + id);
  }

  async discountCodetList(params) {
    return await httpApi.get("api/v1/discount-codes?" + params);
  }

  async getDiscountCodetDetail(id) {
    return await httpApi.get("api/v1/discount-codes/" + id);
  }

  async createDiscount(data) {
    return await httpApi.post("/api/v1/discount-codes", data);
  }

  async updateDiscount(id, data) {
    return await httpApi.post("/api/v1/discount-codes/" + id, data);
  }

  async getAllPlanVariants(params) {
    return await httpApi.get("api/v1/all-plan-variants?" + params);
  }

  async getAccountManagersDashboardData() {
    return await httpApi.get("api/v1/dashboard/account-manager");
  }

  async getSupervisorDashboardData() {
    return await httpApi.get("api/v1/dashboard/account-supervisor");
  }

  async getManagerDashboardData() {
    return await httpApi.get("api/v1/dashboard/manager");
  }

  async getSuperAdminDashboardData() {
    return await httpApi.get("api/v1/dashboard/super-admin");
  }

  async getAccountAdminDashboardData() {
    return await httpApi.get("api/v1/dashboard/account-admin");
  }

  async getSalesAgentDashboardData() {
    return await httpApi.get("api/v1/dashboard/sales-agent");
  }

  async getNutritionistDashboardData() {
    return await httpApi.get("api/v1/nutrition-queue");
  }

  async getClientRemarks(id) {
    return await httpApi.get("api/v1/clients/" + id + "/remarks");
  }

  async createClientRemark(id, data) {
    return await httpApi.post("api/v1/clients/" + id + "/remarks", data);
  }

  async getListsConsultations(client_id) {
    return await httpApi.get("api/v1/clients/" + client_id + "/consultations");
  }

  async getConsultationsDetrails(client_id, consultations_id) {
    return await httpApi.get(
      "api/v1/clients/" + client_id + "/consultations/" + consultations_id
    );
  }

  async createConsultations(id, data) {
    return await httpApi.post("api/v1/clients/" + id + "/consultations", data);
  }

  async updateConsultations(id, record_id, data) {
    return await httpApi.post(
      "api/v1/clients/" + id + "/consultations/" + record_id,
      data
    );
  }

  getInvoiceTypeChoices(empty_option_name) {
    let options = ["Debit Note", "Credit Note"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/InvoiceType.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getInvoiceInitiatorTypeChoices(empty_option_name) {
    let options = [
      "AM",
      "New Biz",
      "Gymnation",
      "Partner In Health",
      "Business Development",
      "Brand Ambassador",
      "Organic",
    ]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/InvoiceInitiatorType.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getSourceChoices(empty_option_name) {
    let options = ["Web", "Mobile App", "Ladybird"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/Source.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getPaymentSourceChoices(empty_option_name) {
    let options = [
      "Cash",
      "POS Terminal",
      "Cheque",
      "Bank Deposit",
      "Gift Card",
      "Payfort",
      "Postpay",
      "Apple Pay",
      "Wallet",
    ]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PaymentSource.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  async saveManualPaymentInvoice(invoice_number, data) {
    return await httpApi.post(
      "api/v1/invoices/" + invoice_number + "/add-manual-payment",
      data
    );
  }

  getPaymentStatusChoices(empty_option_name) {
    let options = ["Paid", "Cancelled", "Rejected", "Pending"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PaymentStatus.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getInvoiceStatusChoices(empty_option_name) {
    let options = ["Draft", "Published", "Paid", "Completed", "Cancelled"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/InvoiceStatus.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getPlanVariantPackageTypes() {
    let options = ["Full", "AM", "PM"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PackageType.php
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  getValidDaysPerWeek() {
    let options = [5, 6, 7]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/ValidDaysPerWeek.php
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }

  getValidWeeks() {
    let options = ["1", "2", "4", "8", "12"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/ValidNumberOfWeeks.php
    let result = [
      {
        value: "",
        label: "Select",
        id: "",
      },
    ];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }

  getPlanSizes() {
    let options = ["Regular", "Plus", "Wellness", "Athlete"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/PlanSize.php
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }

  async updatePlan(id, data) {
    return await httpApi.post("/api/v1/plans/" + id, data);
  }

  async updatePlanVariant(planId, planVariantId, data) {
    return await httpApi.post(
      "/api/v1/plans/" + planId + "/variants/" + planVariantId,
      data
    );
  }

  async createPlan(data) {
    return await httpApi.post("/api/v1/plans/", data);
  }

  async createPlanVariant(planId, data) {
    return await httpApi.post("/api/v1/plans/" + planId + "/variants/", data);
  }

  async createFoodGroup(data) {
    return await httpApi.post("/api/v1/food-groups/", data);
  }

  async updateFoodGroup(id, data) {
    return await httpApi.post("/api/v1/food-groups/" + id, data);
  }

  async getFoodGroup(id) {
    return await httpApi.get("api/v1/food-groups/" + id);
  }

  async getBagMovements(params) {
    return await httpApi.get("api/v1/lists/bag-movements?" + params);
  }

  async getBagMovement(id) {
    return await httpApi.get("api/v1/bag-movements/" + id);
  }

  async getClientBagMovements(client_id, params) {
    return await httpApi.get(
      "api/v1/clients/" + client_id + "/bag-movements?" + params
    );
  }

  async getDashboardStatistics() {
    return await httpApi.get("api/v1/dashboard");
  }

  async getInvoiceMonthToDate() {
    return await httpApi.get("api/v1/graphs/invoice/mtd");
  }

  async getRevenueMonthToDate() {
    return await httpApi.get("api/v1/graphs/revenue/mtd");
  }

  async getAddons(params) {
    return await httpApi.get("api/v1/addons?" + params);
  }

  async getMealType() {
    return await httpApi.get("api/v1/misc/lists/meal-types");
  }

  getClientDeliveryScheduleChoices() {
    let options = ["Morning", "Evening"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/DeliverySchedule.php
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }
  formatUserDataForEditUser(data) {
    let roles = [];
    for (let i in data.roles.data) {
      roles.push(data.roles.data[i].id);
    }
    return {
      name: data.name,
      email: data.email,
      username: data.username,
      mobile_number: data.mobile_number,
      bio: data.bio,
      image: data.image,
      roles: roles,
    };
  }

  formatDataToChoicesJs(data, empty_option, fields) {
    return formatDataToChoicesJs(data, empty_option, fields);
  }

  formatUserPermissionsToCaslAbilities(data) {
    let result = [];
    for (let i in data) {
      let t = data[i].name.split(".");
      result.push({
        action: t[1],
        subject: t[0],
      });
    }
    return result;
  }

  handleError(err) {
    return handleError(err);
  }
}

export default new API();