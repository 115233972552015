<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="client-name">Client name</label>
          <input
            id="client-name"
            v-model="filter['clients.name']"
            type="search"
            class="form-control"
            placeholder="Client name"
          />
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="delivery-status"
            >Bag Delivery Status</label
          >
          <select
            id="delivery-status"
            v-model="filter.bag_delivery_status"
            class="form-control"
            multiple
          ></select>
        </div>
        <div class="col-md-4 mt-3">
          <label class="visually-hidden" for="status">Status</label>
          <select
            id="status"
            v-model="filter.status"
            class="form-control"
            multiple
          ></select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Created between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="created-after"
            >Created after</label
          >
          <flat-pickr
            id="created-after"
            v-model="filter.start_date"
            class="form-control"
            placeholder="Created after"
            :config="configs.start_date"
            @on-change="onStartDateChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="created-before"
            >Created before</label
          >
          <flat-pickr
            id="created-before"
            v-model="filter.end_date"
            class="form-control"
            placeholder="Created before"
            :config="configs.end_date"
            @on-change="onEndDateChange"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <button
            class="btn btn-success text-white btn-sm mb-0"
            @click="tableDraw"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          ref="listTable"
          class="table align-items-center mb-5"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Order
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Customer
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Area
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Delivery schedule
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Delivery Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <loading-spinner :loading="loading" />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import ApiMiscList from "@/services/apiMiscList";
import ApiList from "@/services/apiList";
import ApiClientDelivery from "@/services/apiClientDelivery";
import { showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import { flatPickrConfig } from "@/lib/flatPickrHelper";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "ListTable",
  components: {
    flatPickr,
    LoadingSpinner,
  },
  data() {
    return {
      removeRows: false,
      loading: true,
      clientChoices: [],
      bagDeliveryStatusChoices: [],
      filter: {
        client_id: "",
        order_id: "",
        bag_delivery_status: [],
        status: [],
        start_date: dateFormat("today", "YYYY-MM-DD"),
        end_date: dateFormat("today", "YYYY-MM-DD"),
      },
      configs: {
        start_date: {
          ...flatPickrConfig,
          maxDate: null,
        },
        end_date: {
          ...flatPickrConfig,
          minDate: null,
        },
      },
    };
  },
  async mounted() {
    await this.initbagDeliveryStatusChoices();
    await this.initStatusChoices();
    this.initDataTable();
    this.initDropdownHandle();
  },
  beforeUnmount() {
    this.destroyDataTable();
  },
  methods: {
    dateFormat,
    destroyDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.listTable)) {
        $(this.$refs.datatable).DataTable().destroy();
      }
    },
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $(this.$refs.listTable).DataTable({
        serverSide: true,
        searching: false,
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "60px",
          },
          {
            data: "delivery_date",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                dateFormat(data) +
                "</h6></div>"
              );
            },
          },
          {
            data: null,
            render: function (data) {
              let button =
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">';
              if (appInstance.$ability.can("view", "clients")) {
                button =
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Client Profile",
                    params: {
                      id: data.client_id,
                    },
                  }).href +
                  '">' +
                  data["client.name"] +
                  "</a>";
              } else {
                button += data["client.name"];
              }
              button += "</h6></div>";
              return button;
            },
            orderable: false,
          },
          {
            data: null,
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data["area.name"] +
                "</h6></div>"
              );
            },
            orderable: false,
          },
          {
            data: null,
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data["delivery_schedule"] +
                "</h6></div>"
              );
            },
            orderable: false,
          },
          {
            data: null,
            render: function (data) {
              let html =
                '<div class="d-flex flex-column justify-content-center">' +
                '<div class="dropdown">' +
                '<button id="btn-for-' +
                data.id +
                '" class="btn btn-sm btn-primary dropdown-toggle mb-0 w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">' +
                data.bag_delivery_status +
                "</button>" +
                '<ul class="dropdown-menu">';
              appInstance.bagDeliveryStatusChoices.forEach((item) => {
                html +=
                  '<li><a class="dropdown-item update-status" data-id="' +
                  data.id +
                  '" data-client_id="' +
                  data.client_id +
                  '" data-status="' +
                  item.id +
                  '"href="#">' +
                  item.label +
                  "</a></li>";
              });
              html += "</ul>" + "</div>" + "</div>";
              return html;
            },
            orderable: false,
            width: "100px",
          },

          {
            data: "status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "Active" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                data +
                "</span></span>"
              );
            },
            orderable: false,
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          if (appInstance.removeRows) {
            callback({ data: [], recordsTotal: 0, recordsFiltered: 0 });
          }
          data.filter = {};
          if (appInstance.filter["clients.name"]) {
            data.filter["clients.name"] = appInstance.filter["clients.name"];
          }
          if (appInstance.filter.status) {
            data.filter["status"] = appInstance.filter.status;
          }
          if (appInstance.filter.bag_delivery_status) {
            data.filter["bag_delivery_status"] =
              appInstance.filter.bag_delivery_status;
          }
          data.filter.delivery_date_after = appInstance.filter.start_date;
          data.filter.delivery_date_before = appInstance.filter.end_date;
          appInstance.loading = true;
          ApiList.clientDeliveries($.param(data))
            .then((res) => {
              appInstance.loading = false;
              callback(res.data);
            })
            .catch((err) => {
              appInstance.loading = false;
              const response = handleError(err);
              showMessage(response.message, "error");
            });
        },
      });
    },
    tableDraw() {
      this.removeRows = true;
      $(this.$refs.listTable).DataTable().draw();
      this.removeRows = false;
      $(this.$refs.listTable).DataTable().draw();
    },
    onStartDateChange(selectedDates, dateStr) {
      this.configs.end_date.minDate = dateStr;
    },
    onEndDateChange(selectedDates, dateStr) {
      this.configs.start_date.maxDate = dateStr;
    },
    async initStatusChoices() {
      const id = "status";
      const response = await ApiMiscList.clientDeliveryStatus().catch(
        handleError
      );
      if (response.status == 200) {
        await initChoices(id, {
          choices: formatDataToChoicesJs(
            response.data.data,
            "no empty option",
            { id: "value", value: "value", label: "value" }
          ),
          placeholder: true,
          placeholderValue: "Select delivery status",
          duplicateItemsAllowed: false,
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    async initbagDeliveryStatusChoices() {
      const id = "delivery-status";
      const response = await ApiMiscList.bagDeliveryStatus().catch(handleError);
      if (response.status == 200) {
        this.bagDeliveryStatusChoices = formatDataToChoicesJs(
          response.data.data,
          "no empty option",
          { id: "value", value: "value", label: "value" }
        );
        await initChoices(id, {
          choices: this.bagDeliveryStatusChoices,
          placeholder: true,
          placeholderValue: "Select bag delivery status",
          duplicateItemsAllowed: false,
        });
      } else {
        showMessage(response.message, "error");
      }
    },

    initDropdownHandle() {
      $(document)
        .off("click")
        .on("click", ".update-status", async function () {
          const $this = $(this);
          const deliveryId = $this.attr("data-id");
          const clientId = $this.attr("data-client_id");
          const status = $this.attr("data-status");
          const $btn = $("#btn-for-" + deliveryId);
          if ($btn.text() == status) {
            return;
          }

          let data = {
            bag_delivery_status: status,
          };

          const response = await ApiClientDelivery.updateStatus(
            clientId,
            deliveryId,
            data
          ).catch(handleError);
          if (response.status == 200) {
            $btn.text(status);
            showMessage(
              "Success, Delivery status changed successfully",
              "success"
            );
          } else {
            showMessage(response.message, "error");
          }
        });
    },
  },
};
</script>
