<template>
  <main class="main-content mt-0">
    <div class="page-header min-vh-100">
      <div class="container">
        <loading-spinner :loading="loading" />
        <div v-if="!loading" class="card my-5">
          <div class="card-header bg-transparent">
            <div class="text-center mt-2">
              <img
                :src="logo"
                class="navbar-brand-img"
                alt="main_logo"
                width="60"
              />
            </div>
            <h5 class="text-dark text-center mt-3 mb-3">
              Lead # {{ lead.id }}
            </h5>
          </div>
          <div class="card-body">
            <h6>Answers repliyed by client</h6>
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped"
                style="width: 100%"
              >
                <tbody>
                  <tr>
                    <th>How active are you?</th>
                    <td>{{ activityLevel }}</td>
                  </tr>
                  <tr>
                    <th>Now, please describe your body shape.</th>
                    <td>{{ bodyShape }}</td>
                  </tr>
                  <tr>
                    <th>What are your health goals?</th>
                    <td>{{ healthGoal }}</td>
                  </tr>
                  <tr>
                    <th>What's your goal weight?</th>
                    <td>{{ lead.weight_goal }}Kg</td>
                  </tr>

                  <template
                    v-for="(answer, question) in lead.meta.extra"
                    :key="question"
                  >
                    <tr v-if="answer">
                      <th>{{ question }}</th>
                      <td>{{ formatAnswer(answer) }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-body">
            <h6>Final Report</h6>
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped"
                style="width: 100%"
              >
                <tbody>
                  <tr>
                    <th>Full Name</th>
                    <td>{{ lead.name }}</td>
                  </tr>
                  <tr>
                    <th>Email:</th>
                    <td>{{ lead.email }}</td>
                  </tr>
                  <tr>
                    <th>Mobile:</th>
                    <td>{{ lead.mobile_number }}</td>
                  </tr>
                  <tr>
                    <th>Gender:</th>
                    <td>{{ lead.meta?.gender ?? "N/A" }}</td>
                  </tr>
                  <tr>
                    <th>Age:</th>
                    <td>{{ lead.meta?.age ?? "N/A" }}</td>
                  </tr>
                  <tr>
                    <th>Weight:</th>
                    <td>{{ lead.weight }} kg</td>
                  </tr>
                  <tr>
                    <th>Height:</th>
                    <td>{{ lead.height }} cm</td>
                  </tr>

                  <tr v-if="lead.meta.source">
                    <th>Lead Source:</th>
                    <td>{{ lead.meta.source }}</td>
                  </tr>
                  <tr v-if="lead.meta.utm_medium">
                    <th>Lead Medium:</th>
                    <td>{{ lead.meta.utm_medium ?? "" }}</td>
                  </tr>
                  <tr v-if="lead.meta.utm_source">
                    <th>Lead Origin:</th>
                    <td>{{ lead.meta.utm_source }}</td>
                  </tr>
                  <tr v-if="lead.meta.utm_campaign">
                    <th>Lead Campaign:</th>
                    <td>{{ lead.meta.utm_campaign }}</td>
                  </tr>
                  <tr v-if="lead.calculated_kcal">
                    <th>Total Calories:</th>
                    <td>{{ lead.calculated_kcal }}</td>
                  </tr>

                  <tr v-if="plan.name">
                    <th>Preferred Meal Plan</th>
                    <td>{{ plan.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="!loading && lead.id == 0" class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1
              class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5"
            >
              Error 404
            </h1>
            <h2 class="fadeIn3 fadeInBottom mt-3 text-white">Not found</h2>
            <router-link
              :to="{ name: 'Dashboard' }"
              class="mt-4 fadeIn2 fadeInBottom btn bg-gradient-warning"
              >Go to Homepage</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import logo from "@/assets/img/kcal-logo.svg";
import { handleError } from "@/lib/helpers";
import { mapMutations } from "vuex";
import apiBodyAssesment from "@/services/apiBodyAssesment";
import apiMiscList from "@/services/apiMiscList";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import api from "@/services/api";
export default {
  name: "Error403",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      logo,
      lead: {
        hash_id: this.$route.params.id,
      },
      plan: {},
      loading: true,
      healthGoalChoices: [],
      activityLevelChoices: [],
      bodyShapeChoices: [],
    };
  },
  computed: {
    healthGoal() {
      let item = this.healthGoalChoices.find(
        (el) => el.value == this.lead.health_goal
      );
      if (item) {
        return item.label;
      }
      return this.lead.health_goal;
    },
    activityLevel() {
      let item = this.activityLevelChoices.find(
        (el) => el.value == this.lead.activity_level
      );
      if (item) {
        return item.label;
      }
      return this.lead.activity_level;
    },
    bodyShape() {
      let item = this.bodyShapeChoices.find(
        (el) => el.value == this.lead.body_shape
      );
      if (item) {
        return item.label;
      }
      return this.lead.body_type;
    },
  },
  async mounted() {
    this.loading = true;
    await this.setLead();
    await this.setPlan();
    this.healthGoalChoices = apiMiscList.healthGoalChoices();
    this.activityLevelChoices = apiMiscList.activityLevelChoices();
    this.bodyShapeChoices = apiMiscList.bodyShapeChoices(
      this.lead.meta?.gender
    );
    this.loading = false;
  },
  created() {
    this.hideDefaultLayout();
  },
  beforeUnmount() {
    this.showDefaultLayout();
  },
  methods: {
    ...mapMutations(["showDefaultLayout", "hideDefaultLayout"]),
    formatAnswer(answer) {
      if (Array.isArray(answer)) {
        return answer.join(", ");
      }
      return answer;
    },
    async setLead() {
      const response = await apiBodyAssesment
        .show(this.lead.hash_id)
        .catch(handleError);
      if (response.status == 200) {
        this.lead = response.data.data;
      } else {
        this.lead.id = 0;
        showMessage(response.message, "error");
      }
    },
    async setPlan() {
      const response = await api
        .getPlan(this.lead.meta?.plan)
        .catch(handleError);
      if (response.status == 200) {
        this.plan = response.data.data;
      }
    },
  },
};
</script>
