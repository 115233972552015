<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            v-if="$can('create', 'substitute_menus')"
            :to="{ name: 'Substitute Menus List' }"
            class="btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <loading-spinner :loading="loading" />
    <div v-show="!loading" class="row">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Copy Substitute Menu</h5>
            <div class="row align-items-center">
              <div class="col-md-4 col-12">
                <label for="source-plan-type" class="form-label"
                  >Source plan type</label
                >
                <select
                  id="source-plan-type"
                  v-model="source_plan_type"
                ></select>
              </div>
              <div class="col-md-3 col-12">
                <label for="source-start-date">Source start date </label>
                <flat-pickr
                  id="source-start-date"
                  v-model="source_start_date"
                  class="form-control"
                  placeholder=""
                  :config="configs.sourceStartDate"
                  @on-change="onSourceStartDateChange"
                />
              </div>
              <div class="col-md-3 col-12">
                <label for="source-end-date" class="label-form"
                  >Source end date
                </label>
                <flat-pickr
                  id="source-end-date"
                  v-model="source_end_date"
                  class="form-control"
                  placeholder=""
                  :config="configs.sourceEndDate"
                  @on-change="onSourceEndDateChange"
                />
              </div>
              <div class="col-md-2 col-12">
                <label class="label-form">Source days</label>
                <input
                  class="form-control"
                  readonly
                  :value="dateDiff(source_start_date, source_end_date)"
                />
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="col-md-4 col-12">
                <label for="destination-plan-type" class="form-label"
                  >Destination plan plan</label
                >
                <select
                  id="destination-plan-type"
                  v-model="destination_plan_type"
                ></select>
              </div>
              <div class="col-md-3 col-12">
                <label for="destination-start-date" class="form-label"
                  >Destination start date</label
                >
                <flat-pickr
                  id="destination-start-date"
                  v-model="destination_start_date"
                  class="form-control"
                  placeholder=""
                  :config="configs.destinationStartDate"
                  @on-change="onDestinationStartDateChange"
                />
              </div>
              <div class="col-md-3 col-12">
                <label for="destination-end-date" class="form-label"
                  >Destination end date
                </label>
                <flat-pickr
                  id="destination-end-date"
                  v-model="destination_end_date"
                  class="form-control"
                  placeholder=""
                  :config="configs.destinationEndDate"
                  @on-change="onDestinationEndDateChange"
                />
              </div>
              <div class="col-md-2 col-12">
                <label class="label-form">Destination days</label>
                <input
                  class="form-control"
                  readonly
                  :value="
                    dateDiff(destination_start_date, destination_end_date)
                  "
                />
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="col">
                <argon-switch
                  id="delete-destination"
                  v-model:checked="delete_destination"
                  name="delete_destination"
                  label-position="start"
                  label-class="me-2"
                  input-class="ms-2"
                  >Delete destination</argon-switch
                >
              </div>
            </div>
            <div class="row mt-4">
              <div
                class="text-right col-lg-12 d-flex flex-column justify-content-center"
              >
                <div class="mb-0 ms-lg-auto me-lg-0 me-auto">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="copySubstituteMenu"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import API from "@/services/api";
import { handleError } from "@/lib/helpers";
import { dateDiff } from "@/lib/dateHelper";
import { showMessage } from "@/assets/js/show-message";
import ApiSubstituteMenu from "@/services/apiSubstituteMenu";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import ArgonSwitch from "@/components/ArgonSwitch";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "Copy",
  components: {
    LoadingSpinner,
    flatPickr,
    ArgonSwitch,
  },
  data() {
    return {
      loading: true,
      planTypeChoices: [],
      source_plan_type: this.$route.params.id,
      source_start_date: "",
      source_end_date: "",
      destination_plan_type: this.$route.params.id,
      destination_start_date: "",
      destination_end_date: "",
      delete_destination: false,
      configs: {
        sourceStartDate: {
          maxDate: null,
        },
        sourceEndDate: {
          minDate: null,
        },
        destinationStartDate: {
          maxDate: null,
        },
        destinationEndDate: {
          minDate: null,
        },
      },
    };
  },
  async mounted() {
    await this.setPlanTypeChoices();
    await initChoices(
      "source-plan-type",
      {
        choices: this.planTypeChoices,
      },
      this.source_plan_type
    );
    await initChoices(
      "destination-plan-type",
      {
        choices: this.planTypeChoices,
      },
      this.destination_plan_type
    );
    this.loading = false;
  },
  methods: {
    dateDiff,
    async setPlanTypeChoices() {
      await API.getPlanTypes()
        .then((res) => {
          this.planTypeChoices = formatDataToChoicesJs(
            res.data.data,
            "no empty option",
            {
              id: "value",
              value: "value",
              label: "name",
            }
          );
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    onSourceEndDateChange(selectedDates, dateStr) {
      this.configs.sourceStartDate.maxDate = dateStr;
    },
    onSourceStartDateChange(selectedDates, dateStr) {
      this.configs.sourceEndDate.minDate = dateStr;
    },
    onDestinationEndDateChange(selectedDates, dateStr) {
      this.configs.destinationStartDate.maxDate = dateStr;
    },
    onDestinationStartDateChange(selectedDates, dateStr) {
      this.configs.destinationEndDate.minDate = dateStr;
    },
    async copySubstituteMenu() {
      let formData = new FormData();
      formData.append("source_plan_type", this.source_plan_type);
      formData.append("source_start_date", this.source_start_date);
      formData.append("source_end_date", this.source_end_date);
      formData.append("destination_plan_type", this.destination_plan_type);
      formData.append("destination_start_date", this.destination_start_date);
      formData.append("destination_end_date", this.destination_end_date);
      formData.append(
        "delete_destination",
        this.delete_destination ? "1" : "0"
      );
      let saveResponse = await ApiSubstituteMenu.copy(formData).catch(
        API.handleError
      );

      if (saveResponse.status === 200) {
        showMessage("Substitute Menu copied successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }
    },
  },
};
</script>
