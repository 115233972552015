<template>
  <div>
    <div class="row">
      <div class="col-12 col-xl-4">
        <label>Refund type</label>
        <div class="form-group">
          <select
            id="refund-request-type"
            v-model="refundRequest.type"
            class="form-control"
          ></select>
          <error-display :errors="v$.refundRequest.type.$errors" />
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label>Refund medium</label>
        <div class="form-group">
          <select
            id="refund-request-medium"
            v-model="refundRequest.meta.refund.medium"
            class="form-control"
          ></select>
          <error-display
            :errors="v$.refundRequest.meta.refund.medium.$errors"
          />
        </div>
      </div>
      <div v-show="requiresCancellationCharge" class="col-12 col-xl-4">
        <label>Cancellation charge percentage</label>
        <argon-input
          id="cancellation-charge-percent"
          type="text"
          placeholder=""
          :model-value="refundRequest.cancellation_charge_percent"
          :errors="v$.refundRequest.cancellation_charge_percent.$errors"
          @update:model-value="
            refundRequest.cancellation_charge_percent = $event
          "
        />
      </div>
    </div>
    <div v-show="requiresBankDetails" class="row">
      <div class="col-12 col-xl-4">
        <label>Bank</label>
        <div class="form-group">
          <select
            id="refund-request-bank"
            v-model="refundRequest.meta.bank.name"
            class="form-control"
          ></select>
          <error-display :errors="v$.refundRequest.meta.bank.name.$errors" />
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label>Bank account name</label>
        <argon-input
          id="refund-request-bank-account-name"
          type="text"
          placeholder="Enter bank account name"
          :model-value="refundRequest.meta.bank.account.name"
          :errors="v$.refundRequest.meta.bank.account.name.$errors"
          @update:model-value="refundRequest.meta.bank.account.name = $event"
        />
      </div>
      <div class="col-12 col-xl-4">
        <label>IBAN</label>
        <argon-input
          id="refund-request-bank-account-number"
          type="text"
          placeholder="Enter IBAN"
          :model-value="refundRequest.meta.bank.account.number"
          :errors="v$.refundRequest.meta.bank.account.number.$errors"
          @update:model-value="refundRequest.meta.bank.account.number = $event"
        />
      </div>
    </div>
    <div v-show="requiresAddressDetails" class="row">
      <div class="col-12 col-xl-4">
        <label>Emirate</label>
        <div class="form-group">
          <select
            id="refund-request-address-city"
            v-model="refundRequest.meta.address.city_id"
            class="form-control"
          ></select>
          <error-display
            :errors="v$.refundRequest.meta.address.city_id.$errors"
          />
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label>Master area</label>
        <div class="form-group">
          <select
            id="refund-request-address-area"
            v-model="refundRequest.meta.address.area_id"
            class="form-control"
          ></select>
          <error-display
            :errors="v$.refundRequest.meta.address.area_id.$errors"
          />
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label>Address</label>
        <argon-input
          id="refund-request-address-line"
          type="text"
          placeholder="Enter street / building/  room no. / villa number"
          :model-value="refundRequest.meta.address.line1"
          :errors="v$.refundRequest.meta.address.line1.$errors"
          @update:model-value="refundRequest.meta.address.line1 = $event"
        />
      </div>
    </div>
    <div v-show="requiresKcalHqDetails" class="row">
      <div class="col-12 col-xl-4">
        <label>Emirate</label>
        <argon-input
          id="refund-request-hq-city"
          type="text"
          placeholder="Emirate"
          :model-value="cityName(hqAddress.cityId)"
          readonly="readonly"
        />
      </div>
      <div class="col-12 col-xl-4">
        <label>Master area</label>
        <argon-input
          id="refund-request-hq-area"
          type="text"
          placeholder="Master area"
          :model-value="areaName(hqAddress.areaId)"
          readonly="readonly"
        />
      </div>
      <div class="col-12 col-xl-4">
        <label>Address</label>
        <argon-input
          id="refund-request-address-line"
          type="text"
          placeholder="Enter street / building/  room no. / villa number"
          :model-value="hqAddress.line1"
          readonly="readonly"
        />
      </div>
    </div>
    <div class="row"></div>
    <div class="row">
      <div class="col-12 col-xl-4">
        <label class="form-label"
          >Date of invoice with bag payment included</label
        >
        <div class="form-group">
          <flat-pickr
            v-model="refundRequest.meta.invoice_date"
            class="form-control"
            placeholder="Select date of invoice with bag payment included"
          ></flat-pickr>
          <error-display :errors="v$.refundRequest.meta.invoice_date.$errors" />
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label class="form-label">Refund date</label>
        <div class="form-group">
          <flat-pickr
            v-model="refundRequest.meta.refund.date"
            class="form-control"
            placeholder="Select refund date"
          ></flat-pickr>
          <error-display :errors="v$.refundRequest.meta.refund.date.$errors" />
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label>Payment source</label>
        <div class="form-group">
          <select
            id="refund-request-payment-source"
            v-model="refundRequest.payment_source"
            class="form-control"
          ></select>
          <error-display :errors="v$.refundRequest.payment_source.$errors" />
        </div>
      </div>
      <div class="col-12">
        <argon-textarea
          id="refund-request-notes"
          placeholder=""
          :model-value="refundRequest.notes"
          @update:model-value="refundRequest.notes = $event"
          >Notes
        </argon-textarea>
      </div>
    </div>
    <div :class="footerClass">
      <button
        v-if="showCloseButton"
        type="button"
        class="btn btn-secondary"
        @click="$emit('close')"
      >
        Close
      </button>
      <button
        v-if="refundRequest.status == 'Pending'"
        type="button"
        class="btn bg-gradient-success"
        @click="saveRefundRequest"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import flatPickr from "vue-flatpickr-component";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { validatorMessages, helpers } from "@/lib/validators";
import { required, numeric, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ApiInvoiceRefundRequests from "@/services/apiInvoiceRefundRequests";
import apiAddresses from "@/services/apiAddresses";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import apiMiscList from "@/services/apiMiscList";
export default {
  name: "RefundRequestForm",
  components: {
    ErrorDisplay,
    flatPickr,
    ArgonTextarea,
    ArgonInput,
  },
  props: {
    footerClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    invoicePaymentSource: {
      type: String,
      default: "",
    },
    invoiceId: {
      type: [String, Number],
      required: true,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saved", "close"],
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      refundRequest: this.getDefaultRefundRequest(),
      refundRequestFormSubmitted: false,
      paymentSourceChoices: [],
      typeChoices: [],
      mediumChoices: [],
      bankChoices: [],
      cities: [],
      areas: [],
      hqAddress: {
        cityId: 1,
        areaId: 155,
        line1: "Kcal HQ, Floor 15, Tower X2, Cluster X",
      },
    };
  },
  computed: {
    requiresBankDetails() {
      return this.refundRequest.meta.refund.medium == "Bank deposit refund";
    },
    requiresAddressDetails() {
      return (
        this.refundRequest.meta.refund.medium ==
        "Driver handover refund (NOT ADVISABLE)"
      );
    },
    requiresKcalHqDetails() {
      return this.refundRequest.meta.refund.medium == "Kca HQ Reception";
    },

    requiresCancellationCharge() {
      return this.refundRequest.type === "Invoice";
    },
  },
  async mounted() {
    this.setRefundRequest();
    await this.setMediumChoices();
    await this.initMediumChoices();
    await this.setPaymentSourceChoices();
    await this.initPaymentSourceChoices(this.refundRequest.meta.refund.medium);
    await this.setTypeChoices();
    await this.initTypeChoices();
    await this.setBankChoices();
    await this.initBankChoices();
    await this.setCities();
    await this.initCityChoices();
    await this.setAreas();
    await this.initAreaChoices(this.refundRequest.meta.address.city_id);
  },
  methods: {
    getDefaultRefundRequest() {
      return {
        status: "Pending",
        type: "",
        payment_source: "",
        cancellation_charge_percent: "",
        meta: this.getDefaultMeta(),
      };
    },
    getDefaultMeta() {
      return {
        bank: {
          name: "",
          account: {
            name: "",
            number: "",
          },
        },
        refund: {
          medium: "",
          date: "",
        },
        invoice_date: "",
        address: {
          city_id: "",
          area_id: "",
          line1: "",
        },
      };
    },
    setRefundRequest() {
      if (this.initialData.id) {
        this.refundRequest = this.initialData;
      }
      if (!this.refundRequest.meta) {
        this.refundRequest.meta = this.getDefaultMeta();
      }
    },
    areaName(areaId) {
      const area = this.areas.find((el) => el.id == areaId);
      if (area) {
        return area.name;
      }
      return areaId;
    },
    cityName(cityId) {
      const city = this.cities.find((el) => el.id == cityId);
      if (city) {
        return city.name;
      }
      return cityId;
    },
    async saveRefundRequest() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }
      this.v$.$reset();
      this.refundRequestFormSubmitted = true;
      if (this.refundRequest.type == "Bag deposit") {
        this.refundRequest.cancellation_charge_percent = 0;
      }
      let response = { status: 0 };
      if (this.refundRequest.id > 0) {
        response = await ApiInvoiceRefundRequests.update(
          this.invoiceId,
          this.refundRequest.id,
          this.refundRequest
        ).catch(handleError);
      } else {
        response = await ApiInvoiceRefundRequests.create(
          this.invoiceId,
          this.refundRequest
        ).catch(handleError);
      }
      if (response.status == 200) {
        showMessage("Refund request saved successfully", "success");
        this.$emit("saved");
        this.$emit("close");
      } else {
        showMessage(response.message, "error");
      }
      this.refundRequestFormSubmitted = false;
    },
    async setPaymentSourceChoices() {
      this.paymentSourceChoices = [];
      const response = await apiMiscList
        .manualPaymentSources()
        .catch(handleError);
      if (response.status == 200) {
        this.paymentSourceChoices = formatDataToChoicesJs(
          response.data.data,
          [],
          { id: "value", value: "value", label: "value" }
        );
        this.paymentSourceChoices = this.paymentSourceChoices.filter(
          (item) => item.value !== "Wallet"
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    getPaymentSourceChoices(medium) {
      let choices = this.paymentSourceChoices;
      if (!medium) {
        return choices;
      }
      if (medium == "Online refund") {
        choices = [];
        if (
          this.invoicePaymentSource &&
          [
            "Payfort",
            "Payfort Apple Pay",
            "Postpay",
            "Tabby 4Weeks",
            "Tabby 8Weeks",
            "Tabby 12Weeks",
          ].some((val) => val == this.invoicePaymentSource)
        ) {
          choices = [
            {
              id: this.paymentSourceChoices.length,
              value: this.invoicePaymentSource,
              label: this.invoicePaymentSource,
            },
          ];
        }
      }
      if (medium == "Wallet") {
        choices = [
          {
            id: this.paymentSourceChoices.length,
            value: "Wallet",
            label: "Wallet",
          },
        ];
      }
      if (
        !choices.some(
          (source) => source.value === this.refundRequest.payment_source
        )
      ) {
        this.refundRequest.payment_source = "";
      }
      if (choices.length == 1) {
        this.refundRequest.payment_source = choices[0]["value"];
      }
      return choices;
    },
    async initPaymentSourceChoices(medium) {
      const id = "refund-request-payment-source";
      let choices = this.getPaymentSourceChoices(medium);

      await initChoices(
        id,
        {
          choices: choices,
          placeholder: true,
          placeholderValue: "",
          duplicateItemsAllowed: false,
          searchEnabled: false,
        },
        this.refundRequest.payment_source
      );
    },
    async setTypeChoices() {
      this.typeChoices = [];
      const response = await apiMiscList
        .invoiceRefundRequestType()
        .catch(handleError);
      if (response.status == 200) {
        this.typeChoices = formatDataToChoicesJs(response.data.data, [], {
          value: "value",
          label: "value",
          id: "value",
        });
      } else {
        showMessage(response.message, "error");
      }
    },
    async initTypeChoices() {
      const id = "refund-request-type";
      await initChoices(
        id,
        {
          choices: this.typeChoices,
          placeholder: true,
          placeholderValue: "",
          duplicateItemsAllowed: false,
          searchEnabled: false,
        },
        this.refundRequest.type
      );
    },
    async setBankChoices() {
      this.bankChoices = formatDataToChoicesJs(apiMiscList.banks());
    },
    async initBankChoices() {
      const id = "refund-request-bank";
      await initChoices(
        id,
        {
          choices: this.bankChoices,
          placeholder: true,
          placeholderValue: "",
          duplicateItemsAllowed: false,
        },
        this.refundRequest.meta.bank.name
      );
    },
    async setMediumChoices() {
      this.refundMediumChoices = formatDataToChoicesJs(
        apiMiscList.refundMediums()
      );
    },
    async initMediumChoices() {
      const id = "refund-request-medium";
      await initChoices(
        id,
        {
          choices: this.refundMediumChoices,
          placeholder: true,
          placeholderValue: "",
          duplicateItemsAllowed: false,
        },
        this.refundRequest.meta.refund.medium
      );
      document.getElementById(id).addEventListener("addItem", async (event) => {
        await this.initPaymentSourceChoices(event.detail.value);
      });
      document.getElementById(id).addEventListener("removeItem", async () => {
        await this.initPaymentSourceChoices();
      });
    },
    async setCities() {
      this.cities = await apiAddresses.getCities("start=0&length=-1");
    },
    async initCityChoices() {
      const id = "refund-request-address-city";
      await initChoices(
        id,
        {
          choices: formatDataToChoicesJs(this.cities),
          placeholder: true,
          placeholderValue: "",
          duplicateItemsAllowed: false,
          searchEnabled: false,
        },
        this.refundRequest.meta.address.city_id
      );
      document.getElementById(id).addEventListener("addItem", async (event) => {
        await this.initAreaChoices(event.detail.value);
      });
      document.getElementById(id).addEventListener("removeItem", async () => {
        await this.initAreaChoices();
      });
    },
    async setAreas() {
      this.areas = await apiAddresses.getAreas("start=0&length=-1");
    },
    async initAreaChoices(city_id) {
      const id = "refund-request-address-area";
      let selected = this.refundRequest.meta.address.area_id;
      let addressAreasChoices = [];
      if (city_id > 0) {
        addressAreasChoices = formatDataToChoicesJs(
          this.areas.filter(function (el) {
            return el.city_id == city_id;
          }),
          "no empty option"
        );
      }
      if (addressAreasChoices.length === 0) {
        addressAreasChoices = [
          {
            value: "",
            label: "Select city",
            id: "",
          },
        ];
        selected = "";
      }
      await initChoices(
        id,
        {
          choices: addressAreasChoices,
          searchEnabled: true,
        },
        selected
      );
    },
  },

  validations() {
    return {
      refundRequest: {
        type: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        payment_source: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        meta: {
          bank: {
            name: {
              required: helpers.withMessage(
                validatorMessages.required,
                requiredIf(() => this.requiresBankDetails)
              ),
            },
            account: {
              name: {
                required: helpers.withMessage(
                  validatorMessages.required,
                  requiredIf(() => this.requiresBankDetails)
                ),
              },
              number: {
                required: helpers.withMessage(
                  validatorMessages.required,
                  requiredIf(() => this.requiresBankDetails)
                ),
              },
            },
          },
          refund: {
            date: {
              required: helpers.withMessage(
                validatorMessages.required,
                required
              ),
            },
            medium: {
              required: helpers.withMessage(
                validatorMessages.required,
                required
              ),
            },
          },
          invoice_date: {
            required: helpers.withMessage(validatorMessages.required, required),
          },
          address: {
            city_id: {
              required: helpers.withMessage(
                validatorMessages.required,
                requiredIf(() => this.requiresAddressDetails)
              ),
            },
            area_id: {
              required: helpers.withMessage(
                validatorMessages.required,
                requiredIf(() => this.requiresAddressDetails)
              ),
            },
            line1: {
              required: helpers.withMessage(
                validatorMessages.required,
                requiredIf(() => this.requiresAddressDetails)
              ),
            },
          },
        },

        cancellation_charge_percent: {
          required: helpers.withMessage(
            validatorMessages.required,
            requiredIf(() => this.requiresCancellationCharge)
          ),
          numeric: helpers.withMessage(validatorMessages.numeric, numeric),
        },
      },
    };
  },
};
</script>
