import { httpApi } from "@/services/httpApi";
import {buildQueryParams} from "@/lib/queryHelper";

class apiPlanAutoAmendments {
  async index(options) {
    let baseUrl = `api/v1/auto-amendments`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  async create(data) {
    return await httpApi.post("api/v1/auto-amendments", data);
  }
}

export default new apiPlanAutoAmendments();