<template>
  <div class="card">
    <div class="card-header pb-0 p-3">
      <h6 class="mb-0">{{ title }}</h6>
    </div>
    <div class="card-body p-3">
      <draggable
        v-if="ingredients.length > 0"
        v-model="ingredients"
        item-key="sequence"
        @end="updateIngredientSequence"
      >
        <template #item="{ element, index }">
          <div
            :id="'row' + index"
            class="row bg-gray-100 border-radius-lg p-2 m-2 cursor-move"
          >
            <div class="col-md-auto col-12 d-flex">
              <label class="text-sm my-auto cursor-move"
                ><span class="d-md-none">Sequence</span>
                {{ element.sequence }}</label
              >
            </div>
            <div class="col-md col-12 my-auto">
              <div class="row">
                <div class="col-12 col-lg">
                  <label>Name</label>
                  <select
                    :id="getIngredientId(index)"
                    v-model="element.ingredient_id"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-md col-12">
                  <label>Remarks</label>
                  <input
                    v-model="element.remark"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="col-md col-12">
                  <label class="">Used for</label>
                  <input
                    v-model="element.used_for"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-auto d-flex">
              <div class="row my-auto">
                <div class="col-auto">
                  <label>Is public</label>
                  <div class="d-flex">
                    <label
                      class="form-check-label mb-0 me-2"
                      :for="'ingredient-is-public-switch' + index"
                    >
                      Yes
                    </label>
                    <argon-switch
                      :id="'ingredient-is-public-switch' + index"
                      v-model:checked="element.is_public"
                      name="element_is_public[]"
                    />
                  </div>
                </div>
                <div class="col-auto">
                  <label>Is removable</label>
                  <div class="d-flex">
                    <label
                      class="form-check-label mb-0 me-2"
                      :for="'ingredient-is-removable-switch' + index"
                    >
                      Yes
                    </label>
                    <argon-switch
                      :id="'ingredient-removable-switch' + index"
                      v-model:checked="element.removable"
                      name="element_removable[]"
                    />
                  </div>
                </div>
                <div class="col d-flex">
                  <span
                    v-if="ingredients.length > minIngredientsRequired"
                    class="text-danger text-gradient ms-2 d-inline-block cursor-pointer my-auto"
                    @click="deleteRecipeIngredient(index)"
                  >
                    <i class="far fa-trash-alt me-2" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="footer pt-0 p-3">
      <div class="d-flex align-items-center">
        <div class="text-end ms-auto">
          <argon-button
            class="mb-0"
            color="success"
            variant="gradient"
            size="sm"
            @click="addRecipeIngredient"
            >{{ buttonText }}
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import apiIngredients from "@/services/apiIngredients";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
export default {
  name: "IngredientsCard",
  components: {
    ArgonSwitch,
    ArgonButton,
    draggable,
  },
  props: {
    title: {
      type: String,
      default: "Ingredients",
    },
    buttonText: {
      type: String,
      default: "Add ingredient",
    },
    initialIngredients: { type: Array, required: true },
    allergen: {
      type: Boolean,
      default: false,
    },
    cardId: {
      type: Number,
      default: 1,
    },
    minIngredientsRequired: {
      type: Number,
      default: 1,
    },
  },
  emits: ["change"],
  data() {
    return {
      ingredients: [],
      recipeIngredientsChoices: [],
      searchTimeOut: null,
    };
  },
  async mounted() {
    this.ingredients = this.initialIngredients;
    this.recipeIngredientsChoices = await this.getIngredients();
    this.setRecipeIngredientsChoices();
  },
  methods: {
    getIngredientId(index) {
      return "ingredient-name-" + this.cardId + "-" + index;
    },
    async getIngredients(search_value) {
      let params = "start=0&length=10";
      if (search_value) {
        params =
          "draw=10&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true" +
          "&columns[0][search][value]=&columns[0][search][regex]=false" +
          "&columns[1][data]=name&columns[1][name]=name&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=" +
          "&columns[1][search][regex]=false&start=0&length=10&search[value]=" +
          search_value;
      }
      if (this.allergen) {
        params += "&allergen=1";
      }
      const response = await apiIngredients.get(params).catch(handleError);
      if (response.status === 200) {
        return response.data.data;
      } else {
        showMessage(response.message, "error");
        return [];
      }
    },
    async addRecipeIngredient() {
      this.ingredients.push({
        ingredient_id: "",
        "ingredient.name": "",
        is_public: false,
        recipe: "",
        recipe_id: this.$route.params.id,
        remark: "",
        removable: false,
        sequence: this.ingredients.length + 1,
        used_for: "",
      });
      this.$emit("change", this.ingredients);
      this.setRecipeIngredientsChoices();
    },
    async deleteRecipeIngredient(x) {
      this.ingredients.splice(x, 1);
      this.$emit("change", this.ingredients);
    },
    async setRecipeIngredientsChoices() {
      await this.$nextTick();
      for (let i in this.ingredients) {
        let choices = this.recipeIngredientsChoices;
        let id = this.getIngredientId(i);
        let el = document.getElementById(id);
        if (
          this.ingredients[i].ingredient_id > 0 &&
          !this.recipeIngredientsChoices.find((el) => {
            el.id == this.ingredients[i].ingredient_id;
          })
        ) {
          choices = [
            {
              id: this.ingredients[i].ingredient_id,
              name: this.ingredients[i]["ingredient.name"],
            },
          ];
        }
        await initChoices(
          id,
          {
            choices: formatDataToChoicesJs(choices),
            removeItems: false,
            removeItemButton: false,
            searchFloor: 1,
          },
          this.ingredients[i].ingredient_id
        );
        el.addEventListener("addItem", this.handleAddIngredient, false);
        el.addEventListener("search", this.handleSearchIngredient, false);
      }
    },
    async updateIngredientSequence() {
      for (let i in this.ingredients) {
        this.ingredients[i]["sequence"] = i * 1 + 1;
      }
      this.$emit("change", this.ingredients);
      await this.setRecipeIngredientsChoices();
    },
    async handleAddIngredient(event) {
      await this.$nextTick();
      let index = this.ingredients.findIndex(
        (el) => el.ingredient_id == event.detail.value
      );
      if (index > -1) {
        this.ingredients[index]["ingredient.name"] = event.detail.label;
      }
    },
    async handleSearchIngredient(event) {
      clearTimeout(this.searchTimeOut);
      this.searchTimeOut = setTimeout(async () => {
        initChoices(event.target.id, {
          choices: formatDataToChoicesJs(
            await this.getIngredients(event.detail.value)
          ),
        });
      }, 500);
    },
  },
};
</script>
