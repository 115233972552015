<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    :show-close-button="showCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <h6>{{ title }}</h6>
    </template>
    <div class="row">
      <div class="col-12 mt-2">
        <label class="form-label">Number of Referral Days</label>
        <div class="form-group">
          <select v-model="form.number_of_days" class="form-control">
            <option v-for="number in 14" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
          <error-display :errors="v$.form.number_of_days.$errors" />
        </div>
      </div>
    </div>
    <template #footer>
      <button
        type="button"
        class="btn btn-sm btn-secondary mb-0"
        @click="$emit('close')"
      >
        Close
      </button>
      <submit-form-button
        :form-submitted="formSubmitted"
        :default-button-text="buttonText"
        @click="handleClick"
      />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import { validatorMessages, helpers } from "@/lib/validators";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import apiClientPlanDetails from "@/services/apiClientPlanDetails";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "CompensatoryDaysModal",
  components: {
    Modal,
    ErrorDisplay,
    SubmitFormButton,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    clientPlanDetailId: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: "Add Referral Days",
    },
    buttonText: {
      type: String,
      default: "Save",
    },
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change", "close"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formSubmitted: false,
      form: { number_of_days: "" },
    };
  },
  methods: {
    async handleClick() {
      const isValid = await this.v$.$validate();
      if (!isValid || this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const data = {
        number_of_days: this.form.number_of_days,
        _method: "patch",
      };
      const response = await apiClientPlanDetails
        .addReferralDays(this.clientId, this.clientPlanDetailId, data)
        .catch(handleError);
      if (response.status == 200) {
        showMessage("Referral days added successfully", "success");
        this.$emit("close");
        this.$emit("change");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
  validations() {
    return {
      form: {
        number_of_days: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>
