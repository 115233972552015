<template>
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-6 col-sm-auto">
        <label class="form-label">Start Date</label>
        <flat-pickr
          v-model="filter.start_date"
          class="form-control"
          placeholder="Please select start date"
          :config="localConfigs.start_date"
          @on-change="onstart_dateChange"
        ></flat-pickr>
      </div>
      <div class="col-6 col-sm-auto">
        <label class="form-label">End Date</label>
        <flat-pickr
          v-model="filter.end_date"
          class="form-control"
          placeholder="Please select end date"
          :config="localConfigs.end_date"
          @on-change="onend_dateChange"
        ></flat-pickr>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <button
          v-if="showFilterButton"
          class="btn btn-success text-white btn-sm mb-0"
          @click="emitFilterEvent"
        >
          Filter
        </button>
        <button
          class="btn btn-success text-white btn-sm mb-0"
          :class="showFilterButton ? 'ms-3' : ''"
          @click="emitDownloadEvent"
        >
          Download Excel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "ReportsFilter",
  components: {
    flatPickr,
  },
  props: {
    initialStartDate: {
      type: String,
      default: null,
    },
    initialEndDate: {
      type: String,
      default: null,
    },
    configs: {
      type: Object,
      default: () => ({}),
    },
    showFilterButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["start_dateChange", "end_dateChange", "download", "filter"],
  data() {
    return {
      filter: {
        start_date: "",
        end_date: "",
      },
      localConfigs: {
        start_date: Object.assign({}, this.configs.start_date),
        end_date: Object.assign({}, this.configs.end_date),
      },
    };
  },
  mounted() {
    this.filter = {
      start_date: this.initialStartDate,
      end_date: this.initialEndDate,
    };
  },
  methods: {
    onstart_dateChange(selectedDates, dateStr) {
      this.localConfigs.end_date.minDate = dateStr;
      this.$emit("start_dateChange", dateStr);
    },
    onend_dateChange(selectedDates, dateStr) {
      this.localConfigs.start_date.maxDate = dateStr;
      this.$emit("end_dateChange", dateStr);
    },
    emitDownloadEvent() {
      this.$emit("download", this.filter);
    },
    emitFilterEvent() {
      this.$emit("filter", this.filter);
    },
  },
};
</script>
