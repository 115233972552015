<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <operations-filter
            class="card-header"
            :initial-date="dateFormat('yesterday', 'YYYY-MM-DD')"
            :form-submitted="formSubmitted"
            :show-type-filter="false"
            :show-download-button="false"
            :show-email-button="true"
            @email="email"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OperationsFilter from "./components/OperationsFilter.vue";
import { dateFormat } from "@/lib/dateHelper";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";
import apiTDO from "@/services/apiTDO";
export default {
  name: "FoodLabels",
  components: {
    OperationsFilter,
  },
  data() {
    return {
      formSubmitted: false,
    };
  },
  methods: {
    dateFormat,
    async email(filter) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const requestData = { date: filter.date };
      let response = await apiTDO.foodLabel(requestData).catch(handleError);
      if (response.status === 200) {
        showMessage(response.data.message, "success");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
